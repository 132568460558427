/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { observer } from 'mobx-react';
import ReactDOM from 'react-dom';
import { RALogo } from './radio-ambulante-logo';
import { PoweredByLogo } from './powered-by-logo';
import version from '../../../lib/macros/version.macro';
import { useRootStore } from 'mst';

const FooterPortal = ({ children }) => {
  return ReactDOM.createPortal(children, document.getElementById('footer'));
};

export const GlobalFooter = observer(() => {
  const store = useRootStore();
  const apiEnv =
    store?.globalConfig?.apiEnv === 'prod' ? '' : store?.globalConfig?.apiEnv;
  return (
    <FooterPortal>
      <div className="version">
        {version} {apiEnv}
      </div>
      <div className="site-footer">
        <div className="site-footer__content">
          <div className="site-footer__links">
            <div className="site-footer__links__set">
              <h3 className="site-footer__links__heading">Help and contact</h3>
              <ul className="site-footer__links__list">
                <li className="site-footer__links__item">
                  <a
                    href="https://intercom.help/jiveworld"
                    className="site-footer__links__link"
                    target="_blank"
                  >
                    Help center
                  </a>
                </li>
                <li className="site-footer__links__item">
                  <a
                    href="https://lupa.app/contact-us"
                    className="site-footer__links__link"
                  >
                    Contact and support
                  </a>
                </li>
              </ul>
            </div>
            <div className="site-footer__links__set">
              <h3 className="site-footer__links__heading">Follow Lupa</h3>

              <ul className="site-footer__links__list">
                <li className="site-footer__links__item">
                  <a
                    href="https://twitter.com/LupaApp"
                    className="site-footer__links__link"
                    target="_blank"
                  >
                    <svg
                      className="site-footer__links__icon"
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path d="M0 0h18v18H0z"></path>
                        <path
                          fill="#FFF"
                          d="M15.941 4.459a3.437 3.437 0 001.526-1.89 7.005 7.005 0 01-2.204.83 3.487 3.487 0 00-2.532-1.08c-1.916 0-3.469 1.53-3.469 3.416 0 .268.03.528.089.779A9.903 9.903 0 012.2 2.944a3.364 3.364 0 00-.47 1.718c0 1.184.613 2.23 1.543 2.843A3.506 3.506 0 011.7 7.077v.043c0 .437.086.854.238 1.238a3.464 3.464 0 002.546 2.113 3.475 3.475 0 01-1.568.058A3.469 3.469 0 006.16 12.9a7.029 7.029 0 01-4.31 1.463 7.08 7.08 0 01-.828-.048A9.944 9.944 0 006.34 15.85c5.424 0 8.757-3.758 9.637-7.657a9.376 9.376 0 00.227-2.503 6.976 6.976 0 001.73-1.768 7.004 7.004 0 01-1.993.537z"
                        ></path>
                      </g>
                    </svg>
                    Twitter
                  </a>
                </li>
                <li className="site-footer__links__item">
                  <a
                    href="https://www.instagram.com/lupa.app/"
                    className="site-footer__links__link"
                    target="_blank"
                  >
                    <svg
                      className="site-footer__links__icon"
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path d="M0 0h18v18H0z"></path>
                        <path
                          fill="#FFF"
                          d="M8.014 0h1.972c1.568.004 1.912.017 2.725.054.958.044 1.612.196 2.184.419.592.23 1.094.537 1.594 1.038.5.5.809 1.002 1.039 1.594.222.572.374 1.226.418 2.184C17.99 6.25 18 6.556 18 9s-.01 2.75-.054 3.71c-.044.959-.196 1.613-.418 2.185a4.412 4.412 0 01-1.039 1.594c-.5.5-1.002.809-1.594 1.039-.572.222-1.226.374-2.184.418-.813.037-1.157.05-2.725.053H8.014c-1.568-.003-1.912-.016-2.725-.053-.958-.044-1.612-.196-2.184-.418a4.412 4.412 0 01-1.594-1.039c-.5-.5-.809-1.002-1.038-1.594-.223-.572-.375-1.226-.419-2.184-.037-.813-.05-1.157-.053-2.725V8.014c.003-1.568.016-1.912.053-2.725.044-.958.196-1.612.419-2.184A4.411 4.411 0 011.51 1.51c.5-.5 1.002-.809 1.594-1.038C3.677.25 4.33.098 5.289.054 6.102.017 6.446.004 8.014.001h1.972zm1.524 1.622H8.463c-1.916.001-2.227.012-3.1.052-.877.04-1.354.187-1.67.31a2.79 2.79 0 00-1.036.673c-.315.315-.51.615-.673 1.035-.123.317-.27.794-.31 1.671-.04.873-.05 1.184-.052 3.1v1.075c.001 1.915.012 2.226.052 3.099.04.877.187 1.354.31 1.67.163.421.358.72.673 1.036.315.315.615.51 1.035.673.317.123.794.27 1.671.31.873.04 1.184.05 3.1.052h1.075c1.915-.001 2.226-.012 3.099-.052.877-.04 1.354-.187 1.67-.31a2.79 2.79 0 001.036-.673c.315-.315.51-.615.673-1.035.123-.317.27-.794.31-1.671.04-.873.05-1.184.052-3.1V8.464c-.001-1.916-.012-2.227-.052-3.1-.04-.877-.187-1.354-.31-1.67a2.788 2.788 0 00-.673-1.036 2.788 2.788 0 00-1.035-.673c-.317-.123-.794-.27-1.671-.31-.873-.04-1.184-.05-3.1-.052zM9 4.378a4.622 4.622 0 110 9.244 4.622 4.622 0 010-9.244zM9 6a3 3 0 100 6 3 3 0 000-6zm4.804-2.884a1.08 1.08 0 110 2.16 1.08 1.08 0 010-2.16z"
                        ></path>
                      </g>
                    </svg>
                    Instagram
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <ul className="site-footer__legal">
            <li className="site-footer__legal__item">
              <a
                className="site-footer__legal__link"
                href="https://www.jiveworld.com/terms-of-service"
              >
                Terms of service
              </a>
            </li>
            <li className="site-footer__legal__item">
              <a
                className="site-footer__legal__link"
                href="https://www.jiveworld.com/privacy"
              >
                Privacy
              </a>
            </li>
            <li className="site-footer__legal__item">
              <a
                className="site-footer__legal__link"
                href="https://www.jiveworld.com/eula"
              >
                EULA
              </a>
            </li>
            {process.env.NODE_ENV !== 'production' ? (
              <li className="site-footer__legal__item">
                <a className="site-footer__legal__link" href="/_showcase">
                  Components
                </a>
              </li>
            ) : null}
          </ul>
          <div className="site-footer__logos">
            <a
              className="site-footer__logos__link"
              href="https://radioambulante.org/"
              target="_blank"
            >
              <RALogo className="site-footer__logos__logo" />
            </a>
            <a
              className="site-footer__logos__link"
              href="https://www.jiveworld.com/"
              target="_blank"
            >
              <PoweredByLogo className="site-footer__logos__logo" />
            </a>
          </div>
        </div>
      </div>
    </FooterPortal>
  );
});
