import React from 'react';
import { Text } from 'components/ds/common/text';
import { getColor, getDimension } from 'lib/theme/utils';
import { useStickyness } from 'lib/hooks/use-stickyness';
import styled from 'styled-components';

const Container = styled.div`
  & > .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &.with-rule {
    padding-bottom: 8px;
    border-bottom: 1px solid ${getColor('gray-1')};
  }

  &.with-margin {
    margin-bottom: 16px;
  }

  &.without-margin {
    &.with-rule {
      margin-bottom: -1px;
    }
  }

  @media screen and (min-width: 551px) {
    &.is-sticky {
      position: sticky;
      left: 0;
      top: 68px;
      right: 0;
      background: ${getColor('off-white')};
      padding: 8px 0;
      z-index: 1900;
      border-bottom: 1px solid ${getColor('gray-1')};

      & > .content {
        max-width: ${getDimension('max-screen-width-without-padding')}px;
        margin: 0 auto;
      }

      .header-back-link {
        display: none;
      }

      @media screen and (min-width: 768px) {
        top: 80px;
      }
    }
  }

  @media screen and (max-width: 550px) {
    &.is-sticky {
      position: sticky;
      left: 0;
      top: 0;
      right: 0;
      background: ${getColor('off-white')};
      padding: 8px 0;
      z-index: 1900;
      border-bottom: 1px solid ${getColor('gray-1')};

      & > .content {
        max-width: ${getDimension('max-screen-width-without-padding')}px;
        margin: 0 auto;
      }

      .header-back-link {
        display: none;
      }
    }

    & > .content {
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
    }

    .heading-control {
      margin-top: 16px;
    }
  }
`;

export const Heading = ({
  withRule = true,
  withMargin = true,
  control,
  children,
  makeSticky = false,
  // TODO: maybe add stickyOffset prop,
  // now it just assumes there's a top header
  // so it's hardcoded to 80px
}) => {
  const { isSticky, Tracker } = useStickyness(makeSticky, { threshold: -80 });
  return (
    <>
      <Tracker />
      <Container
        className={`${withRule ? 'with-rule' : 'without-rule'} ${
          withMargin ? 'with-margin' : 'without-margin'
        } ${isSticky ? 'is-sticky' : ''}`}
      >
        <div className="content">
          <Text textStyle={'medium-heading'}>{children}</Text>
          <div className="heading-control">{control}</div>
        </div>
      </Container>
    </>
  );
};
