import React from 'react';
import { MenuItem, ConfirmableMenuItem } from 'components/ds/menus';
import { useRenameClassroomPrompt } from '../classroom-hooks';

import __ from 'jw-core/lib/localization';

export const RenameClassMenuItem = ({ classroom, afterAction }) => {
  const [Prompt, promptActions] = useRenameClassroomPrompt(classroom, {
    afterAction,
  });

  const itemAction = () => {
    promptActions.on();
    // this is to prevent the menu to dismount automatically and close the dialog prematurely
    return false;
  };

  return (
    <>
      <MenuItem
        action={itemAction}
        label={__('Rename class', 'classroom.renameClass')}
        testId="rename-class"
      />
      <Prompt />
    </>
  );
};

export const useClassroomListActions = () => {
  const getMenuItems = classroom => ({ afterAction }) => {
    return (
      <>
        <RenameClassMenuItem classroom={classroom} afterAction={afterAction} />
        <ConfirmableMenuItem
          label={__('Delete class', 'classroom.archiveClass')}
          testId={'delete-class'}
          isDestructive={true}
          action={classroom.archive}
          confirmationTitle={__(
            'Delete class "%{label}"?',
            'common.confirmClassDeletionTitle',
            {
              label: classroom.label,
            }
          )}
          confirmationBody={__(
            'Students in this class will no longer see this class or its assignments in the Lupa app. Deleting the class will not change any full access to Lupa they have on their accounts. This action cannot be undone.',
            'common.confirmClassDeletionBody'
          )}
          confirmationOkButtonLabel={__('Yes, delete', 'classroom.yesDelete')}
          confirmationCancelButtonLabel={__('No, keep', 'classroom.noKeep')}
        ></ConfirmableMenuItem>
      </>
    );
  };

  return { getMenuItems };
};
