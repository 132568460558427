import React, { useRef, useState } from 'react';
import dayjs from 'dayjs';
import cx from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { useSwitch } from 'lib/hooks/use-switch';
import { useOnClickOutside } from 'lib/hooks/use-onclick-outside';
import { observer } from 'mobx-react';
import { IconDateS, IconArrowRightS } from 'components/ds/icons';
import { IconControlWrapper } from './icon-control-wrapper';
import { getColor, includeTextStyles } from 'lib/theme/utils';

// I hate including external stylesheets, but this is a necessary evil
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import { Calendar } from 'react-modern-calendar-datepicker';

import __ from 'jw-core/lib/localization';

import styled from 'styled-components';

const DateControlWrapper = styled.div`
  position: relative;

  @media screen and (max-width: 550px) {
    position: static;
  }

  .calendar-positioner {
    z-index: 1000;

    & > .calendar-inner-wrapper {
      background: white;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
      border-radius: 8px;
    }

    @media screen and (min-width: 551px) {
      position: absolute;
      z-index: 1000;
    }

    @media screen and (max-width: 550px) {
      display: flex;
      flex-direction: column;
      width: 100%;
      position: absolute;
      left: 0;
      align-items: stretch;
      padding-bottom: 32px; /* This is for the auto-scrolling benefit */
    }

    /* Ovewrite component built-in styles for customization sake */
    .Calendar {
      /* The component uses css custom props, but we need to use important so they don't get overriden */
      --cl-color-primary: ${getColor('teal')} !important;
      --cl-color-primary-light: ${getColor('bright-teal')} !important;
      --animation-duration: 0.4s;
      box-shadow: none;
      margin: 0 auto;
    }

    .buttons-container {
      flex-flow: row-reverse;
      display: flex;
      justify-content: 'space-between';
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      margin: 0 16px;

      & > .dialog-button {
        ${includeTextStyles('body-bold')}
        border: none;
        background: none;

        text-align: left;
        display: flex;
        flex: 1;
        display: inline-block;
        transition: color 0.1s;
        margin: 0 auto;
        padding: 12px 16px;
        cursor: pointer;

        @media screen and (max-width: 550px) {
          align-self: stretch;
        }

        &:active {
          outline: none;
        }

        &.type-ok {
          --button-color: ${getColor('teal')};
          --button-alignment: right;
          color: var(--button-color);
          text-align: var(--button-alignment);
          margin-right: -16px;

          &:hover {
            --button-color: ${getColor('dark-teal')};
          }

          &.is-disabled {
            --button-color: ${getColor('gray-1')};
            &:hover {
              --button-color: ${getColor('gray-1')};
            }
          }
        }

        &.type-cancel {
          --button-color: ${getColor('red')};
          margin-left: -16px;
          color: var(--button-color);

          &.is-disabled {
            --button-color: ${getColor('gray-1')};
            &:hover {
              --button-color: ${getColor('gray-1')};
            }
          }
        }
      }
    }
  }
`;

// What's this? well, The datepicker we are using
// only accepts values in the form of an object with the shape
// {year:2020,day:16,month:6} (month values are zero-index based so we need to compensate )
// the object that dayjs creates already has a weird internal representation
// of this, so we just translate the keynames
const dateToObject = date => {
  if (!date) {
    return null;
  }
  const { $y: year, $D: day, $M: month } = dayjs(date);
  return { year, month: month + 1, day };
};

export const DueDateControl = observer(
  ({ dueDate, onChangeDueDate, onResetDueDate }) => {
    const isMobile = useMediaQuery({
      query: '(max-device-width: 550px)',
    });
    const [showCalendar, { toggle, off }] = useSwitch();
    const [selectedDate, setSelectedDate] = useState(dateToObject(dueDate));
    const modalRef = useRef();
    const btnRef = useRef();
    useOnClickOutside(
      modalRef,
      () => {
        off();
        // reset selected due date
        setSelectedDate(dateToObject(dueDate));
      },
      { exception: btnRef } // otherwise, clicking on the button itself retriggers the action
    );

    const handleOkButton = () => {
      const { day, month, year } = selectedDate;
      const date = [year, month, day].join('-');
      off();
      onChangeDueDate(date);
    };

    const handleClick = e => {
      toggle();
      // only auto-scroll in mobile because it requires more logic to work properly on desktop
      if (isMobile) {
        // the timeout is to execute the scrolling in the next frame, after the toggle made effect
        setTimeout(() => {
          if (modalRef.current) {
            modalRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'end',
            });
          }
        }, 0);
      }
    };

    const handleClearButton = () => {
      setSelectedDate(null);
      off();
      onResetDueDate(null);
    };

    const label = dueDate
      ? __('Due %{date}', 'classroom.dueDateDisplay', {
          date: dayjs(dueDate).format('MMM DD'),
        })
      : __('Set due date', 'classrooms.setDueDate');

    return (
      <DateControlWrapper>
        <IconControlWrapper
          className={cx({ 'has-date': dueDate })}
          onClick={handleClick}
          ref={btnRef}
        >
          {dueDate ? <IconDateS /> : <IconArrowRightS />}
          {label}
        </IconControlWrapper>
        {showCalendar && (
          <div className="calendar-positioner" ref={modalRef}>
            <div className="calendar-inner-wrapper">
              <Calendar onChange={setSelectedDate} value={selectedDate} />
              <div className="buttons-container">
                <button
                  onClick={handleOkButton}
                  className={`
              dialog-button
              type-ok
              ${selectedDate === null ? 'is-disabled' : ''}
            `}
                  data-test-id="okButton"
                >
                  {__('Set due date', 'classrooms.setDueDate')}
                </button>
                <button
                  href={'#'}
                  className={`dialog-button type-cancel ${
                    selectedDate === null ? 'is-disabled' : ''
                  }`}
                  onClick={handleClearButton}
                  data-test-id="cancelButton"
                >
                  {__('Clear', 'common.clear')}
                </button>
              </div>
            </div>
          </div>
        )}
      </DateControlWrapper>
    );
  }
);
