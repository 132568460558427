/* eslint-disable no-console */
import invariant from 'invariant';
import { isString } from 'lodash';
import { sleep } from '../shared/util';
import logger from './log';

const log = logger('downloader');

/**
 * utility mock function
 */
const urlToFile = url => {
  return url.split('/').reverse()[0];
};

// returns a random time between 500 and 2500 milliseconds
const randomTime = () => 500 * Math.floor(Math.random() * 5) + 1;

// randomly fails 1/6 of the time
const dice = (success, fail, chance = 6) => {
  // if (
  //   root.simulateNetworkFailure ||
  //   (chance > 0 && Math.floor(Math.random() * 1e3) % chance === 0)
  // ) {
  //   return fail();
  // }
  success();
};

/**
 * Mocks the download implementation
 */
const createDownloader = () => {
  let timers = {};
  // todo: consider changing interface to take target path and return bytes downloaded
  const download = url => {
    invariant(isString(url), `url must be a string. got ${url} instead.`);
    return new Promise((resolve, reject) => {
      const success = () => {
        console.log('MOCKING DOWNLOAD FOR ' + url);
        timers[url] = setTimeout(() => {
          timers[url] = null;
          console.log('MOCKING RESOLVED DOWNLOAD FOR ' + url);
          resolve(urlToFile(url));
        }, randomTime());
      };

      const fail = async () => {
        console.log('FAIL!');
        await sleep(1000);
        // reject('download failed');
        resolve(undefined); // for now, returning as error status
      };

      dice(success, fail, 10);
    });
  };

  const cancel = async url => {
    if (timers[url]) {
      clearTimeout(timers[url]);
    }
  };

  // beware, hardwired to json response for now
  const open = async asset => {
    const url = asset.sourceUrl;
    log(`opening: ${url}`);
    const response = await window.fetch(url);
    const data = await response.json();
    log(`data size: ${JSON.stringify(data).length}`);
    return data;
  };

  const remove = async path => {
    log(`removing file: ${path}...`);
  };

  const fullPath = tail => {
    return 'file://downloads/' + tail;
  };

  const getAvailableSpaceInMb = async (debugReducedAvailableSpaceInMb = 0) => {
    const megabytes = 64;
    return megabytes - debugReducedAvailableSpaceInMb;
  };

  return {
    download,
    cancel,
    open,
    remove,
    fullPath,
    getAvailableSpaceInMb,
  };
};

export default createDownloader();
