import React, { useRef } from 'react';
import { observer } from 'mobx-react';
import DialogService from '../../lib/services/dialog-service';
import { Modal } from '../ds/modals/modal';
import { AlertDialog } from './alert-dialog';

export const DialogTarget = observer(() => {
  const dialog = DialogService.currentDialog.get();
  const ref = useRef();

  if (dialog === null) {
    return null;
  }

  const onDismiss = DialogService.closeDialog;

  return (
    <Modal
      // onDismiss={onDismiss}
      contentRef={ref}
    >
      <AlertDialog {...dialog} onDismiss={onDismiss} ref={ref} />
    </Modal>
  );
});
