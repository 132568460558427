import { types } from 'mobx-state-tree';
import * as safeTypes from '../lib/safe-mst-types';

/**
 * ListeningLog
 *
 * holds study stats for a given day and story
 */
export const ListeningLog = types.model('ListeningLog', {
  date: safeTypes.stringDefaultBlank,
  storySlug: safeTypes.stringDefaultBlank,
  listenedMillis: safeTypes.numberDefaultZero,
  relistenedMillis: safeTypes.numberDefaultZero,
});
