import {
  flow,
  getEnv,
  getRoot,
  getParent,
  getSnapshot,
  types,
} from 'mobx-state-tree';

// import { getParentOfName } from '../lib/type-utils/get-parent-of-name';
import * as safeTypes from '../lib/safe-mst-types';
import { DiskSpaceError } from '../lib/errors';
import minibus from '../lib/minibus';
import { sleep } from '../lib/util';
import { downloadStatus } from './download-constants';

// provide unscoped local access
const {
  NOT_DOWNLOADED,
  PENDING_DOWNLOAD,
  DOWNLOADING,
  DOWNLOAD_FAILED,
  DOWNLOADED,
} = downloadStatus;

export const assetKeys = {
  BANNER_IMAGE: 'story-image', // better names, perhaps someday rename the property keys
  LIST_IMAGE: 'story-thumbnail',
  FULL_DATA: 'full-data',
  NORMAL_AUDIO_PREFIX: 'normal-audio-',
};

const {
  BANNER_IMAGE,
  LIST_IMAGE,
  FULL_DATA,
  // NORMAL_AUDIO_PREFIX,
} = assetKeys;

const FAILURE_RETRY_LIMIT = 3;
const FAILURE_RETRY_DELAY_MILLIS = 1000;

export const AssetDownload = types
  .model('AssetDownload', {
    key: safeTypes.stringDefaultBlank,
    sourceUrl: safeTypes.stringDefaultBlank,
    path: safeTypes.stringOrNull,
    status: types.optional(types.maybeNull(types.string), NOT_DOWNLOADED),
    failureCount: safeTypes.numberDefaultZero,
    //todo fileSize: types.optional(types.number, 0),
  })
  .views(self => ({
    get $log() {
      const { createLogger = () => {} } = getEnv(self);
      const log = createLogger('dm:asset-download');
      return log;
    },
    get $downloader() {
      const { downloader = {} } = getEnv(self);
      return downloader;
    },
    get $track() {
      const { track = () => {} } = getEnv(self);
      return track;
    },
  }))
  .views(self => ({
    get isCatalogLevel() {
      return [LIST_IMAGE, BANNER_IMAGE].includes(self.key);
    },

    get isFullData() {
      return self.key === FULL_DATA;
    },

    get isAudio() {
      // return ![LIST_IMAGE, BANNER_IMAGE, FULL_DATA].includes(self.key);
      return self.key.startsWith(assetKeys.NORMAL_AUDIO_PREFIX);
    },

    get isPending() {
      return [PENDING_DOWNLOAD, DOWNLOADING].includes(self.status);
    },

    get isDownloaded() {
      return self.status === DOWNLOADED;
    },

    // @armando todo: i'd like to remove this data translation hack and replace with error
    // handling which resets the downloaded state if the local path isn't found where it's expected

    // strips base path from legacy persisted data
    get sanitizedPath() {
      if (self.path) {
        return self.path.substr(self.path.lastIndexOf('/') + 1);
      }
      return self.path;
    },

    get fullPath() {
      return self.$downloader.fullPath(self.sanitizedPath);
    },

    get dump() {
      return JSON.stringify(getSnapshot(self));
    },
  }))
  .actions(self => {
    // JE: I don't trust this. perhaps getting initialized too early?
    // const manager = getRoot(self).downloadManager;

    return {
      download: flow(function* download() {
        // const storyDownload = getParentOfName(self, 'StoryDownload');
        const storyDownload = getParent(self); // beware, may now be a StoryDownload or VolumeDownload

        try {
          self.status = DOWNLOADING;

          const path = yield self.$downloader.download(self.sourceUrl);
          self.$log.debug(`download asset result ${self.sourceUrl} -> ${path}`);
          if (path) {
            self.path = path;

            if (self.status === NOT_DOWNLOADED) {
              self.$log.info(`after cancel straggler - removing: ${path}`);
              yield self.remove();
              return self.status;
            }

            self.status = DOWNLOADED;
            if (storyDownload.status === DOWNLOADED) {
              self.$track('story__download_succeeded', {
                story_slug: storyDownload.slug,
              });
            }

            if (!self.isCatalogLevel) {
              getRoot(self).asyncPersistAll();
            }
          } else {
            self.$log.warn(
              `missing download path - treating as failure - sourceUrl: ${self.sourceUrl}, key: ${self.key}`
            );
            self.incrementFailureCount();
            if (self.failureCount < FAILURE_RETRY_LIMIT) {
              self.$log.info(
                `failure count: ${self.failureCount} - sleeping...`
              );
              yield sleep(FAILURE_RETRY_DELAY_MILLIS);
              self.$log.debug(`retrying...`);
              yield self.download();
            } else {
              self.$log.warn(`failure count: ${self.failureCount} - giving up`);
              self.$track('story__download_failed', {
                story_slug: storyDownload.slug,
              });
              self.status = DOWNLOAD_FAILED;
            }
          }
        } catch (error) {
          // console.log({ error });
          if (error instanceof DiskSpaceError) {
            self.$log.warn(`DiskSpaceError: ${error} - cancelling download`);
            minibus.emit('DISK_IS_FULL');
            storyDownload.cancelDownload();
            getRoot(self).downloadManager.interruptQueue();
          }
          self.status = DOWNLOAD_FAILED;
        }
        return self.status;
      }),

      remove: flow(function* remove() {
        try {
          self.status = NOT_DOWNLOADED;
          self.$log.info(`removeFile(${self.fullPath})`);
          // todo: be smarter about only attempting to remove if successfully downloaded
          yield self.$downloader.remove(self.fullPath);
          self.path = null;
          getRoot(self).asyncPersistAll();
        } catch (error) {
          self.$log.warn(`remove asset failed: ${error}`);
        }
      }),

      // note, this didn't seem to work anymore, but seemed simpler to check right after the asset was downloaded
      /**
       * after a download is cancelled and the other assets are moved, there's usually one last
       * asset which needs to be cleaned up after it finishes downloading.
       */
      // cleanupIfCanceled: flow(function* cleanupIfCanceled() {
      //   if (self.isCatalogLevel) {
      //     return;
      //   }
      //   // @armando, was the 'getParentOfName' behavior important here?
      //   // const storyDownload = getParentOfName(self, 'StoryDownload');
      //   let storyDownload = getParent(self); // beware, may now be a StoryDownload or VolumeDownload
      //   if (Array.isArray(storyDownload)) {
      //     self.$log.warn(
      //       `cleanupIfCancelled - getParent is an array, using first element`
      //     );
      //     // @armando, any idea how we could be getting an array here?
      //     storyDownload = storyDownload[0];
      //   }
      //
      //   self.$log.info(
      //     `cleanupIfCancelled - parent state: ${JSON.stringify(storyDownload)}`
      //   );
      //   if (storyDownload.status === NOT_DOWNLOADED) {
      //     self.$log.info(
      //       `cleanupIfCancelled - removing straggler: ${self.key}`
      //     );
      //     yield self.remove();
      //     self.$log.debug(`after self.remove`);
      //   }
      // }),

      incrementFailureCount() {
        self.failureCount = self.failureCount + 1;
      },
    };
  });
