import React from 'react';
import { Link } from 'react-router-dom';
import { useRootStore } from 'mst';
import { observer } from 'mobx-react';
import { getConfig } from 'config';
import { Nag } from 'components/ds/nags';
import { MarkdownContainer } from 'components/ds/common/markdown-container';

import __ from 'jw-core/lib/localization';

export const AllScreensNag = () => {
  const store = useRootStore();
  const { showClassroomLicenseNag } = store?.userManager?.accountData ?? {};

  if (!showClassroomLicenseNag) {
    return null;
  }

  return (
    <Nag type="red" zIndex={1000}>
      {__(
        'One of your classes has exceeded the license quota.',
        'nags.oneOfYourClasses'
      )}{' '}
      <Link to="/classrooms">
        {__('Go to classes list.', 'nags.goToClassesList')}
      </Link>
    </Nag>
  );
};

export const ClassroomNag = observer(() => {
  return (
    <Nag type="pink" showIcon zIndex={1100}>
      <MarkdownContainer
        source={__(
          'This class has exceeded its license quota. Please [contact sales](mailto:%{email}) to add additional seats, or remove students.',
          'nags.thisClass',
          { email: getConfig('website.emails.educationalSales') }
        )}
      />
    </Nag>
  );
});
