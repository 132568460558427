export const textStyles = {
  'medium-heading': {
    fontFamily: 'larsseit-bold',
    fontWeight: 'normal',
    fontSize: '23px',
    letterSpacing: '-.3px',
    lineHeight: '28px',
    WebkitFontSmoothing: 'antialiased',
  },
  'website--nav': {
    fontFamily: 'larsseit-medium',
    fontWeight: 'normal',
    fontSize: '20px',
    letterSpacing: 0,
    lineHeight: '24px',
    WebkitFontSmoothing: 'antialiased',
  },
  body: {
    fontFamily: 'roboto',
    fontWeight: 300,
    fontSize: '17px',
    letterSpacing: 0,
    lineHeight: '24px',
  },
  // 'body-bold': {
  //   fontFamily: 'roboto',
  //   fontWeight: 400,
  //   fontSize: '17px',
  //   letterSpacing: 0,
  //   lineHeight: '24px',
  // },
  stat: {
    fontFamily: 'larsseit-bold',
    fontWeight: 'normal',
    fontSize: '29px',
    letterSpacing: 0,
    lineHeight: '32px',
    WebkitFontSmoothing: 'antialiased',
  },
  'script--script': {
    fontFamily: 'sourceserifpro-regular',
    fontWeight: 400,
    fontSize: '20px',
    letterSpacing: 0,
    lineHeight: '36px',
  },
  'script--notes': {
    fontFamily: 'sourceserifpro-regular',
    fontWeight: 400,
    fontSize: '19px',
    letterSpacing: -0.20000000298023224,
    lineHeight: '28px',
  },
  code: {
    fontFamily: 'Monaco',
    fontPostScriptName: 'Monaco',
    fontWeight: 400,
    fontSize: '16px',
    letterSpacing: 0,
    lineHeight: '20px',
  },
  'coupon-code': {
    fontFamily: 'Roboto Mono',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '24px',
    WebkitFontSmoothing: 'antialiased',
  },
  'script--chapter-heading': {
    fontFamily: 'Source Serif Pro',
    fontPostScriptName: 'SourceSerifPro-Regular',
    fontWeight: 400,
    fontSize: '24px',
    letterSpacing: 0,
    lineHeight: '32px',
  },
  nav: {
    fontFamily: 'Roboto',
    fontPostScriptName: 'Roboto-Medium',
    fontWeight: 500,
    fontSize: '19px',
    letterSpacing: '-.2px',
    lineHeight: '24px',
    WebkitFontSmoothing: 'antialiased',
  },
  'script--hint': {
    fontFamily: 'Source Serif Pro',
    fontPostScriptName: 'SourceSerifPro-It',
    fontWeight: 400,
    fontSize: '20px',
    letterSpacing: 0,
    lineHeight: '24px',
  },
  'body-bold': {
    fontFamily: 'Roboto',
    fontPostScriptName: 'Roboto-Medium',
    fontWeight: 500,
    fontSize: '17px',
    letterSpacing: 0,
    lineHeight: '24px',
    WebkitFontSmoothing: 'antialiased',
  },
  'small-text': {
    fontFamily: 'Roboto',
    fontWeight: 300,
    fontSize: '15px',
    letterSpacing: 0,
    lineHeight: '20px',
  },
  'small-text-bold': {
    fontFamily: 'Roboto',
    fontPostScriptName: 'Roboto-Medium',
    fontWeight: 500,
    fontSize: '15px',
    letterSpacing: 0,
    lineHeight: '20px',
  },
  'tiny-text': {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '12px',
    letterSpacing: 0,
    lineHeight: '20px',
  },
  'small-heading': {
    fontFamily: 'Roboto',
    fontPostScriptName: 'Roboto-Bold',
    fontWeight: 'normal',
    fontSize: '16px',
    letterSpacing: 0,
    lineHeight: '24px',
  },
  'pricing-card-heading': {
    fontFamily: 'larsseit-bold',
    fontWeight: 'normal',
    fontSize: '27px',
    lineHeight: '32px',
    WebkitFontSmoothing: 'antialiased',
  },
  'pricing-card-price': {
    fontFamily: 'larsseit-bold',
    fontWeight: 'normal',
    fontSize: '40px',
    lineHeight: '44px',
    WebkitFontSmoothing: 'antialiased',
  },
  'pricing-card-cta': {
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '24px',
  },
  'large-heading--mobile': {
    fontFamily: 'larsseit-bold',
    fontWeight: 'normal',
    fontSize: '30px',
    letterSpacing: '-.5px',
    lineHeight: '36px',
    WebkitFontSmoothing: 'antialiased',
  },
  'large-heading--desktop': {
    fontFamily: 'larsseit-bold',
    fontWeight: 'normal',
    fontSize: '37px',
    letterSpacing: '-.5px',
    lineHeight: '48px',
    WebkitFontSmoothing: 'antialiased',
  },
};
