import React from 'react';
import { SuccessIcon } from '../ui/icons/success-icon';

export const AccountClosedScreen = () => {
  return (
    <section className="section section--off-white section--h100">
      <div className="message message--standalone message--white">
        <h2 className="message__heading">
          <SuccessIcon className={'message__heading__icon'} />
          Your account has been closed
        </h2>
        <p className="message__body">
          Thank you for using Lupa. Please take a moment to{' '}
          <a href="mailto:feedback@jiveworld.com">share your feedback</a> – we'd{' '}
          love to know what we could improve.
        </p>
      </div>
    </section>
  );
};
