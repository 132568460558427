import React from 'react';
import { observer } from 'mobx-react';
import { StoryCell } from 'components/stories/story-cell';
import { IconNoteS } from 'components/ds/icons';
import { includeTextStyles, getColor } from 'lib/theme/utils';

import { DueDateControl } from './duedate-control';
import { NoteControl } from './note-control';
import { LessonPlanControl } from './lesson-plan-control';
import { StoryGuideControl } from './story-guide-control';

import __ from 'jw-core/lib/localization';

import styled from 'styled-components';

const Container = styled.div`
  ${includeTextStyles('small-text')};
  color: ${getColor('gray-4')};
  margin-top: 16px;
`;

const AssignmentControls = observer(({ assignment }) => {
  const { story } = assignment;
  return (
    <Container>
      <DueDateControl
        dueDate={assignment.dueDate}
        onChangeDueDate={assignment.setDueDate}
        onResetDueDate={assignment.resetDueDate}
      />
      <NoteControl
        icon={<IconNoteS />}
        label={__('Notes', 'common.notes')}
        note={assignment.details}
        onChangeNote={assignment.setNote}
      />
      <LessonPlanControl url={story.catalogData.lessonPlanUrl} />
      <StoryGuideControl story={story} />
    </Container>
  );
});

export const AssignmentCell = observer(
  ({ assignment, onSelectAssignment, ...props }) => {
    const handleClick = () => onSelectAssignment(assignment);
    return (
      <StoryCell
        story={assignment.story}
        onClickTitle={handleClick}
        onClickThumbnail={handleClick}
        storyDetailExtras={
          <AssignmentControls assignment={assignment} {...props} />
        }
      />
    );
  }
);
