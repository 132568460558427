import React, { useLayoutEffect } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
// import { IconX } from 'components/ds/icons';
import { Gap } from 'components/ds/common/spacers';
import { MarkdownContainer } from 'components/ds/common/markdown-container';
import { getColor } from 'lib/theme/utils';
import { useRootStore } from 'mst';
import { Button } from 'components/ds/buttons';
import __ from 'jw-core/lib/localization';

const message = __(
  `
Welcome to your first Lupa classroom. You're almost ready to teach with Lupa!

1. **Download the app** — Visit get.lupa.app on your mobile device to listen to the stories. Lupa is currently mobile-only.
1. **Assign stories** — We offer two free Lupa stories, so you can get started with your students quickly and without any cost. You can change assignments (tap "Edit"), specify due dates, and add assignment notes.
1. **Send the "Instructions for Students"** to your class (see button below).

If you have any questions or want an evaluation license for the premium stories, chat with us (see below). We'd be delighted to help you with anything.
`,
  'classrooms.onboardingMessage'
);

const Wrapper = styled.div`
  position: relative;
  background: ${getColor('light-yellow')};
  border: 1px solid ${getColor('yellow')};
  border-radius: 8px;
  padding: 16px;
  @media screen and (min-width: 768px) {
    padding: 20px;
  }
  .close {
    position: absolute;
    right: 10px;
    top: 10px;
    opacity: 0.5;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
  .container {
    max-width: 820px;
  }
`;

export const OnboardingMessage = observer(() => {
  const store = useRootStore();
  const { accountData = {}, clearClassroomPortalWelcome } =
    store?.userManager ?? {};

  useLayoutEffect(() => {
    requestAnimationFrame(() => {
      window.scrollTo(0, 0);
    });
  }, []);

  if (!accountData.classroomPortalWelcomePending) {
    return null;
  }

  return (
    <>
      <Wrapper>
        {/*         <span className="close" onClick={clearClassroomPortalWelcome}>
          <IconX />
        </span>
 */}
        <div className="container" data-test-id="onboarding">
          <MarkdownContainer>{message}</MarkdownContainer>
          <Gap size={2}></Gap>
          <Button
            label={__('Got it', 'common.gotIt')}
            onClick={clearClassroomPortalWelcome}
            data-test-id="got-it-onboarding"
          />
        </div>
      </Wrapper>
      <Gap size={5} />
    </>
  );
});
