/* eslint-disable no-unused-expressions */
import { getEnv, getRoot, flow, types } from 'mobx-state-tree';
import { some, every, max } from 'lodash';
import * as safeTypes from '../lib/safe-mst-types';
import { __, getLocale } from '../lib/localization';
import env from '../lib/simple-env';
import { minutesToPrettyDuration } from '../lib/pretty-duration';
import { appName, simulateIap } from '../lib/app-util';
import { Credit } from './credit';
import { VolumeDownload } from '../download-manager/volume-download';
import { businessWars } from './show';

/**
 * v4 catalog - "Volume" level. i.e. "Nike vs Adidas", parent of "units"
 *
 */
export const Volume = types
  .model('Volume', {
    slug: safeTypes.identifierDefaultBlank,
    credits: types.optional(types.maybeNull(types.array(Credit)), []),
    seasonNumber: safeTypes.stringOrNull,
    imageThumbUrl: safeTypes.stringDefaultBlank, //listImageUrl: types.string, // url,
    imageBannerUrl: safeTypes.stringDefaultBlank, //bannerImageUrl: types.string, //url,
    trial: safeTypes.booleanDefaultFalse, // - is a free trial episode,
    // price: safeTypes.stringOrNull,

    l1Code: safeTypes.stringDefaultBlank, // ja
    l2Code: safeTypes.stringDefaultBlank, // en

    // todo: qualify all volume level strings by locale instead of l1/l2
    titleL1: safeTypes.stringDefaultBlank,
    titleL2: safeTypes.stringDefaultBlank,

    taglineL1: safeTypes.stringDefaultBlank,
    taglineL2: safeTypes.stringDefaultBlank,

    descriptionL1: safeTypes.stringDefaultBlank,
    descriptionL2: safeTypes.stringDefaultBlank,

    topics: safeTypes.arrayOfStringsDefaultEmpty,
    countries: safeTypes.arrayOfStringsDefaultEmpty,

    unitSlugs: safeTypes.arrayOfStringsDefaultEmpty,

    totalDurationMinutes: safeTypes.numberDefaultZero,
    totalDownloadSize: safeTypes.numberDefaultZero,

    originalBroadcastDate: safeTypes.stringOrNull,
    releaseDate: safeTypes.stringOrNull,

    download: types.optional(
      types.maybeNull(types.late(() => VolumeDownload)),
      {}
    ),
    //publishedOn: types.maybeNull(types.string), // TODO: figure out how JSON dates should be encoded
    //releaseDate: types.maybeNull(types.string), // note, tried to provide a default value, but that didn't work when server provides an explicit null
    //weblink: types.string, //url,
    //lessonPlanUrl: types.maybeNull(types.string), //url,
    //version: types.number,
  })
  .volatile(() => ({
    // cached the IAP product fetched pricing display text
    fetchedPrice: null,

    // controls which unit will be shown in the carousel.
    // might need to make this persisted, but will try for now initializing
    // from the collective progress state. i think there are only a few
    // edge-cases where what's needed isn't going to match what can be
    // automatically derived
    // selectedUnit: null,

    // this needs to be controlled by the business logic when skipping between units.
    // it feels cleaner to manage the state here instead bridging somehow
    // to the component state
    carouselShown: false,
  }))
  .views(self => ({
    get $log() {
      const { createLogger = () => {} } = getEnv(self);
      const log = createLogger('volume');
      return log;
    },
    get $platform() {
      const { platform = {} } = getEnv(self);
      return platform;
    },
  }))
  .views(self => ({
    get show() {
      return businessWars; // hardwire for now
    },

    get weblink() {
      // hardwire until added to editorial data
      return 'https://wondery.com/shows/business-wars/';
    },

    get units() {
      const storyManager = getRoot(self).storyManager;
      return self.unitSlugs.map(slug => storyManager.story(slug));
    },

    get unitCount() {
      return self.unitSlugs.length;
    },

    get multiUnit() {
      return self.unitCount > 1;
    },

    get singleUnit() {
      return !self.multiUnit;
    },

    get firstUnit() {
      return self.unitByNumber(1);
    },

    get currentUnitNumber() {
      return getRoot(self)?.userManager?.userData?.volumeCurrentUnitNumber(
        self.slug
      );
    },

    get currentUnit() {
      return self.unitByNumber(self.currentUnitNumber) || self.firstUnit;
    },

    unitByNumber(number) {
      return self.units.find(story => story.unitNumber === number);
    },

    // get furthestUnit() {
    //   if (self.singleUnit) { // small optimization
    //     return self.firstUnit;
    //   }
    //
    //   // return the first unit which is in progress
    //   const unitInProgress = self.units.find(unit => unit.inProgress) ?? null;
    //   if (unitInProgress) {
    //     return unitInProgress;
    //   }
    //
    //   /// …OR the first unit that isn't complete
    //   const firstUncompleted = self.units.find(
    //     unit => !unit?.progress?.completed
    //   );
    //
    //   // for completed volumes, default to first unit for relistening
    //   return firstUncompleted ?? self.firstUnit;
    // },

    // perhaps rename this later to displayedPrice, but one step at a time
    get price() {
      if (simulateIap()) {
        return env.get('simulateIapPrice') || '¥2080';
      } else {
        return self.fetchedPrice;
      }
    },

    // hopefully we can use a single consumable sku, but even if we need to
    // switch back to non-consumables, i think this is a better approach to
    // mapping from the catalog to the iap skus.
    // for now, hardwire here. in future this will be a fetched catalog property
    get iapSkuSuffix() {
      return 'volume.tier20';
    },

    get appleIapSku() {
      const bundleId = self.$platform.getBundleId();
      // const result = `${bundleId}.${self.slug.replace(/-/g, '_')}`;
      const result = `${bundleId}.${self.iapSkuSuffix}`;
      self.$log.info(`iapSku: ${self.slug} -> ${result} `);
      return result;
    },

    get title() {
      return self.titleL2; // right now we only have L2 version of title
    },

    get tagline() {
      switch (getLocale()) {
        case self.l2Code:
          return self.taglineL2;
        default:
          return self.taglineL1;
      }
    },

    get description() {
      switch (getLocale()) {
        case self.l2Code:
          return self.descriptionL2;
        default:
          return self.descriptionL1;
      }
    },

    get unitsDescription() {
      return __(
        {
          one: '%{count} episode',
          other: '%{count} episodes',
        },
        'volume.unitsCount',
        { count: self.unitCount }
      );
    },

    // we apparently need a different flavor of this for use in the volume
    // header as a button to return to the volume info view
    // note, we can assume always multi-unit in this case
    get unitsDescriptionInfoReturn() {
      return __('%{count} episodes', 'volume.header.unitsCount', {
        count: self.unitCount,
      });
    },

    // used on the dashboard volume cards.
    // adapts between the full volume duration when not in progress
    // or just the current unit remaining display
    get durationOrCurrentRemainingDescription() {
      if (self.inProgress) {
        const duration = minutesToPrettyDuration(
          self.currentUnit?.timeLeftMinutes
        );
        if (self.currentUnit?.inProgress) {
          return __('%{duration} remaining', 'duration.remaining', {
            duration,
          });
        } else {
          return duration; // show just the current duration if at start of subsequent unit
        }
      } else {
        // return self.adaptiveDurationDescription;
        return self.episodesDurationDescription;
      }
    },

    // used in volume screen header
    get episodesDurationDescription() {
      const duration = minutesToPrettyDuration(self.totalDurationMinutes);
      return __(
        '%{unitsDescription}, %{duration}',
        'volume.durationDescription',
        {
          unitsDescription: self.unitsDescription,
          duration,
        }
      );
    },

    get locked() {
      return !self.isUnlocked;
    },

    // todo: align interface with story.js (which currently uses 'locked')
    get isUnlocked() {
      if (self.trial) {
        return true;
      }
      const root = getRoot(self);
      const slugs = root.userManager?.accountData?.unlockedVolumeSlugs ?? [];
      return slugs.indexOf(self.slug) !== -1;
    },

    get downloaded() {
      return self.download?.isDownloaded && !self.locked; // force to not downloaded logic to always apply if still locked
    },

    // eligible to be studied, used to drive unit card styles
    get ready() {
      return self.downloaded || self.inProgress;
    },

    get completed() {
      return every(self.units, 'progress.completed');
    },

    // get completedProgress() {
    //   const completed = self.units.reduce((acc, unit) => {
    //     return unit.progress?.completed ? acc + 1 : acc;
    //   }, 0);
    //
    //   return (completed / self.units.length).toFixed(2);
    // },
    // drives the lighter layer of the progress pie
    get furthestProgress() {
      // todo: rename to furthestCompletedProgress?
      const furthestNumber =
        max(
          self.units.filter(_ => _?.progress?.completed).map(_ => _?.unitNumber)
        ) || 0;
      return (furthestNumber / self.unitCount).toFixed(2);
    },

    // used to determine 'skip' vs 'goto' a unit
    // todo: don't warn for the edge case of skipping forward to the next unit
    // if the previous is completed (but 'continue' was never pressed)
    get furthestUnitNumber() {
      return (
        max(self.units.filter(_ => _?.played).map(_ => _?.unitNumber)) || 0
      );
    },

    get currentProgress() {
      return ((self.currentUnitNumber - 1) / self.unitCount).toFixed(2);
    },

    get showExportVocab() {
      return (
        self.hasVocab && getRoot(self)?.userManager?.showVocabListExportOption
      );
    },

    get hasVocab() {
      return self.vocabCount > 0;
    },

    get vocabCount() {
      return self.units.reduce((acc, unit) => {
        return acc + unit.vocabCount;
      }, 0);
    },

    get vocabLookupDataAvailable() {
      return every(self.units, 'vocabLookupDataAvailable');
    },

    get totalPoints() {
      // return self.units.reduce((acc, unit) => {
      //   return acc + unit.totalPoints;
      // }, 0);
      return self.listeningStats.totalPoints;
    },

    get listeningStats() {
      return getRoot(self).userManager.userData.volumeListeningStats(self.slug);
    },

    get inProgress() {
      // some units are in progress
      const someInProgress = some(self.units, 'inProgress');
      if (someInProgress) {
        return true;
      }

      // some, but not all units have been completed
      const completeCount = self.units.filter(unit => unit?.progress?.completed)
        .length;
      if (completeCount > 0 && completeCount < self.unitCount) {
        return true;
      }

      return false;
    },

    // the state when all units have been completed, but the user has navigated
    // back to an earlier unit or chapter
    get relistening() {
      return self.completed && self.listening;
    },

    get listening() {
      return (
        self.inProgress ||
        some(self.units, 'listening') ||
        (self.completed && self.currentUnitNumber < self.unitCount)
      );
    },

    get atEndOfVolume() {
      return (
        self.currentUnitNumber === self.unitCount &&
        self.currentUnit?.atEndOfStory
      );
    },

    get downloadSizeInMB() {
      return Math.round(self.totalDownloadSize / (1024 * 1024));
    },

    get downloadSizeDisplay() {
      return `${self.downloadSizeInMB}MB`;
    },

    get thumbImagePath() {
      // return self.download?.thumbImagePath;
      // console.log(
      //   `getThumbImagePath - firstUnit?.thumbImagePath ${self.firstUnit?.thumbImagePath}`
      // );
      return self.firstUnit?.thumbImagePath;
    },

    // hack in for wondery. to be eventually captured into editorial workflow and data
    get headerGradientColors() {
      return self.slug === 'bw39-cola-space'
        ? ['#F16A41', '#6C1514']
        : ['#F24F40', '#842C21'];
    },

    // controls the 'continue' link visibility in the volume screen header
    get showContinueAction() {
      return self.downloaded && self.inProgress;
      // return true;
    },

    // controls the vocab and point modal buttons from the volume screen header
    get showVocabPointsActions() {
      return self.downloaded || self.vocabCount > 0 || self.totalPoints > 0;
    },

    //
    // volume info screen support
    //

    get releaseDisplay() {
      // todo: proper localization of date display
      return __('%{appName} release: %{date}', 'volume.info.release', {
        appName: appName(),
        date: self.releaseDate,
      });
    },

    get originalBroadcastDisplay() {
      return __('Original broadcast: %{date}', 'volume.info.broadcast', {
        date: self.originalBroadcastDate,
      });
    },

    get aboutDisplay() {
      return self.show.channel.aboutDisplay;
    },

    get showPurchaseDownloadButton() {
      return self.locked || !self.download.isDownloaded;
    },

    get showOpenUnitButton() {
      return !self.locked && self.download?.isDownloaded && self.currentUnit;
    },

    get openUnitButtonData() {
      let label = null;
      // @armando/@nuwan can you think of a better interface or naming convention to communicate this display mode logic from core to the app?
      let flavor = 'normal';
      if (self.completed && !self.relistening) {
        label = __('Relisten', 'story.carousel.relisten');
        flavor = 'deemphasized';
      } else {
        if (self.currentUnit.unplayed) {
          if (self.singleUnit) {
            label = __('Begin episode', 'volume.beginEpisode.single');
          } else {
            label = __(
              'Begin episode %{unitNumber}',
              'volume.beginEpisode.multi',
              self.currentUnit.catalogData
            );
          }
        } else {
          if (self.currentUnit.listening) {
            if (self.singleUnit) {
              label = __('Continue episode', 'volume.continueEpisode.single');
            } else {
              label = __(
                'Continue episode %{unitNumber}',
                'volume.continueEpisode.multi',
                self.currentUnit.catalogData
              );
            }
          } else {
            // handle the edge case that we're at the end of one unit, but
            // haven't yet proceeded to the next unit
            label = __('Continue', 'volume.continue');
          }
        }
      }
      return { label, flavor };
    },

    get purchaseEnabled() {
      return !!self.price;
    },

    get purchaseButtonLabel() {
      return self.singleUnit
        ? __('Purchase episode', 'volume.purchaseEpisode') // not an expected use case, but include for completeness
        : __('Purchase season', 'volume.purchaseSeason');
    },

    get downloadButtonLabel() {
      return self.singleUnit
        ? __('Download episode', 'volume.downloadEpisode')
        : __('Download season', 'volume.downloadSeason');
      // not sure if we can factor this without adding sentence case logic
      // return __('Download %{volumeType}', 'volume.download', { volumeType: self.volumeTypeName });
    },

    get volumeCompleteLabel() {
      return self.singleUnit
        ? __('Episode complete', 'volume.episodeComplete')
        : __('Season complete', 'volume.seasonComplete');
    },

    get volumeTypeName() {
      return self.singleUnit
        ? __('episode', 'volume.type.episode')
        : __('season', 'volume.type.season');
    },
  }))
  // consider moving these to a user-manager model
  .actions(self => ({
    // called upon logout. will be cleaner to separate out the user managed aspects
    // of this modal at some point
    resetVolatile() {
      // self.selectedUnit = null;
      self.carouselShown = false;
    },

    // will force the carousel on a particular unit w/o affecting any progress state
    // setSelectedUnit(story) {
    //   self.$log.debug(`setSelectedUnit: ${story.slug}`);
    //   self.selectedUnit = story;
    // },

    // called by useEffect on volume screen to make sure we have a good state
    // ensureSelectedUnit() {
    //   if (!self.selectedUnit) {
    //     self.deriveSelectedUnit();
    //   }
    // },

    // used to advance to next unit when continuing from summary card
    // deriveSelectedUnit() {
    //   self.setSelectedUnit(self.derivedCurrentUnit);
    // },

    setCarouselShown(bool) {
      self.carouselShown = bool;
    },

    showCarousel() {
      self.carouselShown = true;
    },

    hideCarousel() {
      self.carouselShown = false;
    },

    toggleCarousel() {
      if (self.atEndOfVolume) {
        self.$log.info(`toggleCarousel - marking for relisten`);
        self.relistenVolume();
      }
      self.carouselShown = !self.carouselShown;
    },

    resetVolume() {
      self.units.forEach(story => story.progress.resetStory());
      self.setCurrentUnitNumber(1);
    },

    resetUnit() {
      self.currentUnit.progress.resetStory();
      // todo: confirm if we should force the carousel to stay on current unit or potentionally change
      // self.setSelectedUnit(null);
    },

    setCurrentUnit(story) {
      self.setCurrentUnitNumber(story.unitNumber);
    },

    setCurrentUnitNumber(number) {
      return getRoot(self)?.userManager?.userData?.setVolumeCurrentUnitNumber(
        self.slug,
        number
      );
    },

    selectNextUnit() {
      // const candidateNumber = self.currentUnitNumber + 1;
      const story = self.unitByNumber(self.currentUnitNumber + 1); // || self.firstUnit;
      if (story) {
        self.setCurrentUnit(story);
      } else {
        // at end of volume.
        // todo: confirm exactly what our behavior should be here
        // can't select first unit without prematurely putting volume
        // into the 'relistening' state.
        // might want a sepcial END_OF_VOLUME unitNumber
      }
    },

    markVolumeComplete() {
      self.units.forEach(story => story.progress.markStoryComplete());
      // self.setCurrentUnitNumber(1);
    },

    markUnitComplete() {
      self.currentUnit.progress.markStoryComplete();
      if (self.carouselShown) {
        // todo: make sure we handle correctly when invoked from the dashboard
        // stay on the carousel if already open, but advance to the summary card.
        // this seems to happen naturally in test harness, not sure how to force this for native
      } else {
        // advance info view to next episode
        // self.deriveSelectedUnit();
        self.selectNextUnit();
      }
    },

    // used to skip forward to future unit within the volume, or force carousel
    // back to an past unit
    markCurrentUnit(story) {
      self.$log.info(`${self.slug}.markCurrentUnit(${story?.slug}`);
      // mark all prior units as complete
      self.units
        .filter(_ => _.unitNumber < story.unitNumber && !_.completed)
        .forEach(_ => _.progress?.markStoryComplete());

      self.setCurrentUnit(story);
      if (story.completed) {
        self.$log(`prepping for relisten`);
        // for now, will leave the summary card as 'current' and not touch the currentPointer unless actually played
        // will possibly tweak the initial carousel position when
        // opening a completed unit
        // story.progress?.relistenStory();
      }
      self.showCarousel();
    },

    relistenVolume() {
      self.setCurrentUnit(self.firstUnit);
      self.firstUnit.progress?.relistenStory();
    },

    ensureVocabLookupData: flow(function* ensureVocabData() {
      self.$log.debug(`volume[${self.slug}].ensureVocabLookupData`);
      if (!self.downloaded) {
        return; // nop unless downloaded
      }

      // note, can't do a yield inside a forEach
      for (let i = 0; i < self.units.length; i++) {
        yield self.units[i].ensureVocabLookupData();
      }
    }),

    // TODO: proper server-api to send out a single vocab email
    exportVocab: flow(function* exportVocab() {
      for (let i = 0; i < self.units.length; i++) {
        const unit = self.units[i];
        if (unit.vocabCount) {
          yield unit.exportVocab();
        }
      }
    }),

    setDisplayedPrice(price) {
      self.fetchedPrice = price;
    },
  }));
