import { createSegmentAnalyticsContainer } from './segment-analytics';
import { createSimulatedAnalyticsContainer } from './sim-analytics';
import createLogger from '../log';

const log = createLogger('analytics');

const containers = [
  createSegmentAnalyticsContainer(),
  createSimulatedAnalyticsContainer(),
];

const runInContainer = fnName => (...args) => {
  // these try…catch blocks are necessary because the startupSequence is swallowing errors.
  containers.forEach(c => {
    try {
      return c[fnName](...args);
    } catch (error) {
      log(`Error running ${fnName}`, error);
    }
  });
};

export const setContextData = runInContainer('setContextData');
export const trackEvent = runInContainer('trackEvent');
export const trackPage = runInContainer('trackPage');

export const startTimeEvent = (eventName, eventProperties = {}) => {
  const start = new Date().getTime();
  return (extraProperties = {}) => {
    const end = new Date().getTime();
    const duration = end - start;
    const props = {
      ...eventProperties,
      ...extraProperties,
      $duration: duration,
    };
    trackEvent(eventName, props);
  };
};

export const autoTrackBefore = (fn, ...eventArgs) => (...args) => {
  trackEvent(...eventArgs);
  fn(...args);
};

export const autoTrackAfter = (fn, ...eventArgs) => (...args) => {
  fn(...args);
  trackEvent(...eventArgs);
};
