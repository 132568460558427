import React from 'react';
import { values, chunk } from 'lodash';
import cx from 'classnames';
import __ from 'jw-core/lib/localization';
import styled from 'styled-components';
import Markdown from 'react-markdown';

const Wrapper = styled.div`
  .vocabs,
  .sics {
    --background-color: var(--light-green);
    background-color: var(--background-color);
    border-radius: 8px;
    padding: 16px 0;

    @media print, screen and (min-width: 700px) {
      display: flex;
    }

    h5 {
      font: var(--body-font);
      font-weight: 600;
      padding: 0 16px;
      margin-bottom: 16px;
      flex: 2;
      @media print, screen and (min-width: 700px) {
        margin-bottom: 0;
      }
    }

    ul {
      padding: 0 16px;
      flex: 5;
      &.single {
        flex: 10;
      }
    }

    li {
      margin: 0;
      padding: 0;
      line-height: 3rem;
      div {
        display: inline;
      }
      div p {
        display: inline;
        font: var(--body-font);
        line-height: 3rem;
      }
      .term-note p {
        color: var(--gray-4);
      }
    }
  }

  .sics {
    margin-top: 24px;
    --background-color: var(--lighter-orange);
  }
`;

const clean = word => {
  return word.replace(/[*+]/g, '');
};

export const Columns = ({ vocabs, className = 'vocabs', id, title }) => {
  const items = values(vocabs);
  if (items.length === 0) {
    return null;
  }
  const columns =
    items.length > 1 ? chunk(items, Math.ceil(items.length / 2)) : [items];

  return (
    <div className={className} id={id}>
      <h5>{title}</h5>
      {columns.map(column => {
        return (
          <ul className={cx({ single: items.length === 1 })}>
            {column.map(item => (
              <li>
                <Markdown className="term-text" source={clean(item.text)} />
                &nbsp;
                <Markdown
                  className="term-note"
                  source={__('– %{note}', 'storyGuide.vocabsNote', {
                    note: item.head ?? item.note,
                  })}
                />
              </li>
            ))}
          </ul>
        );
      })}
    </div>
  );
};

export const Glossary = ({ vocabs, sics, id }) => {
  return (
    <Wrapper>
      <Columns
        title={__('Vocab', 'common.vocab')}
        vocabs={vocabs}
        id={`${id}-vocab`}
      />
      <Columns
        title={__('Sic', 'story.sic')}
        vocabs={sics}
        className="sics"
        id={`${id}-sic`}
      />
    </Wrapper>
  );
};
