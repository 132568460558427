import { getConfig } from 'config';
import version from './macros/version.macro';
// default platform mode for mst-proto
export const os = 'website';

export const platformChoices = ['ios', 'android', 'website'];

const theState = { platform: os };

export const getPlatform = () => theState.platform;

export const setPlatform = platform => (theState.platform = platform);

// dynamic versions for the places that we need to test with the mst-proto
export const onIos = () => false;
export const onAndroid = () => false;

// when running within the lupa-spa
export const onWebsite = () => true;

export const androidOnly = callback => {
  if (onAndroid()) {
    return callback();
  }
};

export const iosOnly = callback => {
  if (onIos()) {
    return callback();
  }
};

// backward compatible constants for existing native code that doesn't
// need to honor the dynamic config
export const isIOS = onIos();
export const isAndroid = onAndroid();

// wraps the device specific id needed by the anonymous api's is a way that
// can be dynamically updated within the mst-proto.
// @armando, is there a better home for this?

// the native peer of this will Constants.installationId
export const setInstallationId = id => (theState.installationId = id);
export const getInstallationId = () => theState.installationId;
export const getProductVersion = () => `${version}-${getPlatform()}`;
// the native peer of this will be:
// export const getInstallationId = () => Constants.installationId;

// not relevant for spa
export const getBuildNumber = () => 0;

// export const eslMode = false; // not relevant
export const lupaMode = getConfig('appSlug', null) === 'myLupa';
