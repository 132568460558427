import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { MessageTarget } from '../ui/message-target';
import { objectFromUrlQuery } from '../../lib/object-from-url-query';
import MessageService from '../../lib/services/message-service';
import { getConfig } from 'config';
import { useRootStore } from '../../truth';

const { emails, emailOptions } = getConfig('website');

const FeedbackLink = ({ children }) => {
  const href = `mailto:${emails.feedback}?subject=${emailOptions.subject}&body=${emailOptions.body}`;
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a href={'#'} onClick={() => window.open(href)}>
      {children}
    </a>
  );
};

const SupportLink = ({ children }) => {
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      href="#"
      onClick={() => {
        window.open(`mailto:${emails.support}`);
      }}
    >
      {children}
    </a>
  );
};

const getMessages = accountData => {
  const paymentData = accountData.paymentData ?? {};

  const messages = {
    'extending-subscribe-success': {
      icon: 'success',
      title: 'Subscription successful!',
      body: `You're ready to go. Your next billing date will be ${accountData.fullAccessUntil}.`,
    },

    'extending-full-access': {
      icon: 'success',
      title: 'Success!',
      body: `Your full access membership has been extended until ${accountData.fullAccessUntil}.`,
    },

    'auto-renew-cancelled-with-days-left': {
      icon: 'success',
      title: 'Your subscription has been canceled.',
      body: () => (
        <>
          Thank you for trying Lupa. You will continue to have full access until{' '}
          {accountData.fullAccessUntil}. Please take a moment to share your{' '}
          <FeedbackLink>your feedback</FeedbackLink> — we'd love to know what we
          could improve.
        </>
      ),
    },

    'auto-renew-cancelled-no-days-left': {
      icon: 'success',
      title: 'Your subscription has been canceled.',
      body: () => (
        <>
          Thank you for trying Lupa. Please take a moment to share your{' '}
          <FeedbackLink>your feedback</FeedbackLink> — we'd love to know what we
          could improve.
        </>
      ),
    },

    'first-full-access': {
      icon: 'success',
      title: "You're ready to go!",
      body: `Congratulations! You're on the last leg of your journey to real-world Spanish fluency. All Lupa stories are unlocked on your account: so pick something that catches your eye, dive in, and start listening like a native.`,
    },

    'first-subscribe-success': {
      icon: 'success',
      title: "You're subscribed!",
      body: `Congratulations! You're on the last leg of your journey to real-world Spanish fluency. All Lupa stories are unlocked on your account: so pick something that catches your eye, dive in, and start listening like a native.`,
    },

    'card-update-success': {
      icon: 'success',
      title: 'Update successful!',
      body: `You're ready to go.`,
    },

    'auto-renew-failure': {
      icon: 'alert',
      title: 'Payment unsuccessful',
    },

    'auto-renew-failure-with-access': {
      icon: 'alert',
      title: 'Payment unsuccessful',
      body: `We were unable to charge your card ending ${paymentData.cardLastFour} on ${accountData.fullAccessUntil}. To ensure uninterrupted access to all Lupa stories please check that the card information is correct and valid. Your access will expire after ${accountData.fullAccessUntil}`,
    },

    'checkout-failure': {
      icon: 'alert',
      title: 'Payment unsuccessful',
      body: () => (
        <>
          Your credit card payment was not processed. Please{' '}
          <FeedbackLink>let us know</FeedbackLink> if you encountered an
          unexpected error.
        </>
      ),
    },

    'new-account-email-verified': {
      icon: 'success',
      title: "You're ready to go",
      body: 'Thank you for verifying your email.',
    },

    'student-pricing': {
      icon: 'success',
      title: 'Success!',
      body: 'Student discount pricing activated.',
    },

    'discount-pricing': {
      icon: 'success',
      title: 'Success!',
      body: 'Discount pricing activated.',
    },

    'purchase-gift': {
      title: 'Purchase complete',
      body: `Instructions to redeem gift coupon will appear shortly in your ${accountData.email} inbox. You can forward it to the giftee. The status of the coupon will update below when it is redeemed.`,
      icon: 'success',
    },
  };

  return messages;
};

const cleanAddressBar = () => {
  var yourCurrentUrl = window.location.href.split('?')[0];
  window.history.replaceState({}, '', yourCurrentUrl);
};

const getDefaultFlashMessage = flash => {
  if (flash?.message) {
    return {
      title: flash.message,
    };
  }

  return null;
};

export const AccountMessageTarget = observer(() => {
  const store = useRootStore();

  useEffect(() => store.userManager.resetApiResult(), [store.userManager]);

  // TODO: we should deprecate this approach in favor more generic.
  useEffect(() => {
    const { apiResult } = store.userManager;
    if (apiResult && apiResult.title) {
      MessageService.showMessage({
        title: apiResult.title,
        body: apiResult.message,
      });
    } else {
      MessageService.dismissMessage();
    }
  }, [store]);

  useEffect(() => {
    const query = objectFromUrlQuery();
    if (process.env.NODE_ENV === 'production') {
      cleanAddressBar();
    }

    if (query.checkout === 'error') {
      MessageService.showMessage({
        type: 'alert',
        title: 'Payment unsuccessful',
        body: () => (
          <>
            Your credit card payment was not processed. Please{' '}
            <SupportLink>let us know</SupportLink> if you encountered an
            unexpected error.
          </>
        ),
      });
    } else {
      const { accountData } = store.userManager;
      if (accountData) {
        const { flash } = store;
        const messageKey = flash?.messageKey ?? query.messageKey;
        const messages = getMessages(accountData);
        const message = messages[messageKey] ?? getDefaultFlashMessage(flash);

        if (message) {
          MessageService.showMessage(message);
        } else {
          if (accountData?.paymentData?.autoRenewFailed) {
            MessageService.showMessage('auto-renew-failure-with-access');
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.userManager.accountData, store.flash]);

  return <MessageTarget />;
});
