import { useRef, useEffect } from 'react';
import { getConfig } from 'config';

export const useTitle = title => {
  const prefix = getConfig('appTitle');
  if (prefix) {
    title = `${title} | ${prefix}`;
  }
  const prevTitleRef = useRef(document.title);
  document.title = title;
  useEffect(() => {
    const prevTitle = prevTitleRef.current;
    return () => {
      document.title = prevTitle;
    };
  }, []);
};
