import { get, isString, isNumber, isDate, deburr, memoize } from 'lodash';
import logger from '../lib/log';

const log = logger('util');

export const sleep = ms => {
  return new Promise(res => {
    setTimeout(res, ms);
  });
};

export const SORT_ORDER = Object.freeze({
  ASC: 'ASC',
  DESC: 'DESC',
});

// removes leading punctuaction symbols which are common in Spanish
// so they don't affect sorting
// e.g. "¿Dónde están la biblioteca?"
export const compareNormalize = memoize(str =>
  deburr(str).replace(/^[^a-zA-Z0-9]+/, '')
);

// utility for string comparison
export const localeCompare = (a, b) => {
  return compareNormalize(a).localeCompare(compareNormalize(b));
};

/**
 *
 * uses lodash.get to create a comparator function
 * for a particular field that can be used with Array.prototype.sort
 *
 * @param {string} abPath
 */
export const makePathSortFn = (abPath, sortOrder = SORT_ORDER.ASC) => (
  a,
  b
) => {
  const x = get(a, abPath, null);
  const y = get(b, abPath, null);
  if (x && y) {
    log.debug(`simpleComparator - ${abPath} y: ${JSON.stringify(y)}`);
    // we assume x and y are the same type. We have bigger issues if they aren't.

    if (isString(y)) {
      return sortOrder === SORT_ORDER.ASC
        ? localeCompare(x, y)
        : localeCompare(y, x);
    }

    if (isNumber(y)) {
      return sortOrder === SORT_ORDER.ASC ? x - y : y - x;
    }

    if (isDate(y)) {
      if (x === y) {
        return 0;
      }
      if (sortOrder === SORT_ORDER.ASC) {
        return x > y ? -1 : 1;
      } else {
        return x > y ? 1 : -1;
      }
    }

    return 0;
  }

  if (y === x) {
    return 0;
  }

  return x === null ? 1 : -1;
};

/**
 * takes a field path (uses lofash.get internally) and returns a story sorting function
 * @param {string} abPath
 */
export const sortBy = (abPath, sortOrder = SORT_ORDER.ASC) => {
  const sortFn = makePathSortFn(abPath, sortOrder);
  return stories => [...stories].sort(sortFn);
};
