import React, { useRef } from 'react';
import cx from 'classnames';
import { isFunction } from 'lodash';
import styled from 'styled-components';
import { getColor, includeTextStyles, cond } from 'lib/theme/utils';
import { prevent } from 'lib/prevent';
import { useKeyboardBinding } from 'lib/hooks/use-keyboard-binding';
import { Modal } from './modal';

const Container = styled.div`
  min-width: 360px;
  background: ${getColor('white')};
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 16px;

  & > .dialog-title {
    ${includeTextStyles('body-bold')};
    margin-bottom: 16px;
    display: block;
  }

  & > .dialog-body {
    ${includeTextStyles('body')};
    margin-bottom: 16px;
    max-width: 360px;
  }

  & > .buttons-container {
    flex-flow: row-reverse;
    display: flex;
    justify-content: ${cond('hasCancelButton', 'space-between', 'center')};
    border-top: 1px solid rgba(0, 0, 0, 0.1);

    &.no-ruler {
      border-top: none;
      margin-top: -16px;
    }

    & > .dialog-button {
      ${includeTextStyles('body-bold')}
      border: none;
      background: none;
      text-align: left;
      display: flex;
      flex: 1;
      display: inline-block;
      transition: color 0.1s;
      margin: 0 auto;
      padding: 16px 0;
      /* To compensate for the container padding, but to keep the tappable area */
      margin-bottom: -16px;
      cursor: pointer;

      opacity: 1;

      &:hover {
        opacity: 0.5;
      }

      &:active {
        outline: none;
      }

      &.type-ok {
        --button-color: ${getColor('teal')};
        --button-alignment: center;
        color: var(--button-color);
        text-align: var(--button-alignment);

        &:hover {
          color: ${getColor('dark-teal')};
        }

        &.is-dangerous {
          --button-color: ${getColor('red')};
        }
        &.is-disabled {
          --button-color: ${getColor('gray-1')};
        }

        &.has-cancel {
          --button-alignment: right;
        }
      }

      &.type-cancel {
        --cancel-button-color: ${getColor('gray-3')};
        color: var(--cancel-button-color);
        &.is-dangerous {
          --cancel-button-color: ${getColor('red')};
        }
        &.is-disabled {
          --cancel-button-color: ${getColor('gray-1')};
        }
      }

      &.type-third {
        text-align: center;
        color: var(--cancel-button-color);
        /* position: relative;
        right: -32px; */
        z-index: 2;

        &.is-dangerous {
          --cancel-button-color: ${getColor('red')};
        }
        &.is-disabled {
          --cancel-button-color: ${getColor('gray-1')};
        }
      }
    }
  }
`;

export const Dialog = ({
  title = null,
  body = '',
  placeholder = '',
  onDismiss = () => {},
  okButtonAction,
  okButtonLabel = 'OK',
  okIsDangerous = false,
  okIsDisabled = false,
  cancelButtonAction,
  cancelButtonLabel = 'Cancel',
  cancelIsDangerous = false,
  cancelIsDisabled = false,
  showCancelButton = true,
  thirdButtonAction,
  thirdButtonLabel = '',
  thirdButtonIsDangerous = false,
  thirdButtonIsDisabled = true,
  showThirdButton = false,
  showIcon = true,
  iconType = 'success',
  hideRulerOnButtonsContainer = false,
  autoDismissOnOk = true,
  ...props
}) => {
  const ref = useRef();

  const handleOkButton = () => {
    if (isFunction(okButtonAction)) {
      if (okButtonAction() === false) {
        return;
      }
    }

    if (autoDismissOnOk) {
      onDismiss();
    }
  };

  const handleCancelButton = () => {
    if (isFunction(cancelButtonAction)) {
      if (cancelButtonAction() === false) {
        return;
      }
    }

    onDismiss();
  };

  // close with enter
  useKeyboardBinding('Enter', handleOkButton);
  const bodyContent = isFunction(body) ? body() : body;

  return (
    <Modal contentRef={ref} {...props}>
      <Container ref={ref}>
        {title ? <span className="dialog-title">{title}</span> : null}
        {bodyContent ? <div className="dialog-body">{bodyContent}</div> : null}
        <div
          className={`buttons-container ${
            hideRulerOnButtonsContainer ? 'no-ruler' : ''
          }`}
        >
          <button
            onClick={handleOkButton}
            className={cx('dialog-button', 'type-ok', {
              'is-disabled': okIsDisabled,
              'is-dangerous': okIsDangerous,
              'has-cancel': showCancelButton,
            })}
            data-test-id="okButton"
          >
            {okButtonLabel}
          </button>
          {showThirdButton ? (
            <button
              href={'#'}
              className={cx('dialog-button', 'type-third', {
                'is-disabled': thirdButtonIsDisabled,
                'is-dangerous': thirdButtonIsDangerous,
              })}
              onClick={prevent(thirdButtonAction)}
              data-test-id="thirdButton"
            >
              {thirdButtonLabel}
            </button>
          ) : null}
          {showCancelButton ? (
            <button
              href={'#'}
              className={cx('dialog-button', 'type-cancel', {
                'is-disabled': cancelIsDisabled,
                'is-dangerous': cancelIsDangerous,
              })}
              onClick={prevent(handleCancelButton)}
              data-test-id="cancelButton"
            >
              {cancelButtonLabel}
            </button>
          ) : null}
        </div>
      </Container>
    </Modal>
  );
};
