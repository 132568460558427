import React from 'react';

export const IconBookS = ({ color = 'currentColor', ...props }) => (
  <svg width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M9.74575 5.37543L10 5.52558L10.2543 5.37543C11.9117 4.39658 13.7755 4.38498 15.2655 4.64045C16.0062 4.76745 16.6407 4.95826 17.0893 5.11722C17.2527 5.17514 17.391 5.22863 17.5 5.27293V15.2572C17.475 15.2482 17.4494 15.239 17.4232 15.2297C16.9343 15.0565 16.2438 14.8487 15.4345 14.7099C13.8938 14.4458 11.8711 14.4238 9.99748 15.4278C8.16086 14.466 6.14228 14.4889 4.5993 14.7412C3.78674 14.8741 3.09003 15.0731 2.59588 15.239C2.56304 15.25 2.53107 15.2609 2.5 15.2716V5.27293C2.60905 5.22863 2.74727 5.17514 2.91074 5.11722C3.35934 4.95826 3.99377 4.76745 4.73449 4.64045C6.22454 4.38498 8.08826 4.39658 9.74575 5.37543Z"
      stroke={color}
    />
    <rect x={2} y={15} width={16} height={1} fill={color} />
  </svg>
);
