import React from 'react';
import styled from 'styled-components';
import { ErrorIcon } from 'components/ui/icons/error-icon';

const DisplayError = styled.p`
  position: relative;
  font-family: roboto, sans-serif;
  font-size: 2.125rem;
  line-height: 3rem;
  font-weight: 400;
  color: #e55c58;
  padding-left: 3.25rem;
  max-height: 9rem;
  transition: all 0.3s;
`;

const ErrorIconContainer = styled.span`
  position: absolute;
  left: 0;
  top: 3px;
`;

export const FieldError = ({ error }) => {
  if (!error) {
    return null;
  }
  return (
    <DisplayError>
      <ErrorIconContainer>
        <ErrorIcon />
      </ErrorIconContainer>
      {error.message}
    </DisplayError>
  );
};
