import { useRef } from 'react';
import { observable, action, computed, decorate } from 'mobx';
import { map } from 'lodash';

/**
 * This is a MobX store to handle the local state
 * of the story assignment selection screen.
 */
class StorySelector {
  // we use an Object instead of a Set or Array because it makes lookups simpler
  // `!!__selected[slug]` is simpler than `__selected.indexOf(slug) !== -1`
  __selected = {};
  // flag to prevent sending to the server if the data has not being changed
  touched = false;

  constructor(initialSlugs = []) {
    initialSlugs.forEach(slug => {
      this.__selected[slug] = true;
    });
  }

  isSelected(story) {
    // this handles false values, too
    return this.__selected[story.slug];
  }

  toggle(story) {
    // `touched` means the user has made changes
    this.touched = true;
    this.__selected[story.slug] = !this.__selected[story.slug];
  }

  // this is an event handler factory for the host component
  makeTogglerForStory = story => () => this.toggle(story);

  // returns an array of selected slugs
  get selectedItems() {
    const keys = Object.keys(this.__selected);
    return keys.filter(key => this.__selected[key] === true);
  }
}

decorate(StorySelector, {
  __selected: observable,
  selectedItems: computed,
  toggle: action,
});

export const useStorySelector = assignments => {
  // we need a ref so the instance persists across re-renders
  const ref = useRef(new StorySelector(map(assignments, 'episodeSlug')));
  return ref;
};
