import env from './simple-env';

export const appSlug = () => env.get('appSlug', 'lupa');

export const jwMode = () => appSlug() === 'jw'; // wondery
export const eslBetaMode = () => appSlug() === 'esl'; // bolero
export const lupaMode = () => !jwMode(); // a bit confusing, but should also be true for the eslbeta build for now

export const v4CatalogMode = () => jwMode();

export const appName = () => env.get('appName');

export const simulateIap = () => env.get('simulateIap');
