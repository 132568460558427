import React from 'react';
import { keys } from 'lodash';
import { includeTextStyles, getColor } from 'lib/theme/utils';
import { textStyles } from 'components/ds/tokens/text-styles';
import { Text } from 'components/ds/common/text';
import styled from 'styled-components';

const TextStyle = styled.div`
  margin: 32px;

  span.name {
    ${includeTextStyles('body')};
    font-size: 14px;
    color: ${getColor('gray-4')};
  }
`;

const sampleText = 'Lorem ipsum dolor sit amet consectetur ';

export const ShowcaseTextStyles = () => {
  const styles = keys(textStyles);
  return (
    <>
      {styles.map(style => {
        return (
          <TextStyle>
            <Text as={'p'} textStyle={style}>
              {sampleText}
            </Text>
            <span className="name">{style}</span>
          </TextStyle>
        );
      })}
    </>
  );
};
