import React from 'react';
import { useHistory } from 'react-router-dom';
import { PageLayout } from 'components/ds/layouts/page-layout';
import { Header } from 'components/ds/header';

export const AssignmentDetailLayout = ({
  story,
  classroom,
  children,
  stickyHeader = false,
}) => {
  const history = useHistory();
  const goBack = () => {
    return history.push(`/classrooms/${classroom.id}`);
  };

  return (
    <PageLayout>
      <Header
        title={story.catalogData.title}
        showBackLink
        backLinkLabel={classroom.label}
        onBackLinkClick={goBack}
        sticky={stickyHeader}
      />
      {children}
    </PageLayout>
  );
};
