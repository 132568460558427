import { getColor } from 'lib/theme/utils';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';

import styled from 'styled-components';

export const IconControlWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 8px;
  cursor: pointer;
  color: ${getColor('gray-4')};

  &:hover {
    color: ${getColor('gray-5')};
  }

  &.is-a-link {
    color: inherit;
    &:hover {
      color: ${getColor('gray-5')};
    }
  }

  svg {
    position: relative;
    top: 1px;
    min-width: 20px;
    margin-right: 4px;
  }

  &.has-date {
    svg {
      color: ${getColor('teal')};
    }
  }

  &.has-note {
    svg {
      color: ${getColor('teal')};
    }
  }

  span.remove-note {
    &:hover {
      color: ${getColor('red')};
    }
  }

  .vr {
    height: 16px;
    width: 1px;
    background: ${getColor('gray-1')};
    margin: 0 6px;
  }
`;
