import React from 'react';
import { observer } from 'mobx-react';
import { Message } from './message';
import MessageService from '../../lib/services/message-service';

export const MessageTarget = observer(() => {
  const message = MessageService.currentMessage.get();

  if (message === null) {
    return null;
  }

  return <Message {...message} />;
});
