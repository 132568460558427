import { types } from 'mobx-state-tree';
import {
  BEGINNING_OF_CHAPTER,
  END_OF_STORY_CHAPTER,
} from '../lib/constants/vars';
import * as safeTypes from '../lib/safe-mst-types';

/**
 * LocationPointer
 *
 * study position within an story.
 * two instances for each story: currentPoint, furthestPoint
 */
export const LocationPointer = types
  .model('LocationPointer', {
    address: types.optional(
      types.maybeNull(types.string),
      BEGINNING_OF_CHAPTER
    ),
    chapter: types.optional(types.maybeNull(types.number), 1),
    iteration: types.optional(types.maybeNull(types.number), 1),
    millisPlayed: safeTypes.numberDefaultZero, //reflects address, not actual play counter. - Perhaps rename to just "millis"?
  })
  .views(self => ({
    // this should probably be false when completed (atEndOfStory)
    // deprecated
    get inProgress() {
      return self.played;
    },

    get played() {
      return self.chapter > 1 || self.iteration > 1 || self.millisPlayed > 0;
    },

    // drives the "continue" CTA label
    get listening() {
      return self.inProgress && !self.atEndOfStory;
    },

    isLessThan(other) {
      function toString(pointer) {
        return `${pointer.chapter
          .toString()
          .padStart(3, '0')}-${pointer.iteration.toString().padStart(3, '0')}:${
          pointer.address
        }`;
      }
      return toString(self) < toString(other);
    },

    get atEndOfStory() {
      return self.chapter === END_OF_STORY_CHAPTER;
    },

    get completedChapters() {
      if (self.atEndOfStory) {
        return END_OF_STORY_CHAPTER;
      } else {
        return self.chapter - 1;
      }
    },
  }));
