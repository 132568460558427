import { getConfig } from 'config';
import Bugsnag from '@bugsnag/js';
import createLogger from '../lib/log';
import minibus from '../lib/minibus';
import { setContextData as setAnalyticsContextData } from '../lib/analytics';

const log = createLogger('error-reporting');

const enableBugsnag = getConfig('bugsnag.enabled');

export const setErrorReportingContext = data => {
  log.info('setting reporting context', data);
  setAnalyticsContextData(data);

  const { accountData } = data;
  Bugsnag.setUser(accountData.userId, accountData.email, accountData.name);
  Bugsnag.addMetadata('account', accountData);
};

minibus.subscribe('SET_REPORTING_CONTEXT', setErrorReportingContext);

export const logglyTags = () => {};

export const debugLog = (message, data) => {};

export const addBreadcrumb = config => {
  const { message = null, ...meta } = config;
  Bugsnag.addBreadcrumb(message, meta);
};

const reportExceptionWithBugsnag = async (error, info = {}, options = {}) => {
  const {
    //handlerTag = null,
    level = 'error',
  } = options;

  if (!(error instanceof Error) && info.exception) {
    error = info.exception; // for backward compatibility
  }

  Bugsnag.notify(error, event => {
    event.severity = level;
    event.addMetadata('info', info);
  });
};

let __disabledNotice = false;
export const reportException = enableBugsnag
  ? reportExceptionWithBugsnag
  : () => {
      if (!__disabledNotice) {
        log.error('Bugsnag is disabled for this build.');
        __disabledNotice = true;
      }
    };

window.notify = reportException;

export const notify = (...args) => {
  // eslint-disable-next-line no-console
  console.log('notify is deprecated, use notifyException instead');
  reportException(...args);
};
