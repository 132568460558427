import React from 'react';
export const IconArrowRight = ({ color = 'currentColor', ...props }) => (
  <svg
    height="24"
    width="24"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.9782 11L11.3146 4.72837L12.6854 3.27197L21.9591 12.0002L12.6854 20.7284L11.3146 19.272L17.9786 13H2V11H17.9782Z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);
