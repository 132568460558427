import React from 'react';
import { observer } from 'mobx-react';
import { useRootStore } from 'mst';
import { SectionBreak, SectionEnds } from 'components/ds/common/spacers';
import { Table } from 'components/ds/tables';
import { PageLayout } from 'components/ds/layouts/page-layout';
import { Header } from 'components/ds/header';
import { ClassLabelCell } from '../table-cells/class-label-cell';
import { CodeCell } from '../table-cells/code-cell';
import { ClassStudentsCell } from '../table-cells/class-students-cell';
import { MobileOnlyTableHeader } from '../mobile-only-table-header';
import { Text } from 'components/ds/common/text';
import { useClassroomListActions } from './use-classroom-list-actions';
import { useClassroomRedirectionEffect } from './use-classroom-redirection-effect';
import { CreateClassButton } from './create-class-button';
import { LicenseBadge } from 'components/classrooms/license-badge';

import { ClassroomNag } from '../classroom-nag';

import __ from 'jw-core/lib/localization';

export const ClassroomList = observer(() => {
  const store = useRootStore();
  const { loggedInAndReady } = store?.userManager;
  const { managedClassrooms, showClassroomLicenseNag } =
    store?.userManager?.accountData ?? {};

  const { getMenuItems } = useClassroomListActions();
  useClassroomRedirectionEffect();

  if (!loggedInAndReady) {
    return null;
  }

  return (
    <PageLayout>
      <Header
        title={__('My classes', 'classroom.myClasses')}
        accessory={<CreateClassButton />}
      />
      <MobileOnlyTableHeader>
        {__(
          {
            one: '%{count} class',
            other: '%{count} classes',
          },
          'classroom.countClasses',
          {
            count: managedClassrooms.length,
          }
        )}
      </MobileOnlyTableHeader>
      {managedClassrooms.length > 0 ? (
        <Table
          data={managedClassrooms}
          overflowMenuItems={getMenuItems}
          cells={[
            {
              fieldName: 'label',
              headerLabel: __('Label', 'classroom.label'),
              component: ClassLabelCell,
            },
            {
              headerLabel: __('Class code', 'classroom.classCode'),
              component: CodeCell,
            },
            {
              headerLabel: __('Students', 'classroom.students'),
              component: ClassStudentsCell,
            },
            {
              headerLabel: __('License', 'classroom.license'),
              renderCell: classroom => {
                return <LicenseBadge classroom={classroom} />;
              },
            },
          ]}
        />
      ) : (
        <Text>
          {__(
            'No active classes. New classes will appear here when you create them',
            'classroom.classListZeroState'
          )}
        </Text>
      )}
      <SectionBreak />
      <SectionEnds />
      {showClassroomLicenseNag ? <ClassroomNag /> : null}
    </PageLayout>
  );
});
