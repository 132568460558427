export const getParentOfName = (target, name) => {
  const $targetTreeNode = target.$treenode;
  let $parentNode = $targetTreeNode.parent;
  while ($parentNode) {
    if ($parentNode.type.name === name) {
      return $parentNode.storedValue;
    }
    $parentNode = $parentNode.parent;
  }
  throw new Error(
    `Failed to find the parent of ${$targetTreeNode} with name ${name}`
  );
};
