import React from 'react';
import ReactMarkdown from 'react-markdown';
import { includeTextStyles, getColor } from 'lib/theme/utils';

import styled from 'styled-components';

/**
 * We have to redefine styles here because there are very annoying global styles
 */
const Wrapper = styled.div`
  div,
  p,
  ul,
  ol,
  li {
    ${includeTextStyles('body')};
  }

  p {
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  a {
    color: ${getColor('dark-teal')};
    text-decoration: underline;
  }

  li {
    text-indent: 0;
    margin-left: 1em;
    margin-bottom: 16px;
  }

  ul {
    list-style-type: disc;
    li {
      list-style-type: disc;
    }
  }

  ol {
    list-style-type: decimal;
    li {
      list-style-type: decimal;
    }
  }
  strong {
    font-weight: 600;
  }
`;

export const MarkdownContainer = ({
  styleWrapper: StyleWrapper = Wrapper,
  ...props
}) => {
  return (
    <StyleWrapper>
      <ReactMarkdown {...props} />
    </StyleWrapper>
  );
};
