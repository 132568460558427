import { types } from 'mobx-state-tree';

export const stringOrNull = types.maybeNull(types.string);
export const stringDefaultBlank = types.optional(
  types.maybeNull(types.string),
  ''
);
export const stringDefaultUnexpected = types.optional(
  types.maybeNull(types.string),
  'UNEXPECTEDLY MISSING'
);

export const numberOrNull = types.maybeNull(types.number);
export const numberDefaultZero = types.optional(
  types.maybeNull(types.number),
  0
);

export const arrayOfStringsOrNull = types.maybeNull(types.array(types.string));
export const arrayOfStringsDefaultEmpty = types.optional(
  types.maybeNull(types.array(types.string)), // beware, still doesn't guard against null array elements (je: which i learned the hard way)
  []
);

export const booleanDefaultFalse = types.optional(
  types.maybeNull(types.boolean),
  false
);
export const booleanDefaultTrue = types.optional(
  types.maybeNull(types.boolean),
  true
);

export const identifierDefaultBlank = types.optional(
  types.maybeNull(types.identifier),
  ''
);
