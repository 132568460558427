import React from 'react';
export const IconNoteS = ({ color = 'currentColor', ...props }) => (
  <svg
    height="20"
    width="20"
    fill="none"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.5 18H5.5C4.67157 18 4 17.3284 4 16.5V3.5C4 2.67157 4.67157 2 5.5 2H14.5C15.3284 2 16 2.67157 16 3.5V12.5L10.5 18ZM15 12V3.5C15 3.22386 14.7761 3 14.5 3H5.5C5.22386 3 5 3.22386 5 3.5V16.5C5 16.7761 5.22386 17 5.5 17H10V13.5C10 12.6716 10.6716 12 11.5 12H15ZM11 16.0858V13.5C11 13.2239 11.2239 13 11.5 13H14.0858L11 16.0858Z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);
