import React from 'react';
import { ResendEmailButton } from './resend-email-btn.js';
import { CopyCodeButton } from './copy-code-btn.js';

export const CouponPurchase = ({ coupon }) => {
  // TODO: BEM is dumb. Rewrite as styled components at some point.
  const modifier = coupon.redeemed ? 'redeemed' : 'not-redeemed';
  const classNameModifier = className => {
    return `${className} ${className}--${modifier}`;
  };
  const codeClassName = classNameModifier('coupon-purchase__code');
  const redemptionClassName = classNameModifier('coupon-purchase__redemption');

  return (
    <div className="coupon-purchase">
      <div>
        <span className={codeClassName}>{coupon.code}</span>
        <span className="coupon-purchase__status-message">
          {coupon.purchaseStatusMessage}
        </span>
        <div className={redemptionClassName}>
          <span className="coupon-purchase__redemption-dot"></span>
          {coupon.redemptionStatusMessage}
        </div>
        {coupon.redeemed ? null : (
          <div className="coupon-purchase__actions">
            <CopyCodeButton code={coupon.code} />
            <ResendEmailButton coupon={coupon} name="name" />
          </div>
        )}
      </div>
    </div>
  );
};
