import React from 'react';
import { DialogTarget } from 'components/ui/dialog-target';
import { LoadingScreen } from 'components/ui/loading-screen';
import { BrowserRouter as Router } from 'react-router-dom';
// import { Switch, Route } from 'react-router-dom';
import { JwRoutes } from 'components/routes/jw-routes';
// import { StripeProvider } from './lib/stripe';
// import { GlobalNav } from 'components/portals/global-nav';
// import { GlobalFooter } from 'components/portals/global-footer';
import version from './lib/macros/version.macro';

// import { StoryGuideScreen } from 'components/routes/story-guide-screen';

window.jw_version = version;

const App = () => {
  return (
    <Router>
      {/* <GlobalNav /> */}
      <JwRoutes />
      <DialogTarget />
      <LoadingScreen />
      {/* <GlobalFooter /> */}
    </Router>
  );
};

export default App;
