import { compact, includes, pick } from 'lodash';
import { getEnv, getParent, getRoot, types } from 'mobx-state-tree';
import {
  BEGINNING_OF_CHAPTER,
  END_OF_CHAPTER,
  END_OF_STORY_CHAPTER,
} from '../lib/constants/vars';

import invariant from '../lib/invariant';
import { safelyHandleError } from '../lib/error-handling';
import * as safeTypes from '../lib/safe-mst-types';
import { LocationPointer } from './location-pointer';

/**
 * StoryProgress
 *
 * holds user's listening progress of a particular story
 */
export const StoryProgress = types
  .model('StoryProgress', {
    currentPoint: types.optional(
      types.maybeNull(types.late(() => LocationPointer)),
      {}
    ),
    furthestPoint: types.optional(
      types.maybeNull(types.late(() => LocationPointer)),
      {}
    ),
    vocabs: safeTypes.arrayOfStringsDefaultEmpty, //  word group slugs, i.e. "765-cruzar" (millis/100 + first word)
    lastListened: safeTypes.numberDefaultZero, // todo: figure out and document units here
  })
  .views(self => ({
    get $log() {
      const { createLogger = () => {} } = getEnv(self);
      const log = createLogger('um:story-progress');
      return log;
    },
  }))
  .actions(self => {
    // function completeChapterProgress() {
    //   logger('mst')('Finished current chapter');
    //   self.markCompleteChapter(self.currentPoint.chapter);
    // }

    /**
     *
     * [ ] - invoked upon exiting study view,
     * [ ] - update the StoryProgress' currentPoint and potentially furthestPoint
     * [ ] - if chapter is completed (based on lastPoint) then add/update ListeningLog entry
     *
     * @param highWaterMarkPoint LocationPointer
     */
    function updateProgress(
      furthestListenedAddress,
      furthestListenedMillis = 0 // not relevant if END_OF_CHAPTER address passed in
    ) {
      try {
        self.$log.info(
          `updateProgress to: ${furthestListenedAddress} ${furthestListenedMillis}`
        );
        const story = getParent(self);
        let { currentPoint, furthestPoint } = self;
        const chapter = story.currentChapter;
        invariant(chapter, 'updateProgress - no currentChapter');
        self.$log.info(`current chapter: ${JSON.stringify(chapter)}`);
        const isRelisten = currentPoint.iteration > 1;
        const {
          durationMillis: chapterLengthMillis,
          markCompleteMillis,
        } = chapter;

        if (furthestListenedMillis >= markCompleteMillis) {
          furthestListenedAddress = END_OF_CHAPTER;
        }

        const isChapterComplete = furthestListenedAddress === END_OF_CHAPTER;

        currentPoint.address = furthestListenedAddress;
        currentPoint.millisPlayed = furthestListenedMillis;

        if (isChapterComplete) {
          currentPoint.address = BEGINNING_OF_CHAPTER;
          currentPoint.millisPlayed = 0;
          if (currentPoint.iteration === 1) {
            currentPoint.iteration = 2;
          } else {
            currentPoint.chapter += 1;
            if (currentPoint.chapter > story.chapterCount) {
              self.$log.warn(
                `updateProgress - current chapter greater than total, marking story complete`
              );
              currentPoint = LocationPointer.create({
                chapter: END_OF_STORY_CHAPTER,
                // defaults are fine
                // address: BEGINNING_OF_CHAPTER,
                // iteration: 1,
                // millisPlayed: 0,
              });
            } else {
              if (furthestPoint.isLessThan(currentPoint)) {
                currentPoint.iteration = 1;
              } else {
                currentPoint.iteration = 2;
              }
            }
          }

          getRoot(self).userManager.userData.addListeningLog(
            self.slug,
            isRelisten ? 0 : chapterLengthMillis,
            isRelisten ? chapterLengthMillis : 0
          );
        }
        if (furthestPoint.isLessThan(currentPoint)) {
          furthestPoint = LocationPointer.create({ ...currentPoint });
        }
        self.currentPoint = currentPoint;
        self.furthestPoint = furthestPoint;
        // @armando, does this make sense to fork this last piece off, or should this entire method be made async and yield here?
        getRoot(self).asyncPersistAndSync();
      } catch (error) {
        safelyHandleError(self, error);
      }
    }

    function vocabExists(slug) {
      return includes(self.vocabs, slug);
    }

    function addVocab(slug) {
      if (!vocabExists(slug)) {
        self.vocabs.push(slug);
      }
    }

    function addVocabs(slugs = []) {
      self.$log.info(`addVocabs[${slugs}]`);
      slugs.map(addVocab);
    }

    function removeVocab(slug) {
      // should we check it exists?
      self.vocabs.remove(slug);
    }

    function clearVocabs() {
      self.$log.info('clearVocabs');
      self.vocabs.clear();
    }

    function removeVocabs(slugs = []) {
      self.$log.info(`removeVocabs[${slugs}`);
      slugs.map(removeVocab);
    }

    /**
     * remove any vocabs now found in current story data
     */
    function pruneOrphanVocabs() {
      const vocabLookupData = getParent(self).vocabLookupData;
      const orphans = self.vocabs.filter(slug => {
        return !vocabLookupData[slug];
      });
      if (orphans.length > 0) {
        self.$log.info(`removing orphaned vocabs: ${JSON.stringify(orphans)}`);
        self.removeVocabs(orphans);
      }
    }

    function markStoryComplete() {
      const location = {
        chapter: END_OF_STORY_CHAPTER,
      };
      self.currentPoint = LocationPointer.create(location);
      self.furthestPoint = LocationPointer.create(location);
      getRoot(self).asyncPersistAll();
    }

    function resetStory() {
      self.currentPoint = LocationPointer.create();
      self.furthestPoint = LocationPointer.create();
      self.clearVocabs();
      getRoot(self).asyncPersistAll();
    }

    // used when navigating back to a completed unit
    function relistenStory() {
      openChapter(1);
    }

    function openChapter(chapterPosition) {
      self.$log.info(
        `furthestPoint.chapter: ${self.furthestPoint.chapter}, chapterPosition: ${chapterPosition}`
      );
      const story = getParent(self);
      if (self.currentPoint.chapter === chapterPosition) {
        self.$log.info(`resuming current chapter - no change to pointers`);
        return;
      }
      if (self.furthestPoint.chapter < chapterPosition) {
        if (chapterPosition > story.chapterCount) {
          markStoryComplete();
        } else {
          self.$log.info(`advancing to new chapter: ${chapterPosition}`);
          const location = {
            chapter: chapterPosition,
          };
          self.currentPoint = LocationPointer.create(location);
          self.furthestPoint = LocationPointer.create(location);
        }
      } else if (self.furthestPoint.chapter > chapterPosition) {
        self.$log.info(`revisiting previous chapter: ${chapterPosition}`);
        self.currentPoint = LocationPointer.create({
          chapter: chapterPosition,
          iteration: 2,
        });
      } else {
        self.$log.info(
          `resuming furthest chapter: ${chapterPosition} - old furthest point: ${JSON.stringify(
            self.furthestPoint
          )}`
        );
        self.currentPoint = LocationPointer.create({
          ...self.furthestPoint,
        });
      }
    }

    function unlockChapter(chapterPosition) {
      markCompleteChapter(chapterPosition - 1);
    }

    function markCompleteChapter(chapterPosition) {
      if (chapterPosition >= getParent(self).chapterCount) {
        markStoryComplete();
      } else {
        const location = {
          chapter: chapterPosition + 1,
          // default values are fine for the rest of the properties
          // iteration: 1,
          // address: BEGINNING_OF_CHAPTER,
          // millisPlayed: 0,
        };
        self.currentPoint = LocationPointer.create(location);
        if (self.furthestPoint.chapter <= self.currentPoint.chapter) {
          self.furthestPoint = LocationPointer.create(location);
        }
        getRoot(self).asyncPersistAll();
      }
    }

    return {
      // completeChapterProgress,
      updateProgress,
      vocabExists,
      addVocab,
      addVocabs,
      removeVocab,
      removeVocabs,
      clearVocabs,
      pruneOrphanVocabs,
      markStoryComplete,
      resetStory,
      relistenStory,
      openChapter,
      unlockChapter,
      markCompleteChapter,
    };
  })
  .views(self => ({
    get slug() {
      return getParent(self).slug;
    },

    get unplayed() {
      return !self.played;
    },

    get played() {
      return self.furthestPoint?.played;
    },

    get inProgress() {
      return self.furthestPoint?.played && !self.completed;
    },

    // effectively a sub-state of completed.
    // used by the volume-level logic which reflects the distinction
    // of 'inProgress' when the user has started relistening after completing a volume
    get relistening() {
      return self.completed && self.listening;
    },

    // drives the "continue" CTA label
    get listening() {
      return self.currentPoint?.listening;
    },

    get completed() {
      return self.furthestPoint?.chapter === END_OF_STORY_CHAPTER;
    },

    get completedChapters() {
      // const chaptersCompleted = progress.storyProgress?.furthestPoint ? progress.storyProgress.furthestPoint.chapter + progress.storyProgress.furthestPoint.iteration - 2 : null;
      // if (self.furthestPoint?.chapter && self.furthestPoint?.iteration) {
      //   return self.furthestPoint.chapter + self.furthestPoint.iteration - 2;
      // } else {
      //   return 'n/a';
      // }
      return self.furthestPoint?.completedChapters;
    },

    get status() {
      if (self.unplayed) {
        return 'UNPLAYED';
      }
      if (self.completed) {
        return 'COMPLETED';
      }
      return 'IN_PROGRESS';
    },

    // @armando should be using `vocabs` or `vocab` for these getter names?
    get vocabsCount() {
      return self.vocabs.length;
    },

    get hasVocab() {
      return self.vocabs.length > 0;
    },

    /**
     * data needed by vocab view
     */
    get vocabsViewData() {
      const { vocabLookupData } = getParent(self);
      const list = compact(
        self.vocabs.map(slug => {
          return vocabLookupData[slug];
        })
      ).sort((vocabA, vocabB) => {
        if (vocabA.chapterPosition !== vocabB.chapterPosition) {
          return vocabA.chapterPosition - vocabB.chapterPosition;
        }
        return vocabA.address.localeCompare(vocabB.address);
      });
      const chapterMap = {};
      list.forEach(row => {
        let chapterData = chapterMap[row.chapterPosition];
        if (!chapterData) {
          chapterData = pick(row, ['chapterPosition', 'chapterTitle']);
          chapterData.data = [];
          chapterMap[row.chapterPosition] = chapterData;
        }
        chapterData.data.push(row);
      });
      return Object.values(chapterMap);
    },

    get listeningStats() {
      return getRoot(self).userManager.userData.storyListeningStats(self.slug);
    },
  }));
