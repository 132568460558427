import React from 'react';
import __ from 'jw-core/lib/localization';
import { PricingCard } from './pricing-card';

export const FreeTrialPricingCard = () => {
  return (
    <PricingCard
      cta={'Download now'}
      featureTitle={__('2 sample stories', 'plans.twoSampleStories')}
      featureSubTitle={__(
        'No credit card, no obligation',
        'plans.noCreditCardNoObligation'
      )}
      normalPrice={0}
      price={0}
      title={__('Free trial', 'plans.freeTrial')}
      type={'free-trial'}
      onCtaClick={() => {
        window.open('https://get.lupa.app');
      }}
    />
  );
};
