import React from 'react';
import { observer } from 'mobx-react';
import { useRootStore } from 'mst';
import { includeTextStyles, getColor } from 'lib/theme/utils';
import { OverflowMenu, ConfirmableMenuItem } from 'components/ds/menus';
import { SectionTitle } from 'components/ds/common/section-title';
import { Gap } from 'components/ds/common/spacers';
import { SectionBreak } from 'components/ds/common/spacers';
import styled, { keyframes } from 'styled-components';
import __ from 'jw-core/lib/localization';
import { MarkdownContainer } from 'components/ds/common/markdown-container';
import cx from 'classnames';

// #FFEEAA = light-yellow,
// we cannout use getColor inside of keyframes for some reason.
const fade = keyframes`
    0% { background: #FFEEAA; }
    50% { background: #FFEEAA; }
    100% { background: transparent; }
`;

const Wrapper = styled.div`
  --border-color: ${getColor('black-hairline')};
  ${includeTextStyles('body')}

  .notice {
    ${includeTextStyles('small-text')};
    color: ${getColor('gray-4')};
    p {
      /* stupid global styles */
      color: inherit;
      font-size: inherit;
    }
  }
  .class-list {
    border-bottom: 1px solid var(--border-color);
  }
  .class-row {
    border-top: 1px solid var(--border-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    &.highlighted {
      animation-name: ${fade};
      animation-duration: 2.5s;
    }

    div.name {
      flex: 1;
      padding: 20px 0;
    }
    div.menu {
      margin-left: auto;
    }
  }
`;

export const JoinedClassrooms = observer(() => {
  const store = useRootStore();
  const { flash } = store;
  const { joinedClassrooms = [] } = store?.userManager?.accountData ?? {};
  if (joinedClassrooms.length === 0) {
    return null;
  }
  return (
    <Wrapper>
      <SectionTitle>{__('My classes', 'classroom.myClasses')}</SectionTitle>
      <div className="notice">
        <MarkdownContainer>
          {__(
            'Sign in on the [Lupa mobile app](%{url}) to see class assignments ',
            'account.joinedClassroomsNotice',
            {
              url: 'https://get.lupa.app/',
            }
          )}
        </MarkdownContainer>
        <Gap size={2}></Gap>
      </div>
      <div className="class-list">
        {joinedClassrooms.map(classroom => {
          return (
            <div
              className={cx('class-row', {
                highlighted:
                  flash?.messageParams?.classroomLabel === classroom.label,
              })}
              key={`class-${classroom.id}`}
            >
              <div className="name">{classroom.label}</div>
              <div className="menu">
                <OverflowMenu menuPosition="top-right">
                  <ConfirmableMenuItem
                    label={__('Leave classroom...', 'account.leaveClassroom')}
                    action={classroom.drop}
                    confirmationTitle={__(
                      'Leave %{class}?',
                      'account.leaveClassroomDialogTitle',
                      { class: classroom.label }
                    )}
                    confirmationBody={__(
                      'You will no longer see class assignments, and your progress will not be visible to the teacher.',
                      'account.leaveClassroomDialogBody'
                    )}
                    confirmationOkButtonLabel={__(
                      'Leave',
                      'account.leaveClass'
                    )}
                  />
                </OverflowMenu>
              </div>
            </div>
          );
        })}
      </div>
      <SectionBreak />
    </Wrapper>
  );
});
