import { flow, getEnv, types } from 'mobx-state-tree';
import { getParentOfName } from '../lib/type-utils/get-parent-of-name';
import * as safeTypes from '../lib/safe-mst-types';
import __ from '../lib/localization';
import { millisToPrettyDuration } from '../lib/pretty-duration';
import { assetKeys } from '../download-manager';
import { getCarouselButtonLabels } from '../lib/constants/vars';

export const ChapterCatalogData = types
  .model('ChapterCatalogData', {
    title: safeTypes.stringDefaultBlank,
    position: safeTypes.numberDefaultZero,
    durationMillis: safeTypes.numberDefaultZero,
    markCompleteMillis: safeTypes.numberDefaultZero,
    normalAudioUrl: safeTypes.stringDefaultBlank,
    noMusicAudioUrl: safeTypes.stringOrNull,
  })
  .views(self => ({
    get $log() {
      const { createLogger = () => {} } = getEnv(self);
      const log = createLogger('mst-root');
      return log;
    },
  }))
  .actions(self => {
    const story = getParentOfName(self, 'Story');
    return {
      open: flow(function* open() {
        self.$log.info(`open chapter ${self.position}`);
        story.progress.openChapter(self.position);
        yield story.open();
      }),

      unlock() {
        self.$log.info(`unlock chapter ${self.position}`);
        story.progress.unlockChapter(self.position);
      },

      markComplete() {
        self.$log.info(`markComplete chapter ${self.position}`);
        story.progress.markCompleteChapter(self.position);
      },
    };
  })
  .views(self => {
    const story = getParentOfName(self, 'Story');

    const flattenChapter = (root, flatList = []) => {
      if (root.entityType === 'sentence') {
        flatList.push(root);
      } else {
        const { elements, ...rest } = root;
        if (root.entityType !== 'chapter') {
          flatList = [...flatList, rest];
        }
        if (elements && elements.length) {
          elements.forEach(element => {
            flatList = flattenChapter(element, flatList);
          });
        }
      }
      return flatList;
    };

    const extractVocabSlugs = (node, result = []) => {
      if (node.entityType === 'word_group' && node.groupType === 'vocab') {
        result.push(node.id);
      }
      const { elements } = node;
      if (elements && elements.length) {
        elements.forEach(element => {
          result = extractVocabSlugs(element, result);
        });
      }
      return result;
    };

    return {
      get story() {
        return getParentOfName(self, 'Story');
      },

      get normalAudioAssetKey() {
        return assetKeys.NORMAL_AUDIO_PREFIX + self.position;
      },

      get normalAudioPath() {
        const story = getParentOfName(self, 'Story');
        return story?.download.assetPath(self.normalAudioAssetKey);
      },

      get fullData() {
        if (story.fullData) {
          const { chapters } = story.fullData;
          const data = chapters[self.position - 1];
          return data || null;
        }
        return null;
      },

      get flatElements() {
        return flattenChapter(self.fullData);
      },

      /**
       * proto support - list of all vocab in current chapter
       */
      get vocabSlugs() {
        return extractVocabSlugs(self.fullData);
      },

      /**
       * proto support - user progress selected vocabs filtered to this chapter
       */
      get selectedVocabSlugs() {
        const story = getParentOfName(self, 'Story');
        const vocabLookupData = story.vocabLookupData;
        return story.progress.vocabs.filter(slug => {
          const data = vocabLookupData[slug];
          return data && data.chapterPosition === self.position;
        });
      },

      // deprecated
      get timeLeft() {
        return self.timeLeftMillis();
      },

      get timeLeftMillis() {
        return self.durationMillis - story.progress.currentPoint.millisPlayed;
      },

      get isCurrent() {
        return story.progress.currentPoint.chapter === self.position;
      },

      get isFurthest() {
        return story.progress.furthestPoint.chapter === self.position;
      },

      get isSemiLocked() {
        const { furthestPoint } = story.progress;
        return (
          furthestPoint.chapter === self.position - 1 &&
          furthestPoint.iteration === 2
        );
      },

      get isCompleted() {
        const { furthestPoint } = story.progress;
        return self.position < furthestPoint.chapter;
      },

      get isLocked() {
        const { furthestPoint } = story.progress;
        return !(self.position <= furthestPoint.chapter || self.isSemiLocked);
      },

      get activeCrumb() {
        const { furthestPoint } = story.progress;
        if (self.isSemiLocked) {
          return 0;
        }

        if (self.isCompleted || furthestPoint.iteration > 2) {
          return 1000;
        }

        if (furthestPoint.iteration === 1) {
          return 1;
        }
        if (furthestPoint.iteration >= 2) {
          return 2;
        }

        // this should never happen.
        self.$log.debug(
          `unexpected active crumb - ${JSON.stringify(story.progress)}`
        );
        return -1;
      },

      get currentPlayProgress() {
        if (!self.isCurrent) {
          return 0;
        }
        const { currentPoint } = story.progress;
        return currentPoint.millisPlayed / self.durationMillis;
      },

      get furthestPlayProgress() {
        if (!self.isFurthest) {
          return 0;
        }
        const { furthestPoint } = story.progress;
        // not sure why this wasn't working in web-proto - revisit later
        // self.$log.info(`furthestPlayProgress - millisPlayed ${furthestPoint.millisPlayed}, duration: ${self.durationMillis}`);
        return furthestPoint.millisPlayed / self.durationMillis;
      },

      get durationInWords() {
        // const prettyDuration = duration => {
        //   const minutes = Math.floor(duration / (1000 * 60));
        //   const seconds = Math.floor((duration / 1000) % 60);
        //   return `${
        //     minutes > 0
        //       ? __('%{minutes}m', 'duration.minutes', { minutes })
        //       : ''
        //   }${__('%{seconds}s', 'duration.seconds', { seconds })}`;
        // };

        if (self.isCurrent) {
          const durationString = millisToPrettyDuration(self.timeLeftMillis);
          if (story.progress.currentPoint.millisPlayed > 0.0) {
            return __('%{duration} remaining', 'duration.remaining', {
              duration: durationString,
            });
          }
          return durationString;
        }

        return millisToPrettyDuration(self.durationMillis);
      },

      // this doesn't belong at the catalog level
      get buttonLabel() {
        const {
          BEGIN,
          RELISTEN,
          CONTINUE_LISTEN,
          CONTINUE_RELISTEN,
          AGAIN,
        } = getCarouselButtonLabels();
        const { furthestPoint: fp, currentPoint: cp } = story.progress;

        const isOnFirstListen = cp.iteration === 1;
        const isFurthestPointFirstListen = fp.iteration === 1;
        const isListeningChapter = cp.millisPlayed > 0;
        const isListeningFurthestPoint = fp.millisPlayed > 0;

        if (self.isCurrent && isListeningChapter) {
          if (isOnFirstListen) {
            return CONTINUE_LISTEN;
          }
          return CONTINUE_RELISTEN;
        }

        if (self.isFurthest && isListeningFurthestPoint) {
          if (isFurthestPointFirstListen) {
            return CONTINUE_LISTEN;
          }
          return CONTINUE_RELISTEN;
        }

        if (self.isCompleted) {
          return AGAIN;
        }
        if (self.isFurthest && fp.iteration === 2) {
          return RELISTEN;
        }
        return BEGIN;
      },

      get startingAssistMode() {
        const { currentPoint, furthestPoint } = story.progress;

        if (
          (currentPoint.chapter === self.position &&
            currentPoint.iteration > 1) ||
          self.position < furthestPoint.chapter
        ) {
          return false;
        }

        return true;
      },

      get showUnlock() {
        return self.isLocked || self.isSemiLocked;
      },

      get showMarkComplete() {
        return self.isFurthest && !self.isCompleted; // furthest can probably never be complete, but no harm in the redundancy
      },

      get hasCarouselCardContextMenu() {
        return self.showUnlock || self.showMarkComplete;
      },
    };
  });
