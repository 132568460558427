const palette = {
  'gray-6': '#444444',
  'gray-5': '#626262',
  'gray-4': '#999999',
  'gray-3': '#BBBBBB',
  'gray-2': '#D0D0D0',
  'gray-1': '#E5E5E5',
  'off-off-white': '#F0F0F0',
  'off-white': '#FAFAFA',
  white: '#FFFFFF',
  'darker-blue': '#45769F',
  'dark-blue': '#4C7FAA',
  'dark-teal': '#00ABC6',
  'darker-teal': '#0a91a6',
  teal: '#19BBD4',
  'bright-teal': '#40E6FF',
  'very-dark-green': '#42A745',
  'dark-green': '#45B248',
  'dark-soft-green': '#009B6F',
  green: '#44C548',
  'light-green': '#4ED152',
  'very-dark-yellow': '#DEB100',
  'dark-yellow': '#F6C790',
  yellow: '#FFDF5E',
  'light-yellow': '#FFEEAA',
  'very-light-yellow': '#FFF2CD',
  orange: '#F5832B',
  'light-orange': '#FFA000',
  red: '#FF6767',
  'dark-red': '#F43D3D',
  pink: '#FFADAD',
  'light-pink': '#FFE0E0',
  'black-hairline': 'rgba(0, 0, 0, 0.12)',
};

export const colors = {
  ...palette,
  /**
   * Tables
   * =====================================
   */
  'table-header-text-color': palette['gray-4'],
  'table-header-background-color': palette['off-white'],
  'table-header-border-color': palette['gray-1'],
  'table-cell-alternate-background-color': palette['white'],
  'table-cell-border-color': palette['gray-1'],

  /**
   * Headers
   * =====================================
   */
  'header-main-text-color': palette['gray-6'],
  'header-backlink-text-color': palette['gray-4'],
  'header-backlink-text-hover-color': palette['dark-teal'],
  'header-backlink-icon-color': palette['gray-4'],
  'header-backlink-icon-hover-color': palette['dark-teal'],
};
