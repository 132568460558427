import styled from 'styled-components';
import { isNumber, isFunction, get } from 'lodash';

export const addUnit = (n, unit = 'px') => {
  if (isNumber(n) && n !== 0) {
    return `${n}${unit}`;
  }
  return n;
};

export const px = n => {
  return addUnit(n, 'px');
};

export const includeProp = propName => props => {
  const { theme } = props;
  return get(theme, propName);
};

export const includeTextStylesFromProp = (
  propName = 'textStyle',
  defaultStyle = 'body'
) => props => {
  const { theme } = props;
  const key = props[propName] || defaultStyle;
  return get(theme, ['textStyles', key]);
};

export const includeTextStyles = (textStyle = 'body') => props => {
  const { theme } = props;
  return get(theme, ['textStyles', textStyle]);
};

export const getColorFromProp = (
  propName = 'color',
  defaultValue = 'inherit'
) => props => {
  const { theme } = props;
  const colorName = get(props, propName, defaultValue); // if there's no prop it will default to `inherit` or the value of `defaultValue`
  const value = get(theme, ['colors', colorName], colorName);
  return value;
};

const createThemePropertyAccessor = propertyRoot => propertyKey => props => {
  const { theme } = props;
  return get(theme, [propertyRoot, propertyKey], propertyKey);
};

export const getColor = colorName => props => {
  const { theme } = props;
  return get(theme, ['colors', colorName], colorName);
};

export const getFontName = fontName => props => {
  const { theme } = props;
  return get(theme, ['fonts', fontName], fontName);
};

export const getDimension = createThemePropertyAccessor('dimensions');

const maybeFunc = (fn, props) => {
  if (isFunction(fn)) {
    return fn(props);
  }

  return fn;
};

export const prop = propName => props => {
  return get(props, ['theme', propName]);
};

export const cond = (prop, whenTrue, whenFalse) => props => {
  const result = get(props, prop) === true;
  return result ? maybeFunc(whenTrue, props) : maybeFunc(whenFalse, props);
};

export const extend = (Component, defaultProps = {}) =>
  styled(Component).attrs(defaultProps);
