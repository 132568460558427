// unstructured error object properties used by main error handler:
// alertLevel - string - controls if red or yellow style notifications are presented, fallback: 'WARN'
// report - boolean - if false, skip bugsnag report, fallback: true
// userMessage - string - end-user friendly version of message to display
// expected - boolean - an error that's expected to be seen under normal conditions, if not marked as expected, then user message is prefixed as such
// globalErrorHandler - boolean - set to true when picked up the global error handler - used to avoid duplicate bugsnag reports

import minibus from './minibus';
import __ from './localization';

// import logger from '../../lib/log';
// const log = logger('errors');

export const alertLevels = {
  ERROR: 'ERROR', // intended for end-users, always visible
  WARN: 'WARN', // extra visibility intended only for internal builds
  // NONE: 'NONE',
};

const { ERROR, WARN } = alertLevels;

const prefix = (p, s) => {
  return `[${p}] : ${s}`;
};

class GenericError extends Error {
  constructor(message) {
    super(message);
    this.name = this.constructor.name;
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }
  }
}

export class NetworkError extends GenericError {
  constructor(error, context) {
    super(
      error instanceof Error
        ? prefix(error.constructor.name, error.message)
        : error
    );

    this.context = context;
    this.alertLevel = ERROR;
    this.report = false;

    // console.log(
    //   `constructor name: ${error.constructor.name}, isError: ${String(
    //     error instanceof Error
    //   )}`
    // );

    if (error instanceof Error) {
      // get the fetch options too
      this.context = { error, ...context };
      this.type = this.constructor.type;
    }
  }
}

export class ValidationError extends GenericError {
  constructor(error) {
    super(error.message);
    this.context = { error };
    this.key = error.key;
    this.code = error.code;
    this.expected = true;
    this.report = false;
    // expected to be usually by the safelyHandleError logic
    // but if this does reach the gobal-error-handler it should generally be
    // end-user visible
    this.alertLevel = ERROR;
  }
}

export class APIError extends GenericError {
  constructor(error) {
    // super(prefix('API Error', error.message));
    super(error.message);
    this.context = { error };
    this.key = error.key;
    this.type = this.constructor.type;
    this.alertLevel = ERROR;
    this.report = false;
    this.expected = true;
  }
}

/**
 * We just need a class to do instanceof on
 */
export class DiskSpaceError extends GenericError {
  constructor(error) {
    super(error.message);
    this.alertLevel = ERROR;
    this.report = true; // not our fault, but capture for visibility
  }
}

export const isDiskSpaceError = error => {
  /* Android No-space Error */
  if (error.message?.indexOf('ENOSPC') !== -1) {
    return true;
  }

  /* iOS No-space Error */
  if (error.message?.indexOf('Code=640') !== -1) {
    return true;
  }

  /* SQLite error */
  if (error.message?.indexOf('SQLITE_FULL') !== -1) {
    return true;
  }
};

/**
 * Handles either an error instance or:
 * @param type - server provided error class name
 * @param message
 * @param key - corresponding form field name if a validation style error
 * @returns {GenericError}
 */
export const createError = errorData => {
  if (errorData instanceof Error) {
    return errorData;
  }
  const { type, message, key, code } = errorData;

  switch (type) {
    case undefined: {
      // right now the server will send {"error":"message"} style responses if there's a grape level validation error
      const resolvedMessage = message || String(errorData);
      // return new GenericError(resolvedMessage);
      // log.info(
      //   `errors.createError - wrapping w/ HandleableError - data: ${errorData}`
      // );
      return new HandleableError(resolvedMessage, {
        alertLevel: ERROR,
        report: false,
      });
    }

    case 'ValidationError':
      return new ValidationError({ message, key, code });

    default:
      return new APIError({ type, message, key });
  }
};

/**
 * This is an error class that can wrap another error
 * and has useful properties that instructs our global handler
 * on how to better deal with errors
 */
export class HandleableError extends GenericError {
  constructor(error, options = {}) {
    const {
      context = null,
      alertLevel = WARN,
      report = true,
      alert = false,
      level = 'error',
      handlerTag = 'nonfatal-global-error',
      code = 'JW_HANDLEABLE',
    } = options;
    if (error instanceof Error) {
      const message = error.message;
      super(message);
      // this wraps the original error
      this.cause = error;
      // surface the code from internal errors
      // so we can blanket-ignore some of them
      if (error.code) {
        this.code = error.code;
      } else {
        this.code = code;
      }

      // this.framesToPop = error.framesToPop;
      this.stack = error.stack;
    } else {
      super(error);
    }

    this.name = 'HandleableError';
    this.context = context;
    this.report = report;
    this.level = level;
    this.handlerTag = handlerTag;
    this.alert = alert;
    this.alertLevel = alertLevel;
  }
}

/**
 * Like HandleableError, but tweaked to deal with StoreKit quirks
 */
export class StoreKitError extends GenericError {
  name = 'StoreKitError';
  constructor(error, options = {}) {
    let { message, code } = error;

    if (code === 'E_USER_CANCELLED') {
      message = __(
        'Purchase has been cancelled',
        'purchase.cancelledByTheUser'
      );
    }

    super(message);

    this.originalError = error;
    this.report = false;
    this.alertLevel = ERROR;

    // mostly for analytics, but we don't want this file depending on analytics
    minibus.emit(`STOREKIT_ERROR:${code}`);
  }
}

/**
 * utility function to create errors that can be properly handled by the global error handler
 *
 * options = {
 *   defer = if true, wraps exception in a RAF
 *   report = if true, tells the global error handler to report to sentry
 *   level = error level
 *   alert = if set to true, it will show a toast
 * }
 *
 * @param {*} error
 */
export const fail = (error, options = {}) => {
  const { defer = true, ...rest } = options;

  let ErrorConstructor = null;
  if (error.domain && error.domain === 'SKErrorDomain') {
    ErrorConstructor = StoreKitError;
  } else {
    if (!error instanceof Error) {
      ErrorConstructor = HandleableError;
    }
  }

  const throwError = () => {
    if (ErrorConstructor) {
      // log.info(`errors.fail - wrapping base error: ${error}`);
      // this wraps the error received into an error that can be handled by the global error handler
      throw new ErrorConstructor(error, { ...rest });
    } else {
      // the global error handler should be able to handle most typed Error instances
      throw error;
    }
  };

  if (defer) {
    // WHY am I wrapping this? in a RAF?
    // Glad you asked! It's because if we throw inside a component
    // and we interrupt the rendering of such component, then React
    // intercepts the error and it never gets to our global error handler
    // which is what we want most of the time.
    window.requestAnimationFrame(throwError);
  } else {
    throwError();
  }
};
