import React from 'react';
import { Link } from 'react-router-dom';
import { useRootStore } from '../../truth';
import __ from 'jw-core/lib/localization';

export const CloseAccountLink = () => {
  const store = useRootStore();

  if (store.userManager?.accountData?.membershipState !== 'trial') {
    return null;
  }
  return (
    <div className="account__close-account-container">
      <Link to="/close-account" className="account__close-account-link">
        {__('Close account', 'account.closeAccount')}
      </Link>
    </div>
  );
};
