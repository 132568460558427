import { flow, getEnv, getRoot, types } from 'mobx-state-tree';
import { composeWithName } from '../lib/type-utils/compose-with-name';
import { getParentOfName } from '../lib/type-utils/get-parent-of-name';

import Dayjs from 'dayjs';
import { ApiAccess } from '../api-access';
import { CLASSROOM_FILTER_KEY_PREFIX } from '../lib/constants/vars';
import __ from '../lib/localization';
import { safelyHandleError } from '../lib/error-handling';
import { sortBy } from '../lib/util';
import { StudentProgress } from './student-progress';

import * as safeTypes from '../lib/safe-mst-types';
import { alertLevels } from '../lib/errors';

const { ERROR } = alertLevels;

/**
 * Assignment
 */
export const Assignment = types
  .model('Assignment', {
    episodeSlug: safeTypes.stringOrNull, // storySlug
    dueDate: safeTypes.stringOrNull, // iso8601 formatted date string
    details: safeTypes.stringOrNull, // ad hoc teacher notes
    studentProgresses: types.optional(
      types.maybeNull(types.late(() => types.array(StudentProgress))),
      []
    ),
  })
  .actions(self => ({
    updateProps(props) {
      const classroom = getParentOfName(self, 'Classroom');
      return classroom?.updateAssignmentProps(self.episodeSlug, props);
    },

    // the UI calls it "note". The API calls it "details". This helps with the cognitive dissonance.
    setNote(details) {
      return self.updateProps({ details });
    },

    setDueDate(dueDate) {
      return self.updateProps({ dueDate });
    },

    resetDueDate() {
      return self.setDueDate('1970-01-01'); // magic date
    },
  }))
  .views(self => ({
    get story() {
      return getRoot(self).storyManager.story(self.episodeSlug);
    },
  }));

export const Student = types
  .model('Student', {
    email: safeTypes.stringDefaultBlank,
    name: safeTypes.stringDefaultBlank,
    membershipType: safeTypes.stringDefaultBlank,
    accessDescription: safeTypes.stringDefaultBlank,
    fullAccess: safeTypes.booleanDefaultFalse,
    fullAccessUntil: safeTypes.stringOrNull, // iso8061 formatted date string
  })
  .actions(() => ({
    // none yet
  }))
  .views(self => ({
    // none yet
    get localizedFullAccessUntil() {
      return Dayjs(self.fullAccessUntil).format(
        __('DD MMM, YYYY', 'common.dateFormat')
      );
    },
  }));

const localizeLicenseDate = date =>
  Dayjs(date).format(__('DD MMM, YYYY', 'common.dateFormat'));

export const License = types
  .model('License', {
    isActive: safeTypes.booleanDefaultFalse,
    isExpired: safeTypes.booleanDefaultFalse,
    startDate: safeTypes.stringOrNull, // never expected to be null - server will default to 'today' if missing from db
    endDate: safeTypes.stringOrNull, // never expected to be null - server will default to 'today' if missing from db

    seatLimit: safeTypes.numberDefaultZero,
    studentCount: safeTypes.numberDefaultZero,
    isOverSubscribed: safeTypes.booleanDefaultFalse,

    ownerName: safeTypes.stringOrNull, // not currently used by client
    licenseNotes: safeTypes.stringOrNull, /// not currently used by client

    // todo: remove these once new license support fully rolled out
    seatsTaken: safeTypes.numberDefaultZero, // beware, should not be used anymore. remove once all other project dependencies are removed
    seatsAvailable: safeTypes.numberDefaultZero,
    fixedDurationMonths: safeTypes.numberDefaultZero,
    fixedExpirationDate: safeTypes.stringOrNull, // iso8061 formatted date string
    durationDescription: safeTypes.stringOrNull, // no longer displayed within client
  })
  .actions(() => ({
    // none yet
  }))
  .views(self => ({
    // none yet
    get expirationDateAsDate() {
      // TODO: take timezones into account. That's why UNIX timestamps ftw
      return new Date(self.fixedExpirationDate);
    },

    get localizedExpirationDate() {
      return localizeLicenseDate(self.fixedExpirationDate);
    },

    get licenseDescription() {
      if (self.isExpired) {
        return __(
          'Expired, %{date}',
          'classroom.licensing.expiredDescription',
          {
            date: localizeLicenseDate(self.endDate),
          }
        );
      } else {
        return __(
          '%{startDate}–%{endDate}',
          'classroom.licensing.startDashEndDescription',
          {
            startDate: localizeLicenseDate(self.startDate),
            endDate: localizeLicenseDate(self.endDate),
          }
        );
      }
    },

    get seatsDescription() {
      return __('%{taken}/%{limit}', 'classroom.licensing.seatsDescription', {
        taken: self.studentCount,
        limit: self.seatLimit,
      });
    },

    get type() {
      if (self.fixedExpirationDate !== null) {
        return 'fixed-date';
      }

      return 'time-based';
    },
  }));

/**
 * Classroom
 *
 * for 'managedClassroom's:
 * the teacher's view of created classrooms and student progress
 *
 * for 'joinedClassroom's:
 * the students view of assigned episodes
 */
export const Classroom = composeWithName(
  'Classroom',
  ApiAccess,
  types
    .model('Classroom', {
      id: safeTypes.identifierDefaultBlank, // db key for now, might be changed to a GUID in the future
      label: safeTypes.stringDefaultBlank, // class display name
      code: safeTypes.stringOrNull, // classroom join code
      archived: safeTypes.booleanDefaultFalse, // for now, always filtered out by the server
      studentCount: safeTypes.numberDefaultZero,
      trialStudentCount: safeTypes.numberDefaultZero,
      assignments: types.optional(
        types.maybeNull(types.late(() => types.array(Assignment))),
        []
      ),
      students: types.optional(
        types.maybeNull(types.late(() => types.array(Student))),
        []
      ),
      license: types.maybeNull(types.maybeNull(types.late(() => License))), // classroom join code
    })
    .volatile(() => ({}))
    .views(self => ({
      get $log() {
        const { createLogger = () => {} } = getEnv(self);
        const log = createLogger('classroom');
        return log;
      },
      // @armando, this seems untrustworthy, so just inlining where used
      // get $notifications() {
      //   // yikes! this is somehow getting initialized too soon now for the spa
      //   // and $notifications is just a blank object
      //   const { notifications = {} } = getEnv(self);
      //   return notifications;
      // },
    }))
    .actions(self => ({
      archive: flow(function* archive() {
        try {
          self.$log.info('classroom.archive');
          const {
            message,
            // messageKey,
            accountData,
          } = yield self.apiPut(`classrooms/${self.id}`, {
            archived: true,
          });

          const manager = getParentOfName(self, 'UserManager');
          yield manager?.applyNewAccountData(accountData);
          // self.$notifications.notifySuccess(message);
          // it appears that something about the apply account data is breaking
          // our injected services. will address in a separate pass,
          // but at least it won't crash this way.
          getEnv(self).notifications?.notifySuccess(message);
        } catch (error) {
          safelyHandleError(self, error, { unexpectedAlertLevel: ERROR });
        }
      }),

      updateLabel: flow(function* updateLabel(label) {
        try {
          self.$log.info('classroom.updateLabel');
          const {
            message,
            // messageKey,
            accountData,
          } = yield self.apiPut(`classrooms/${self.id}`, {
            label: label,
          });

          const manager = getParentOfName(self, 'UserManager');
          yield manager?.applyNewAccountData(accountData);
          // self.$notifications.notifySuccess(message);
          getEnv(self).notifications?.notifySuccess(message);
        } catch (error) {
          safelyHandleError(self, error, { unexpectedAlertLevel: ERROR });
        }
      }),

      updateAssignmentList: flow(function* updateAssignments(slugs) {
        try {
          self.$log.info('classroom.updateAssignments');
          const { message, /*messageKey,*/ accountData } = yield self.apiPut(
            `classrooms/${self.id}`,
            {
              episodeSlugs: slugs.join(','),
            }
          );

          // this success notif works when triggered before the account update, but actually
          // changes behavior vs what's currently in production, so will
          // leave the original behavior until after we revisit this on a wider level
          // self.$log.info(`before apply - ns: ${JSON.stringify(getEnv(self).notifications)} - message: ${message}`);
          // getEnv(self).notifications?.notifySuccess(message);

          const manager = getParentOfName(self, 'UserManager');
          yield manager?.applyNewAccountData(accountData);
          // self.$notifications.notifySuccess(message);
          // console.log(`after apply ns: ${JSON.stringify(getEnv(self).notifications)} - message: ${message}`);
          // this still won't actually do anything, but at least it won't crash
          getEnv(self).notifications?.notifySuccess(message);
        } catch (error) {
          safelyHandleError(self, error, { unexpectedAlertLevel: ERROR });
        }
      }),

      updateAssignmentProps: flow(function* updateAssignment(
        episodeSlug,
        props
      ) {
        try {
          self.$log.info('classroom.updateAssignmentDetails');
          if (!episodeSlug) {
            throw new Error('episodeSlug required');
          }
          const { message, /*messageKey,*/ accountData } = yield self.apiPut(
            `classrooms/${self.id}/assignments/${episodeSlug}`,
            props
          );

          const manager = getParentOfName(self, 'UserManager');
          yield manager?.applyNewAccountData(accountData);
          // self.$notifications.notifySuccess(message);
          getEnv(self).notifications?.notifySuccess(message);
        } catch (error) {
          safelyHandleError(self, error, { unexpectedAlertLevel: ERROR });
        }
      }),

      fetchAssignmentData: flow(function* fetchAssignmentData(episodeSlug) {
        try {
          self.$log.info('classroom.fetchAssignmentData', episodeSlug);
          if (!episodeSlug) {
            throw new Error('episodeSlug required');
          }
          const data = yield self.apiGet(
            `classrooms/${self.id}/assignments/${episodeSlug}`
          );
          // self.$log.debug('fetched assignment data: ', data);
          return Assignment.create(data);
        } catch (error) {
          safelyHandleError(self, error, { unexpectedAlertLevel: ERROR });
        }
      }),

      resetFetchedAssignmentData() {
        self.fetchedAssignment = null;
      },

      dropStudent: flow(function* dropStudent(email) {
        try {
          self.$log.info('classroom.dropStudent');
          const { message, /*messageKey,*/ accountData } = yield self.apiPost(
            `classrooms/${self.id}/drop_student`,
            {
              email,
            }
          );

          const manager = getParentOfName(self, 'UserManager');
          yield manager?.applyNewAccountData(accountData);
          // self.$notifications.notifySuccess(message);
          getEnv(self).notifications?.notifySuccess(message);
        } catch (error) {
          safelyHandleError(self, error, { unexpectedAlertLevel: ERROR });
        }
      }),

      // allows a student to remove themself from a class
      drop: flow(function* drop() {
        try {
          self.$log.info('classroom.drop');
          const { message, /*messageKey,*/ accountData } = yield self.apiPost(
            `classrooms/${self.id}/drop`
          );

          const manager = getParentOfName(self, 'UserManager');
          yield manager?.applyNewAccountData(accountData);
          // self.$notifications.notifySuccess(message);
          getEnv(self).notifications?.notifySuccess(message);
        } catch (error) {
          safelyHandleError(self, error, { unexpectedAlertLevel: ERROR });
        }
      }),

      assignSeat: flow(function* assignSeat(email) {
        try {
          self.$log.info('classroom.assignSeat', email);
          const { message, /*messageKey,*/ accountData } = yield self.apiPost(
            `classrooms/${self.id}/assign_seat`,
            {
              email,
            }
          );

          const manager = getParentOfName(self, 'UserManager');
          yield manager?.applyNewAccountData(accountData);
          // self.$notifications.notifySuccess(message);
          getEnv(self).notifications?.notifySuccess(message);
        } catch (error) {
          safelyHandleError(self, error, { unexpectedAlertLevel: ERROR });
        }
      }),
    }))
    .views(self => ({
      get requiresFullAccess() {
        return (
          self.assignments.filter(assignment => {
            return assignment?.story?.catalogData?.trial === false;
          }).length > 0
        );
      },

      // if we have trial access students but non-trial assignments
      get needsAccessWarning() {
        return self.trialStudentCount > 0 && self.requiresFullAccess;
      },

      // removes any orphaned assignments
      get sanitizedAssignments() {
        return self.assignments.filter(assignment => assignment.story);
      },

      // list of all assignment stories
      get stories() {
        const stories = self.assignments
          .map(assignment => {
            return getRoot(self).storyManager.story(assignment.episodeSlug);
          }) // todo: unit test to confirm graceful handling of unmatched episodeSlug
          .filter(story => story); // ignore any unmatched stories - @armando is there a better JS idiom for this?
        return stories;
      },

      get storiesWithDueDate() {
        const sortFn = sortBy('assignment.dueDate');
        const stories = self.stories.filter(
          assignedStory => assignedStory.assignment.dueDate !== null
        );
        return sortFn(stories);
      },

      // list stories to feature on dashboard
      get dashboardStories() {
        //Soonest date after today with an assignment
        const today = new Dayjs();
        const soonestDueDateAfterToday = self.storiesWithDueDate.find(story => {
          const dueDate = Dayjs(story.assignment.dueDate);
          return dueDate.isAfter(today, 'date');
        })?.assignment.dueDate;

        // Most recent date (today or before) with an assignment
        let mostRecentDueDate = '1970-01-01'; // unix time 0
        self.storiesWithDueDate.forEach(story => {
          const dueDate = Dayjs(story.assignment.dueDate);
          if (
            (dueDate.isBefore(today, 'date') ||
              dueDate.isSame(today, 'date')) &&
            dueDate.isAfter(Dayjs(mostRecentDueDate))
          ) {
            mostRecentDueDate = story.assignment.dueDate;
          }
        });

        const assignments = self.storiesWithDueDate.filter(
          story =>
            story.assignment.dueDate === soonestDueDateAfterToday ||
            story.assignment.dueDate === mostRecentDueDate
        );
        return assignments.reverse();
      },

      assignmentForSlug(slug) {
        return self.assignments.find(
          assignment => assignment.episodeSlug === slug
        );
      },

      // used to drive selected filter view
      get filterKey() {
        return `${CLASSROOM_FILTER_KEY_PREFIX}${self.id}`;
      },

      get token() {
        const manager = getParentOfName(self, 'UserManager'); // user token as expected by api-access
        return manager?.token;
      },
    }))
);

// putting this here so it can be shared between the UserData and ListeningStats model view functions
