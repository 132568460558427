import React from 'react';
export const IconDateS = ({ color = 'currentColor', ...props }) => (
  <svg
    height="20"
    width="20"
    fill="none"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7 2H6V3H4.5C3.67157 3 3 3.67157 3 4.5V15.5C3 16.3284 3.67157 17 4.5 17H15.5C16.3284 17 17 16.3284 17 15.5V4.5C17 3.67157 16.3284 3 15.5 3H14V2H13V3H7V2ZM6 4V5H7V4H13V5H14V4H15.2C15.6418 4 16 4.35817 16 4.8V7H4V4.8C4 4.35817 4.35817 4 4.8 4H6ZM4 8V15.2C4 15.6418 4.35817 16 4.8 16H15.2C15.6418 16 16 15.6418 16 15.2V8H4Z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);
