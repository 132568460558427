import React from 'react';
export const IconLock = ({ color = 'currentColor', ...props }) => (
  <svg
    height="24"
    width="24"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9 7.375C9 5.42481 10.4256 4 12 4C13.5744 4 15 5.42481 15 7.375V8H9V7.375ZM7 8V7.375C7 4.4927 9.15612 2 12 2C14.8439 2 17 4.4927 17 7.375V8H19C20.1046 8 21 8.89543 21 10V18C21 19.1046 20.1046 20 19 20H5C3.89543 20 3 19.1046 3 18V10C3 8.89543 3.89543 8 5 8H7Z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);
