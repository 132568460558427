import React from 'react';
import { observer } from 'mobx-react';
import { useRootStore } from 'mst';
import __ from 'jw-core/lib/localization';
import { SectionTitle } from 'components/ds/common/section-title';
import {
  Table,
  TableRow,
  TableLabelCell,
  TableValueCell,
} from 'components/account/account-form/table';
import { PricingCards } from 'components/account/pricing-cards';
import { BlueLink } from 'components/ui/buttons/link-button';
import { prevent } from 'lib/prevent';
import { useSwitch } from 'lib/hooks/use-switch';
import dayjs from 'dayjs';
import { SectionBreak } from 'components/ds/common/spacers';

/**
 * Returns date in format "August, 27 2020"
 */
const formatDate = dateStr => {
  const date = dayjs(dateStr);
  if (date.isValid) {
    return date.format('MMMM, DD YYYY');
  }
  return dateStr;
};

export const FullNoRenew = observer(() => {
  const store = useRootStore();
  const [changing, changingSwitch] = useSwitch(false);

  const { userManager } = store;
  const { accountData } = userManager ?? {};

  return (
    <>
      <SectionTitle>
        {__('My membership', 'membershipTable.myMembership')}
      </SectionTitle>
      <Table>
        <tbody>
          <TableRow>
            <TableLabelCell>
              {__('Membership type', 'membershipTable.membershipType')}
            </TableLabelCell>
            <TableValueCell>{accountData.membershipDisplay}</TableValueCell>
          </TableRow>

          <TableRow>
            <TableLabelCell>
              {__('Recurring billing', 'membershipTable.recurringBilling')}
            </TableLabelCell>
            <TableValueCell>
              {accountData.recurringBillingDisplay}
            </TableValueCell>
          </TableRow>

          <TableRow>
            <TableLabelCell>
              {__('Expires', 'membershipTable.expires')}
            </TableLabelCell>
            <TableValueCell>
              {formatDate(accountData.fullAccessUntil)}
              <BlueLink onClick={prevent(changingSwitch.toggle)}>
                {changing ? 'Cancel' : 'Extend'}
              </BlueLink>
            </TableValueCell>
          </TableRow>
          {changing ? (
            <TableRow noBorder={true}>
              <td colSpan="2">
                <div>
                  <PricingCards />
                </div>
              </td>
            </TableRow>
          ) : null}
        </tbody>
      </Table>
      <SectionBreak />
    </>
  );
});
