import __ from 'jw-core/lib/localization';
import { useRootStore } from '../../truth';
import { usePrompt } from 'components/ds/modals';

export const useCouponPrompt = () => {
  const store = useRootStore();
  const { validationErrorForKey, userManager } = store;

  const [Prompt, promptActions] = usePrompt({
    type: 'text',
    initialValue: '',
    label: __('Enter your code', 'account.enterYourCode'),
    hint: __(
      'You can type in lowercase, without dashes',
      'account.couponDialogLabel'
    ),
    placeholder: __(
      'Classroom, coupon or promo code',
      'account.couponDialogPlaceholder'
    ),
    okButtonAction: code => {
      handleApplyCode(code);
    },
    okButtonLabel: __('Apply', 'common.apply'),
    error: validationErrorForKey('code'),
    autoDismissOnOk: false,
  });

  const handleApplyCode = code => {
    userManager.applyCoupon(code).then(() => {
      const error = validationErrorForKey('code');
      if (error === null) {
        promptActions.off();
      }
    });
  };

  return [Prompt, promptActions];
};
