import React from 'react';
import { MarkdownContainer } from 'components/ds/common/markdown-container';
import { usePrompt, useAlert, useConfirm } from 'components/ds/modals';
import { useRootStore } from 'mst';
import { getConfig } from 'config';
import __ from 'jw-core/lib/localization';

export const getClassLabelValidationRules = () => {
  const min = 2;
  const max = 20;
  const minMaxMsg = __(
    'Class name needs to be between %{min} and %{max} characters',
    'classroom.labelMaxLengthValidationMessage',
    { min, max }
  );

  const validation = {
    required: {
      value: true,
      message: __(
        'A class name is required',
        'classroom.labelRequiredValidationMessage'
      ),
    },
    maxLength: {
      value: max,
      message: minMaxMsg,
    },
    minLength: {
      value: min,
      message: minMaxMsg,
    },
  };

  return validation;
};

export const useCreateClassroomPrompt = () => {
  const store = useRootStore();

  const [Prompt, promptActions] = usePrompt({
    label: __('Enter a name for this class', 'classroom.enterLabelForClass'),
    initialValue: '',
    placeholder: __('E.g. Fall Spanish 2', 'classroom.enterLabelPlaceholder'),
    okButtonLabel: __('Create class', 'classroom.createClass'),
    okButtonAction: classLabel => {
      store.userManager.createClassroom(classLabel).then(promptActions.off);
    },
    type: 'text',
    validation: getClassLabelValidationRules(),
    autoDismissOnOk: false,
  });

  return [Prompt, promptActions];
};

export const useRenameClassroomPrompt = (
  classroom = {},
  { afterAction = () => {} } = {}
) => {
  const [Prompt, promptActions] = usePrompt({
    label: __(
      'Enter new name for this class',
      'classroom.enterNewLabelForClass'
    ),
    initialValue: classroom.label,
    placeholder: __('E.g. Fall Spanish 2', 'classroom.enterLabelPlaceholder'),
    okButtonLabel: __('Rename class', 'classroom.renameClass'),
    okButtonAction: newLabel => {
      classroom.updateLabel(newLabel).then(() => {
        if (afterAction() !== false) {
          promptActions.off();
        }
      });
    },
    afterDismiss: afterAction,
    type: 'text',
    validation: getClassLabelValidationRules(),
    autoDismissOnOk: false,
  });

  return [Prompt, promptActions];
};

export const useLicenseLearnMoreAlert = (classroom = {}) => {
  const markdown = __(
    `Most stories aren't accessible in the Lupa app with a trial account.
Students can purchase their own subscription by visiting my.lupa.app on the web,
or from inside the app.

Alternatively you may purchase a multi-seat license for your class.
For pricing, email us at [%{displayEmail}](mailto:%{email})`,
    'classroom.licenseLearnMoreAlertText',
    {
      email: getConfig('website.emails.educationalSales'),
      // replace hyphen with non-breaking equivalent to prevent ugly layout issue
      displayEmail: getConfig('website.emails.educationalSales').replace(
        '-',
        '&#8209;'
      ),
    }
  );

  return useAlert({
    title: __('Lupa licenses', 'classroom.lupaLicenses'),
    text: <MarkdownContainer source={markdown} />,
  });
};

export const useLockedStoryScriptAlert = () => {
  const email = getConfig('website.emails.educationalSales');
  const markdown = __(
    'This story script is locked.\nIf you are purchasing Lupa for your classroom, please contact us at [%{displayEmail}](mailto:%{email}) to unlock access to all story scripts.',
    'classroom.lockedStoryAlertText',
    {
      email: getConfig('website.emails.educationalSales'),
      // replace hyphen with non-breaking equivalent to prevent ugly layout issue
      displayEmail: getConfig('website.emails.educationalSales').replace(
        '-',
        '&#8209;'
      ),
    }
  );

  const [Alert, alertActions] = useConfirm({
    body: <MarkdownContainer source={markdown} />,
    okButtonLabel: __('Contact us', 'common.contactUs'),
    onConfirm() {
      window.open(`mailto:${email}`);
      alertActions.off();
    },
  });

  return [Alert, alertActions];
};
