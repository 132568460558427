import { flow, getRoot, types } from 'mobx-state-tree';
import * as safeTypes from '../lib/safe-mst-types';

export const Plan = types
  .model('Plan', {
    slug: safeTypes.identifierDefaultBlank,
    price: safeTypes.numberOrNull,
    applePrice: safeTypes.numberOrNull,
    normalPrice: safeTypes.numberOrNull,
    pricingDescription: safeTypes.stringOrNull,
    pricingDescriptionKey: safeTypes.stringOrNull,
    discountPercentage: safeTypes.numberOrNull,
    hasSpecialPricing: safeTypes.booleanDefaultFalse,
    // these are only relevant for gift coupon plans
    gift: safeTypes.booleanDefaultFalse,
    cardDescriptionText: safeTypes.stringOrNull,
    cardDescriptionKey: safeTypes.stringOrNull,
    cardFootnoteText: safeTypes.stringOrNull,
    cardFootnoteKey: safeTypes.stringOrNull,
  })
  .actions(self => ({
    select: flow(function* select(options) {
      const userManager = getRoot(self)?.userManager;
      if (userManager) {
        // @armando we should have a yield here and make this a generator function correct?
        yield userManager.initiateCheckout(self, options);
      }
    }),
  }));
