import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { getColor } from 'lib/theme/utils';

import styled from 'styled-components';

const Wrapper = styled.div`
  color: ${getColor('gray-6')};
  margin-bottom: 4px;
  padding-top: 16px;
  font-weight: 600;
  padding-right: 32px;
`;

export const StudentNameCell = ({ name }) => {
  const isMobile = useMediaQuery({
    query: '(max-device-width: 550px)',
  });

  if (!isMobile) {
    return name;
  }

  return <Wrapper>{name}</Wrapper>;
};
