import React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'components/ds/tables';
import { ClassroomSection } from './classroom-section';
import { LicenseBadge } from './license-badge/license-badge';
import { Gap } from 'components/ds/common/spacers';
import { MobileOnlyTableHeader } from './mobile-only-table-header';
import { StudentAccessCell } from './table-cells/student-access-cell';
import { StudentEmailCell } from './table-cells/student-email-cell';
import { StudentNameCell } from './table-cells/student-name-cell';
import { StudentsZeroState } from './students-zero-state';

import __ from 'jw-core/lib/localization';

export const ClassroomStudents = observer(({ classroom, onInvite }) => {
  const { students } = classroom;
  const hasStudents = students.length > 0;

  const getOverflowMenuItems = student => {
    return [
      {
        label: __(
          'Drop %{name} from class',
          'classroom.dropStudentActionLabel',
          {
            name: student.name,
          }
        ),
        isDestructive: true,
        requiresConfirmation: true,
        confirmationOkButtonLabel: __('Drop student', 'classroom.dropStudent'),
        action: () => {
          classroom.dropStudent(student.email);
        },
      },
    ];
  };

  return (
    <ClassroomSection
      title={__('Students', 'classroom.students')}
      control={<LicenseBadge classroom={classroom} />}
      withHeadingRule={!hasStudents}
    >
      {hasStudents ? (
        <>
          <MobileOnlyTableHeader>
            {__(
              {
                one: '%{count} student',
                other: '%{count} students',
              },
              'classroom.countStudents',
              {
                count: students.length,
              }
            )}
          </MobileOnlyTableHeader>
          <Table
            data={students}
            cells={[
              {
                headerLabel: __('Name', 'common.name'),
                component: StudentNameCell,
              },
              {
                headerLabel: __('Email', 'common.email'),
                component: StudentEmailCell,
              },
              {
                headerLabel: __('Access type', 'classroom.accessType'),
                fieldName: 'fullAccess',
                renderCell: student => <StudentAccessCell student={student} />,
                show: !classroom.license,
              },
            ]}
            overflowMenuItems={getOverflowMenuItems}
          />
          <Gap size={3} />
        </>
      ) : null}
      <StudentsZeroState classroom={classroom} onInvite={onInvite} />
    </ClassroomSection>
  );
});
