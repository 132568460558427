import React, { useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { getFontName } from 'lib/theme/utils';
import { HidePasswordIcon } from 'components/ui/icons/hide-password-icon';
import { ShowPasswordIcon } from 'components/ui/icons/show-password-icon';

const Input = styled.input`
  border: 1px solid #e9e3d9;
  width: 100%;
  padding: calc(1.5rem - 1px) 2rem;
  margin-top: -1.5rem;
  margin-bottom: 1rem;
  border-radius: 4px;
  display: block;
  font-family: ${getFontName('button')};
  font-size: 2.125rem;
  line-height: 3rem;
  font-weight: 400;
`;

const EditInputContainer = styled.div`
  position: relative;
`;

const PasswordIconContainer = styled.a`
  position: absolute;
  width: 6.5rem;
  height: 6rem;
  border: none;
  padding: 0;
  top: 0;
  right: 0;
`;

export const EditInput = observer(
  ({ isPassword, value, name = 'value', register, ...props }) => {
    const [type, setType] = useState(isPassword ? 'password' : 'text');
    const togglePasswordVisibility = useCallback(
      e => {
        e.preventDefault();
        setType(type === 'password' ? 'text' : 'password');
      },
      [type]
    );

    return (
      <EditInputContainer>
        <Input
          name={name}
          type={type}
          maxlength="16"
          autocapitalize="none"
          value={value}
          ref={register}
          data-test-id="edit-input"
          {...props}
        />
        {isPassword ? (
          <PasswordIconContainer href="#" onClick={togglePasswordVisibility}>
            {type === 'password' ? <ShowPasswordIcon /> : <HidePasswordIcon />}
          </PasswordIconContainer>
        ) : null}
      </EditInputContainer>
    );
  }
);
