import React from 'react';
import { map } from 'lodash';
import __ from 'jw-core/lib/localization';
import styled from 'styled-components';

const Wrapper = styled.div`
  h3 {
    font: var(--small-heading-font);
    text-transform: uppercase;
    padding-bottom: 8px;
    border-bottom: 1px solid var(--gray-1);
    margin-bottom: 12px;
  }

  dl {
    margin-bottom: 32px;
  }

  dt,
  dd {
    font: var(--body-font);
  }

  dt {
    color: var(--gray-4);
  }

  dd {
    margin-bottom: 16px;
  }

  @media print, screen and (min-width: 700px) {
    dl {
      column-count: 2;
      column-gap: 32px;
    }
    .credit {
      break-inside: avoid-column;
    }
  }
  @media print, screen and (min-width: 900px) {
    dl {
      column-count: 3;
    }
  }
`;

export const StoryCredits = ({ story }) => {
  const labels = {
    producedBy: __('Produced by', 'story.creditsLabels.producedBy'),
    producedIn: __('Produced in', 'story.creditsLabels.producedIn'),
    editedBy: __('Edited by', 'story.creditsLabels.editedBy'),
    soundDesignBy: __('Sound design by', 'story.creditsLabels.soundDesignBy'),
    artworkBy: __('Artwork by', 'story.creditsLabels.artworkBy'),
  };
  const { credits } = story;
  return (
    <Wrapper>
      <h3>{__('Credits', 'story.credits')}</h3>
      <dl>
        {map(credits, (person, title) => {
          return (
            <div className="credit" key={`person-${title}`}>
              <dt>{labels[title]}</dt>
              <dd>{person}</dd>
            </div>
          );
        })}
      </dl>
    </Wrapper>
  );
};
