import React from 'react';
import { AccountHeader } from '../account/account-header';
import { AccountForm } from '../account/account-form';
import { AccountFlash } from '../account/account-flash';
import { MembershipTable } from '../account/membership-table';
import { MarketingLinks } from '../account/marketing-links';
import { JoinedClassrooms } from './joined-classrooms';
import { CheckoutResultTarget } from '../account/checkout-result-target';
import { AccountMessageTarget } from '../account/account-message-target';
// import { DownloadCta } from '../account/download-cta';
import { AccountCta } from '../account/account-cta';
// import { Pricing } from '../account/pricing';
import { DownloadLinks } from '../account/download-links';
import { EnsureAuth } from '../../components/ensure-auth';
import { SectionEnds } from '../../components/ds/common/spacers';
import { useTitle } from '../../lib/hooks/use-title';
import { useSwitch } from '../../lib/hooks/use-switch';
import __ from 'jw-core/lib/localization';

import styled from 'styled-components';

const Wrapper = styled.section`
  padding: 13rem 16px 0;
  @media screen and (min-width: 550px) {
    max-width: 576px;
    margin: 0 auto;
  }
  @media screen and (min-width: 768px) {
    padding-top: 17rem;
  }
`;

export const AccountScreen = () => {
  useTitle(__('My account', 'title.accountPage'));

  const [extending, extendingSwitch] = useSwitch(false);

  return (
    <EnsureAuth>
      <Wrapper>
        <AccountHeader />
        <AccountFlash />
        <AccountMessageTarget />
        {/*<DownloadCta />*/}
        <AccountCta />
        <MarketingLinks />
        <JoinedClassrooms />
        <AccountForm />
        <MembershipTable
          extending={extending}
          extendingSwitch={extendingSwitch}
        />
        {/*<Pricing extending={extending} />*/}
        <SectionEnds />
      </Wrapper>
      <DownloadLinks />
      <CheckoutResultTarget />
    </EnsureAuth>
  );
};
