import { types } from 'mobx-state-tree';
import * as safeTypes from '../lib/safe-mst-types';
import { localizedProperty } from '../lib/localization-helper';
import { wondery } from './channel';

/**
 * Represents the "Show" (programme) that a volume belongs to.
 * i.e. Business Wars
 */
export const Show = types
  .model('Show', {
    slug: safeTypes.identifierDefaultBlank,
    channelSlug: safeTypes.stringDefaultBlank,

    title: safeTypes.stringDefaultBlank,
    // titleEn: safeTypes.stringDefaultBlank,
    // titleEs: safeTypes.stringDefaultBlank,
    // titleJa: safeTypes.stringDefaultBlank,

    taglineEn: safeTypes.stringDefaultBlank,
    taglineEs: safeTypes.stringDefaultBlank,
    taglineJa: safeTypes.stringDefaultBlank,

    descriptionEn: safeTypes.stringDefaultBlank,
    descriptionEs: safeTypes.stringDefaultBlank,
    descriptionJa: safeTypes.stringDefaultBlank,

    weblinkUrl: safeTypes.stringDefaultBlank,
    weblinkDisplay: safeTypes.stringDefaultBlank,

    bannerImageCatalogPath: safeTypes.stringDefaultBlank,
    color: safeTypes.stringOrNull, // hero header background color
  })
  .views(self => ({
    get channel() {
      return wondery;
    },

    // get title() {
    //   // return self.title;
    //   return 'Business Wars';
    // },

    get tagline() {
      return localizedProperty(self, 'tagline');
    },

    get description() {
      return localizedProperty(self, 'description');
    },

    get aboutDisplay() {
      return self.channel.aboutDisplay;
    },
  }));

const businessWarsData = {
  slug: 'bw',
  title: 'Business Wars',
  descriptionEn:
    'Sometimes the prize is your wallet or your attention. Sometimes, it’s just the fun of beating the other guy. The outcome of these battles shapes what we buy and how we live.',
  descriptionJa:
    '大企業は業績を上げ、注目を集めるために争う。そして相手を潰し、勝利の甘い蜜を吸うためにも争う。これらのバトルの行方は、我々の消費生活と絡み、生活全般に大きく影響する。',
  weblinkUrl: 'https://wondery.com/shows/business-wars/',
  weblinkDisplay: 'wondery.com/shows/business-wars',
  bannerImageCatalogPath: 'jw-assets/bw_hero.jpg',
  color: '#E53D30',
};

export const businessWars = Show.create(businessWarsData);
