import React from 'react';
import { observer } from 'mobx-react';
import { useRootStore } from 'mst';
import __ from 'jw-core/lib/localization';
import { SectionTitle } from 'components/ds/common/section-title';
import {
  Table,
  TableRow,
  TableLabelCell,
  TableValueCell,
} from 'components/account/account-form/table';
// import { PricingCards } from 'components/account/pricing';
import { BlueLink, RedLink } from 'components/ui/buttons/link-button';
import DialogService from 'lib/services/dialog-service';
import { prevent } from 'lib/prevent';
// import { useSwitch } from 'lib/hooks/use-switch';
import { ErrorIcon } from 'components/ui/icons/error-icon';
import dayjs from 'dayjs';
import { SectionBreak } from 'components/ds/common/spacers';

/**
 * Returns date in format "August, 27 2020"
 */
const formatDate = dateStr => {
  const date = dayjs(dateStr);
  if (date.isValid) {
    return date.format('MMMM, DD YYYY');
  }
  return dateStr;
};

const formatMoney = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
}).format;

const capitalizeFirstLetter = str => {
  if (typeof str === 'string') {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return '';
};

const formatCardDate = date => {
  if (date) {
    const [month, year] = date.split(' / ');
    return `${month.length > 1 ? month : '0' + month}/${year.slice(2)}`;
  }
  return '';
};

export const FullAutoRenew = observer(() => {
  const store = useRootStore();
  // const [changing, changingSwitch] = useSwitch(false);

  const { userManager } = store;
  const { accountData } = userManager ?? {};
  const { membershipState, hasApplePaidAccess /* autoRenew */ } = accountData;
  const isSuspended = membershipState === 'suspended';
  const { paymentData = {} } = accountData;

  const updatePaymentMethod = () => {
    const monthlyPlan = accountData.plans.find(plan => plan.slug === 'monthly');
    if (monthlyPlan) {
      monthlyPlan.select();
    }
  };

  const handleAutoRenew = () => {
    if (hasApplePaidAccess) {
      DialogService.openDialog({
        title: 'Cancel auto-renew',
        body:
          'Your subscription is managed by the Apple App Store. You can cancel from your device’s Settings.',
        okButtonAction: () => {
          DialogService.closeDialog();
          setTimeout(() => {
            window.open('https://support.apple.com/en-us/HT202039');
          }, 100);
        },
        okButtonLabel: 'Learn more',
        cancelButtonAction: DialogService.closeDialog,
        cancelButtonLabel: 'Go back',
        showCancelButton: true,
      });
    } else {
      DialogService.openDialog({
        body: __(
          "By canceling your subscription you won't receive any further charges, and your membership to Lupa will end on %{date}",
          'dialogs.cancelAutoRenew',
          {
            date: formatDate(accountData.fullAccessUntil),
          }
        ),
        cancelButtonLabel: 'Go back',
        cancelButtonAction: DialogService.closeDialog,
        showCancelButton: true,
        okButtonLabel: 'Cancel subscription',
        okButtonAction: () => {
          userManager.cancelAutoRenew();
          setTimeout(() => {
            DialogService.closeDialog();
          }, 100);
        },
      });
    }
  };

  if (membershipState === 'full-auto-renew' || isSuspended) {
    return (
      <>
        <SectionTitle>
          {__('My membership', 'membershipTable.myMembership')}
        </SectionTitle>
        <Table>
          <tbody>
            <TableRow>
              <TableLabelCell>
                {__('Membership type', 'membershipTable.membershipType')}
              </TableLabelCell>
              <TableValueCell>
                {accountData.membershipDisplay}
                {/*hasApplePaidAccess && autoRenew ? null : (
                  <BlueLink onClick={prevent(changingSwitch.toggle)}>
                    {changing ? 'Hide' : 'Change'}
                  </BlueLink>
                )*/}
              </TableValueCell>
            </TableRow>
            {/*changing ? (
              <TableRow noBorder={true}>
                <td colSpan="2">
                  <div>
                    <PricingCards />
                  </div>
                </td>
              </TableRow>
            ) : null*/}

            <TableRow>
              <TableLabelCell>
                {__('Recurring billing', 'membershipTable.recurringBilling')}
              </TableLabelCell>
              <TableValueCell>
                {accountData.recurringBillingDisplay}
                <RedLink
                  onClick={prevent(handleAutoRenew)}
                  data-test-id="auto-renew-off"
                >
                  {__('Turn off', 'membershipTable.turnOff')}
                </RedLink>
              </TableValueCell>
            </TableRow>

            <TableRow>
              <TableLabelCell>
                {__('Renews', 'membershipTable.renews')}
              </TableLabelCell>
              <TableValueCell>
                {formatDate(accountData.fullAccessUntil)}
              </TableValueCell>
            </TableRow>
            <TableRow>
              <TableLabelCell>
                {__('Renewal amount', 'membershipTable.renewalAmount')}
              </TableLabelCell>
              <TableValueCell>
                {paymentData && formatMoney(paymentData.autoRenewAmount)}
              </TableValueCell>
            </TableRow>
            <TableRow>
              <TableLabelCell>
                {__('Payment method', 'membershipTable.paymentMethod')}
              </TableLabelCell>
              {hasApplePaidAccess ? (
                <TableValueCell error={isSuspended}>
                  {isSuspended ? <ErrorIcon /> : null}
                  Apple subscription
                </TableValueCell>
              ) : (
                <TableValueCell error={isSuspended}>
                  {isSuspended ? <ErrorIcon /> : null}
                  {paymentData &&
                    capitalizeFirstLetter(paymentData.cardBrand)}{' '}
                  ****
                  {paymentData && paymentData.cardLastFour},{' '}
                  {isSuspended ? 'expired' : 'expires'}
                  {paymentData && formatCardDate(paymentData.cardExp)}
                  <BlueLink onClick={prevent(updatePaymentMethod)}>
                    Update
                  </BlueLink>
                </TableValueCell>
              )}
            </TableRow>
          </tbody>
        </Table>
        <SectionBreak />
      </>
    );
  }

  return null;
});
