import logger from '../log';

const log = logger('context-service');

const BREADCRUMBS_LIMIT = 30;
const ACTIONSTRAIL_LIMIT = 20;

const navigationActionTypes = {
  NAVIGATE: 'Navigation/NAVIGATE',
  BACK: 'Navigation/BACK',
};

const push = (arr = [], item, sizeLimit) => {
  if (arr.length < sizeLimit) {
    return [...arr, item];
  } else {
    return [...arr.splice(1), item];
  }
};

const path = (...segments) => segments.join('/');

class ContextService {
  breadcrumbs = [];
  actionTrail = [];

  addBreadcrumb = (currentScreen, action) => {
    if (action.type === navigationActionTypes.NAVIGATE) {
      const { routeName, params } = action;
      let name = routeName;
      switch (name) {
        case 'Story':
          if (params.storySlug) {
            name = path(name, params.storySlug);
          }
          break;
        case 'Study':
          if (params.slug && params.chapterPosition) {
            name = path(name, params.slug, params.chapterPosition);
          }
          break;
        default:
          break;
      }
      // prevent the [Dashboard,Dashboard] thing
      if (this.currentBreadcrumb !== name) {
        this.breadcrumbs = push(this.breadcrumbs, name, BREADCRUMBS_LIMIT);
      }
    }
    if (action.type === navigationActionTypes.BACK) {
      let name = currentScreen;
      const prevCrumb = this.breadcrumbs[this.breadcrumbs.length - 2];
      if (prevCrumb) {
        name = prevCrumb;
      }
      this.breadcrumbs = push(this.breadcrumbs, name, BREADCRUMBS_LIMIT);
    }

    // hack to support offline debug logs
    if (action === 'log') {
      this.breadcrumbs = push(
        this.breadcrumbs,
        currentScreen,
        BREADCRUMBS_LIMIT
      );
    }
  };

  get currentBreadcrumb() {
    return this.breadcrumbs[this.breadcrumbs.length - 1];
  }

  addAction = (action, type = 'mobx') => {
    const currentScreen = this.currentBreadcrumb;
    type = action.type || type;
    this.actionsTrail = push(
      this.actionsTrail,
      { type, currentScreen, ...action },
      ACTIONSTRAIL_LIMIT
    );

    const { name, type: actionType, ...data } = action;
    log(`[${type}] ${name}`, {
      data: { currentScreen, ...data },
    });
  };

  addUserAction = (name, context = {}) => {
    //
    this.addAction({
      name,
      type: 'user-action',
      path: 'user-action',
      ...context,
    });
  };

  dump = () => {
    return {
      breadcrumbs: this.breadcrumbs,
      actionsTrail: this.actionsTrail,
    };
  };
}

const singleton = new ContextService();

export default singleton;
