import createLogger from './create-logger';
import chalk from 'chalk';
import { getConfig } from 'config';
import { logLevelKeys } from './log-levels';

function createDefaultLogger() {
  const LOGGERKEY = 'logger.disabled';

  // force color in Chrome.
  const colorChalk = new chalk.Instance({ enabled: true, level: 1 });
  // initial disabled namespaces
  // we're getting them from localstorage here, but the logger is agnostic to that
  let disabled = (localStorage.getItem(LOGGERKEY) || '').split('★') || [];

  const formatterFn = (message, options) => {
    const { namespace, level, chalkFn } = options;
    return (
      chalkFn.bold(`[${logLevelKeys[level]}][${namespace}]`) +
      chalkFn(` - ${message}`)
    );
  };

  // colors that look good on the browser
  const palette = [
    '#990000',
    '#990099',
    'green',
    '#996600',
    // 'blue',
    'magenta',
    '#6638f0',
    '#37aabc',
  ];

  const config = { disabled, chalk: colorChalk, formatterFn, palette };

  if (getConfig('logging.enableConsoleLog', true) === false) {
    // pass a noOp function instead of console.log
    config.logFn = () => {};
  }

  // create a singleton instance of logger.
  const { createLogger: defaultLogger } = createLogger(config);

  // utility functions to disable logs from the browser console
  defaultLogger.disable = pattern => {
    if (pattern === '*') {
      disabled = ['*'];
    } else {
      disabled.push(pattern);
    }
    localStorage.setItem(LOGGERKEY, disabled.join('★'));
  };

  // utility functions to enable logs from the browser console
  defaultLogger.enable = pattern => {
    if (pattern === '*') {
      disabled = [];
      localStorage.setItem(LOGGERKEY, '');
    }
    const index = disabled.indexOf(pattern);
    if (index > -1) {
      disabled.splice(index, 1);
      localStorage.setItem(LOGGERKEY, disabled.join('👓'));
    }
  };

  // make this singleton instance available in the console
  window.logger = defaultLogger;
  return defaultLogger;
}

export default createDefaultLogger();
