import React from 'react';
import { observer } from 'mobx-react';
import { find } from 'lodash';
import { useRenameClassroomPrompt } from './classroom-hooks';
import { useSwitch } from 'lib/hooks/use-switch';
import { useParams } from 'react-router-dom';
import { useRootStore } from 'mst';
import { IconEditL } from 'components/ds/icons';
import { getColor } from 'lib/theme/utils';
import { ClassroomDetailLayout as Layout } from './classroom-detail-layout';
import { ClassroomAssignments } from './assignments';
import { ClassroomStudents } from './students';
import { NotFoundScreen } from 'components/routes/not-found-screen';
import { OnboardingMessage } from './onboarding-message';
import { ClassroomShareModal } from 'components/classrooms/classroom-share-modal';
import { ClassroomNag } from '../classrooms/classroom-nag';

import styled from 'styled-components';

const EditButton = styled.button`
  border: none;
  background: none;
  margin-left: 2px;
  margin-top: 5px;
  color: ${getColor('gray-4')};
  outline: none;
  cursor: pointer;
  &:hover {
    color: ${getColor('dark-teal')};
  }
  @media screen and (max-width: 550px) {
    position: absolute;
    right: 0;
  }
`;

export const ClassroomDetail = observer(() => {
  const { id } = useParams();
  const store = useRootStore();
  const { accountData = {} } = store.userManager;
  const classroom = find(accountData.managedClassrooms, { id });
  const [RenamePrompt, renamePromptSwitch] = useRenameClassroomPrompt(
    classroom
  );

  const [inviting, inviteSwitch] = useSwitch(false);

  if (!classroom) {
    return <NotFoundScreen />;
  }

  return (
    <Layout
      classroom={classroom}
      stickyHeader
      headerActionControl={
        <EditButton onClick={renamePromptSwitch.on}>
          <IconEditL />
        </EditButton>
      }
    >
      <OnboardingMessage />
      <ClassroomAssignments classroom={classroom} />
      <ClassroomStudents classroom={classroom} onInvite={inviteSwitch.on} />
      {inviting ? (
        <ClassroomShareModal
          classroom={classroom}
          onDismiss={inviteSwitch.off}
        />
      ) : null}
      <RenamePrompt />
      {classroom?.license?.isOverSubscribed ? <ClassroomNag /> : null}
    </Layout>
  );
});
