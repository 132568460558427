import React from 'react';
import { getColor } from '../../lib/theme/utils';

import styled from 'styled-components';

const AuthBackgroundFill = styled.section`
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 14rem;
  padding-bottom: 14rem;
  background: ${getColor('offWhite')};
  height: 100%;
  @media screen and (min-width: 768px) {
    padding-top: 19rem;
    padding-bottom: 15rem;
    padding-left: 40px;
    padding-right: 40px;
  }
`;

const AuthInnerContainer = styled.div`
  @media screen and (min-width: 440px) {
    width: 50rem;
    margin: 0 auto;
  }
`;

export const AuthContainer = ({ children }) => (
  <AuthBackgroundFill>
    <AuthInnerContainer>{children}</AuthInnerContainer>
  </AuthBackgroundFill>
);
