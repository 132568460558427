import React from 'react';
import { FullScreenLoader } from 'components/ds/modals';
import { DisplayStatus } from '../components/debug/display-status';
import { GlobalNav } from 'components/portals/global-nav/dummy';
import { GlobalFooter } from 'components/portals/global-footer';
import { lupaMode } from '../lib/platform';

/**
 * Shows a loading screen with a Footer and a Dummy nav bar while MST is being initialized
 */
export const LoadingRoot = ({ root }) => {
  return (
    <>
      <FullScreenLoader />
      {lupaMode ? (
        <>
          <GlobalNav />
          <GlobalFooter />
        </>
      ) : null}
      <DisplayStatus status={root.status} />
    </>
  );
};
