import React, { useEffect } from 'react';
import { useRootStore } from 'mst';
import { observer } from 'mobx-react';
import { useParams, useHistory } from 'react-router-dom';
import { NotFoundScreen } from './not-found-screen';

import { FullScreenLoader } from 'components/ds/modals/full-screen-loader';
import { StoryGuide } from 'components/guides/story-guide';
import { useStoryData } from 'components/guides/use-story-data';

import { trackPage } from 'lib/analytics';

const usePageTracker = () => {
  const history = useHistory();

  useEffect(() => {
    // the first page, just after the effect has mounted
    trackPage(history.location.pathname.replace(/^\//, ''));
  }, [history]);
};

export const StoryGuideScreen = observer(() => {
  const store = useRootStore();
  const { slug } = useParams();
  const [story, loading] = useStoryData(slug);

  usePageTracker();

  if (loading) {
    return <FullScreenLoader />;
  }

  if (!story) {
    return <NotFoundScreen />;
  }

  const canAccess = story.trial
    ? true
    : store.userManager?.accountData?.canAccessAllStoryScripts;

  if (!canAccess) {
    return <NotFoundScreen />;
  }

  return <StoryGuide story={story} />;
});
