/* eslint-disable no-console */
import { createAnalyticsContainer } from './analytics-container';
import createLogger from '../log';
import { getConfig } from 'config';

export const createSimulatedAnalyticsContainer = () => {
  const log = createLogger('analytics:sim');

  const enabled = getConfig('analytics.enableSimulatedAnalytics', false);

  const trackFn = enabled
    ? (eventName, data) => {
        console.group('analytics:trackEvent');
        console.log('event', eventName);
        console.table({ data });
        console.groupEnd();
      }
    : null;

  const identifyFn = enabled
    ? userId => {
        console.group('analytics:identify');
        console.table({ userId });
        console.groupEnd();
      }
    : null;

  const pageFn = enabled
    ? (page, data) => {
        console.group('analytics:page');
        console.log('page', page);
        console.table({ data });
        console.groupEnd();
      }
    : null;

  const container = createAnalyticsContainer({
    log,
    trackFn,
    identifyFn,
    pageFn,
  });

  return container;
};
