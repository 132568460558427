import React from 'react';
import { Button } from 'components/ds/buttons';
import { useCreateClassroomPrompt } from '../classroom-hooks';

import __ from 'jw-core/lib/localization';

export const CreateClassButton = () => {
  const [Prompt, promptActions] = useCreateClassroomPrompt();

  return (
    <>
      <Button
        label={__('Add a class', 'classrooms.addAClass')}
        presentation="primary"
        onClick={promptActions.on}
        size="small"
        data-test-id="create-class"
      />
      <Prompt />
    </>
  );
};
