import {
  applySnapshot,
  flow,
  getEnv,
  getParent,
  getRoot,
  types,
} from 'mobx-state-tree';

import env from './lib/simple-env';
import * as ln from './lib/localization';
import * as safeTypes from './lib/safe-mst-types';

const apiEnvChoices = [
  'prod',
  'bolero',
  'preprod',
  'staging',
  'devtest',
  'beta',
  'eslbeta',
  'jfedev',
];

const apiHosts = {
  jfedev: 'https://jfedev.ngrok.io',
  devtest: 'https://service.devtest.jiveworld.com',
  staging: 'https://service.staging.jiveworld.com',
  beta: 'https://service.beta.jiveworld.com',
  eslbeta: 'https://service-esl-beta.jiveworld.com',
  bolero: 'https://service.bolero.jiveworld.com',
  prod: 'https://service.lupa.app',
  preprod: 'https://service.preprod.jiveworld.com',
};

const UNINITIALIZED = 'UNINITIALIZED';

/**
 * GlobalConfig
 *
 * settings which should be preserved independent of current user
 *
 * persistable trunk
 */
export const GlobalConfig = types
  .model('GlobalConfig', {
    savedApiEnv: types.optional(types.maybeNull(types.string), UNINITIALIZED),
    modelVersion: safeTypes.numberDefaultZero,
    cpuAdjust: safeTypes.numberDefaultZero,
    locale: types.optional(
      types.maybeNull(types.string),
      ln.systemDefaultLocale
    ),
    appInstallAttribution: types.maybeNull(types.map(types.string)),
  })
  .views(self => ({
    get $log() {
      const { createLogger = () => {} } = getEnv(self);
      const log = createLogger('global-config');
      return log;
    },
    get $track() {
      const { track = () => {} } = getEnv(self);
      return track;
    },
  }))
  .actions(self => ({
    /**
     * sets the internal apiEnv value, which will be persisted to disk
     * and will be used to access the API by api-access.js
     *
     * @param {string} apiEnv
     * @returns {Promise}
     *
     */
    selectApiEnv: flow(function* selectApiEnv(apiEnv) {
      if (apiEnv !== self.savedApiEnv) {
        yield getParent(self).resetPersistedData();
        applySnapshot(self, { savedApiEnv: apiEnv });
        self.$log.info(`selectApiEnv: ${apiEnv}`);
        self.$track('internal__select_api_env');
      }
    }),

    /**
     * sets the global locale
     *
     * @param {string} locale
     * @returns {Promise}
     */
    setLocale: flow(function* setLocale(locale) {
      self.locale = locale;
      ln.setLocale(locale);
      yield getRoot(self).persistAndSync();
      // how to best force restart / refresh
    }),

    /**
     * sets a global value for benchmarking purposes
     * TODO: check if this is used anywhere still.
     *
     * @param {number} adjust
     * @returns {void}
     */
    setCpuAdjust(adjust) {
      if (adjust !== self.cpuAdjust) {
        applySnapshot(self, {
          cpuAdjust: adjust,
          savedApiEnv: self.savedApiEnv,
        });
        self.$log.info(`setCpuAdjust: ${adjust}`);
      }
    },
    /**
     * sets app install attribution data
     *
     * @param {map} attribution
     * @returns {void}
     */
    setAppInstallAttribution(attribution) {
      self.appInstallAttribution = attribution;
    },
  }))
  .views(self => ({
    /**
     * it returns the current apiEnv(ironment) according to these rules
     * - if `forcedApiEnv` is set in the global SimpleEnv instance, that is what is returned
     * - if there's a savedApiEnv, that value is returned
     * - Finally, we return `defaultApiEnv` from the SimpleEnv instance.
     *
     * @returns {string} current ApiEnv
     *
     */
    get apiEnv() {
      const forcedApiEnv = env.get('forcedApiEnv', null);

      if (forcedApiEnv) {
        return forcedApiEnv;
      }

      const defaultApiEnv = env.get('defaultApiEnv');
      if (!self.savedApiEnv || self.savedApiEnv === UNINITIALIZED) {
        return defaultApiEnv;
      }
      return self.savedApiEnv;
    },
    /**
     * returns the correct url for the API, depending on the current apiEnv
     *
     * @returns {string} apiUrl http address
     */
    get apiUrl() {
      return `${apiHosts[self.apiEnv]}/api/v3/`;
    },

    /**
     * returns the valid choices for apiEnv
     *
     * @returns {string[]}
     */
    get availableChoices() {
      return apiEnvChoices;
    },

    /**
     * @returns {string}
     */
    whatToShowOnWelcomeModal() {
      if (self.whatsNewUrl) {
        return self.whatsNewUrl;
      }
    },
  }));
