// import get from 'lodash/get';
// import set from 'lodash/set';
//
// /**
//  * SimpleEnv container
//  */
// class SimpleEnv {
//   /**
//    * Holds all the data
//    * @private
//    */
//   __env = {};
//
//   /**
//    * flag to determine if the container has been initialized
//    */
//   __initialized = false;
//
//   /**
//    * initialize container
//    * @param {Object} env
//    */
//   initialize = env => {
//     if (this.__initialized) {
//       throw new Error('Cannot initialize twice');
//     }
//     this.__env = env;
//     this.__initialized = true;
//   };
//
//   /**
//    * Sets a value in the env container
//    * @param {string} key
//    * @param {any} value
//    */
//   set = (key, value) => {
//     return set(this.__env, key, value);
//   };
//
//   /**
//    * get a value from the env container
//    * @param {string} key
//    * @param {any} defaultValue
//    */
//   get = (key, defaultValue = null) => {
//     return get(this.__env, key, defaultValue);
//   };
// }

import { getConfig, setConfig } from '../../config';

// eslint-disable-next-line no-console
console.log('jw-core/lib/simple-env.js');

const env = { get: getConfig, set: setConfig };
export default env;
