import { types } from 'mobx-state-tree';
import * as safeTypes from '../lib/safe-mst-types';
import { ListeningLog } from './listening-log';
import { StoryProgress } from './story-progress';

// todo: find a better home for time formatting utilities
const hourMinDuration = millis => {
  const hours = Math.floor(millis / (1000 * 60 * 60));
  const minutes = Math.floor((millis / (1000 * 60)) % 60);
  // const seconds = Math.floor((millis / 1000) % 60);
  const result = (hours > 0 ? `${hours}hr ` : '') + `${minutes}m`;
  return result;
};

export const StudentProgress = types
  .model('StudentProgress', {
    slug: safeTypes.stringDefaultUnexpected,
    email: safeTypes.stringDefaultUnexpected,
    name: safeTypes.stringDefaultUnexpected,
    allTimeStudiedMillis: safeTypes.numberDefaultZero,
    vocabCount: safeTypes.numberDefaultZero,

    // was there a reason this wasn't previously optional?
    storyProgress: types.optional(
      types.maybeNull(types.late(() => StoryProgress)),
      {}
    ),
    listeningLogs: types.optional(
      types.maybeNull(types.array(types.late(() => ListeningLog))),
      []
    ),
    vocabs: safeTypes.arrayOfStringsDefaultEmpty,
  })
  .actions(self => ({
    resolveChaptersCompleted() {
      return self.storyProgress?.chaptersCompleted;
    },
    // none yet
  }))
  .views(self => ({
    get allTimeStudiedHourMin() {
      return hourMinDuration(self.allTimeStudiedMillis);
    },
  }));
