import React from 'react';

export const ShowPasswordIcon = () => {
  return (
    <svg width="52" height="48">
      <path
        fill="#999"
        d="M23 16c-4.968 0-9.472 2.806-12.797 7.363a1.09 1.09 0 000 1.268C13.528 29.194 18.033 32 23 32c4.968 0 9.472-2.806 12.797-7.363a1.09 1.09 0 000-1.268C32.472 18.806 27.967 16 23 16zm.356 13.633c-3.297.215-6.02-2.591-5.813-6 .17-2.812 2.378-5.09 5.1-5.266 3.298-.215 6.021 2.591 5.814 6-.176 2.807-2.383 5.085-5.1 5.266zm-.165-2.602c-1.776.115-3.244-1.395-3.127-3.229.09-1.515 1.282-2.74 2.75-2.838 1.776-.116 3.244 1.394 3.127 3.228-.096 1.521-1.287 2.746-2.75 2.839z"
      ></path>
    </svg>
  );
};
