import React from 'react';
import { useRootStore } from 'mst';
import { PricingCard } from './pricing-card';
import __ from 'jw-core/lib/localization';

export const PlanPricingCard = ({ plan }) => {
  const {
    slug,
    price,
    // applePrice,
    normalPrice,
    pricingDescription,
    hasSpecialPricing,
    discountPercentage,
  } = plan;

  const store = useRootStore();
  const isCurrent = store?.userManager?.accountData?.currentPlan === slug;
  const isMonthly = slug === 'monthly';
  const isDiscounted =
    discountPercentage > 0 || (!isMonthly && normalPrice !== price);

  // console.log({
  //   price,
  //   normalPrice,
  //   isDiscounted,
  // });

  let pricingExplanation = isMonthly
    ? __('Billed monthly', 'plans.billedMonthly')
    : __('$%{price} one-time purchase', 'plans.nOneTimePurchase', {
        price,
      });

  if (isDiscounted) {
    if (isMonthly) {
      pricingExplanation = __('Usually $%{price}/mo', 'plans.usuallyNMonthly', {
        price: normalPrice,
      });
    }
  }
  const displayPrice = isMonthly ? price : price / 12;

  let ribbonTitle = pricingDescription;

  if (hasSpecialPricing && isDiscounted) {
    ribbonTitle = __(
      '%{pricingDescription}: save %{discountPercentage}%',
      'plans.discountedRibbonTitle',
      {
        pricingDescription,
        discountPercentage,
      }
    );
  } else if (isDiscounted) {
    ribbonTitle = __(
      'Save %{discountPercentage}%',
      'plans.savePercentageRibbonTitle',
      {
        discountPercentage,
      }
    );
  }

  return (
    <PricingCard
      cta={isMonthly ? 'Subscribe' : 'Purchase'}
      discountPercentage={discountPercentage}
      featureTitle={__('All Lupa stories', 'plans.allLupaStories')}
      featureSubTitle={__(
        'New stories every Thursday',
        'plans.newStoriesEveryThursday'
      )}
      interval={'/mo'}
      isCurrent={isCurrent}
      isDiscounted={isDiscounted}
      showOriginalPrice={isDiscounted && !isMonthly}
      normalPrice={normalPrice}
      price={displayPrice}
      ribbonTitle={ribbonTitle}
      showRibbon={hasSpecialPricing || isDiscounted}
      pricingExplanation={pricingExplanation}
      title={isMonthly ? 'Monthly' : '1 year'}
      type={isMonthly ? 'monthly' : 'one-year'}
      onCtaClick={plan.select}
    />
  );
};
