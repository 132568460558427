import React from 'react';
import { JsxLogo } from './jw-logo';
import styled from 'styled-components';

const Center = styled.div`
  display: grid;
  place-content: center;
`;

export const PlaceholderScreen = () => (
  <section className="error-404">
    <div className="error-404__content">
      <p className="error-404__body">
        <Center>
          <JsxLogo />
        </Center>
      </p>
    </div>
  </section>
);
