import { types } from 'mobx-state-tree';
import { msToPoints } from './ms-to-points';
import * as safeTypes from '../lib/safe-mst-types';

// Maybe this should be a TS type definition, instead of a model
export const ListeningStats = types
  .model('ListeningStats', {
    millisListened: safeTypes.numberDefaultZero,
    millisRelistened: safeTypes.numberDefaultZero,
  })
  .views(self => {
    function msToMins(ms, floor = true) {
      const amount = ms / (1000 * 60);

      if (floor) {
        return Math.floor(amount);
      }

      return amount;
    }

    return {
      get minsListened() {
        return msToMins(self.millisListened);
      },

      get minsRelistened() {
        return msToMins(self.millisRelistened);
      },

      get totalPoints() {
        return (
          msToPoints(self.millisListened) + msToPoints(self.millisRelistened)
        );
      },
    };
  });
