import React from 'react';
import { observer } from 'mobx-react';
import { useRootStore } from '../../truth';
import { getColor, getFontName } from '../../lib/theme/utils';
import __ from 'jw-core/lib/localization';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;

const Greeting = styled.h1`
  font-family: ${getFontName('heading')};
  color: ${getColor('text')};
  font-size: 3.75rem;
  line-height: 4.5rem;
  margin-bottom: 7rem;
  @media screen and (min-width: 768px) {
    font-size: 4.625rem;
    line-height: 6rem;
    margin-bottom: 10rem;
  }
`;

const Button = styled.button`
  font-family: ${getFontName('button')};
  font-weight: 400;
  font-size: 1.75rem;
  line-height: 2.5rem;
  color: ${getColor('text')};
  border-radius: 16px;
  border: 1px solid ${getColor('blackHairline')};
  padding: 0.75rem 2rem;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  margin-top: 0.5rem;
  white-space: nowrap;
  background: transparent;
  @media screen and (min-width: 768px) {
    margin-top: 1.5rem;
  }
  &:hover {
    cursor: pointer;
    border-color: ${getColor('gray2')};
  }
`;

const LogoutButton = ({ onPress }) => {
  return (
    <Button onClick={onPress} data-test-id="logout">
      Log out
    </Button>
  );
};

export const AccountHeader = observer(({ children }) => {
  const store = useRootStore();
  const { name } = store.userManager.accountData ?? {};

  const handleLogout = async () => {
    await store.userManager.logout();
    window.location.reload();
  };

  if (!name) {
    return null;
  }

  return (
    <Container>
      <Greeting>{__('My account', 'account.myAccount')}</Greeting>
      <LogoutButton onPress={handleLogout} />
    </Container>
  );
});
