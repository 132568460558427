/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { getColor } from 'lib/theme/utils';
import { NoLicense } from './no-license';
import { Seats } from './seats';
import styled from 'styled-components';

const LicenseContainer = styled.div`
  display: flex;
  .side {
    height: 32px;
    display: flex;
    align-items: center;
  }
  .side + .side {
    flex: 1;
    display: flex;
    border-left: 1px solid ${getColor('gray-1')};
    margin-left: 8px;
    padding-left: 8px;
  }
`;

// const Pi = styled.button`
//   opacity: 0;
//   &:hover {
//     opacity: 1;
//   }
// `;

export const LicenseBadge = ({ classroom }) => {
  return (
    <LicenseContainer>
      {!classroom.license ? (
        <div className="side" data-test-id="classroom-no-license">
          <NoLicense classroom={classroom} />
        </div>
      ) : (
        <div className="side" data-test-id="classroom-seats">
          <Seats classroom={classroom} />
        </div>
      )}
      {/*process.env.NODE_ENV !== 'production' ? (
        <Pi onClick={() => console.log(classroom.toJSON())}>π</Pi>
      ) : null*/}
    </LicenseContainer>
  );
};
