import { getEnv, types, getRoot } from 'mobx-state-tree';

import dayjs from 'dayjs';
import { sum } from 'lodash';
import __ from '../lib/localization';
import { INITIAL_NUM_COMPLETED_FOR_REVIEW_CTA } from '../lib/constants/vars';
import { AssistSettings } from './assist-settings';
import { ListeningLog } from './listening-log';
import { ListeningStats } from './listening-stats';
import { UserSettings } from './user-settings';
import { msToPoints } from './ms-to-points';

/**
 * UserData
 *
 * conceptual owner of the client-side mutable user state which needs to get synced to/from the server
 * note the 'storyProgress's now live under the storyManager trunk, but get spliced during the
 * sync process.
 */
export const UserData = types
  .model('UserData', {
    listeningLogs: types.optional(
      types.maybeNull(types.array(types.late(() => ListeningLog))),
      []
    ),
    // map of volume slugs to unit numbers
    currentUnits: types.optional(types.map(types.number), {}),
    assistSettings: types.optional(
      types.maybeNull(types.late(() => AssistSettings)),
      {}
    ),
    userSettings: types.optional(
      types.maybeNull(types.late(() => UserSettings)),
      {}
    ),
    /*0 and -1 magic values will be assigned to this variable as follows,
     0: user accept the request to review
    -1: user decline the  request to review
    see the sepc for more details :
    https://jiveworld.slite.com/app/channels/ptvqrYJErL/notes/ODyxK9uJfe
    */
    numCompletedForReviewCta: types.optional(
      types.maybeNull(types.number),
      INITIAL_NUM_COMPLETED_FOR_REVIEW_CTA
    ),
  })
  .views(self => ({
    get $log() {
      const { createLogger = () => {} } = getEnv(self);
      const log = createLogger('um:user-data');
      return log;
    },
  }))
  .actions(self => ({
    addListeningLog(slug, listenedMillis, relistenedMillis) {
      const currentISODate = dayjs().startOf('day').toISOString();
      const log = self.listeningLogs.filter(
        _log => _log.date === currentISODate && _log.storySlug === slug
      );
      if (log.length) {
        log[0].listenedMillis += listenedMillis;
        log[0].relistenedMillis += relistenedMillis;
      } else {
        self.listeningLogs.push(
          ListeningLog.create({
            date: currentISODate,
            storySlug: slug,
            listenedMillis,
            relistenedMillis,
          })
        );
      }
    },

    resetListeningLogs() {
      self.listeningLogs = [];
    },

    setVolumeCurrentUnitNumber(slug, number) {
      self.$log.debug(`setVolumeCurrentUnitNumber(${slug}, ${number})`);
      return self.currentUnits.set(slug, number);
    },

    postponedReviewCta() {
      self.numCompletedForReviewCta *= 2;
    },

    acceptedReviewCta() {
      self.numCompletedForReviewCta = 0;
    },

    declinedReviewCta() {
      self.numCompletedForReviewCta = -1;
    },
  }))
  .views(self => ({
    get totalMillis() {
      return sum(
        self.listeningLogs.map(
          ({ listenedMillis, relistenedMillis }) =>
            listenedMillis + relistenedMillis
        )
      );
    },
    get totalPoints() {
      return msToPoints(self.totalMillis);
    },
    get showReviewCta() {
      const root = getRoot(self);
      const numCompletedStories = root.storyManager.completed.length;
      const hasFullAccess = root.userManager.accountData.fullAccess;
      return (
        hasFullAccess &&
        self.numCompletedForReviewCta > 0 &&
        numCompletedStories >= self.numCompletedForReviewCta
      );
    },
    get showReviewCtaMenuItem() {
      const root = getRoot(self);
      const numCompletedStories = root.storyManager.completed.length;
      const hasFullAccess = root.userManager.accountData.fullAccess;
      return (
        hasFullAccess &&
        numCompletedStories >= INITIAL_NUM_COMPLETED_FOR_REVIEW_CTA
      );
    },

    volumeCurrentUnitNumber(slug) {
      return self.currentUnits.get(slug) || 1;
    },
  }))
  .views(self => {
    function getStatsFromLogs(logs) {
      self.$log.debug('getStatsFromLogs', logs);
      return ListeningStats.create(
        logs.reduce(
          (acc, curr) => {
            return {
              // todo: clean up naming dissidence between logs and stats
              millisListened: acc.millisListened + curr.listenedMillis,
              millisRelistened: acc.millisRelistened + curr.relistenedMillis,
            };
          },
          {
            millisListened: 0,
            millisRelistened: 0,
          }
        )
      );
    }

    return {
      storyListeningStats(slug) {
        return getStatsFromLogs(
          self.listeningLogs.filter(log => log.storySlug === slug)
        );
      },

      volumeListeningStats(slug) {
        return getStatsFromLogs(
          self.listeningLogs.filter(log => log.storySlug.includes(slug))
        );
      },

      get meView() {
        const today = dayjs().startOf('day');

        function filterLogsByDate(logs, date) {
          return logs.filter(log => dayjs(log.date) >= date);
        }

        const sevenDaysAgo = dayjs(today).subtract(7, 'days');
        const fifteenDaysAgo = dayjs(today).subtract(15, 'days');

        const data15Days = filterLogsByDate(self.listeningLogs, fifteenDaysAgo);
        const data7Days = filterLogsByDate(data15Days, sevenDaysAgo);

        function getPointsByDay(logs, startDate, endDate) {
          let currentDate = dayjs(startDate);
          const dates = [];
          while (currentDate <= endDate) {
            dates.push(dayjs(currentDate));
            currentDate = currentDate.add(1, 'days');
          }

          const dayInitials = __('SMTWTFS', 'global.dayInitials');
          const daysAbbreviations = dayInitials.split('');
          return dates.map(date => ({
            letter: daysAbbreviations[date.day()],
            points: logs.reduce(
              (acc, log) =>
                dayjs(log.date).isSame(date, 'day')
                  ? acc +
                    msToPoints(log.listenedMillis) +
                    msToPoints(log.relistenedMillis)
                  : acc,
              0
            ),
          }));
        }

        const graphData = getPointsByDay(data15Days, fifteenDaysAgo, today);
        const highestDayPoints = Math.max(...graphData.map(p => p.points));

        return {
          graphData,
          highestDayPoints,
          lastSevenStats: getStatsFromLogs(data7Days),
          allTimeStats: getStatsFromLogs(self.listeningLogs),
        };
      },
    };
  });
