import { applySnapshot, flow, getEnv, getRoot, types } from 'mobx-state-tree';
import { get } from 'lodash';
import moment from 'moment';
import * as safeTypes from '../lib/safe-mst-types';
import {
  CLASSROOM_FILTER_KEY_PREFIX,
  storySortKeys,
} from '../lib/constants/vars';
import { SORT_ORDER, sortBy } from '../lib/util';
import { composeWithName } from '../lib/type-utils/compose-with-name';
import { ApiAccess } from '../api-access';
import { StoryCatalogData } from '../catalog';
import { StoryProgress } from '../user-manager';
import filterNewStories from '../lib/filter-new-stories';
import invariant from '../lib/invariant';
import { Story } from './story';
import { Volume } from '../catalog/volume';
import { v4CatalogMode } from '../lib/app-util';

const sortByTitle = sortBy('catalogData.title');
const sortByDuration = sortBy('catalogData.durationMinutes');
// switch to millis once schema update propagated to local store
// const sortByDuration = sortBy('catalogData.durationMillis');
const sortByMostRecent = sortBy('catalogData.releaseDate', SORT_ORDER.DESC);
const sortByDownloadedAt = sortBy('download.downloadedAt');
const sortByOriginalBroadcastDate = sortBy('catalogData.originalBroadcastDate');
const sortByAssignmentDueDate = sortBy('assignment.dueDate');

/**
 * ApplyMetadata + create and object with the key provide
 *
 * @param {array} collection
 * @param {object} metadata
 * @param {function} sortFn
 * @param {string} key
 * @returns {object}
 */
const sectionize = (collection, sortFn, key) => {
  if (key === 'all') {
    return [{ title: 'all', data: sortFn(collection) }];
  }

  const sectionized = collection.reduce((acc, current) => {
    current.catalogData[key].forEach(item => {
      if (Array.isArray(acc[item])) {
        acc[item].push(current);
      } else {
        acc[item] = [current];
      }
    });

    return acc;
  }, {});
  return Object.keys(sectionized)
    .sort()
    .map(_key => ({
      title: _key,
      data: sortFn(sectionized[_key]),
    }));
};

// Note, this map is a peer of storySortLabels, using the same storySortKeys
export const storySortParams = {
  assignmentDueDate: { sortFn: sortByAssignmentDueDate, sectionKey: 'all' },
  mostRecent: { sortFn: sortByMostRecent, sectionKey: 'all' },
  title: { sortFn: sortByTitle, sectionKey: 'all' },
  duration: { sortFn: sortByDuration, sectionKey: 'all' },
  country: { sortFn: sortByMostRecent, sectionKey: 'countries' },
  topic: { sortFn: sortByMostRecent, sectionKey: 'topics' },
  originalBroadcast: { sortFn: sortByOriginalBroadcastDate, sectionKey: 'all' },
};

const applySort = (collection, sortKey) => {
  const params = storySortParams[sortKey];
  return sectionize(collection, params.sortFn, params.sectionKey);
};

const __currentDate = moment().format('YYYY-MM-DD');

/**
 * StoryManager
 *
 * Now directly owns and persists all of the story related data.
 * (which used to be sliced up between Catalog, DownloadManager and UserData)
 */
export const StoryManager = composeWithName(
  'StoryManager',
  ApiAccess,
  types
    .model('StoryManager', {
      catalogUrl: safeTypes.stringOrNull,
      version: safeTypes.numberDefaultZero,
      stories: types.optional(
        types.maybeNull(types.array(types.late(() => Story))),
        []
      ),
      volumes: types.optional(types.array(types.late(() => Volume)), []),
    })
    .volatile(() => ({
      /**
       * 'opened' story, for which we have cached fullData
       */
      currentStory: null,
      currentFilterKey: null, // null implies index view
      currentSortKey: storySortKeys.MOST_RECENT,
      currentDate: __currentDate,
    }))
    .views(self => ({
      get $log() {
        const { createLogger = () => {} } = getEnv(self);
        const log = createLogger('mst-root');
        return log;
      },
      get $notifications() {
        const { notifications = {} } = getEnv(self);
        return notifications;
      },
    }))
    .actions(self => {
      function spliceInCatalogData(data) {
        if (!data) {
          throw new Error('missing catalog data');
        }
        const {
          stories: catalogStories0,
          volumes: volumes0,
          ...baseData
        } = data;
        const catalogStories = catalogStories0 || [];
        const volumes = volumes0 || [];
        // console.log(
        //   `volumes.length: ${volumes?.length}, stories.length: ${catalogStories?.length}`
        // );
        applySnapshot(self.volumes, volumes);

        // const catalogSlugs = catalogStories.map(({ slug }) => slug);
        // console.log(`before cs.map`);
        // console.log(`cs[0]: ${JSON.stringify(catalogStories[0])}`);
        const catalogSlugs = catalogStories.map(data => {
          // console.log(`data: ${JSON.stringify(data)}`);
          return data?.slug;
        });
        // console.log(`catalogSlugs: ${JSON.stringify(catalogSlugs)}`);

        self.$log.debug(
          `spliceInCatalogData - baseData: ${JSON.stringify(
            baseData
          )}, catalog slugs: ${JSON.stringify(catalogSlugs)}`
        );

        // beware, I had thought applySnapshot would leave unreferenced properties alone,
        // but it seems to nuke them.
        // what's a cleaner way to just merge the present properties?
        // applySnapshot(self, baseData);
        self.catalogUrl = baseData.catalogUrl;
        self.version = baseData.version;

        catalogStories.forEach(catalogData => {
          try {
            const slug = catalogData?.slug;
            if (slug) {
              // this validation check appears to be a reliable check for the device
              // build even when the catch below is not triggered for invalid catalog data
              const errors = StoryCatalogData.validate(catalogData, [
                { path: '', type: StoryCatalogData },
              ]);
              if (errors.length) {
                const message = `validation errors for catalog data - slug: ${slug}`; //, errors: ${JSON.stringify(errors)}, data: ${JSON.stringify(catalogData)}`;
                // console.log(message);
                self.$notifications.alertError(message);
              } else {
                let story = self.story(slug);
                if (story) {
                  self.$log.debug(`merging catalog data: ${slug}`);
                  applySnapshot(story.catalogData, catalogData);
                } else {
                  self.$log.debug(`adding catalog data: ${slug}`);
                  story = Story.create({
                    slug,
                    catalogData,
                  });
                  self.stories.push(story);
                }
              }
            }
          } catch (error) {
            // beware, this catch only seems to work for a dev build when there is a validation error
            // in the device build corrupted data can apparently be applied to the in-memory
            // mst data, and then the entire storyManager trunk gets silently nuked when the full tree
            // is hydrated on the next cold start
            const message = `spliceInCatalogData error - ${catalogData?.slug}: ${error}`; //, data: ${JSON.stringify(catalogData)}`;
            // console.log(message);
            self.$notifications.alertError(message);
          }
        });

        self.stories = self.stories.filter(story =>
          catalogSlugs.includes(story.slug)
        );

        // todo: remove orphaned downloaded assets
      }

      return {
        ensureCatalogUrl: flow(function* ensureCatalogUrl(url) {
          self.$log.info(
            `entering ensureCatalogUrl(${url}) - current url: ${self.catalogUrl}`
          );
          if (url !== self.catalogUrl) {
            try {
              self.currentStory = null;
              const response = yield self.fetch(url);
              const data = yield response.json();
              // log('catalog data', JSON.stringify(data));
              spliceInCatalogData(data);
              getRoot(self).downloadManager.ensureAllCatalogAssets();
              self.$log.debug('ensureCatalogUrl - end of try block');
            } catch (error) {
              self.$notifications.alertWarning(
                `Unable to load catalog data: ${url}, ${error}`
              );
            }
          }
        }),

        spliceInStoryProgresses(progresses = []) {
          progresses.forEach(progressData => {
            const story = self.story(progressData.slug);
            if (story) {
              self.$log.info(`merging progress data: ${progressData.slug}`);
              applySnapshot(story.progress, progressData);
            } else {
              const selfRoot = getRoot(self);
              if (selfRoot !== null) {
                // todo: consider saving orphaned progress someplace else
                self.$log.warn(
                  `catalog story not found for ${progressData.slug} - ignoring progress data`
                );
              }
            }
          });
        },

        /**
         * called on logout to nuke the user's local state
         */
        resetProgress() {
          self.stories.forEach(story => {
            story.progress = StoryProgress.create({});
          });
          self.volumes.forEach(volume => volume.resetVolatile());
        },

        setCurrentStory(story) {
          self.currentStory = story;
        },

        setCurrentDate(currentDate = null) {
          if (currentDate === null) {
            currentDate = moment().format('YYYY-MM-DD');
          }
          self.currentDate = currentDate;
        },

        setCurrentFilterKey(filterKey) {
          self.setCurrentFilterParams({ filterKey });
        },

        setCurrentSortKey(sortKey) {
          self.setCurrentFilterParams({ sortKey });
        },

        /**
         * Set filterKey and/or sortKey in a single operation
         * @param filterKey
         * @param sortKey
         */
        setCurrentFilterParams({ filterKey = undefined, sortKey = undefined }) {
          if (filterKey !== undefined) {
            self.currentFilterKey = filterKey;
          }
          if (sortKey !== undefined) {
            self.currentSortKey = sortKey;
          }
        },
      };
    })
    .views(self => ({
      /**
       * used when syncing to server. assemble the data which used to live under userData.stories
       */
      get storyProgresses() {
        const progresses = self.stories.map(story => {
          // we'll need the slug to splice back after fetching back from the server
          return { ...story.progress, slug: story.slug };
        });
        return progresses;
      },

      get storyDownloads() {
        const result = self.stories.map(story => {
          return { ...story.download, slug: story.slug };
        });
        return result;
      },

      /**
       * used to drive automatic download for new user (new user status assumed if no progress)
       */
      get areAllUnplayed() {
        self.$log.info(
          `areAllUnplayed - stories length: ${self.stories.length}`
        );
        return self.stories.every(story => {
          self.$log.debug(
            `${story.slug} - unplayed: ${story.progress.unplayed}`
          );
          return story.progress.unplayed;
        });
      },

      get availableStories() {
        if (
          get(getRoot(self), 'userManager.accountData.showFutureStories', null)
        ) {
          // for users with `showFutureStories` flag enabled, show everything
          return self.stories;
        } else {
          // for everybody else, suppress stories with a future release date
          return self.stories.filter(story => story.isReleased);
        }
      },

      get all() {
        return self.availableStories;
      },

      get unplayed() {
        return self.availableStories.filter(story => {
          return story.progress.unplayed;
        });
      },

      get inProgress() {
        return self.availableStories.filter(story => {
          return story.progress.inProgress;
        });
      },

      get completed() {
        return self.availableStories.filter(story => {
          return story.progress.completed;
        });
      },

      get notDownloaded() {
        return self.availableStories.filter(story => {
          return story.download.notDownloaded || story.download.pending;
        });
      },

      get downloaded() {
        return self.availableStories.filter(story => {
          return story.download.downloaded;
        });
      },

      get downloadPending() {
        return self.availableStories.filter(story => {
          return story.download.pending;
        });
      },

      get firstWithPendingAssets() {
        // honor any active downloads
        const sortedQueue = self.downloadQueue;
        if (sortedQueue.length > 0) {
          const result = sortedQueue[0];
          self.$log.info(`queue head: ${result.slug}`);
          return result;
        }

        // give priority to the remaining list view thumbs
        const candidate = self.all.find(story => {
          return story.download.hasPendingListImage;
        });
        if (candidate) {
          return candidate;
        }

        // mop up the banner images
        return self.stories.find(story => {
          return story.download.hasPendingAssets;
        });
      },

      // optimize fetching of assets seen on a new user's dashboard
      get firstPendingDashboardImage() {
        let candidate = null;

        // for wondery, download all 5 queued list images first
        if (v4CatalogMode()) {
          candidate = self.stories.find(story => {
            return story.download.hasPendingListImage;
          });
          if (candidate) {
            return candidate.download.listImage;
          }
        }

        candidate = self.trial.find(story => {
          return story.download.hasPendingListImage;
        });
        if (candidate) {
          return candidate.download.listImage;
        }

        // featured weekly story banner
        candidate = self.newThisWeekAndUnplayed.find(story => {
          return story.download.hasPendingBannerImage;
        });
        if (candidate) {
          return candidate.download.bannerImage;
        }

        candidate = self.inProgress.find(story => {
          return story.download.hasPendingListImage;
        });
        if (candidate) {
          return candidate.download.listImage;
        }

        candidate = self.recommended.find(story => {
          return story.download.hasPendingListImage;
        });
        if (candidate) {
          return candidate.download.listImage;
        }

        // not sure about the rest of thumbs vs trial show downloads
        // candidate = self.all.find(story => {
        //   return story.download.hasPendingListImage;
        // });
        // if (candidate) {
        //   return candidate.download.listImage;
        // }
        return null;
      },

      get firstPendingVolumeImage() {
        let candidate = self.volumes.find(volume => {
          return volume.download.hasPendingThumbImage;
        });
        if (candidate) {
          return candidate.download.thumbImage;
        }
        return null;
      },

      get firstVolumeWithPendingAssets() {
        // note, for now, not worrying about honoring already active download
        // the whole download stuff needs some rethinking and refactoring
        return self.volumes.find(volume => {
          return volume.download.hasPendingAssets;
        });
      },

      // filtered and sorted by `downloadAt`
      get downloadQueue() {
        const filtered = self.stories.filter(story => {
          return story.download.downloadedAt && story.download.hasPendingAssets;
        });
        // todo: order asc by unit number. (right now we seem to get a reverse order)
        const sorted = sortByDownloadedAt(filtered);
        return sorted;
      },

      get newThisWeek() {
        const manager = getRoot(self).storyManager;
        return filterNewStories(self.stories, manager.currentDate);
      },

      get unreleased() {
        return self.stories.filter(story => !story.isReleased);
      },

      // downloaded, but not in-progress - drives dashboard view
      get readyToPlay() {
        return self.stories.filter(story => {
          return story.download.downloaded && story.progress.unplayed;
        });
      },

      /**
       *  has recommended flag, but not yet in progress or downloaded - drives dashboard view
       */
      get recommended() {
        const sortFn = (a, b) => {
          return a.catalogData.releaseDate > b.catalogData.releaseDate ? -1 : 1;
        };

        const rec = self.availableStories
          .filter(story => {
            return (
              story.catalogData.headPart && // excludes part >= 2
              story.progress.unplayed &&
              !story.download.isDownloaded &&
              !story.isNew
            );
          })
          .sort(sortFn);
        if (rec) {
          return rec.slice(0, 4);
        }

        return self.stories.sort(sortFn).slice(0, 6);
      },

      /**
       *  available to subscribers
       */
      get availableToSubscribers() {
        const sortFn = (a, b) => {
          return a.catalogData.releaseDate > b.catalogData.releaseDate ? -1 : 1;
        };

        const rec = self.availableStories
          .filter(story => {
            return (
              // todo: better factor this with 'recommended'
              story.catalogData.headPart && // excludes part >= 2
              story.progress.unplayed &&
              !story.download.isDownloaded &&
              !story.isNew
            );
          })
          .sort(sortFn);

        if (rec) {
          return rec.slice(0, 4);
        }

        return self.stories.sort(sortFn).slice(0, 6);
      },

      get trial() {
        return self.stories.filter(story => {
          return story.catalogData.trial;
        });
      },

      get notTrial() {
        return self.stories.filter(story => {
          return !story.catalogData.trial;
        });
      },

      get filterCounts() {
        return {
          all: self.all.length,
          unplayed: self.unplayed.length,
          inProgress: self.inProgress.length,
          completed: self.completed.length,
          notDownloaded: self.notDownloaded.length,
          downloaded: self.downloaded.length,
        };
      },

      story(slug) {
        return self.stories.find(story => {
          return story.slug === slug;
        });
      },

      get dashboardTrialData() {
        return {
          showSampleStoryMessage: true, // todo: proper logic
          sampleStories: self.trial,
          // todo: break these out to their own view getters. i think consolidating here
          // might break some of the responsiveness of the dashboard view
          availableToSubscribers: self.availableToSubscribers,
        };
      },

      get dashboardFullAccessData() {
        return {
          newThisWeek: self.newThisWeek,
          inProgress: self.inProgress,
          readyToPlay: self.readyToPlay,
          recommended: self.recommended, // todo: adapt to in progress count
        };
      },

      get currentSectionedStories() {
        const filtered = self.filter(self.currentFilterKey);
        return applySort(filtered, self.currentSortKey);
      },

      /**
       * number of stories matching the current filter
       */
      get currentFilterCount() {
        return self.filter(self.currentFilterKey).length;
      },

      filter(name = 'all') {
        if (name.startsWith(CLASSROOM_FILTER_KEY_PREFIX)) {
          const classroom = getRoot(
            self
          ).userManager.accountData.joinedClassroomForFilterKey(name);
          invariant(classroom, `classroom not found for filter key: ${name}`);
          if (classroom) {
            return classroom.stories;
          } else {
            return [];
          }
        }

        return self[name] ?? self.all;

        // switch (name) {
        //   case 'all':
        //     return self.all;
        //   case 'unplayed':
        //     return self.unplayed;
        //   case 'inProgress':
        //     return self.inProgress;
        //   case 'completed':
        //     return self.completed;
        //   case 'notDownloaded':
        //     return self.notDownloaded;
        //   case 'downloaded':
        //     return self.downloaded;
        //   default:
        //     return self.all; // the current filters screen hits this for the 'all' case
        // }
      },

      // Return filtered story who releaseDate is within a week or in the future + never was played
      get newThisWeekAndUnplayed() {
        return self.availableStories.filter(story => {
          return (
            story.isNew && story.progress.unplayed && !story.download.downloaded
          );
        });
      },
    }))
    .views(self => ({
      volume(slug) {
        return self.volumes.find(v => v.slug === slug);
      },
      get trialVolumes() {
        return self.volumes.filter(volume => volume.trial);
      },
      get unlockedVolumes() {
        return self.volumes.filter(volume => volume.isUnlocked);
      },
      get lockedVolumes() {
        return self.volumes.filter(volume => !volume.isUnlocked);
      },
      get myVolumes() {
        return self.unlockedVolumes.sort((a, b) => {
          // need to segregate on completed first to make them last
          if (a.completed && !b.completed) {
            return 1;
          } else if (!a.completed && b.completed) {
            return -1;
          }

          // show in-progress first
          if (a.inProgress && !b.inProgress) {
            return -1;
          } else if (!a.inProgress && b.inProgress) {
            return 1;
          }

          // rest, by alphabetical order
          if (a.title > b.title) {
            return 1;
          } else if (a.title < b.title) {
            return -1;
          }

          return 0;
        });
      },
    }))
);
