import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { GoogleLogin } from 'react-google-login';
import { useRootStore } from '../../../truth';
import { GoogleIcon } from '../icons/google-icon';
import { IconButton } from './icon-button';
import { alertError } from '../../../lib/services/notification-service';
import { getConfig } from 'config';

const GOOGLE_CLIENT_ID = getConfig('google.clientId');

export const GoogleButton = observer(({ title }) => {
  const history = useHistory();

  const store = useRootStore();

  useEffect(() => {
    if (store.userManager.loggedInAndReady) {
      history.push('account');
    }
  }, [store.userManager.loggedInAndReady, history]);

  const onGoogleSuccess = response => {
    store.userManager.omniauth('google', response.accessToken);
  };

  const onGoogleError = error => {
    // const err = new Error('Google auth error');
    // err.context = error;
    // console.log(error);
    if (error.details) {
      // this error returned by google is not an actual Error instance,
      // if we pass it as-is, bugsnag will complain and log the complaint instead of the error
      alertError(error.details, { exception: new Error(error.details) });
      return;
      // throw new Error(error.details);
    }
    throw error;
  };

  return (
    <GoogleLogin
      clientId={GOOGLE_CLIENT_ID}
      buttonText="Login"
      onSuccess={onGoogleSuccess}
      onFailure={onGoogleError}
      cookiePolicy={'single_host_origin'}
      render={({ onClick, disabled }) => (
        <IconButton
          title={title}
          icon={GoogleIcon}
          loading={false}
          onClick={onClick}
          disabled={disabled}
        />
      )}
    />
  );
});
