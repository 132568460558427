import styled from 'styled-components';

export const StoriesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 32px;

  margin-bottom: -16px;

  @media screen and (max-width: 560px) {
    grid-template-columns: auto;
  }

  @media screen and (min-width: 1020px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  /* medium-gap */
  padding-bottom: 40px;
  @media screen and (min-width: 550px) {
    padding-bottom: 56px;
  }
`;

export const StoriesGridSeparator = styled.div`
  grid-column-start: 1;
  grid-column-end: -1;
  margin-bottom: -1px;
`;
