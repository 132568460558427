import * as React from 'react';
import { Portal } from 'components/ds/modals/portal';
import styled from 'styled-components';
import { includeTextStyles, getColor } from 'lib/theme/utils';
import { IconTriangleAlert } from 'components/ds/icons';

const Wrapper = styled.div`
  ${includeTextStyles('body')}
  padding: 16px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${p => p.zIndex};

  a {
    margin-left: 4px;
    text-decoration: underline;
    color: inherit;
  }

  &.type-red {
    background: ${getColor('red')};
    color: white;
  }

  &.type-pink {
    background: ${getColor('light-pink')};
    color: ${getColor('dark-red')};
  }

  svg {
    display: inline-block;
    margin-right: 4px;
  }

  p {
    color: inherit;
  }
`;

export const Nag = ({
  children,
  type = 'pink',
  showIcon = false,
  zIndex = 1000,
}) => {
  React.useLayoutEffect(() => {
    document.body.classList.add('__with-nag');
    return () => {
      document.body.classList.remove('__with-nag');
    };
  });
  return (
    <Portal>
      <Wrapper className={`type-${type}`} zIndex={zIndex}>
        {showIcon ? <IconTriangleAlert /> : null}
        {children}
      </Wrapper>
    </Portal>
  );
};
