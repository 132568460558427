/* eslint-disable no-alert */
import React from 'react';
import {
  Menu,
  MenuItem,
  ConfirmableMenuItem,
  OverflowMenu,
} from 'components/ds/menus';
import { Heading } from 'components/ds/common/heading';
import { includeTextStyles } from 'lib/theme/utils';
import styled from 'styled-components';

const MenuContainer = styled.div`
  width: 280px;
  margin-bottom: 40px;
`;

const OverflowContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;

  span {
    ${includeTextStyles('body')};
    margin-right: 20px;
  }
`;

const items = [
  { label: 'Item one', action: () => alert('You clicked Item one') },
  { label: 'Item two', action: () => alert('You clicked Item two') },
  {
    label: 'Item three',
    disabled: true,
    action: () => alert('This should never happen'),
  },
  {
    label: 'This requires confirmation',
    requiresConfirmation: true,
    isDestructive: true,
    action: () => alert('You confirmed'),
  },
];

export const ShowcaseMenus = () => {
  return (
    <>
      <Heading>Array based menu</Heading>
      <MenuContainer>
        <Menu items={items} />
      </MenuContainer>
      <Heading>Children based menu</Heading>
      <MenuContainer>
        <Menu>
          <MenuItem
            label={'This is great'}
            action={() => {
              alert('Did this work?');
            }}
          />
          <MenuItem label={'No, this is dumb'} disabled />
          <ConfirmableMenuItem
            label={'Ruin my life'}
            isDestructive
            action={() => {
              alert('You did confirm');
            }}
            confirmationTitle="Are you dumb?"
            confirmationBody="This will ruin your life"
            confirmationOkButtonLabel="Ruin it!"
            confirmationCancelButtonLabel="Chicken out!"
          />
        </Menu>
      </MenuContainer>
      <Heading>Overflow Menu</Heading>
      <OverflowContainer>
        <span>Default overflow menu</span>
        <OverflowMenu items={items} />
      </OverflowContainer>
      <OverflowContainer>
        <span>Outline button, menuPosition=top-right</span>
        <OverflowMenu
          items={items}
          buttonType="outline"
          menuPosition="top-right"
        />
      </OverflowContainer>
    </>
  );
};
