/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { observer } from 'mobx-react';
import { useRootStore } from '../../truth';
import { SectionTitle } from 'components/ds/common/section-title';
import { SectionDescription } from 'components/ui/section-description';
import { PricingCards } from './pricing-cards';

// user agent sniffing is never a good idea
// but this is the way it was in the old site
// at least is lightway
// const isMobile = () => {
//   return (
//     navigator.userAgent.match(/Android|webOS|iPhone|iPad|iPod/i) ||
//     window.innerWidth <= 500
//   );
// };

// const DesktopMessage = ({ isTrial }) => {
//   return (
//     <p className="message__body">
//       {isTrial ? 'You have two exclusive stories waiting for you. ' : ''}
//       To download the Lupa app, visit{' '}
//       <a href="https://get.lupa.app" target="_blank">
//         get.lupa.app
//       </a>{' '}
//       on your mobile device (or look for the email with the download links).
//     </p>
//   );
// };

const TrialNotDownloaded = ({ accountData }) => {
  return (
    <>
      <SectionTitle>
        {accountData?.hasAffiliatePricing
          ? accountData.affiliateWelcomeHeading
          : 'Get started'}
      </SectionTitle>
      <SectionDescription>
        Lupa is available for iOS and Android. Download now and try our two free
        stories. Ready to unlock all Lupa content?{' '}
        {accountData?.hasAffiliatePricing
          ? 'Your discount has already been applied below.'
          : 'Subscribe or purchase below.'}
      </SectionDescription>
      <PricingCards showFreeTrialCard />
    </>
  );
};

const TrialDownloaded = ({ accountData }) => {
  return (
    <>
      <SectionTitle>
        {/*todo: confirm if we keep affiliate heading in this case*/}
        {accountData?.hasAffiliatePricing
          ? accountData.affiliateWelcomeHeading
          : 'Unlock full access'}
      </SectionTitle>
      <SectionDescription>
        You’re currently using our free trial. Subscribe or purchase now to
        unlock all Lupa content.
      </SectionDescription>
      <PricingCards />
    </>
  );
};

const ExpiringSoon = ({ accountData }) => {
  return (
    <>
      <SectionTitle>Extend your subscription</SectionTitle>
      <SectionDescription>
        {/*todo: friendly date formatting*/}
        Your full access will expire on {accountData.fullAccessUntil}. Subscribe
        or purchase now to keep access to all Lupa content.
      </SectionDescription>
      <PricingCards />
    </>
  );
};

export const AccountCta = observer(() => {
  const store = useRootStore();
  const accountData = store.userManager?.accountData;
  const isTrial = accountData.membershipState === 'trial';

  if (isTrial) {
    if (accountData.promoteDownload) {
      return <TrialNotDownloaded accountData={accountData} />;
    } else {
      return <TrialDownloaded accountData={accountData} />;
    }
  } else {
    if (accountData?.promoteEnrollment) {
      return <ExpiringSoon accountData={accountData} />;
    } else {
      return null;
    }
  }
});
