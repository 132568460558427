import { getConfig } from 'config';
import { getInstallationId, getProductVersion } from '../platform';
import { isFunction } from 'lodash';
import { v4 } from 'uuid';

const anonymousUserId = '_anonymous_';
const sessionId = v4();

export const createAnalyticsContainer = ({
  identifyFn,
  trackFn,
  pageFn,
  log,
}) => {
  let contextData = null;
  let userId = null;

  const setContextData = data => {
    if (!isFunction(identifyFn)) {
      return;
    }
    contextData = data;
    log.info('setContextData', {
      userId: data.accountData?.userId,
      apiEnv: data.globalConfig?.apiEnv,
      data,
    });

    userId = data.accountData?.userId ?? null;
    if (userId) {
      identifyFn(userId);
    } else if (getConfig('analytics.enableAnonymous', true)) {
      identifyFn(anonymousUserId);
    }
  };

  const trackEvent = (eventName, eventProperties = {}) => {
    if (!isFunction(trackFn)) {
      return;
    }
    const date = Date.now();
    // const releaseChannel = 'dev_build'; // this is not
    eventProperties = {
      email: contextData?.accountData?.email, // redundant data, but makes live event feed easier to browse
      server_env: contextData?.globalConfig?.apiEnv,
      installation_id: getInstallationId(),
      session_id: sessionId,
      $ae_session: sessionId,
      event_timestamp: date,
      // Note, the proper Mixpanel key here should be $app_version_string, but this value seems to get
      // stomped by Segment even when we provide here, so using a more distinctly named key for our purposes.
      jw_app_version: getProductVersion(),
      product_flavor: getConfig('productFlavor'),
      ...eventProperties,
    };
    if (!userId) {
      eventProperties['anonymous_track'] = true;
    }
    // log.info(`tracking event: ${format(eventName)}`);
    // log.debug(`tracking properties ${format(eventName)}`, eventProperties);
    trackFn(eventName, eventProperties);
  };

  const trackPage = (eventName, eventProperties = {}) => {
    if (!isFunction(pageFn)) {
      return;
    }

    const date = Date.now();
    // const releaseChannel = 'dev_build'; // this is not
    eventProperties = {
      email: contextData?.accountData?.email, // redundant data, but makes live event feed easier to browse
      server_env: contextData?.globalConfig?.apiEnv,
      installation_id: getInstallationId(),
      session_id: sessionId,
      $ae_session: sessionId,
      event_timestamp: date,
      // Note, the proper Mixpanel key here should be $app_version_string, but this value seems to get
      // stomped by Segment even when we provide here, so using a more distinctly named key for our purposes.
      jw_app_version: getProductVersion(),
      product_flavor: getConfig('productFlavor'),
      ...eventProperties,
    };
    if (!userId) {
      eventProperties['anonymous_track'] = true;
    }
    log.info(`tracking event: ${eventName}`);
    log.debug(`tracking properties ${eventName}`, eventProperties);
    pageFn(eventName, eventProperties);
  };

  return {
    setContextData,
    trackEvent,
    trackPage,
  };
};
