import i18n from 'i18n-js';
import { isString } from 'lodash';
import invariant from './invariant';
import env from './simple-env';
import { observable } from 'mobx';
import { getBus } from '@thumbjive/minibus';

export const systemDefaultLocale = 'en';

function createLocalizationStore() {
  const translations = {};

  const __locale = observable.box(systemDefaultLocale);

  i18n.translations = translations;
  i18n.fallbacks = true;
  i18n.missingTranslation = () => undefined;

  function addTranslations(lang, strings) {
    i18n.translations[lang] = strings;
  }

  function translateWithoutDefault(scope, args = {}, ...rest) {
    args.locale = getLocale();
    const str = i18n.t(scope, { ...args }, ...rest);
    if (env.get('i18n.showBrackets', false)) {
      return `[${str}]`;
    }

    if (!isString(str)) {
      invariant(
        false,
        `string expected: i18n(${scope}) -> ${JSON.stringify(
          str
        )}, type: ${typeof str}`
      );
      return `[${scope}]`;
    }
    return str;
  }

  function setLocale(newLocale) {
    __locale.set(env.get('i18n.forcedLocale') || newLocale);
    getBus('localization').emit('setLocale', newLocale);
  }

  function getLocale() {
    return __locale.get();
  }

  function onLocaleChange(fn) {
    return getBus('localization').subscribe('setLocale', fn);
  }

  function translateWithDefault(defaultValue, scope, args = {}, ...rest) {
    if (env.get('i18n.bypass', false)) {
      return defaultValue;
    }
    return translateWithoutDefault(scope, { defaultValue, ...args }, ...rest);
  }

  return {
    addTranslations,
    translateWithoutDefault,
    setLocale,
    getLocale,
    translateWithDefault,
    onLocaleChange,
  };
}

const store = createLocalizationStore();

// expose a version without a default for usages isolated from the english reference parsing
export const t = store.translateWithoutDefault;

export const getLocale = store.getLocale;
export const setLocale = store.setLocale;
export const onLocaleChange = store.onLocaleChange;

// in runtime all it does is returning the scope,
// but it's picked by the get-strings parser to populate en.json
export const __s = (_, scope) => scope;
export const __f = (...args) => () => store.translateWithDefault(...args);
export const __ = store.translateWithDefault;

export const addTranslations = store.addTranslations;

export const getAvailableTranslations = () => {
  return Object.keys(i18n.translations);
};

// reexport i18n for debug purposes
export const i18nInstance = i18n;

export default __;
