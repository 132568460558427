import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { getColor } from 'lib/theme/utils';

import styled from 'styled-components';

const Wrapper = styled.div`
  color: ${getColor('gray-6')};
  span {
    color: ${getColor('gray-4')};
  }

  @media screen and (max-width: 550px) {
    margin-bottom: 8px;
  }
`;

export const StudentTimeStudiedCell = student => {
  const { allTimeStudiedHourMin = null } = student;
  const isMobile = useMediaQuery({
    query: '(max-device-width: 550px)',
  });

  if (!isMobile) {
    return allTimeStudiedHourMin;
  }

  return (
    <Wrapper>
      {allTimeStudiedHourMin} <span>studied</span>
    </Wrapper>
  );
};
