import React from 'react';
import styled from 'styled-components';
import { prevent } from '../../lib/prevent';
import { BackArrowIcon } from '../ui/icons/back-arrow-icon';

const IconContainer = styled.a`
  position: absolute;
  top: 0;
  left: 0;
`;

export const AuthHeaderContainer = styled.header`
  display: flex;
  justify-content: center;
  margin: 2rem 0 8rem;
  position: relative;
`;

export const AuthHeader = ({
  children,
  showBackButton = false,
  onBackButtonClick = () => {},
}) => {
  return (
    <AuthHeaderContainer>
      {showBackButton ? (
        <IconContainer href="#" onClick={prevent(onBackButtonClick)}>
          <BackArrowIcon />
        </IconContainer>
      ) : null}
      {children}
    </AuthHeaderContainer>
  );
};
