import React from 'react';

export const ExternalNavLinkIcon = props => {
  return (
    <svg width={18} height={24} fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.1 4.8h3.804L5.54 11.164l1.273 1.273L13.2 6.049V9.9H15V3H8.1v1.8zM9.2 12v3.2H1.8V7.8H7V6H0v11h11v-5H9.2z"
        fill="#333"
      />
    </svg>
  );
};
