import React from 'react';
import { includeTextStyles, getColor, getDimension } from 'lib/theme/utils';
import { IconArrowLeft } from '../icons';
import { OverflowMenu } from '../menus';
import { useStickyness } from 'lib/hooks/use-stickyness';

import styled, { keyframes } from 'styled-components';

const affix = keyframes`
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }

`;

const StyleWrapper = styled.div`
  color: ${getColor('header-main-text-color')};
  margin-top: 24px;
  margin-bottom: 40px;
  padding: 16px 0;
  border-bottom: 1px solid transparent;
  position: relative;
  /* background: red; */

  @media screen and (min-width: 768px) {
    margin-top: 48px;
    margin-bottom: 64px;
  }

  & > header {
    /* background: yellow; */
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    &.title-align-center {
      justify-content: center;
      text-align: center;
    }
    &.title-align-right {
      text-align: right;
    }

    h1 {
      ${includeTextStyles('large-heading--mobile')};
      @media screen and (min-width: 768px) {
        ${includeTextStyles('large-heading--desktop')};
      }

      @media screen and (max-width: 550px) {
        margin-right: 24px;
      }

      &.truncate {
        max-width: 12em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .header-back-link {
    opacity: 1;
    transition-duration: 0.3s;
    transition-property: opacity;
    margin-bottom: 8px;

    button {
      color: ${getColor('header-backlink-text-color')};
      ${includeTextStyles('nav')};
      border: none;
      background: none;
      padding: 0;
      margin: 0;
      cursor: pointer;
      display: flex;
      &:hover {
        color: ${getColor('header-backlink-text-hover-color')};
      }
      &:active {
        outline: none;
      }
      svg {
        margin-right: 6px;
        /* fill: red; */
      }
    }
  }

  .header-accessory {
    display: flex;
    margin-left: auto;
  }

  .header-menu {
    margin-left: 12px;
  }

  @media screen and (min-width: 551px) {
    &.is-sticky {
      position: sticky;
      top: 0;
      background: ${getColor('off-white')};
      z-index: 2000;
      border-bottom: 1px solid ${getColor('gray-1')};
      /* animation: ${affix} 0.2s ease-in; */

      & > header {
        max-width: ${getDimension('max-screen-width-without-padding')}px;
        /* margin: 0 auto; */
      }

      h1 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .header-back-link {
        opacity: 0;
      }

      &.with-back-link {
        top: -32px;
      }
    }
  }

  @media screen and (max-width: 550px) {
    header {
      align-items: flex-start;
      flex-direction: column;
    }

    .header-accessory {
      margin-left: 0;
      margin-top: 16px;
    }
  }
`;

const BackLink = ({ backLinkLabel, onBackLinkClick }) => {
  return (
    <nav className="header-back-link">
      <button href="#" onClick={onBackLinkClick}>
        <IconArrowLeft />
        {backLinkLabel}
      </button>
    </nav>
  );
};

export const Header = ({
  title,
  showBackLink = false,
  backLinkLabel = 'Back',
  onBackLinkClick = () => {},
  titleAlign = 'left',
  accessory = null,
  menuItems = null,
  makeSticky = false,
  actionControl = null,
}) => {
  const { isSticky, Tracker } = useStickyness(makeSticky, { threshold: 32 });

  const action = actionControl ? (
    actionControl
  ) : menuItems ? (
    <div className="header-menu">
      <OverflowMenu
        items={menuItems}
        buttonType="outline"
        menuPosition="top-right"
      />
    </div>
  ) : null;

  return (
    <>
      {/* this is only to track the scroll position */}
      <Tracker />
      <StyleWrapper
        className={`${showBackLink ? 'with-back-link' : ''} ${
          isSticky ? 'is-sticky' : ''
        }`}
      >
        {showBackLink ? (
          <BackLink {...{ backLinkLabel, onBackLinkClick }} />
        ) : null}
        <header className={[`title-align-${titleAlign}`]}>
          <h1>{title}</h1>
          {action}
          {accessory ? (
            <div className="header-accessory">{accessory}</div>
          ) : null}
        </header>
      </StyleWrapper>
    </>
  );
};
