import React from 'react';
import { observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';
import { useRootStore } from '../../truth';

export const DefaultRoute = observer(() => {
  const store = useRootStore();
  const loggedIn = store?.userManager?.loggedInAndReady;
  if (loggedIn) {
    return <Redirect to="/account" />;
  }

  return <Redirect to="/login" />;
});
