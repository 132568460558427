import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { isUndefined } from 'lodash';
import { SpinnerIcon } from '../ui/icons/spinner-icon';
import styled from 'styled-components';
import createLogger from '../../lib/log';
import { getColor } from '../../lib/theme/utils';
import { objectFromUrlQuery } from '../../lib/object-from-url-query';

const log = createLogger('back-to-app');

const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background: ${getColor('darkBlue')};
`;

const sendMessage = (type, payload) => {
  const msg = JSON.stringify({ type, payload });
  window.ReactNativeWebView.postMessage(msg);
};

export const BackToAppScreen = () => {
  const history = useHistory();
  useEffect(() => {
    // todo: think about using a route-based approach instead of queryParams
    const { success = null, fail = null } = objectFromUrlQuery();
    if (!isUndefined(window.ReactNativeWebView)) {
      sendMessage('checkout', { success, fail });
    } else {
      log.info('not inside a webview');
      if (process.env.NODE_ENV === 'production') {
        history.push('account');
      }
    }
  }, [history]);

  return (
    <Container>
      <SpinnerIcon />
    </Container>
  );
};
