import { flow, getRoot, types } from 'mobx-state-tree';
import { getParentOfName } from '../lib/type-utils/get-parent-of-name';
import * as safeTypes from '../lib/safe-mst-types';

/**
 * PurchasedCoupon
 *
 * list of gift coupons purchased by this account.
 * for display on the website
 *
 * JE: beware, not sure why this needed to be moved above the AccountData,
 * but even with the 'types.late' declaration, things were failing in the native build
 * when declared below AccountData
 */
export const PurchasedCoupon = types
  .model('PurchasedCoupon', {
    code: safeTypes.stringDefaultBlank,
    redeemed: safeTypes.booleanDefaultFalse,
    purchaseStatusMessage: safeTypes.stringOrNull,
    redemptionStatusMessage: safeTypes.stringOrNull,
    purchasedAt: safeTypes.stringOrNull, // 2020-01-31T23:10:05.756Z
    purchasedOn: safeTypes.stringOrNull, // 2020-01-31
    redeemedAt: safeTypes.stringOrNull, // 2019-12-13T00:29:37.808Z
    redeemedOn: safeTypes.stringOrNull, // 2019-12-12
    redeemedByName: safeTypes.stringOrNull,
    redeemedByEmail: safeTypes.stringOrNull,
  })
  .actions(self => ({
    resendInstructions: flow(function* resendInstructions() {
      const userManager = getParentOfName(self, 'UserManager');
      const result = yield userManager?.sendCouponInstructions(self.code);
      userManager.setApiResult(result);
      return result;
    }),

    select() {
      const userManager = getRoot(self)?.userManager;
      // @armando, i think i've seen places where this 'if' check isn't sufficiently safe
      // and I needed a try/catch when not properly initialized.
      // did you add this check for specific reason or just out of general paranoia?
      if (userManager) {
        // @armando we should have a yield here and make this a generator function correct?
        userManager.initiateCheckout(self);
      }
    },
  }))
  .views(() => ({}));
