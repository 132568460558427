import React, { useState, useEffect, useRef } from 'react';
import { throttle } from 'lodash';

export const useStickyness = (active, options = {}) => {
  const [isSticky, setSticky] = useState(false);
  const { threshold } = options;
  const ref = useRef(null);
  const handleScroll = active
    ? throttle(() => {
        if (ref.current) {
          const rect = ref.current.getBoundingClientRect();
          setSticky(rect.top <= ~threshold);
        }
      }, 125)
    : null;

  useEffect(() => {
    if (!active) {
      return;
    }
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Tracker = () => (active ? <span ref={ref} /> : null);

  return { isSticky, Tracker };
};
