import React from 'react';
import { Heading } from 'components/ds/common';

import styled from 'styled-components';

const Container = styled.div`
  margin-bottom: 80px;
`;

export const ClassroomSection = ({
  title,
  children,
  control,
  withHeadingMargin,
  withHeadingRule,
  stickyHeader = false,
}) => {
  return (
    <Container>
      <Heading
        control={control}
        makeSticky={stickyHeader}
        withRule={withHeadingRule}
        withMargin={withHeadingMargin}
      >
        {title}
      </Heading>
      {children}
    </Container>
  );
};
