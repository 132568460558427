import styled, { keyframes } from 'styled-components';
import { getColor, includeTextStyles } from '../../../lib/theme/utils';

const gradient = keyframes`
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
`;

export const TableStyleWrapper = styled.div`
  --border-radius: 8px;
  --border-color: ${getColor('table-cell-border-color')};

  ${includeTextStyles('body')};
  width: 100%;

  table {
    border-collapse: separate;
    width: 100%;
  }

  th {
    --text-color: ${getColor('table-header-text-color')};

    color: var(--text-color);
    background-color: ${getColor('table-header-background-color')};
    border-top: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);
    padding: 12px 16px;

    text-align: var(--text-align, left);

    &:first-child {
      border-left: 1px solid var(--border-color);
      border-top-left-radius: var(--border-radius);
    }
    &:last-child {
      border-right: 1px solid var(--border-color);
      border-top-right-radius: var(--border-radius);
    }

    &.text-align-right {
      --text-align: right;
    }

    &.text-align-center {
      --text-align: center;
    }
  }

  tr:nth-child(odd) {
    background: ${getColor('table-cell-alternate-background-color')};
  }

  td {
    padding: 16px;
    &:first-child {
      border-left: 1px solid var(--border-color);
    }
    &:last-child {
      border-right: 1px solid var(--border-color);
    }
  }

  tr:last-child {
    td {
      border-bottom: 1px solid var(--border-color);
      &:first-child {
        border-bottom-left-radius: var(--border-radius);
        border-left: 1px solid var(--border-color);
      }
      &:last-child {
        border-bottom-right-radius: var(--border-radius);
        border-right: 1px solid var(--border-color);
      }
    }
  }

  tr.loading {
    td {
      background: linear-gradient(270deg, #fff, #f0f0f0);
      background-size: 400% 400%;
      animation: ${gradient} 2s ease infinite;
    }
    &:nth-child(odd) {
      td {
        animation-delay: 1s;
      }
    }
  }

  .overflow-menu-cell {
    width: 24px;
  }

  @media screen and (max-width: 550px) {
    .tr {
      position: relative;
      border-top: 1px solid ${getColor('gray-1')};
      &:last-child {
        border-bottom: 1px solid ${getColor('gray-1')};
      }
      padding-bottom: 12px;
    }

    .td:first-child {
      /* padding-right: 32px; */
    }

    .overflow-menu-cell {
      position: absolute;
      right: 0;
      top: 16px;
      z-index: 100;
    }
  }
`;
