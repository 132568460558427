import React from 'react';
import __ from 'jw-core/lib/localization';

export const NotFoundScreen = () => (
  <section className="error-404">
    <div className="error-404__content">
      <h1 className="error-404__heading">
        {__('Page not found', 'general.pageNotFound.title')}
      </h1>
      <p className="error-404__body">
        {/*{__('We’re sorry but we can’t find that page. Please use the navigation above to find what you are looking for.', 'general.pageNotFound.text')}*/}
        {__(
          'We’re sorry but we can’t find that page.',
          'general.pageNotFound.text'
        )}
      </p>
    </div>
  </section>
);
