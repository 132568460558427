import React from 'react';
import { useRootStore } from 'mst';
import { observer } from 'mobx-react';
import { IconGirlS, IconLock } from 'components/ds/icons';
import { IconControlWrapper } from './icon-control-wrapper';
import { Link } from 'react-router-dom';
import { useLockedStoryScriptAlert } from '../classroom-hooks';

import __ from 'jw-core/lib/localization';

const OpenStoryGuideControl = observer(({ slug }) => {
  const to = `/scripts/${slug}`;

  return (
    <IconControlWrapper as={Link} to={to} target="_blank" className="is-a-link">
      <IconGirlS />
      <span>{__('Story script', 'classrooms.storyScript')}</span>
    </IconControlWrapper>
  );
});

const LockedStoryGuideControl = observer(({ slug }) => {
  const [Alert, alertActions] = useLockedStoryScriptAlert();
  return (
    <>
      <IconControlWrapper className="is-a-link" onClick={alertActions.on}>
        <IconLock />
        <span>{__('Story script', 'classrooms.storyScript')}</span>
      </IconControlWrapper>
      <Alert />
    </>
  );
});

export const StoryGuideControl = observer(({ story }) => {
  const store = useRootStore();
  const { slug, trial } = story.catalogData;

  const canAccess = trial
    ? true
    : store.userManager?.accountData?.canAccessAllStoryScripts;

  if (canAccess) {
    return <OpenStoryGuideControl slug={slug} />;
  }

  return <LockedStoryGuideControl slug={slug} />;
});
