import React from 'react';
import { Code } from 'components/ds/common/code';
import { MobileHeaderCell } from './mobile-header-cell';

export const CodeCell = ({ code, cell }) => {
  return (
    <MobileHeaderCell header={cell.headerLabel}>
      <Code code={code} />
    </MobileHeaderCell>
  );
};
