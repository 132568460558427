import React from 'react';

export const BackArrowIcon = () => (
  <svg width={32} height={32} viewBox="0 0 32 32">
    <g fill="#19BBD4">
      <path d="M0 16c0 8.837 7.163 16 16 16s16-7.163 16-16S24.837 0 16 0 0 7.163 0 16zm1 0C1 7.716 7.716 1 16 1c8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15-8.284 0-15-6.716-15-15z" />
      <path d="M10.743 15.333H24v1.334H10.743L16 21.67l-.98.997L8 16l7.02-6.667.98.997-5.257 5.003z" />
    </g>
  </svg>
);
