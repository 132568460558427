/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useForm } from 'react-hook-form';
import { useRootStore } from '../../truth';
import { useHistory } from 'react-router-dom';
import { useSwitch } from '../../lib/hooks/use-switch';
import { AuthContainer } from '../auth/auth-container';
import { AuthHeader } from '../auth/auth-header';
import { AuthHeading } from '../auth/auth-heading';
import { AuthButton } from '../auth/auth-button';
import { AuthInput } from '../auth/auth-input';
import { useTitle } from '../../lib/hooks/use-title';
import { prevent } from '../../lib/prevent';
import { MarkdownContainer } from 'components/ds/common/markdown-container';

import { getConfig } from 'config';

import __ from 'jw-core/lib/localization';
import styled from 'styled-components';

const jwMode = getConfig('appSlug') === 'jw';

const ButtonContainer = styled.div`
  margin-top: 24px;
`;

export const ResetSuccessMessage = ({ email, onResend }) => {
  return (
    <div className="auth">
      <div className="auth_content">
        <div className="message loading">
          <h2 className="message__heading">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              className="message__heading__icon"
            >
              <g fill="none">
                <circle cx="16" cy="16" r="16" fill="#69C00D"></circle>
                <path
                  stroke="#FFF"
                  strokeWidth="3"
                  d="M9 17.765L14.444 22 23 10"
                ></path>
              </g>
            </svg>
            Email sent
          </h2>{' '}
          <p className="message__body">
            <MarkdownContainer
              source={__(
                'Check your **%{email}** inbox for instructions from us on how to reset your password.',
                'auth.checkYourEmail',
                { email }
              )}
            />
          </p>{' '}
          <p className="message__body">
            {__('Didn’t receive the email?', 'auth.didntReceiveTheEmail')}{' '}
            <a href="#" onClick={prevent(onResend)}>
              {__('Resend', 'common.resend')}.
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export const ResetPasswordScreen = observer(() => {
  useTitle('Reset password');
  const history = useHistory();
  const [loading, loadingSwitch] = useSwitch(false);
  const [newEmail, setNewEmail] = useState(null);
  const store = useRootStore();
  const { userManager, validationErrorForKey } = store;
  const { apiResult } = userManager;

  // clean error messages from other screens
  useEffect(() => store.clearMessages(), [store]);

  useEffect(() => {
    if (userManager.loggedInAndReady) {
      history.push('account');
    }
  }, [userManager.loggedInAndReady, history]);

  const { handleSubmit, register } = useForm();

  const submitHandler = handleSubmit(({ email }) => {
    loadingSwitch.on();
    setNewEmail(email);
    userManager.sendPasswordReset(email, true).then(() => {
      loadingSwitch.off();
    });
  });

  const push = path => () => history.push(path);

  // technically this could be the apiResult for something else
  // @joseph maybe it should be an object with keys instead?
  // like `resultForKey('sendPasswordReset')` ?
  if (apiResult?.success) {
    return (
      <ResetSuccessMessage
        email={newEmail}
        onResend={() => {
          userManager.sendPasswordReset(newEmail, true);
        }}
      />
    );
  }

  return (
    <AuthContainer>
      <AuthHeader
        showBackButton={!jwMode}
        onBackButtonClick={push('/login-with-email')}
      >
        <AuthHeading>
          {__('Reset password', 'common.resetPassword')}
        </AuthHeading>
      </AuthHeader>
      <form className="form" onSubmit={submitHandler}>
        <p className="auth__help">
          {__(
            'Enter your email address to request a password reset',
            'auth.enterYourEmailAddressToRequest'
          )}
        </p>
        <AuthInput
          ref={register}
          name={'email'}
          autoCapitalize="none"
          label={__('Email address', 'auth.emailAddress')}
          error={validationErrorForKey('email')}
          autocomplete={'off'}
        />
        <ButtonContainer>
          <AuthButton
            isLoading={loading}
            title={__('Reset password', 'common.resetPassword')}
            onClick={submitHandler}
          />
        </ButtonContainer>
      </form>
    </AuthContainer>
  );
});
