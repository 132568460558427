import React from 'react';

export const HidePasswordIcon = () => {
  return (
    <svg width="52" height="48">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#999"
          fillRule="nonzero"
          d="M15.287 29.592c-1.887-1.243-3.602-2.927-5.084-4.96a1.09 1.09 0 010-1.269C13.528 18.806 18.033 16 23 16c1.707 0 3.359.331 4.927.951l-2.172 2.173a5.32 5.32 0 00-3.111-.757c-2.723.175-4.93 2.454-5.1 5.265a5.787 5.787 0 00.647 3.055l-2.904 2.905zm2.786 1.457l2.172-2.173a5.32 5.32 0 003.111.757c2.718-.18 4.925-2.46 5.1-5.265a5.787 5.787 0 00-.647-3.055l2.904-2.905c1.887 1.243 3.602 2.927 5.084 4.96a1.09 1.09 0 010 1.269C32.472 29.194 27.967 32 23 32a13.34 13.34 0 01-4.927-.951zm4.125-4.126l3.664-3.664c.071.296.1.61.08.933-.097 1.521-1.288 2.746-2.75 2.839a2.876 2.876 0 01-.994-.108zm-2.055-2.187a3.14 3.14 0 01-.079-.934c.09-1.515 1.282-2.74 2.75-2.838.346-.023.68.016.993.108l-3.664 3.664z"
        ></path>
        <path stroke="#999" d="M33 14L13 34"></path>
      </g>
    </svg>
  );
};
