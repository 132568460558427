/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useLayoutEffect } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { Button } from 'components/ds/buttons';
import { getColor, getDimension, includeTextStyles } from 'lib/theme/utils';
import { animated, useTransition } from 'react-spring';
import { useKeyboardBinding } from 'lib/hooks/use-keyboard-binding';

import __ from 'jw-core/lib/localization';

const Container = styled(animated.div)`
  position: fixed;
  margin: 0;
  padding: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 10000;
  padding: 8px;
  @media screen and (min-width: 550px) {
    padding: 12px;
  }
  & > .frame {
    background-color: ${getColor('off-white')};
    height: calc(100vh - 16px);
    max-width: ${getDimension('max-screen-width')}px;
    margin: 0 auto;
    border-radius: 8px;
    position: relative;
    display: flex;
    flex-direction: column;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    @media screen and (min-width: 550px) {
      height: calc(100vh - 24px);
    }
    .header {
      .inner {
        height: 56px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-bottom: 1px solid ${getColor('gray-1')};
        margin: 0 16px;
        max-width: ${getDimension('max-screen-width')}px;
        @media screen and (min-width: 550px) {
          margin: 0 24px;
        }
      }
    }
    .content {
      height: 100%;
      overflow-y: scroll;
      overflow-x: hidden;
      padding-bottom: 8px;
      @media screen and (max-width: 550px) {
        padding-bottom: 146px;
      }
    }

    .content-wrapper {
      padding: 0 16px;

      width: 100%;
      max-width: ${getDimension('max-screen-width')}px;
      margin: 0 auto;
      flex: 1 0 auto;
      @media screen and (min-width: 550px) {
        padding: 0 24px;
      }
    }
    .modal-title {
      ${includeTextStyles('medium-heading')};
    }
    .modal-controls {
      margin-left: auto;
      display: flex;
      /* & > * {
        margin-left: 16px;
      } */
    }
  }
`;

export const ModalWrapper = observer(({ onDone, onDismiss, children }) => {
  const [show, set] = useState(true);

  const transitions = useTransition(show, null, {
    from: { transform: 'scale(1.05)', opacity: 0 },
    enter: { transform: 'scale(1.0)', opacity: 1 },
    leave: { transform: 'scale(1.05)', opacity: 0 },
    onDestroyed: onDismiss,
    duration: 100,
  });

  useLayoutEffect(() => {
    // this blurs the active element which would be the button that called the modal
    // and blurs it so the keybindings work
    document.activeElement.blur();
    // this adds a special class to the body that prevent is from scrolling
    document.body.classList.add('__no-scroll');
    return () => {
      document.body.classList.remove('__no-scroll');
    };
  });

  const handleDone = () => {
    onDone();
    set(false);
  };

  const handleCancel = () => {
    set(false);
  };

  useKeyboardBinding('Escape', handleCancel);
  useKeyboardBinding('Meta+Enter', handleDone); //cmd+enter on mac or ctrl+enter in PC

  return transitions.map(
    ({ item, key, props }) =>
      item && (
        <Container key={key} style={props}>
          <div className="frame">
            <div className="header">
              <div className="inner">
                <div className="modal-title">
                  {__('Select stories', 'classroom.selectStories')}
                </div>
                <div className="modal-controls">
                  <Button
                    label={__('Cancel', 'common.cancel')}
                    onClick={handleCancel}
                    size="small"
                    type="text"
                  />
                  <Button
                    label={__('Done', 'common.done')}
                    presentation={'primary'}
                    onClick={handleDone}
                    size="small"
                  />
                </div>
              </div>
            </div>
            <div className="content">
              <div className="content-wrapper">{children}</div>
            </div>
          </div>
        </Container>
      )
  );
});
