import { Passage } from './passage';
import { Paragraph } from './paragraph';
import { Sentence } from './sentence';
import { WordGroup } from './word-group';

export const typeMap = {
  Passage,
  Paragraph,
  Sentence,
  WordGroup,
};
