import React from 'react';
import { getColor, includeTextStyles } from 'lib/theme/utils';
import { useMediaQuery } from 'react-responsive';

import __ from 'jw-core/lib/localization';
import styled from 'styled-components';

// TODO: maybe extract the progress component into a standalone?

const Wrapper = styled.div`
  .progress-container {
    display: block;
    width: 100%;
    height: 6px;
    border-radius: 3px;
    background: rgba(0, 0, 0, 0.04); /* Yep, not a palette color */
    position: relative;
  }

  .progress-bar {
    position: absolute;
    left: 0;
    top: 0;
    height: 6px;
    border-radius: 3px 0 0 3px;
    background: ${getColor('teal')};
    &.is-completed {
      border-radius: 3px;
    }
  }

  .progress-text {
    ${includeTextStyles('small-text')}
    color:${getColor('gray-4')};
  }

  @media screen and (max-width: 550px) {
    margin-top: 4px;
    margin-bottom: 8px;
  }
`;

const ProgressBar = ({ progress, completed }) => {
  const width = `${completed ? 100 : progress * 100}%`;
  return (
    <div className="progress-container">
      <div
        className={`progress-bar ${completed ? 'is-completed' : ''}`}
        style={{ width }}
      ></div>
    </div>
  );
};

const ProgressText = ({ numberOfChapters, completed, completedChapters }) => {
  if (completed) {
    return (
      <span className="progress-text is-completed">
        {__('Completed', 'common.completed')}
      </span>
    );
  }

  return (
    <span className="progress-text">
      {__('%{n}/%{total} chapters complete', 'story.storyProgress', {
        n: completedChapters,
        total: numberOfChapters,
      })}
    </span>
  );
};

export const ProgressCell = ({ catalogData, progress }) => {
  const isMobile = useMediaQuery({
    query: '(max-device-width: 550px)',
  });
  const { storyProgress } = progress;
  const completed = storyProgress?.furthestPoint?.atEndOfStory ?? false;
  const { completedChapters = 0 } = storyProgress ?? {};
  const { numberOfChapters } = catalogData;
  const ratio = Math.min(completedChapters / numberOfChapters, 1);
  return (
    <Wrapper>
      <ProgressBar progress={ratio} completed={completed} />
      {isMobile ? null : (
        <ProgressText {...{ numberOfChapters, completed, completedChapters }} />
      )}
    </Wrapper>
  );
};
