import React from 'react';
import { observer } from 'mobx-react';
import { IconDownloadS } from 'components/ds/icons';
import { IconControlWrapper } from './icon-control-wrapper';

import __ from 'jw-core/lib/localization';

export const LessonPlanControl = observer(({ url }) => {
  if (!url) {
    return null;
  }
  return (
    <IconControlWrapper
      as={'a'}
      href={url}
      target="_blank"
      className="is-a-link"
    >
      <IconDownloadS />
      <span>{__('Example lesson plan', 'classrooms.exampleLessonPlan')}</span>
    </IconControlWrapper>
  );
});
