import React from 'react';
import { useRootStore } from '../../truth';
import { EnsureAuth } from '../../components/ensure-auth';
import { AccountMessageTarget } from '../account/account-message-target';
import { PurchasedCoupons } from '../../components/gift-coupons/purchased-coupons';
import { Ecoupon } from '../../components/gift-coupons/e-coupon';
import { CheckoutResultTarget } from '../account/checkout-result-target';
import { useTitle } from '../../lib/hooks/use-title';

import styled from 'styled-components';

const CouponsWrapper = styled.div`
  padding-top: 40px;

  @media screen and (max-width: 575px) {
    flex-wrap: wrap;
    /* background-color: limegreen; */
    display: flex;
    flex-direction: column;
    align-items: center;
    .card {
      margin-bottom: 100px;
    }
    .card:last-child {
      margin-bottom: 80px;
    }
  }

  @media screen and (min-width: 576px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const FooterContent = () => (
  <div className="gift-coupons__section gift-coupons__section--help">
    <div className="gift-coupons__content">
      <div className="wysiwyg wysiwyg--sans-serif">
        <h3>How do gift coupons work?</h3>
        <p>
          After you buy a gift coupon you will receive an email with the coupon
          code and instructions of how to redeem. The coupon code will also be
          listed at the top of this page, and you'll be able to see when it is
          redeemed, and by whom.
        </p>
        <p>
          The recipient will create their account and redeem the coupon on{' '}
          <a href="https://my.lupa.app">my.lupa.app</a>.
        </p>
        <h3>Any questions?</h3>
        <p>
          We're here to help,{' '}
          <a
            href="https://help.lupa.app"
            target="_blank"
            rel="noreferrer noopener"
          >
            visit our help center
          </a>
          .
        </p>
      </div>
    </div>
  </div>
);

export const GiftCouponsScreen = () => {
  useTitle('Gift coupons');

  const store = useRootStore();
  const accountData = store?.userManager?.accountData ?? {};
  const { purchasedCoupons, giftPlans = [] } = accountData;

  return (
    <EnsureAuth>
      <section className="gift-coupons">
        <div>
          <div className="gift-coupons__section">
            <div className="gift-coupons__content">
              <AccountMessageTarget />
              <PurchasedCoupons
                coupons={purchasedCoupons}
                name={accountData.name}
              />
            </div>
          </div>
          <div className="gift-coupons__section">
            <div className="gift-coupons__content">
              <h1 className="gift-coupons__heading">Purchase a gift coupon</h1>
              <p className="gift-coupons__purchase-prompt">
                Know someone who&rsquo;s studying Spanish at the intermediate
                level? Help them get fluent faster with a Lupa e-coupon.
              </p>
            </div>
          </div>
          <div className="gift-coupons__section gift-coupons__section--coupon-cards">
            <div className="gift-coupons__content">
              <div className="gift-coupons__wave-bg"></div>
              <CouponsWrapper>
                {giftPlans.map(plan => (
                  <Ecoupon plan={plan} key={plan.slug} />
                ))}
              </CouponsWrapper>
            </div>
          </div>
        </div>
        <FooterContent></FooterContent>
      </section>
      <CheckoutResultTarget />
    </EnsureAuth>
  );
};
