import React from 'react';
import { useSwitch } from '../../lib/hooks/use-switch';
import { prevent } from '../../lib/prevent';

const copyStringToClipboard = str => {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style = { position: 'absolute', left: '-9999px' };
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

export const CopyCodeButton = ({ code }) => {
  const [active, activeSwitch] = useSwitch(false);

  const copyCode = () => {
    try {
      copyStringToClipboard(code);
      activeSwitch.on();
      setTimeout(() => {
        activeSwitch.off();
      }, 1000);
    } catch (error) {}
  };

  const label = active ? 'Copied' : 'Copy code';
  const className = `coupon-purchase__action-btn ${
    active ? 'coupon-purchase__action-btn--active' : ''
  }`;

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a href="#" className={className} onClick={prevent(copyCode)}>
      {active ? (
        <svg width="16" height="16" viewBox="0 0 16 16">
          <polygon
            fill="#FFF"
            points="13.843 1 16 2.5 7.19 15 0 9.999 1.51 7.856 6.543 11.357"
          />
        </svg>
      ) : null}
      {label}
    </a>
  );
};
