import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { AuthError } from 'components/auth/auth-input';
import { getColor, includeTextStyles } from 'lib/theme/utils';
import { useForm } from 'react-hook-form';
import __ from 'jw-core/lib/localization';

import { Dialog } from './dialog';

const Wrapper = styled.div`
  ${includeTextStyles('body')};
  .hint {
    padding-bottom: 16px;
  }
  input,
  textarea {
    flex: 1;
    width: 100%;
    display: block;
    background: ${getColor('white')};
    border: 1px solid ${getColor('gray-1')};
    box-sizing: border-box;
    border-radius: 4px;
    padding: 12px 16px;

    &::placeholder {
      color: ${getColor('gray-3')};
    }

    &:focus {
      border-color: ${getColor('teal')};
      outline: none;
    }
  }

  textarea {
    height: 142px;
    resize: none;
  }
`;

export const Prompt = ({
  label = null,
  initialValue = '',
  placeholder = '',
  okButtonAction = () => {},
  cancelButtonAction = () => {},
  showClearButton = false,
  type,
  validation = {},
  hint,
  error: externalError,
  ...props
}) => {
  const inputRef = useRef();

  const {
    handleSubmit,
    register,
    watch,
    errors: localValidationErrors,
    setValue,
    reset,
  } = useForm({
    defaultValues: { value: initialValue },
  });

  const submitHandler = handleSubmit(({ value }) => {
    return okButtonAction(value);
  });

  const valueWatch = watch('value');

  const handleCancelButton = () => {
    reset();
    return cancelButtonAction(valueWatch);
  };

  useEffect(() => {
    inputRef.current.focus();
    inputRef.current.select();
  }, []);

  const reRegister = e => {
    register(e, validation);
    inputRef.current = e;
  };

  const clear = () => {
    if (valueWatch) {
      setValue('value', '', { shouldValidate: true });
    }
  };

  return (
    <Dialog
      title={label}
      okIsDisabled={!valueWatch}
      okButtonAction={submitHandler}
      cancelButtonAction={handleCancelButton}
      hideRulerOnButtonsContainer={true}
      showThirdButton={showClearButton}
      thirdButtonLabel={__('Clear', 'common.clear')}
      thirdButtonIsDisabled={!valueWatch}
      thirdButtonIsDangerous={true}
      thirdButtonAction={clear}
      body={() => (
        <Wrapper>
          <form onSubmit={submitHandler}>
            {hint ? <div className="hint">{hint}</div> : null}
            {type === 'textarea' ? (
              <textarea
                name="value"
                placeholder={placeholder}
                ref={reRegister}
                data-test-id="prompt-input-textarea"
              />
            ) : (
              <input
                name="value"
                placeholder={placeholder}
                type={type}
                ref={reRegister}
                data-test-id="prompt-input-value"
              />
            )}
            {localValidationErrors.value ? (
              <AuthError
                error={localValidationErrors.value}
                maxHeight={false}
              />
            ) : null}
            {externalError ? (
              <AuthError error={externalError} maxHeight={false} />
            ) : null}
          </form>
        </Wrapper>
      )}
      {...props}
    />
  );
};
