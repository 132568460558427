import { useEffect, useState } from 'react';
import { useRootStore } from 'mst';

export const useStoryData = slug => {
  const store = useRootStore();
  const { storyManager } = store;
  const [fullData, setFullData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const requestData = async () => {
      setLoading(true);
      const story = storyManager.story(slug);
      if (!story) {
        return;
      }
      const response = await fetch(story.catalogData.dataUrl);
      if (response.status === 200) {
        const json = await response.json();
        setFullData(json);
        setLoading(false);
      }
    };
    requestData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [fullData, loading];
};
