import { flow, getEnv, getRoot, types } from 'mobx-state-tree';
import { get, sumBy } from 'lodash';

import minibus from '../lib/minibus';
import { v4CatalogMode } from '../lib/app-util';

export const downloadStatus = {
  NOT_DOWNLOADED: 'not-downloaded',
  PENDING_DOWNLOAD: 'pending-download',
  DOWNLOADING: 'downloading',
  DOWNLOAD_FAILED: 'download-failed',
  DOWNLOADED: 'downloaded',
};

// provide unscoped local access

export const assetKeys = {
  BANNER_IMAGE: 'story-image', // better names, perhaps someday rename the property keys
  LIST_IMAGE: 'story-thumbnail',
  FULL_DATA: 'full-data',
  NORMAL_AUDIO_PREFIX: 'normal-audio-',
};

/**
 * DownloadManager
 *
 * owns the download statuses of each story.
 * lives independently of the current user.
 */
export const DownloadManager = types
  .model('DownloadManager', {})
  .volatile(() => ({
    // queue: new Set(),
    downloading: false,
    debugReducedAvailableSpaceInMb: 0,
    interruptingQueue: false,
  }))
  .views(self => ({
    get $log() {
      const { createLogger = () => {} } = getEnv(self);
      const log = createLogger('dm:download-manager');
      return log;
    },
    get $downloader() {
      const { downloader = {} } = getEnv(self);
      return downloader;
    },
    get $platform() {
      const { platform = {} } = getEnv(self);
      return platform;
    },
    get $track() {
      const { track = () => {} } = getEnv(self);
      return track;
    },
    get $notifications() {
      const { notifications = {} } = getEnv(self);
      return notifications;
    },
  }))
  .actions(self => ({
    setDebugReducedAvailableSpaceInMb(mb) {
      self.debugReducedAvailableSpaceInMb = mb;
    },
  }))
  .actions(self => {
    const nextPendingAsset = () => {
      const root = getRoot(self);
      if (root !== null) {
        // todo: revisit this
        // let image = root.storyManager.firstPendingVolumeImage;
        // if (image) {
        //   return image;
        // }

        // const volume = root.storyManager.firstVolumeWithPendingAssets;
        // if (volume) {
        //   return volume.download.firstActiveOrPendingDownload;
        // }

        const image = root.storyManager.firstPendingDashboardImage;
        if (image) {
          return image;
        }

        const story = root.storyManager.firstWithPendingAssets;
        if (story) {
          return story.download.firstActiveOrPendingDownload;
        }
      } else {
        self.$log.error('nextPendingAsset - root is null for some reason');
      }

      return null;
    };

    return {
      /**
       * This runs automatically after getting account data.
       * Makes sure we have required assets for the UI, like images
       * so it enqueues them all and then proceeds to run the download queue
       */
      ensureAllCatalogAssets() {
        const root = getRoot(self);
        if (root !== null) {
          const { storyManager } = root;

          // for v4 mode, only first unit list image will be fetched
          storyManager.stories.forEach(story => {
            story.download.queueCatalogAssets();
          });

          /// enqueue trial stories if this is a new user
          self.queueNewUserAssetsIfNeeded();

          // no longer needed, vocab review requires full download now
          /// download data for stories that had been downloaded once
          // self.queueInProgressFullData();

          self.refreshDownloaded();
          self.runQueue(); // don't await
        } else {
          self.$log.error(
            'ensureAllCatalogAssets - root is null for some reason'
          );
        }
      },

      /**
       * runs queueNewUserAssets for first time users only
       */
      queueNewUserAssetsIfNeeded() {
        const selfRoot = getRoot(self);
        if (selfRoot !== null) {
          // log(`queueNewUserAssetsIfNeeded - areAllUnplayed: ${selfRoot.storyManager.areAllUnplayed}`);
          // if (!!selfRoot.storyManager.areAllUnplayed) {
          //   self.queueNewUserAssets();
          // }

          // areAllUnplayed was strangly incorrect due to what felt like a startup race condition
          // for now just use full-access as a check

          const firstTimeLaunch = get(
            selfRoot.userManager,
            'userData.userSettings.showWelcomeMessage',
            false
          );
          self.$log.debug(`firstTimeLaunch: ${firstTimeLaunch}`);

          if (firstTimeLaunch && !selfRoot.userManager.accountData.fullAccess) {
            self.queueNewUserAssets();
          }
        } else {
          // todo: use a warn here
          self.$log.error('ensureNewUserAssets - root is null for some reason');
        }
      },

      /**
       * Automatically enqueues every trial story provided there's enough space
       */
      queueNewUserAssets: flow(function* queueNewUserAssets() {
        self.$log.debug('queueNewUserAssets');
        const selfRoot = getRoot(self);

        // We won't try to download the trial episodes unless we have enough diskspace

        // get the available space in disk
        const availableSpaceInMb = yield self.$downloader.getAvailableSpaceInMb(
          self.debugReducedAvailableSpaceInMb
        );

        // the sum of the space required by all the trial stories
        const spaceRequired = sumBy(selfRoot.storyManager.trial, story => {
          return story.catalogData.downloadSizeInMB;
        });

        // if we don't have enough space, bail
        if (spaceRequired > availableSpaceInMb) {
          self.$log.warn(
            "Won't queue new user assets because of lack of disk space"
          );
          minibus.defer('DISK_IS_FULL');
          return;
        }

        // download trial stories
        if (v4CatalogMode()) {
          selfRoot.storyManager.trialVolumes.forEach(volume => {
            self.$log.debug(`queueing trial volume downloads: ${volume.slug}`);
            volume.download.addToDownloadQueue();
          });
        } else {
          selfRoot.storyManager.trial.forEach(story => {
            self.$log.debug(`queueing trial downloads: ${story.slug}`);
            story.download.addToDownloadQueue();
          });
        }
      }),

      /**
       * make sure we have the full data needed for the vocab view for any
       * stories which have broken but not currently downloaded
       */
      // queueInProgressFullData() {
      //   self.$log.debug('queueInProgressFullData');
      //   const selfRoot = getRoot(self);
      //   selfRoot.storyManager.inProgress.forEach(story => {
      //     story.download.ensureFullData();
      //   });
      // },

      /**
       * makes sure latest full data and audio assets are refreshed if needed for
       * any already downloaded assets
       */
      refreshDownloaded() {
        self.$log.debug('refreshDownloaded');
        const selfRoot = getRoot(self);
        selfRoot.storyManager.downloaded.forEach(story => {
          story.download.ensureFullAssets();
        });
      },

      /**
       * Sets a flag `downloading` to true to prevent running the queue
       * more than once
       */
      blockQueue() {
        self.$log.debug('blockQueue');
        self.downloading = true;
      },

      /**
       * Releases the queue so it can be called again
       */
      releaseQueue() {
        self.$log.debug('releaseQueue');
        self.downloading = false;
        self.interruptingQueue = false;
      },

      /**
       * Sets the `interruptingQueue` flag to true, which will be checked
       * by `runQueue` on every iteration to interrupt the queue running.
       */
      interruptQueue() {
        self.interruptingQueue = true;
      },

      /**
       * Downloads all the enqueued assets
       */
      runQueue: flow(function* runQueue() {
        if (self.$platform.onWebsite()) {
          self.$log.debug('Skipping downloads for website');
          return;
        }
        if (self.downloading) {
          self.$log.info(`runQueue - already downloading`);
          return;
        }
        let catalogAssetSeen = false;
        try {
          self.blockQueue();
          // track if catalog assets seen to we can persist once at the end
          while (true) {
            if (self.interruptingQueue) {
              // @armando, not sure why we attempted to cancel here.
              // it didn't seem like 'asset' was even a valid reference here
              // and i'm not sure how having a 'const' inside this while loop
              // even works.
              // yield asset.cleanupIfCanceled();
              break;
            }
            const asset = nextPendingAsset();

            if (asset) {
              self.$log.trace(
                `runQueue - next asset: ${JSON.stringify(asset)}`
              );
              if (asset.isCatalogLevel) {
                catalogAssetSeen = true;
              }
              const status = yield asset.download();
              self.$log.trace(`runQueue - asset download status: ${status}`);
              // yield asset.cleanupIfCanceled();
              // self.$log.trace(`runQueue - after cleanupIfCanceled`);
            } else {
              self.$log.trace('runQueue - no pending assets - exiting');
              break;
            }
          }
        } catch (error) {
          // not sure if we need this catch, but be verbose for now
          self.$log.error(
            `unexpected runQueue error: ${JSON.stringify(error)}`
          );
          throw error;
        } finally {
          if (catalogAssetSeen) {
            const selfRoot = getRoot(self);
            try {
              yield selfRoot.persistAll();
            } catch (error) {
              // this can die during shutdown race conditions with the queue execution
              self.$log.error(`runQueue - invalid root: ${String(selfRoot)}`);
            }
          }
          self.releaseQueue();
        }
      }),

      /**
       * removes both catalog and full assets - useful for testing
       */
      removeAllAssets: flow(function* removeAllAssets() {
        self.$log.info('removeAllAssets');
        const selfRoot = getRoot(self);
        if (selfRoot !== null) {
          // eslint-disable-next-line prefer-const
          for (let story of selfRoot.storyManager.stories) {
            yield story.download.removeAllAssets();
          }
          selfRoot.persistAll();
          self.$notifications.notifySuccess('All assets removed');
        } else {
          self.$log.error('removeAllAssets - root is null for some reason');
        }
      }),
    };
  });
