import React from 'react';
import styled from 'styled-components';
import { getFontName } from '../../lib/theme/utils';
import { prevent } from '../../lib/prevent';
import { useSwitch } from '../../lib/hooks/use-switch';
import { ErrorIcon } from '../ui/icons/error-icon';
import { HidePasswordIcon } from '../ui/icons/hide-password-icon';
import { ShowPasswordIcon } from '../ui/icons/show-password-icon';

const Field = styled.div`
  position: relative;
`;

const Label = styled.label`
  margin-bottom: 1rem;
  display: block;
  font-family: ${getFontName('body')};
  font-size: 2rem;
  line-height: 3rem;
  font-weight: 400;
  @media screen and (min-width: 550px) {
    position: absolute;
    top: 1.625rem;
    left: 2rem;
    color: #999;
    width: 16rem;
  }
`;

const Input = styled.input`
  display: block;
  font-family: ${getFontName('body')};
  font-size: 2.125rem;
  line-height: 3rem;
  font-weight: 400;
  border: 1px solid #e9e3d9;
  width: 100%;
  padding: calc(1.5rem - 1px) 2rem;
  margin-bottom: 3rem;
  border-radius: 4px;
  @media screen and (min-width: 550px) {
    margin-bottom: 1rem;
    padding-left: 18rem;
  }
`;

const ErrorContainer = styled.div`
  font-family: ${getFontName('body')};
  font-size: 2.125rem;
  line-height: 3rem;
  font-weight: 400;
  color: #e55c58;
  padding-left: 3.25rem;
  transition: all 0.3s;
  position: relative;
  margin: 8px 0 12px 0;
  &.max-height {
    max-height: 9rem;
  }
  &.no-max-height {
    max-height: auto;
  }
`;

const ErrorIconContainer = styled.div`
  position: absolute;
  left: 0;
  padding: 0;
`;

export const AuthError = ({ error, maxHeight = true }) => {
  if (!error) {
    return null;
  }
  return (
    <ErrorContainer
      data-test-id="error-message"
      className={maxHeight ? 'max-height' : 'no-max-height'}
    >
      <ErrorIconContainer>
        <ErrorIcon />
      </ErrorIconContainer>
      {error.message}
    </ErrorContainer>
  );
};

const PasswordIconContainer = styled.a`
  position: absolute;
  right: 0;
  top: 0;
  @media screen and (max-width: 550px) {
    top: 4rem;
  }
`;

// random ids for inputs
const _id = () => {
  return Math.floor(Math.random() * 1e3).toString(16);
};

const PasswordInput = ({ ...props }) => {
  const [showing, showSwitch] = useSwitch(false);
  const type = showing ? 'text' : 'password';
  return (
    <BaseInput
      {...props}
      type={type}
      accessory={
        <PasswordIconContainer href="#" onClick={prevent(showSwitch.toggle)}>
          {showing ? <HidePasswordIcon /> : <ShowPasswordIcon />}
        </PasswordIconContainer>
      }
    />
  );
};

const BaseInput = ({
  label,
  _ref,
  type = 'text',
  accessory,
  ...inputProps
}) => {
  const id = _id();
  return (
    <Field>
      <Label htmlFor={id}>{label}</Label>
      <Input ref={_ref} type={type} {...inputProps} id={id} />
      {accessory}
      <p className="form__error form__error--hidden" />
    </Field>
  );
};

export const AuthInput = React.forwardRef(
  ({ type = 'text', error = null, ...inputProps }, ref) => {
    const isPassword = type === 'password';

    return (
      <>
        {isPassword ? (
          <PasswordInput type={type} {...inputProps} _ref={ref} />
        ) : (
          <BaseInput type={type} {...inputProps} _ref={ref} />
        )}
        <AuthError error={error}></AuthError>
      </>
    );
  }
);
