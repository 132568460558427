import {
  flow,
  getEnv,
  getParent,
  getRoot,
  getSnapshot,
  types,
} from 'mobx-state-tree';

import * as safeTypes from '../lib/safe-mst-types';
import minibus from '../lib/minibus';
import { AssetDownload } from './asset-download';
import { v4CatalogMode } from '../lib/app-util';

export const downloadStatus = {
  NOT_DOWNLOADED: 'not-downloaded',
  PENDING_DOWNLOAD: 'pending-download',
  DOWNLOADING: 'downloading',
  DOWNLOAD_FAILED: 'download-failed',
  DOWNLOADED: 'downloaded',
};

// provide unscoped local access
const {
  NOT_DOWNLOADED,
  PENDING_DOWNLOAD,
  DOWNLOADING,
  DOWNLOAD_FAILED,
  DOWNLOADED,
} = downloadStatus;

export const assetKeys = {
  BANNER_IMAGE: 'story-image', // better names, perhaps someday rename the property keys
  LIST_IMAGE: 'story-thumbnail',
  FULL_DATA: 'full-data',
  NORMAL_AUDIO_PREFIX: 'normal-audio-',
};

const {
  BANNER_IMAGE,
  LIST_IMAGE,
  FULL_DATA,
  // NORMAL_AUDIO_PREFIX,
} = assetKeys;

/**
 * StoryDownload
 *
 * tracks the download status of a particular story.
 */
export const StoryDownload = types
  .model('StoryDownload', {
    downloadedAt: types.maybeNull(types.Date), // the really represents the 'enqueued' at and used as the queue order
    episodeVersion: safeTypes.numberOrNull,
    assets: types.optional(
      types.maybeNull(types.array(types.late(() => AssetDownload))),
      []
    ),
  })
  .volatile(() => ({
    // todo: we probably need to add some extra state to track the currently downloade story.
    // right now when additional downloads are queued, it will potentially process then
    // in a unexpected order.
  }))
  .views(self => ({
    get $log() {
      const { createLogger = () => {} } = getEnv(self);
      const log = createLogger('dm:story-download');
      return log;
    },
    get $downloader() {
      const { downloader = {} } = getEnv(self);
      return downloader;
    },
    get $track() {
      const { track = () => {} } = getEnv(self);
      return track;
    },
  }))
  .views(self => {
    const fullAssets = () => {
      return self.assets.filter(asset => !asset.isCatalogLevel);
    };

    const audioAssets = () => {
      return self.assets.filter(asset => asset.isAudio);
    };

    const hasAudioAssets = () => {
      return audioAssets().length > 0; //todo: check all expected keys exist
    };

    const hasFullAssets = () => {
      return fullAssets().length > 0; //todo: check all expected keys exist
    };

    const someFullAssetStatus = downloadStatus => {
      return fullAssets().some(asset => asset.status === downloadStatus);
    };

    // const someAudioAssetStatus = downloadStatus => {
    //   return audioAssets().some(asset => asset.status === downloadStatus);
    // };

    const allAudioAssetStatus = downloadStatus => {
      return (
        hasAudioAssets() &&
        audioAssets().every(asset => asset.status === downloadStatus)
      );
    };

    const allFullAssetStatus = downloadStatus => {
      return (
        hasFullAssets() &&
        fullAssets().every(asset => asset.status === downloadStatus)
      );
    };

    return {
      get status() {
        // this used to only reflect audio assets probably related to the
        // desire to keep the full assets aroaund even when the download
        // is removed for the benefit of the vocab review, but that was causing
        // visual weirdness with the volume level download status
        if (someFullAssetStatus(DOWNLOADING)) {
          return DOWNLOADING;
        }
        if (someFullAssetStatus(PENDING_DOWNLOAD)) {
          return PENDING_DOWNLOAD;
        }
        if (someFullAssetStatus(DOWNLOAD_FAILED)) {
          return DOWNLOAD_FAILED;
        }
        if (allFullAssetStatus(DOWNLOADED)) {
          return DOWNLOADED;
        }
        return NOT_DOWNLOADED;
      },

      get hasPendingAssets() {
        return self.assets.some(asset => asset.isPending);
      },

      get hasPendingListImage() {
        const image = self.asset(LIST_IMAGE);
        return image && image.isPending;
      },

      get hasPendingBannerImage() {
        const image = self.asset(BANNER_IMAGE);
        return image && image.isPending;
      },

      // note, if active found, we assume was left in an interrupted state
      get firstActiveOrPendingDownload() {
        // prioritize list view thumbs
        const listImage = self.asset(LIST_IMAGE);
        if (listImage && listImage.isPending) {
          return listImage;
        }

        // todo prioritize new story banner

        return self.assets.find(asset => {
          return [DOWNLOADING, PENDING_DOWNLOAD].includes(asset.status);
        });
      },

      get downloadProgressRatio() {
        const fullAssetCount = fullAssets().length;
        if (fullAssetCount > 0) {
          return (
            fullAssets().filter(asset => asset.status === DOWNLOADED).length /
            fullAssetCount
          );
        }
        return 0;
      },

      get downloadProgressPercentage() {
        return Math.round(self.downloadProgressRatio * 100);
      },

      get fullDataAsset() {
        return self.asset(FULL_DATA);
      },

      get isFullDataAvailable() {
        const asset = self.fullDataAsset;
        return asset && asset.isDownloaded;
      },

      /**
       * true when latest catalog's audio assets for this story are different
       * from the audio assets currently downloaded
       */
      get isAudioUpdateAvailable() {
        const story = getParent(self);
        const catalogData = story.catalogData;
        return catalogData.chapters.some(chapter => {
          const asset = self.asset(chapter.normalAudioAssetKey);
          return asset && asset.sourceUrl !== chapter.normalAudioUrl;
        });
      },

      /**
       * returns the AssetDownload instance corresponding to the given key
       * @param key
       */
      asset(key) {
        return self.assets.find(asset => asset.key === key);
      },

      assetPath(key) {
        const asset = self.asset(key);
        if (asset) {
          return asset.fullPath;
        }
        self.$log.debug(`null assetPath for ${key}`);
        return null;
      },

      get listImage() {
        return self.asset(LIST_IMAGE);
      },

      get listImagePath() {
        return self.assetPath(LIST_IMAGE);
      },

      get bannerImage() {
        return self.asset(BANNER_IMAGE);
      },

      get bannerImagePath() {
        return self.assetPath(BANNER_IMAGE);
      },

      get dump() {
        return JSON.stringify(getSnapshot(self));
      },

      // private functioned exposed for debugging and actions use
      fullAssets,
      audioAssets,
      hasAudioAssets,
      someFullAssetStatus,
      allAudioAssetStatus,
    };
  })
  .actions(self => {
    const manager = getRoot(self).downloadManager;

    const story = getParent(self);
    const catalogData = story.catalogData;

    const ensureAsset = (key, sourceUrl, enableAudioUpdate = false) => {
      // self.$log.debug(`ensureAsset - slug: ${story.slug}, key: ${key}, url: ${sourceUrl}`);
      const status = PENDING_DOWNLOAD;
      const asset = self.asset(key);
      if (asset) {
        // self.$log.info(`ensureAsset - found(${asset.status}) - current url: ${asset.sourceUrl}, sourceUrl: ${sourceUrl}`);
        if (asset.sourceUrl !== sourceUrl) {
          self.$log.debug(
            `asset sourceUrl change detected, ${asset.sourceUrl} -> ${sourceUrl}`
          );
          asset.sourceUrl = sourceUrl;
          asset.status = status;
          asset.failureCount = 0;
        } else if (
          ![DOWNLOADED, DOWNLOADING, PENDING_DOWNLOAD].includes(asset.status)
        ) {
          self.$log.info(`updating asset from ${asset.status} to ${status}`);
          asset.status = status;
          asset.failureCount = 0;
        }
      } else {
        // self.$log.info(`ensureAsset - adding new: ${self.slug}, ${key}, ${sourceUrl}, ${status}`);
        self.assets.push(AssetDownload.create({ key, sourceUrl, status }));
      }
    };

    return {
      queueCatalogAssets() {
        // self.$log.info(`queueCatalogAssets - slug: ${story.slug}, unitNumber: ${catalogData.unitNumber}, isFirst: ${catalogData.unitNumber === 1}`);
        // for the jw/wondery app, we only need first unit's list image
        if (!v4CatalogMode()) {
          ensureAsset(BANNER_IMAGE, catalogData.bannerImageUrl);
        }
        if (!v4CatalogMode() || catalogData.unitNumber === 1) {
          // self.$log.info(`ensureAsset(LIST, ${catalogData.listImageUrl}`);
          ensureAsset(LIST_IMAGE, catalogData.listImageUrl);
        }
      },

      // queueListImage() {
      //   // volume display uses first unit's list image
      //   if (!v4CatalogMode() || catalogData.unitNumber === 1) {
      //     ensureAsset(LIST_IMAGE, catalogData.listImageUrl);
      //   }
      // },
      //
      // queueBannerImage() {
      //   if (!v4CatalogMode()) {
      //     ensureAsset(BANNER_IMAGE, catalogData.bannerImageUrl);
      //   }
      // },

      // maybe need a better name?
      addToDownloadQueue: flow(function* addToDownloadQueue() {
        self.$track('story__queue_download', { story_slug: story.slug });

        const availableSpaceInMb = yield self.$downloader.getAvailableSpaceInMb(
          manager.debugReducedAvailableSpaceInMb
        );

        if (story.catalogData.downloadSizeInMB > availableSpaceInMb) {
          // we use minibus to trigger the rendering of a react component
          // because we don't have access to navigation from here
          minibus.emit('DISK_IS_FULL');
          return;
        }

        self.queueCatalogAssets(); // no-op for jw/wondery
        self.downloadedAt = new Date();
        // ensureAsset(FULL_DATA, catalogData.dataUrl);
        // catalogData.chapters.forEach(chapter => {
        //   ensureAsset(chapter.normalAudioAssetKey, chapter.normalAudioUrl);
        // });
        self.ensureFullAssets();

        manager.runQueue(); // don't await
      }),

      ensureFullAssets() {
        self.$log.debug(`ensureFullAssets: ${self.slug}`);
        self.ensureFullData();
        catalogData.chapters.forEach(chapter => {
          ensureAsset(chapter.normalAudioAssetKey, chapter.normalAudioUrl);
        });
      },

      updateAudioAssets() {
        self.$log.debug(`updateAudioAssets: ${self.slug}`);
        self.ensureFullData();
        catalogData.chapters.forEach(chapter => {
          ensureAsset(
            chapter.normalAudioAssetKey,
            chapter.normalAudioUrl,
            true /*enableAudioUpdate*/
          );
        });
        manager.runQueue(); // don't await
      },

      // ensure just the full data (needed for vocab list)
      ensureFullData() {
        self.$log.debug(`ensureFullData: ${self.slug}`);
        ensureAsset(FULL_DATA, catalogData.dataUrl);
      },

      cancelDownload: flow(function* cancelDownload() {
        self.$track('story__cancel_download', { story_slug: story.slug });

        // todo: try/catch
        yield self.removeFromDisk();
        // note pending asset will be dealt with once completed
        // in future, we can explore preemptively cancelling the current asset
      }),

      removeFromDisk: flow(function* remove() {
        // todo: try/catch
        self.downloadedAt = null;
        // eslint-disable-next-line prefer-const
        for (let asset of self.fullAssets()) {
          yield asset.remove();
        }
        yield getRoot(self).persistAll();
      }),

      // removes both catalog and full assets - useful for testing
      removeAllAssets: flow(function* removeAllAssets() {
        // eslint-disable-next-line prefer-const
        for (let asset of self.assets) {
          yield asset.remove();
        }
      }),
    };
  })
  .views(self => {
    const is = status => self.status === status;

    return {
      get slug() {
        return getParent(self).slug;
      },

      get isDownloaded() {
        return is(DOWNLOADED);
      },

      get isNotDownloaded() {
        return is(NOT_DOWNLOADED) || is(DOWNLOAD_FAILED);
      },

      get isFailed() {
        return is(DOWNLOAD_FAILED);
      },

      get isDownloading() {
        return is(DOWNLOADING);
      },

      get isQueued() {
        return is(PENDING_DOWNLOAD) || is(DOWNLOADING);
      },
    };
  })
  // aliases for backward compatibility
  .views(self => ({
    get downloaded() {
      return self.isDownloaded;
    },

    get notDownloaded() {
      return self.isNotDownloaded;
    },

    get pending() {
      return self.isQueued;
    },
  }));
