import React from 'react';
export const IconArrowRightS = ({ color = 'currentColor', ...props }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.9423 11H2V9H13.9423L9.32172 4.7348L10.6783 3.2652L16.891 9H17V9.10063L17.1783 9.2652L17.9743 10L17.1783 10.7348L17 10.8994V11H16.891L10.6783 16.7348L9.32172 15.2652L13.9423 11Z"
      fill={color}
    />
  </svg>
);
