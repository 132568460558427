import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useRootStore } from '../../truth';
import { objectFromUrlQuery } from 'lib/object-from-url-query';
import createLogger from 'lib/log';

const clearQuery = () => {
  var currentUrl = window.location.href.split('?')[0];
  window.history.replaceState({}, '', currentUrl);
};

const useAfterQueryVar = defaultValue => {
  let redirectAfter = defaultValue;

  const sskey = 'redirect-after-auth';
  const { after: afterFromQuery = null } = objectFromUrlQuery();
  clearQuery();

  if (afterFromQuery) {
    try {
      window.sessionStorage.setItem(sskey, afterFromQuery);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('User disabled localStorage');
    }
    redirectAfter = afterFromQuery;
  } else {
    try {
      const afterFromStorage = window.sessionStorage.getItem(sskey);
      if (afterFromStorage) {
        redirectAfter = afterFromStorage;
      }
    } catch (error) {}
  }

  const clear = () => {
    clearQuery();
    try {
      window.sessionStorage.removeItem(sskey);
    } catch (error) {}
  };

  return [redirectAfter, clear];
};

export const useAfterAuthRedirection = () => {
  const history = useHistory();
  const store = useRootStore();
  const [redirectAfter, clearAfter] = useAfterQueryVar('/account');
  const log = createLogger('auth-redirection-hook');

  useEffect(() => {
    const { loggedInAndReady } = store.userManager;

    if (loggedInAndReady) {
      log('just logged in, will redirect to', redirectAfter);
      clearAfter();
      history.push(redirectAfter);
    } else {
      log('Signed out, after auth, should redirect to', redirectAfter);
    }
    // we only care about the logged in status changing
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.userManager.loggedInAndReady]);
};
