import React from 'react';
import cx from 'classnames';
import { observer } from 'mobx-react';
import { IconNoteS, IconArrowRightS } from 'components/ds/icons';
import { usePrompt } from 'components/ds/modals';
import { IconControlWrapper } from './icon-control-wrapper';

import __ from 'jw-core/lib/localization';

const truncateSentence = (str, wordCount = 8) => {
  const words = str.split(' ');
  if (words.length < wordCount) {
    return str;
  }

  return words.slice(0, wordCount).join(' ') + '…';
};

export const NoteControl = observer(({ note, onChangeNote }) => {
  const [Prompt, promptActions] = usePrompt({
    type: 'textarea',
    initialValue: note ?? '',
    label: __('Add a note', 'classroom.addANote'),
    placeHolder: __('Add a note', 'classroom.addANote'),
    okButtonAction: note => {
      onChangeNote(note).then(promptActions.off());
    },
    okButtonLabel: __('Done', 'common.done'),
    showClearButton: true,
  });

  return (
    <>
      <IconControlWrapper className={cx({ 'has-note': note })}>
        {note ? (
          <IconNoteS onClick={promptActions.on} />
        ) : (
          <IconArrowRightS onClick={promptActions.on} />
        )}
        <span
          onClick={promptActions.on}
          className={!!note ? 'has-note' : 'no-note'}
        >
          {!!note
            ? truncateSentence(note)
            : __('Add note for students', 'classroom.addNote')}
        </span>
      </IconControlWrapper>
      <Prompt />
    </>
  );
});
