import { types } from 'mobx-state-tree';
import * as safeTypes from '../lib/safe-mst-types';
import { getLocale } from '../lib/localization';

export const SpeakerLabel = types
  .model('SpeakerLabel', {
    label: safeTypes.stringDefaultBlank,
    bioEn: safeTypes.stringOrNull,
    bioJa: safeTypes.stringOrNull,
    labelJa: safeTypes.stringOrNull,
  })
  .views(self => ({
    // in future, these getters will dynamically reflect current language pair
    get bioL2() {
      return self.bioEn;
    },
    get bioL1() {
      return self.bioJa;
    },
    get labelL1() {
      return self.labelJa;
    },

    // return data compatible with the legacy 'Cast' structure as expected by current player code
    get v3Data() {
      switch (getLocale()) {
        case 'ja':
          return {
            label: self.label,
            fullName: self.labelJa || self.label,
            accent: null, // future
            description: self.bioJa || self.bioEn,
          };
        default:
          return {
            label: self.label,
            fullName: self.label,
            accent: null, // future
            description: self.bioEn,
          };
      }
    },
  }));
