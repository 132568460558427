import React from 'react';
export const IconRetry = ({ color = 'currentColor', ...props }) => (
  <svg
    height="24"
    width="24"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4 12C4 7.58172 7.58172 4 12 4C14.6362 4 16.9759 5.27494 18.4341 7.24473L16.1812 6.72079L15.8188 8.27921L20.8734 9.45469L22.273 4.20613L20.727 3.79387L20.102 6.13753C18.2859 3.63188 15.3341 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C14.0431 22 15.9459 21.3862 17.5304 20.3327L16.423 18.6673C15.1565 19.5093 13.6371 20 12 20C7.58172 20 4 16.4183 4 12Z"
      fill={color}
    />
  </svg>
);
