import React from 'react';
import { observer } from 'mobx-react';
import { useRootStore } from '../../../truth';
import __ from 'jw-core/lib/localization';
import { TableForm } from './table-form';
import { TableField } from './table-field';
import { SectionTitle } from '../../ds/common/section-title';
import { PendingEmailNote } from '../pending-email-note';
import { CloseAccountLink } from '../close-account-link';
import { SectionBreak } from 'components/ds/common/spacers';

import {
  getNameValidationRules,
  getEmailValidationRules,
  getPasswordValidationRules,
} from 'lib/validation-rules';

export const AccountForm = observer(() => {
  const store = useRootStore();
  const { userManager, validationErrorForKey } = store;
  const { accountData } = userManager;

  const editHandler = callback => (newValue, closeEditor) => {
    callback(newValue).then(closeEditor);
    // closeEditor();
  };

  return (
    <>
      <SectionTitle>{__('My details', 'accountForm.myDetails')}</SectionTitle>
      <TableForm>
        <TableField
          name={'name'}
          label={__('First name', 'auth.firstName')}
          value={accountData.name}
          onEdit={editHandler(userManager.updateName)}
          updateLabel={__('Update name', 'accountForm.updateName')}
          error={validationErrorForKey('name')}
          validation={getNameValidationRules()}
        />
        <TableField
          name={'email'}
          label={__('Email', 'common.email')}
          value={accountData.email}
          onEdit={editHandler(userManager.updateEmail)}
          updateLabel={__('Update email', 'accountForm.updateEmail')}
          footNote={__(
            'A verification link will be sent to the new email address',
            'accountForm.aVerificationLink'
          )}
          error={validationErrorForKey('email')}
          validation={getEmailValidationRules()}
          note={<PendingEmailNote />}
          displayOriginalValue={true}
        />
        <TableField
          name={'password'}
          isPassword
          label={__('Password', 'auth.password')}
          onEdit={editHandler(userManager.updatePassword)}
          updateLabel={__('Update password', 'accountForm.updatePassword')}
          error={validationErrorForKey('password')}
          validation={getPasswordValidationRules()}
          placeholder={'●●●●●●●●'}
        />
      </TableForm>
      <CloseAccountLink />
      <SectionBreak />
    </>
  );
});
