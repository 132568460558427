import { types } from 'mobx-state-tree';
import * as safeTypes from '../lib/safe-mst-types';

export const Cast = types
  .model('Cast', {
    accent: safeTypes.stringOrNull,
    fullName: safeTypes.stringDefaultBlank,
    shortName: safeTypes.stringOrNull,
    description: safeTypes.stringOrNull,
  })
  .views(self => ({
    get label() {
      return self.fullName || self.shortName;
    },
  }));
