/* eslint-disable no-alert */
import React from 'react';
import { Header } from '../../../ds/header';
import styled from 'styled-components';

const Accessory = styled.div`
  width: 20px;
  height: 20px;
  background-color: rebeccapurple;
`;

const Gap = styled.div`
  width: 100%;
  height: 30px;
  background: pink;
`;

const menuItems = [
  { label: 'Item one', action: () => alert('You clicked Item one') },
  { label: 'Item two', action: () => alert('You clicked Item two') },
  {
    label: 'Item three',
    disabled: true,
    action: () => alert('This should never happen'),
  },
];

export const ShowcaseHeaders = () => {
  return (
    <>
      <Header title={'This is a header'} />
      <Gap />
      <Header
        title={'This has a menu'}
        menuItems={menuItems}
        accessory={<Accessory />}
      />
      {/* <Header title={'This is a centered header'} titleAlign={'center'} /> */}
      <Gap />
      <Header
        title={'This is a header with a link'}
        showBackLink
        backLinkLabel={'Parent page'}
        onBackLinkClick={() => {
          alert('you clicked the back link');
        }}
      />
      <Gap />
      <Header
        title={'This is a header with an accessory'}
        showBackLink
        backLinkLabel={'Parent page'}
        accessory={<Accessory />}
      />
    </>
  );
};
