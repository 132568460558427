import React from 'react';
import { isFunction } from 'lodash';
import styled from 'styled-components';
import { getColor, includeTextStyles } from 'lib/theme/utils';
import __ from 'jw-core/lib/localization';

const StoryCellContainer = styled.div`
  --thumbnail-size: 88px;
  --thumbnail-border-radius: 4px;
  --spacing: 12px;

  ${includeTextStyles()};
  display: grid;
  grid-template-columns: 88px auto;
  grid-column-gap: 16px;
  padding: var(--spacing) 0;
  border-top: 1px solid ${getColor('gray-1')};
  position: relative;

  .story-cell-thumbnail {
    grid-column-start: 1;
    margin-top: 4px;
    margin-right: var(--spacing);
    margin-bottom: 4px;
    width: var(--thumbnail-size);
    height: var(--thumbnail-size);
    position: relative;

    img {
      border-radius: 4px;
      align-self: center;
      width: var(--thumbnail-size);
      height: var(--thumbnail-size);
    }

    &.is-clickable {
      cursor: pointer;
      &:hover + .story-cell-details > .story-cell-title {
        color: ${getColor('teal')};
      }
    }
  }

  .story-cell-title {
    display: block;
    font-weight: 600;
    padding-bottom: 2px;
    &.is-clickable {
      cursor: pointer;
      &:hover {
        color: ${getColor('teal')};
      }
    }
  }

  .story-cell-free-badge {
    display: inline-block;
    background: ${getColor('gray-4')};
    padding: 0 4px;
    border-radius: 4px;
    text-transform: uppercase;
    color: white;
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    margin-right: 6px;
    position: relative;
    top: -1px; /* See? I do care about pixels and stuff */
  }

  .story-cell-details {
    grid-column-start: 2;
    padding-bottom: 4px;
    .story-cell-topics,
    .story-cell-duration {
      ${includeTextStyles('small-text')};
      color: ${getColor('gray-4')};
      display: block;
    }
    .story-cell-title,
    .story-cell-duration {
      padding-bottom: 4px;
    }
  }
`;

export const StoryCell = ({
  story,
  thumbnailOverlay,
  storyDetailExtras,
  onClickTitle,
  onClickThumbnail,
}) => {
  const { catalogData } = story;
  // console.log({ story });
  return (
    <StoryCellContainer>
      <div
        className={`story-cell-thumbnail ${
          isFunction(onClickThumbnail) ? 'is-clickable' : ''
        }`}
        onClick={onClickThumbnail}
      >
        <img src={catalogData.listImageUrl} alt={story.catalogData.title} />
        {thumbnailOverlay}
      </div>
      <div className="story-cell-details">
        <span
          className={`story-cell-title ${
            isFunction(onClickTitle) ? 'is-clickable' : ''
          }`}
          onClick={onClickTitle}
        >
          {catalogData.title}
        </span>
        <span className="story-cell-duration">
          {catalogData.trial === true ? (
            <span className="story-cell-free-badge">
              {__('free', 'common.free')}
            </span>
          ) : null}
          {__('%{duration} mins', 'story.durationInMins', {
            duration: catalogData.durationMinutes,
          })}
        </span>
        <span className="story-cell-topics">
          {[...catalogData.countries, ...catalogData.topics].join(', ')}
        </span>
        {storyDetailExtras}
      </div>
    </StoryCellContainer>
  );
};
