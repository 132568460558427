import React from 'react';

export const EmailIcon = () => {
  return (
    <svg width={32} height={32} viewBox="0 0 32 32">
      <g fill="none" fillRule="evenodd" stroke="#BBB">
        <path fill="#F8F8F8" d="M.5 4.5h31v23H.5z" />
        <path d="M31.5 27.5L16 13.5.5 27.5" />
        <path
          fill="#F8F8F8"
          strokeLinecap="square"
          strokeLinejoin="round"
          d="M.5 4.5l15.5 14 15.5-14z"
        />
      </g>
    </svg>
  );
};
