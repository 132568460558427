import {
  applySnapshot,
  flow,
  getEnv,
  getRoot,
  getSnapshot,
  types,
} from 'mobx-state-tree';
import env from './lib/simple-env';
import { isFunction } from 'lodash';
import { ProtoState, TestingConfig } from './web-proto-support';
import { composeWithName } from './lib/type-utils/compose-with-name';

import { ApiAccess } from './api-access';
import { DownloadManager } from './download-manager';
import { GlobalConfig } from './global-config';
import { NetworkError } from './lib/errors';
import { StoryManager } from './story-manager';
import { UserManager } from './user-manager';
import minibus from './lib/minibus';
import { safelyHandleError } from './lib/error-handling';
import { sleep } from './lib/util';
import { alertLevels } from './lib/errors';

const { WARN } = alertLevels;

/**
 * Root model
 */
export const Root = types
  .model('Root', {
    globalConfig: types.optional(
      types.late(() => GlobalConfig),
      {}
    ),
    storyManager: types.optional(
      types.late(() => StoryManager),
      {}
    ),
    downloadManager: types.optional(
      types.late(() => DownloadManager),
      {}
    ),
    userManager: types.optional(
      types.late(() => UserManager),
      {}
    ),
    testingConfig: types.optional(
      types.late(() => TestingConfig),
      {}
    ),
    protoState: types.optional(
      types.late(() => ProtoState),
      {}
    ),
    debugHelper: types.optional(
      types.late(() => DebugHelper),
      {}
    ),
  })
  .volatile(() => ({
    status: 'off', // Enum: off/initializing/backgrounded/ready/dead
    loadingData: false,
    successMessage: null, // @armando, these success and warning messages are no longer used correct?
    warningMessage: null,
    validationError: null,
    startingUp: false, // lock to only attempt one startup sequence at a time
    foregrounding: false, // lock to only attempt one toForeground sequence at a time
    retryingStartup: false, // if we're reattempting the startup sequence after an initial unexpected error. prevents infinite loop
    simulateNetworkFailure: env.get('disableApiNetworkAccess'),
    // true when auto-authenticated via userToken upon cold start or
    // foregrounded into logged-in state
    // used to avoid forced restart modal after OTA directly after logging in
    preauthenticatedSession: false,
    transitioningAnonymousAccount: false, // let's try not to freak the UI out
    flash: null, // This is a mess, but it's necessary because of how the website is architected
  }))
  .views(self => ({
    get $log() {
      const { createLogger = () => {} } = getEnv(self);
      const log = createLogger('mst-root');
      return log;
    },
    get $platform() {
      const { platform = {} } = getEnv(self);
      return platform;
    },
    get $track() {
      const { track = () => {} } = getEnv(self);
      return track;
    },
    get $notifications() {
      const { notifications = {} } = getEnv(self);
      return notifications;
    },
  }))
  .actions(self => ({
    navigate(path) {
      self.$log.info(`navigate: ${path}`);
      minibus.emit('NAVIGATE', path);
    },

    startLoading() {
      self.loadingData = true;
    },

    stopLoading() {
      self.loadingData = false;
    },

    startTransitioningAnonymousAccount() {
      self.transitioningAnonymousAccount = true;
    },
    stopTransitioningAnonymousAccount() {
      self.transitioningAnonymousAccount = false;
    },

    setFlash(flash) {
      self.flash = flash;
    },

    resetFlash() {
      self.flash = null;
    },

    // setLocale(locale) {
    //   setLocale(locale);
    // },
  }))
  .actions(self => ({
    afterCreate() {
      if (isFunction(self.setup)) {
        self.setup();
      }
      self.$log.info('Root store created');
    },

    startupSequence: flow(function* startupSequence(options = {}) {
      const { autoRefreshAccountData = true } = options;
      self.status = 'initializing';
      try {
        self.$log.info('initiating startupSequence');
        self.setReportingContext();
        self.$log.debug('after setReportingContext');
        yield self.hydrate();
        // apply persisted locale
        self.$log.info(
          `localize - hydrated locale: ${self.globalConfig.locale}`
        );
        // setLocale(self.globalConfig.locale);
        self.$log.info(
          'ss.welcomeMessageDismissed:',
          self.userManager?.userData?.userSettings?.welcomeMessageDismissed
        );
        self.$log.info(
          'ss.dismissedMessages:',
          self.userManager?.userData?.userSettings?.dismissedMessages
        );

        if (self.userManager.authenticated) {
          // contextService.addBreadcrumb(`hydrated lastSyncVersion: ${self.userManager.lastSyncedVersion}`, 'log');
          if (autoRefreshAccountData) {
            yield self.userManager.refreshAccountData();
          }
          self.setPreauthenticatedSession(true);

          if (!self.$platform.onWebsite()) {
            // note, this is only be checked on cold start because we want to make sure
            // they have the latest app if it's a "what's new" message
            // todo: handle flow when cold start is unauthenticated and the first login should trigger a what's new message
            yield self.userManager.checkWelcomeUrl();
            self.$log.info(
              `welcome url: ${self.userManager.accountData.welcomeUrl}`
            );
          }
          self.$log.info(
            `authenticated user: ${self.userManager.accountData.userId}`
          );
          self.userManager.loggedInAndReady = true;
        } else {
          yield self.userManager.refreshPreauthAccountData();
          self.setPreauthenticatedSession(false);
          self.$log.info(`unauthenticated`);
        }
        self.$log.debug('before startupComplete()');
        self.startupComplete();
      } catch (error) {
        // we can't use safelyHandleError() here because these are special circumstances
        if (error instanceof NetworkError) {
          self.$notifications.alertWarning(
            'offline startup - using cached user data'
          );

          if (self.userManager.authenticated) {
            self.setPreauthenticatedSession(true);
            self.userManager.loggedInAndReady = true;
          }

          self.startupComplete();
        } else {
          self.$notifications.alertWarning(
            `unexpected startup error: ${String(error).substring(0, 100)}`,
            { exception: error }
          );

          if (self.retryingStartup) {
            self.$notifications.alertWarning('giving up');
            self.status = 'dead';
          } else {
            self.$notifications.alertWarning(
              `resetting local storage and trying again`
            );
            yield self.resetPersistedData();
            self.retryingStartup = true;
            yield self.startupSequence();
          }
        }
      }
    }),

    /**
     * factor out any final initialization upon complete of the normal
     * startup sequence.
     * (private)
     */
    startupComplete() {
      if (self.userManager.authenticated) {
        if (!self.$platform.onWebsite()) {
          // don't fetch catalog assets in website mode
          // self.downloadManager.requeuePending();
          // also refreshed any updated already downloaded episodes
          self.downloadManager.ensureAllCatalogAssets();
        }
      }
      minibus.emit('COLD_STARTED');
      self.$track('system__cold_started');
      self.setReady();
    },

    /**
     * (private)
     */
    setReady() {
      self.$log.info('application state now ready()');
      self.status = 'ready';
    },

    setValidationError(error) {
      self.$log.info(`validation error: ${error}`);
      self.validationError = error;
    },

    clearMessages() {
      self.successMessage = null;
      self.warningMessage = null;
      self.validationError = null;

      // if (self.testingConfig) {
      //   self.testingConfig.clearMessages();
      // }
    },

    coldStart: flow(function* coldStart() {
      // log('coldStart - default log()');
      // log.debug('coldStart - log.debug()');
      self.$log.info('coldStart - log.info()');
      // log.warn('coldStart - log.warn()');
      try {
        self.resetMemory();
        yield self.startupSequence();
      } catch (error) {
        // todo: think more about error handling here
        safelyHandleError(self, error, { unexpectedAlertLevel: WARN });
        // throw error;
      }
    }),

    // coldStop is currently only relevant to the mst-proto
    coldStop: flow(function* coldStop() {
      try {
        self.$log.info('coldStop');

        self.downloadManager.interruptQueue();
        // try to avoid race condition with shutting down queue
        yield sleep(500);

        yield self.persistAndSync();
        self.status = 'off';
        self.resetMemory();
      } catch (error) {
        // todo: think more about error handling here
        safelyHandleError(self, error, { unexpectedAlertLevel: WARN });
      }
    }),

    toBackground: flow(function* toBackground() {
      try {
        // skip syncing to server here since it's unreliable and we're pretty
        // aggressive about saving and syncing after meaningful actions.
        self.$log.info(
          `toBackground initiated, ${new Date()}, lastSyncVersion: ${
            self.userManager.lastSyncedVersion
          }`
        );
        yield self.persistAll();
        self.status = 'backgrounded';
      } catch (error) {
        // todo: think more about error handling here
        safelyHandleError(self, error, { unexpectedAlertLevel: WARN });
      }
    }),

    /**
     * was marked 'private', but currently called by UserManager.applyAuthentication
     */
    persistAndSync: flow(function* persistAndSync() {
      self.$log.info('entered persistAndSync');
      yield self.persistAll();
      if (self.userManager.authenticated) {
        yield self.userManager.syncToServer();
      }
    }),

    asyncPersistAndSync: flow(function* asyncPersistAll() {
      try {
        self.$log.info('asyncPersistAndSync');
        yield self.persistAndSync();
      } catch (error) {
        self.$notifications.alertWarning(
          `asyncPersistAndSync error: ${String(error).substring(0, 10000)}`,
          { error: error } // todo: get the actual error passed along to sentry
        );
      }
    }),

    toForeground: flow(function* toForeground() {
      if (self.foregrounding) {
        self.$log.info('toForeground process already in progress');
        return;
      }
      try {
        self.foregrounding = true;
        self.$log.info(`toForeground initiated, ${new Date()}`, {
          lastSyncVersion: self.userManager.lastSyncedVersion,
        });
        // self.status = 'initializing';
        if (self.userManager.authenticated) {
          const { context = {} } = getEnv(self);
          if (!context.isOnNoForegroundActionScreen) {
            yield self.userManager.refreshAccountData();
            self.$log.info('toForeground refreshed', {
              lastSyncVersion: self.userManager?.lastSyncedVersion,
            });
          }
          self.setPreauthenticatedSession(true);
        } else {
          self.setPreauthenticatedSession(false);
        }
        self.setReady();
        self.foregrounding = false;
      } catch (error) {
        self.$log.warn(`toForeground error: ${error}`);
        self.foregrounding = false;
        // todo: think more about error handling here
        safelyHandleError(self, error, { unexpectedAlertLevel: WARN });
      }
    }),

    validateSnapshotData(data) {
      const errors = Root.validate(data, [{ path: '', type: Root }]);
      self.$log.info(`validateSnapshotData error count: ${errors.length}`);
      return errors;
    },

    /**
     * (private)
     */
    resetMemory() {
      self.clearMessages();
      applySnapshot(self, {
        // beware, just passing in empty hashes here didn't reset the volatile state
        globalConfig: GlobalConfig.create(),
        storyManager: StoryManager.create(),
        downloadManager: DownloadManager.create(),
        userManager: UserManager.create(),
      });
    },

    /**
     * test hardness support flag to facilitate testing of network failures
     * @param bool
     */
    toggleSimulateNetworkFailure() {
      self.simulateNetworkFailure = !self.simulateNetworkFailure;
    },
    /**
     * tracks if we cold-started or foregrounded into an already authenticated
     * state, or if we've explicitly logged in this session.
     * used to suppress the force-restart modal just after logging in
     */
    setPreauthenticatedSession(bool) {
      self.preauthenticatedSession = bool;
    },
  }))
  .actions(self => {
    return {
      /**
       * for now just sets the Sentry context, but can probably use this same hook
       * also for loggly and segment
       */
      setReportingContext() {
        // todo: better way to turn the snapshot into a POJSO?
        // without the stringify/parse dance. my apiEnv patch below had no effect
        const globalConfigData = JSON.parse(
          JSON.stringify(getSnapshot(self.globalConfig))
        );
        // hack because apiEnv is now a view instead of serialized property
        globalConfigData.apiEnv = self.globalConfig.apiEnv;

        const data = {
          globalConfig: globalConfigData,
        };
        if (self.userManager.accountData) {
          data.accountData = getSnapshot(self.userManager.accountData);
        } else {
          data.accountData = {};
        }
        self.$log.debug(`setReportingContext(${JSON.stringify(data)})`);
        minibus.emit('SET_REPORTING_CONTEXT', data);
      },
    };
  })
  .actions(self => ({
    /**
     * @deprecated
     */
    persist: flow(function* persist() {
      // eslint-disable-next-line no-console
      console.log('DEPRECATED, implement `persist` in a local mixin');
      yield sleep(0);
    }),

    /**
     * mst only access. trust that usages wrapped with try/catch
     *
     * @deprecated since 1.0
     *
     * should go into a mixin.
     *
     */
    // @armando: what do you think about adopting a naming convention for Promise functions which always be yield/awaited?
    persistAll: flow(function* persistAll(options) {
      self.$log.info('persisting local data');
      yield self.persist(options);
    }),

    // @armando, does this change make sense?
    // realizing now what was significant here wasn't the absence of an yield within this function, but
    // skipping yield/awaiting when invoked. but we still want a way to capture errors from the async operation
    /**
     * @deprecated since 1.0
     */
    asyncPersistAll: flow(function* asyncPersistAll() {
      try {
        self.$log.info('asyncPersistingAll');
        yield self.persist();
      } catch (error) {
        self.$notifications.alertWarning(
          `asyncPersistAll error: ${String(error).substring(0, 10000)}`,
          { error: error }
        );
      }
    }),

    /**
     * nuke all local state except global config.
     * used when server selection is changed.
     *
     * @deprecated
     *
     */
    resetPersistedData: flow(function* resetPersistedData() {
      self.$log.info('resetPersistedData');
      applySnapshot(self, {
        downloadManager: DownloadManager.create(),
        userManager: UserManager.create(),
      });
      yield self.persist();
    }),
  }))

  .views(self => ({
    get ready() {
      return self.status === 'ready';
    },
    /**
     * which screen to navigate at startup based on hydrated data
     */
    get initialScreen() {
      const { token, /*accountData,*/ loggedInAndReady } = self.userManager;
      if (loggedInAndReady && token) {
        // if (accountData.emailConfirmationRequired) {
        //   return 'ConfirmEmailScreen';
        // }
        return 'DashboardScreen';
      }
      return 'WelcomeScreen';
    },

    validationErrorForKey(key) {
      if (self.validationError?.key === key) {
        return self.validationError;
      }

      return null;
    },

    // get anyError() {
    //   return self.validationError || self.unexpectedError;
    // }
  }));

const DebugHelper = composeWithName(
  'DebugHelper',
  ApiAccess,
  types.model('DebugHelper', {}).actions(self => ({
    // todo: include user token if logged in
    sendMSTSnapshot: flow(function* sendDebugData() {
      yield self.apiPost(
        'users/debug',
        null, // query param
        {
          snapshot: JSON.stringify(getSnapshot(getRoot(self))),
        }
      );
    }),

    sendDebugData: flow(function* sendDebugData(data) {
      yield self.apiPost(
        'users/debug',
        null, // query param
        {
          snapshot: data,
        }
      );
    }),
  }))
);

export default Root;
