import { getEnv, getRoot, types } from 'mobx-state-tree';
import { getParentOfName } from '../lib/type-utils/get-parent-of-name';
import * as safeTypes from '../lib/safe-mst-types';
import { tipsData } from '../lib/constants/vars';

/**
 * UserSettings
 *
 * non-study view user configuration and misc data
 */
export const UserSettings = types
  .model({
    onboarding: safeTypes.booleanDefaultTrue,
    showVocabListExportOption: safeTypes.booleanDefaultFalse,
    shownTips: safeTypes.arrayOfStringsDefaultEmpty,
    dismissedMessages: safeTypes.arrayOfStringsDefaultEmpty,
  })
  .volatile(_ => ({
    currentTip: null,
  }))
  .views(self => ({
    get $log() {
      const { createLogger = () => {} } = getEnv(self);
      const log = createLogger('um:user-settings');
      return log;
    },
    get $track() {
      const { track = () => {} } = getEnv(self);
      return track;
    },
  }))
  .actions(self => ({
    toggleVocabListExport() {
      self.showVocabListExportOption = !self.showVocabListExportOption;
      getRoot(self).asyncPersistAll();
    },

    toggleOnboarding() {
      self.onboarding = !self.onboarding;
      getRoot(self).asyncPersistAll();
    },

    enableOnboarding() {
      self.onboarding = true;
      getRoot(self).asyncPersistAll(); // todo: confirm with of these actions is actually used
    },

    disableOnboarding() {
      self.onboarding = false;
      getRoot(self).asyncPersistAll();
    },

    resetShownTips() {
      self.shownTips = [];
      getRoot(self).asyncPersistAll();
    },

    resetTip(name) {
      self.shownTips.remove(name);
    },

    showTip(tip) {
      if (!self.shownTips.includes(tip)) {
        self.shownTips.push(tip);
        getRoot(self).asyncPersistAll();
      }
    },

    setCurrentTip({ name, layout, actions }) {
      const tip = tipsData[name];
      self.showTip(name);
      self.currentTip = {
        tip,
        layout,
        actions,
      };
    },

    hideCurrentTip() {
      self.currentTip = null;
    },

    dismissMessage(key) {
      self.dismissedMessages.push(key);
      getRoot(self).asyncPersistAll();
    },

    dismissTrialUserBanner() {
      self.dismissSubscriberMessage();
    },
    // this was confusingly named
    dismissSubscriberMessage() {
      self.$log.info('dismissSubscriberMessage');
      self.dismissMessage('subscriber-message');
    },

    dismissFullAccessBanner() {
      self.dismissMessage('full-access-banner');
    },

    dismissWelcomeMessage() {
      self.$track('dashboard__dismiss_welcome');
      self.dismissMessage('welcome-message');
    },

    resetMessage(key) {
      self.dismissedMessages.remove(key);
    },
  }))
  .views(self => ({
    tipIsShown(tip) {
      return self.shownTips.includes(tip);
    },

    tipIsEligible(tip) {
      return self.onboarding && !self.tipIsShown(tip);
    },

    messageIsDismissed(key) {
      return self.dismissedMessages.includes(key);
    },

    get showTrialUserBanner() {
      return self.showSubscriberMessage;
    },
    // todo: this was confusingly named. it really a welcome message
    // for users who are not yet subscribed!
    get showSubscriberMessage() {
      const manager = getParentOfName(self, 'UserManager');
      self.$log.debug(
        `showSubscriberMessage - full access: ${manager.accountData.fullAccess}`
      );
      return (
        !manager?.accountData.fullAccess &&
        !self.messageIsDismissed('subscriber-message')
      );
    },

    get showFullAccessBanner() {
      const manager = getParentOfName(self, 'UserManager');
      return (
        manager?.accountData.fullAccess &&
        !self.messageIsDismissed('full-access-banner')
      );
    },

    get welcomeMessageDismissed() {
      return self.messageIsDismissed('welcome-message');
    },
  }));
