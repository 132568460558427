import React, { useEffect } from 'react';
import { useSwitch } from 'lib/hooks/use-switch';
import { animated, useTransition } from 'react-spring';
import { SuccessIcon } from 'components/ui/icons/success-icon';
import { ErrorIcon } from 'components/ui/icons/error-icon';
import { InfoIcon } from 'components/ui/icons/info-icon';
import { getColor, getDimension, includeTextStyles } from 'lib/theme/utils';
import styled, { keyframes } from 'styled-components';

const notificationTypes = {
  ERROR: 'error',
  SUCCESS: 'success',
  WARNING: 'warning',
};

const IconMap = {
  [notificationTypes.ERROR]: ErrorIcon,
  [notificationTypes.SUCCESS]: SuccessIcon,
  [notificationTypes.WARNING]: InfoIcon,
};

const enter = keyframes`
  0% {
    transform: translateY(100%) scale(0.95);
    opacity:0;
  }

  75% {
    transform: translateY(0) scale(1.05);
    opacity:0.5;
  }


  100% {
    transform: translateY(0) scale(1.0);
    opacity:1;
  }
`;

const exit = keyframes`
  0% {
    transform: translateY(0) scale(1.0);
    opacity:1;
  }



  100% {
    transform: translateY(100%) scale(0.9);
    opacity:0;
  }
`;

const NotificationStyles = styled.div`
  ${includeTextStyles('body')};
  position: fixed;
  z-index: 20000;
  bottom: 32px;
  left: 0;
  right: 0;
  pointer-events: none;

  /* Helps align notification to container */
  & .container {
    max-width: ${getDimension('max-screen-width')}px;
    margin: 0 auto;
    padding: 0 32px;
    display: flex;
  }

  & .wrapper {
    /* animation: ${enter} 0.3s cubic-bezier(0.215, 0.61, 0.355, 1); */
    pointer-events: auto;
    cursor: default;
    background: ${getColor('gray-6')};
    padding: 8px 16px 8px 8px;
    border-radius: 8px;
    color: ${getColor('white')};
    display: flex;
    align-items: center;

    &.exit {
      /* animation: ${exit} 0.3s cubic-bezier(0.215, 0.61, 0.355, 1); */
    }

    .icon {
      margin-right: 12px;
    }
  }
`;

export const Notification = ({ type, message, onDismiss }) => {
  const Icon = IconMap[type];
  const [show, { off }] = useSwitch(true);

  const transitions = useTransition(show, null, {
    from: { transform: 'translate3d(0,100%,0)', opacity: 0 },
    enter: { transform: 'translate3d(0,0,0)', opacity: 1 },
    leave: { transform: 'translate3d(0,100%,0)', opacity: 0 },
    onDestroyed: onDismiss,
  });

  useEffect(() => {
    const timer = window.setTimeout(off, 3500);
    return () => window.clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <NotificationStyles onClick={off}>
      <div className="container">
        {transitions.map(
          ({ item, key, props }) =>
            item && (
              <animated.div className={`wrapper `} key={key} style={props}>
                <span className="icon">
                  <Icon />
                </span>
                <span
                  className="notification-message"
                  data-test-id="notification-message"
                >
                  {message}
                </span>
              </animated.div>
            )
        )}
      </div>
    </NotificationStyles>
  );
};
