import { types } from 'mobx-state-tree';
import { getParentOfName } from '../lib/type-utils/get-parent-of-name';
import * as safeTypes from '../lib/safe-mst-types';

import { snakeCase } from 'lodash';
import { minutesToPrettyDuration } from '../lib/pretty-duration';
import { __, getLocale } from '../lib/localization';
import { assetKeys } from '../download-manager';
import { Cast } from './cast';
import { ChapterCatalogData } from './chapter-catalog-data';
import { SpeakerLabel } from './speaker-label';
import { Credit } from './credit';

const camelCaseToPhrase = s =>
  snakeCase(s)
    .replace(/^./, a => a.toUpperCase())
    .replace(/_/g, ' ');

/**
 * StoryCatalogData
 *
 * the static data for an episode, excluding the study level chapter blobs
 */
export const StoryCatalogData = types
  .model('StoryCatalogData', {
    slug: safeTypes.identifierDefaultBlank,
    title: safeTypes.stringDefaultBlank,
    dataUrl: safeTypes.stringDefaultBlank,
    tagline: safeTypes.stringOrNull,
    description: safeTypes.stringDefaultBlank,
    listImageUrl: safeTypes.stringDefaultBlank, // url,
    bannerImageUrl: safeTypes.stringDefaultBlank, //url,
    downloadSize: safeTypes.numberDefaultZero,
    durationMinutes: safeTypes.numberDefaultZero, // - do we want minutes or millis in our schema?,
    durationMillis: safeTypes.numberOrNull, // must be temporarily nullable when hydrating local store
    chapters: types.optional(
      types.maybeNull(types.array(types.late(() => ChapterCatalogData))),
      []
    ),
    topics: safeTypes.arrayOfStringsDefaultEmpty,
    countries: safeTypes.arrayOfStringsDefaultEmpty,
    cast: types.optional(
      types.maybeNull(types.array(types.late(() => Cast))),
      []
    ),
    baseTitle: safeTypes.stringOrNull,
    partSuffix: safeTypes.stringOrNull,

    // credits: types.array(types.late(() => Credit)),
    // adapt to old or new schema until all code and servers using new schema
    // credits: types.union(types.array(Credit), types.map(types.string)),
    //
    // looks like we still only ever get the map variant here from the server, and doesn't look like any other code would adapt yet
    credits: types.optional(types.maybeNull(types.map(types.string)), {}),

    // publishedOn: types.Date, //iso8601 date,
    // renamed to originalBroadcastDate
    publishedOn: safeTypes.stringOrNull, // TODO: figure out how JSON dates should be encoded
    releaseDate: safeTypes.stringOrNull, // note, tried to provide a default value, but that didn't work when server provides an explicit null
    weblink: safeTypes.stringOrNull, //url,
    lessonPlanUrl: safeTypes.stringOrNull, //url,
    trial: safeTypes.booleanDefaultFalse, // - is a free trial episode,
    recommended: safeTypes.booleanDefaultFalse, // - to be included in 'recommended' dashboard list,
    version: safeTypes.numberDefaultZero,

    ingestedAt: safeTypes.stringOrNull, // iso8601 datetime

    // v4 support
    // not sure if both L1 & L2 versions will be useful, but including for now and we can trim later
    titleL1: safeTypes.stringOrNull,
    titleL2: safeTypes.stringOrNull,
    descriptionL1: safeTypes.stringOrNull,
    descriptionL2: safeTypes.stringOrNull,
    creditsV4: types.optional(types.maybeNull(types.array(Credit)), []),
    volumeSlug: safeTypes.stringDefaultBlank,
    unitNumber: safeTypes.numberDefaultZero,

    speakerLabels: types.optional(
      types.maybeNull(types.array(types.late(() => SpeakerLabel))),
      []
    ),
    // for now we can ignore these and assume that all stories in the catalog will be appropriate
    // for the users current language pair, but in future we'll need a system to support
    // multiple language pairs.
    l1Code: safeTypes.stringOrNull, // ja
    l2Code: safeTypes.stringOrNull, // en
  })
  .views(self => ({
    get numberOfChapters() {
      return self.chapters.length;
    },

    get downloadSizeInMB() {
      return Math.round(self.downloadSize / (1024 * 1024));
    },

    get creditsList() {
      // Plain Old Javascript Object
      const pojo = self.credits.toPOJO();
      // returns an array of objects like:
      //[{role:'Refactored by',name:'Armando Sosa'}]
      // I think the data should come like this, it's way easier
      // and safer to iterate on arrays, than objects
      return Object.keys(pojo).map(k => ({
        roleKey: k, // localization key suffix
        role: camelCaseToPhrase(k),
        name: pojo[k],
      }));
    },

    resolveSpeakerData(label) {
      if (!label) {
        return null;
      }
      // v4/jw catalog mode
      if (self.speakerLabels) {
        const v4Data = self.speakerLabels.find(data => data.label === label);
        if (v4Data) {
          return v4Data.v3Data;
        }
      }
      // v3/lupa catalog mode
      if (self.cast) {
        const v3Data = self.cast.find(
          speaker => speaker.shortName === label || speaker.fullName === label
        );
        if (v3Data) {
          return v3Data;
        }
      }
      // todo: yell
    },

    // renamed
    get originalBroadcastDate() {
      return self.publishedOn;
    },

    get storyDownload() {
      const story = getParentOfName(self, 'Story');
      return story?.download;
    },

    get bannerImagePath() {
      return self.storyDownload.assetPath(assetKeys.BANNER_IMAGE);
    },

    get listImagePath() {
      return self.storyDownload.assetPath(assetKeys.LIST_IMAGE);
    },

    get fullDataPath() {
      return self.storyDownload.assetPath(assetKeys.FULL_DATA);
    },

    // used to exclude subsequent parts from recommended list
    get headPart() {
      return !self.partSuffix || self.partSuffix === '1';
    },

    get durationDescription() {
      const chapters = __(
        { one: '%{count} chapter', other: '%{count} chapters' },
        'story.nChapters',
        { count: self.chapters.length }
      );

      const duration = minutesToPrettyDuration(self.durationMinutes);

      return __('%{chapters}, %{duration}', 'story.durationDescription', {
        chapters,
        duration,
      });
    },

    get localizedTagline() {
      switch (getLocale()) {
        case self.l2Code:
          return self.titleL2 || self.title;
        default:
          return self.titleL1 || self.title;
      }
    },

    get localizedDescription() {
      switch (getLocale()) {
        case self.l2Code:
          return self.descriptionL2 || self.description;
        default:
          return self.descriptionL1 || self.description;
      }
    },
  }));
