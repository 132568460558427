import { types } from 'mobx-state-tree';
import * as safeTypes from '../lib/safe-mst-types';
import { localizedProperty } from '../lib/localization-helper';
import { __ } from '../lib/localization';

/**
 * Represents the content partner that a volume/show belongs to.
 * i.e. Wondery
 */
export const Channel = types
  .model('Channel', {
    slug: safeTypes.identifierDefaultBlank,

    name: safeTypes.stringDefaultBlank,

    descriptionEn: safeTypes.stringDefaultBlank,
    descriptionEs: safeTypes.stringDefaultBlank,
    descriptionJa: safeTypes.stringDefaultBlank,

    weblinkUrl: safeTypes.stringDefaultBlank,
    weblinkDisplay: safeTypes.stringDefaultBlank,

    bannerImageCatalogPath: safeTypes.stringDefaultBlank,
  })
  .views(self => ({
    get description() {
      return localizedProperty(self, 'description');
    },

    get aboutDisplay() {
      return __('About %{name}', 'volume.info.about', self);
    },
  }));

const wonderyData = {
  slug: 'wond',
  name: 'Wondery',
  descriptionEn:
    'Wondery is the largest independent podcast publisher in the world. Launched in 2016, and known for its immersive podcasts, Wondery became the fastest network to join the Top 10 ranker by Podtrac. Twenty of Wondery’s shows have reached # 1 on Apple Podcasts, and Wondery is the only publisher to have simultaneously claimed the # 1 and # 2 "Top New Podcasts" slots on an annual Podtrac ranker.',
  descriptionJa:
    'Wondery社は世界最大の独立系podcast（ポッドキャスト）放送局。2016年に創業されたWondery社は、リスナーが没頭できるpodcastの配信元として定評を作り、Podtrac社が発表するpodcast番付トップ10にランクインするのも世界最速だった。Wondery社が配信する番組でApple Podcast1位に上りつめた番組が20本もある。また、Wondery社はPodtrac社のTop New Podcastsで1位と2位の座を同時獲得した唯一のpodcast配信元である。',
  weblinkUrl: 'https://wondery.com',
  weblinkDisplay: 'wondery.com',
  bannerImageCatalogPath: 'jw-assets/wond_hero.jpg',
};

export const wondery = Channel.create(wonderyData);
