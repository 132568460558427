import React from 'react';
import { startsWith } from 'lodash';
import { observer } from 'mobx-react';
import ReactDOM from 'react-dom';
import { NavLink as Link, useLocation } from 'react-router-dom';
import { useRootStore } from '../../../truth';
import { useSwitch } from '../../../lib/hooks/use-switch';
import { prevent } from '../../../lib/prevent';
import { getConfig } from 'config';
import styled from 'styled-components';

import { LupaLogo } from '../../ui/icons/logo';
import { ExternalNavLinkIcon } from '../../ui/icons/external-nav-link';
import { ClassroomIcon } from '../../ui/icons/classroom-icon';
import { AccountIcon } from '../../ui/icons/account-icon';
import { GiftIcon } from '../../ui/icons/gift-icon';

const MainNavigationPortal = ({ children }) => {
  return ReactDOM.createPortal(children, document.getElementById('header'));
};

const MobileNav = ({ onClick }) => {
  return (
    <span className="site-header__nav-btn" onClick={prevent(onClick)}>
      <span className="site-header__nav-btn__text">Menu</span>
      <div className="site-header__nav-btn__lines js-site-header__nav-btn">
        <div className="site-header__nav-btn__l site-header__nav-btn__l--1"></div>
        <div className="site-header__nav-btn__l site-header__nav-btn__l--2"></div>
        <div className="site-header__nav-btn__l site-header__nav-btn__l--3"></div>
        <div className="site-header__nav-btn__l site-header__nav-btn__l--4"></div>
      </div>
    </span>
  );
};

const LupaLink = ({ standalone = false, loggedIn = false, onClick }) => {
  const Container = standalone ? 'div' : 'li';
  const marketingSiteUrl = getConfig('website.marketingSiteUrl');

  return (
    <Container
      className={`site-header__nav__item ${
        standalone ? 'is-standalone' : 'is-nested'
      } ${loggedIn ? 'is-logged-in' : 'is-logged-out'}`}
    >
      <a
        href={marketingSiteUrl}
        onClick={onClick}
        className="site-header__nav__link site-header__nav__link--to-marketing"
        target="_blank"
        rel="noopener noreferrer"
      >
        About Lupa
        <ExternalNavLinkIcon />
      </a>
    </Container>
  );
};

const NavLink = ({ href, name, children, onClick, testId, to = null }) => {
  const linkClassName = `site-header__nav__link site-header__nav__link--${name}`;
  const activeLinkClassName = `${linkClassName} site-header__nav__link--selected`;

  if (to) {
    return (
      <li className="site-header__nav__item">
        <Link
          className={linkClassName}
          activeClassName={activeLinkClassName}
          onClick={onClick}
          to={to}
          data-test-id={testId}
        >
          {children}
        </Link>
      </li>
    );
  }

  return (
    <li className="site-header__nav__item">
      <a className={linkClassName} href={href} onClick={onClick}>
        {children}
      </a>
    </li>
  );
};

const MobileNavContainer = styled.ul`
  @media screen and (max-width: 879px) {
    position: absolute;
    bottom: 8rem;
    left: 0;
    right: 0;
    transition: transform 0.4s;
    transform: ${p => (p.open ? 'translateY(100%)' : 'translateY(0)')};
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, ${p => (p.open ? '0.15' : '0')});
  }
`;

const Spacer = styled.li`
  display: block;
  height: 8rem;
`;

const NavContainer = ({ children, open, ...props }) => {
  return (
    <MobileNavContainer open={open} {...props}>
      <Spacer></Spacer>
      {children}
    </MobileNavContainer>
  );
};

export const GlobalNav = observer(() => {
  const location = useLocation();
  const store = useRootStore();
  const loggedInAndReady = store?.userManager?.loggedInAndReady;
  // const accountData = store?.userManager?.accountData;
  const [navIsOpen, navIsOpenSwitch] = useSwitch(false);
  const { hasManagedClassrooms } = store?.userManager?.accountData ?? {};

  // hide the nav in the back-to-app screen
  if (startsWith(location.pathname, '/appsupport')) {
    return null;
  }

  return (
    <MainNavigationPortal>
      <header
        className={`site-header js-site-header site-header--off-white ${
          navIsOpen ? 'site-header--nav-is-open' : ''
        }`}
      >
        <div className="site-header__content">
          <div className="site-header__logo-nav-btn">
            <h1 className="site-header__logo site-header__logo--lupa">
              <a className="site-header__logo__link" href="/">
                <span className="site-header__logo__my">My</span>
                <span className="site-header__logo__lupa">Lupa</span>
                <LupaLogo />
              </a>
            </h1>
            {loggedInAndReady ? (
              <MobileNav onClick={navIsOpenSwitch.toggle} />
            ) : null}
          </div>

          {loggedInAndReady ? (
            // TODO: hide first link if no classes
            <NavContainer open={navIsOpen} className="site-header__nav">
              {hasManagedClassrooms ? (
                <NavLink
                  to="/classrooms"
                  onClick={navIsOpenSwitch.toggle}
                  testId="classrooms-tab"
                >
                  <ClassroomIcon />
                  Classrooms
                </NavLink>
              ) : null}
              <NavLink
                to="/account"
                onClick={navIsOpenSwitch.toggle}
                testId="account-tab"
              >
                <AccountIcon />
                Account
              </NavLink>
              <NavLink
                to="/gift-coupons"
                onClick={navIsOpenSwitch.toggle}
                testId="purchase-coupon"
              >
                <GiftIcon />
                Purchase gift coupons
              </NavLink>
              <LupaLink onClick={navIsOpenSwitch.toggle} />
            </NavContainer>
          ) : null}

          <LupaLink standalone loggedIn={loggedInAndReady} />
        </div>
      </header>
    </MainNavigationPortal>
  );
});
