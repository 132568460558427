import React from 'react';
import { Button } from '../../../ds/buttons';
import styled from 'styled-components';
import { includeTextStyles, getColor } from '../../../../lib/theme/utils';
import { IconTrashS as Icon } from '../../../ds/icons';

const ButtonMatrix = styled.div`
  overflow: auto;
  table {
  }
  th {
    padding: 20px;
    ${includeTextStyles('tiny-text')};
    &.disabled {
      color: ${getColor('gray-1')};
    }
  }
  thead th {
    text-align: center;
    &.th-white-outline {
      background-color: ${getColor('gray-5')};
    }
    &.th-white-outline {
      color: white;
      background-color: ${getColor('gray-5')};
    }
  }
  td {
    &.td-white-outline {
      background-color: ${getColor('gray-5')};
    }

    padding: 20px;
  }
`;

const presentations = [
  null,
  'primary',
  'green',
  'primary-outline',
  'red-outline',
  'outline',
  'white-outline',
];

const rows = [
  { name: 'default' },
  { name: 'square', type: 'square' },
  { name: 'small', size: 'small' },
  { name: 'small square', type: 'square', size: 'small', icon: Icon },
  { name: 'icon', icon: Icon },
  { name: 'small-icon', size: 'small', icon: Icon },
];

export const ShowcaseButtons = () => {
  return (
    <ButtonMatrix>
      <table>
        <thead>
          <tr>
            <th className="empty"></th>
            {presentations.map(name => {
              name = name ?? 'default';
              return (
                <th className={`th-${name}`} key={`th-${name}`}>
                  {name}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {rows.map(row => {
            const { name, ...props } = row;
            return (
              <>
                <tr>
                  <th>{name}</th>
                  {presentations.map(presentation => {
                    presentation = presentation ?? 'default';
                    return (
                      <td
                        className={`td-${presentation}`}
                        key={`td-${presentation}`}
                      >
                        <Button
                          {...props}
                          presentation={presentation}
                          label={'Button'}
                        />
                      </td>
                    );
                  })}
                </tr>
                <tr>
                  <th className="disabled">disabled</th>
                  {presentations.map(presentation => {
                    presentation = presentation ?? 'default';
                    return (
                      <td
                        className={`td-${presentation}`}
                        key={`td-${presentation}`}
                      >
                        <Button
                          {...props}
                          presentation={presentation}
                          disabled
                          label={'Button'}
                        />
                      </td>
                    );
                  })}
                </tr>
              </>
            );
          })}
        </tbody>
      </table>
    </ButtonMatrix>
  );
};
