import { clamp } from 'lodash';
import styled from 'styled-components';

const scale = [0, 8, 16, 24, 32, 40, 80, 96];
export const Gap = styled.div`
  height: ${p => scale[clamp(p.size, 0, scale.length - 1)]}px;
`;

Gap.defaultProps = {
  size: 1,
};

export const SectionBreak = styled.div`
  height: 40px;

  @media screen and (min-width: 550px) and (max-width: 799px) {
    height: 56px;
  }

  @media screen and (min-width: 800px) {
    height: 80px;
  }
`;

export const MediumGap = styled.div`
  height: 40px;

  @media screen and (min-width: 550px) and (max-width: 799px) {
    height: 56px;
  }

  @media screen and (min-width: 800px) {
    height: 56px;
  }
`;

export const LargeGap = styled.div`
  height: 40px;

  @media screen and (min-width: 550px) and (max-width: 799px) {
    height: 56px;
  }

  @media screen and (min-width: 800px) {
    height: 80px;
  }
`;

export const SectionEnds = styled.div`
  height: 8px;

  @media screen and (min-width: 550px) {
    height: 16px;
  }
`;
