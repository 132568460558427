import React from 'react';

export const GiftIcon = props => {
  return (
    <svg width={24} height={24} fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 0L0 6v1.5L5 10V8.5L10 6 5 3.5l2-1L12 5l5-2.5 2 1L14 6l5 2.5V10l5-2.5V6L12 0zm5 11V9.5L12 7 7 9.5V11l5 2.5 5-2.5zm-10 .5l5 2.5 5-2.5v9.986L12 24l-5-2.514V11.5zm-2-1L0 8v9.967l5 2.514V10.5zm14 9.98V10.5L24 8v9.967l-5 2.514z"
        fill="#333"
      />
    </svg>
  );
};
