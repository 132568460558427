import React from 'react';
import { observer } from 'mobx-react';
import { useRootStore } from 'mst';
import __ from 'jw-core/lib/localization';
import { SectionTitle } from 'components/ds/common/section-title';
import { SectionBreak } from 'components/ds/common/spacers';
import { SectionDescription } from 'components/ui/section-description';
import { PlanPricingCard } from './plan-pricing-card';
import { FreeTrialPricingCard } from './free-trial-pricing-card';
import { getColor, includeTextStyles } from 'lib/theme/utils';
import cx from 'classnames';

import styled from 'styled-components';

export const Wrapper = styled.div`
  .cards {
    margin-top: -16px;
    padding-top: 40px;
    /* background: blue; */

    @media screen and (max-width: 575px) {
      flex-wrap: wrap;
      /* background-color: limegreen; */
      display: flex;
      flex-direction: column;
      align-items: center;
      .card {
        margin-bottom: 16px;
      }
      .card:last-child {
        margin-bottom: 0;
      }
    }

    @media screen and (min-width: 576px) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      /* background-color: blue; */
    }
    @media screen and (min-width: 856px) {
      &.three-cards {
        width: 824px;
        margin-left: -140px;
      }
    }
    @media screen and (min-width: 576px) and (max-width: 855px) {
      /* background-color: cyan; */
      &.three-cards {
        flex-wrap: wrap;
        /* background-color: purple; */
      }

      .card.type-free-trial {
        margin-left: calc(50% - 132px);
        margin-bottom: 16px;
      }
    }
  }

  .coupon-button-container {
    margin-top: 32px;
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .coupon-button {
    ${includeTextStyles('body')};
    color: ${getColor('dark-teal')};
    border: none;
    background: none;
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const PricingCards = observer(({ showFreeTrialCard = false }) => {
  const store = useRootStore();
  const accountData = store?.userManager?.accountData;
  const { plans } = accountData;

  // const [] = useCouponPrompt();

  return (
    <Wrapper>
      {/* <SectionBreak /> */}
      <div className={cx('cards', { 'three-cards': showFreeTrialCard })}>
        {showFreeTrialCard ? <FreeTrialPricingCard /> : null}
        {plans.map(plan => (
          <PlanPricingCard key={plan.slug} plan={plan} />
        ))}
      </div>
      <SectionBreak />
    </Wrapper>
  );
});

const TrialPricing = () => {
  return (
    <>
      <SectionTitle>
        {__('Unlock full access', 'pricing.unlockFullAccess')}
      </SectionTitle>
      <SectionDescription>
        {__(
          'You currently have access only to free trial stories. Subscribe or purchase now to unlock all Lupa content.        ',
          'pricing.currentlyHaveAccessOnlyToTrial'
        )}
      </SectionDescription>
      <PricingCards />
    </>
  );
};

const PromoteEnrollmentPricing = ({ daysLeft }) => {
  return (
    <>
      <SectionDescription>
        {__(
          'Your full access membership expires in %{daysLeft} days. Select one of these options to extend.',
          'pricing.fullAccessExpiresIn',
          { daysLeft }
        )}
      </SectionDescription>
      <PricingCards />
    </>
  );
};

export const Pricing = observer(() => {
  const store = useRootStore();

  const accountData = store?.userManager?.accountData;

  if (accountData?.membershipState === 'trial') {
    return <TrialPricing />;
  }

  if (accountData?.promoteEnrollment) {
    return <PromoteEnrollmentPricing daysLeft={accountData.daysLeft} />;
  }

  return null;
});
