const logLevels = {
  none: 0,
  trace: 1,
  debug: 2,
  info: 3,
  warn: 4,
  error: 5,
  fatal: 6,
};

export const logLevelKeys = [
  'none',
  'trace',
  'debug',
  'info',
  'warn',
  'error',
  'fatal',
];

export default logLevels;
