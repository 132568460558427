import React from 'react';
import { Prompt } from './prompt';
import { useSwitch } from 'lib/hooks/use-switch';

export const usePrompt = ({ afterDismiss = () => {}, ...hookProps }) => {
  const [showingPrompt, { on, off }] = useSwitch(false);

  const Component = props => {
    if (!showingPrompt) {
      return null;
    }
    return (
      <Prompt
        onDismiss={() => {
          off();
          afterDismiss();
        }}
        {...hookProps}
        {...props}
      />
    );
  };

  return [Component, { on, off }];
};
