import React from 'react';

export const AuthSmallPrint = () => {
  return (
    <p className="auth__small-print">
      By creating an account, you accept our
      <br />
      <a href="https://www.jiveworld.com/terms-of-service">Terms of Service</a>
      {', '}
      <a href="https://www.jiveworld.com/privacy">Privacy Policy</a> and{' '}
      <a href="https://www.jiveworld.com/eula">User Licence Agreement</a>
    </p>
  );
};
