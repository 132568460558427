/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { observer } from 'mobx-react';
import { IconCheckmarkCircle, IconDownloadS } from 'components/ds/icons';
import { StoriesGridSeparator } from 'components/stories/stories-grid';
import { StoryCell } from 'components/stories/story-cell';

import __ from 'jw-core/lib/localization';

const ThumbnailOverlay = ({ selected }) => {
  const className = `story-cell-thumbnail-overlay ${
    selected ? 'selected' : ''
  }`;

  return (
    <div className={className}>
      <IconCheckmarkCircle />
    </div>
  );
};

export const StoriesAssignmentSection = observer(
  ({ title, description, stories, selector, lessonPlans = null }) => {
    return (
      <>
        <StoriesGridSeparator>
          <header className="story-selector-section-header">
            <p className="story-selector-section-title">
              {title}
              {lessonPlans ? (
                <a href={lessonPlans} target="_blank" rel="noopener noreferrer">
                  <IconDownloadS />
                  {__('Example lesson plans', 'classroom.exampleLessonPlans')}
                </a>
              ) : null}
            </p>
            <p className="story-selector-section-description">{description}</p>
          </header>
        </StoriesGridSeparator>
        {stories.map(story => (
          <div
            key={story.slug}
            className="story-selector-item"
            onClick={selector.makeTogglerForStory(story)}
          >
            <StoryCell
              key={`story-cell-${story.slug}`}
              story={story}
              thumbnailOverlay={
                <ThumbnailOverlay selected={selector.isSelected(story)} />
              }
            />
          </div>
        ))}
      </>
    );
  }
);
