import React, { useEffect } from 'react';
import { ErrorBoundary } from '../error-boundary';
import { Switch, Route, useHistory } from 'react-router-dom';
import { DefaultRoute } from './default';
import { AccountScreen } from './account-screen';
import { GiftCouponsScreen } from './gift-coupons';
import { ClassroomScreen } from './classroom-screen';
import { CloseAccountScreen } from './close-account-screen';
import { AccountClosedScreen } from './account-closed-screen';
import { LoginScreen } from './login-screen';
import { LoginWithEmailScreen } from './login-with-email-screen';
import { SignupScreen } from './signup-screen';
import { SignupWithEmailScreen } from './signup-with-email-screen';
import { ResetPasswordScreen } from './reset-password-screen';
import { CreatePasswordScreen } from './create-password-screen';
import { BackToAppScreen } from './back-to-app';
import { RedirectToStripeScreen } from './redirect-to-stripe-screen';
import { NotFoundScreen } from './not-found-screen';
import { DevToolsScreen } from './dev-tools';
import { ShowcaseScreen } from './_showcase/showcase-screen';
import { trackPage } from '../../lib/analytics';
import { NotificationsTarget } from 'lib/services/notification-service';
import { RedeemCode } from './redeem';
import { AllScreensNag } from 'components/classrooms/classroom-nag';

const usePageTracker = () => {
  const history = useHistory();

  // track page changes automatically
  useEffect(() => {
    // the first page, just after the effect has mounted
    trackPage(history.location.pathname.replace(/^\//, ''));
    // listen for subsequent page changes
    return history.listen(location => {
      trackPage(location.pathname.replace(/^\//, ''));
    });
  }, [history]);
};

export const Routes = () => {
  usePageTracker();

  return (
    <ErrorBoundary>
      <NotificationsTarget />

      <Switch>
        <Route path="/" exact component={DefaultRoute} />

        <Route path="/login-with-email" component={LoginWithEmailScreen} />
        <Route path="/login" component={LoginScreen} />
        <Route path="/signup-with-email" component={SignupWithEmailScreen} />
        <Route path="/signup" component={SignupScreen} />
        <Route path="/reset-password" component={ResetPasswordScreen} />
        <Route path="/create-password" component={CreatePasswordScreen} />

        <Route path="/account" component={AccountScreen} />
        <Route path="/gift-coupons" component={GiftCouponsScreen} />
        <Route path="/classrooms" component={ClassroomScreen} />

        <Route path="/close-account" component={CloseAccountScreen} />
        <Route path="/account-closed" component={AccountClosedScreen} />
        <Route path="/appsupport/back-to-app" component={BackToAppScreen} />

        <Route
          path={[
            '/redeem/:code',
            '/redeem-nav-gift/:code',
            '/students/join/:code',
          ]}
          component={RedeemCode}
        />

        <Route
          path="/appsupport/subscribe/:token?"
          component={RedirectToStripeScreen}
        />
        {process.env.NODE_ENV !== 'production' ? (
          <>
            <Route path="/_dev" component={DevToolsScreen} />
            <Route path="/_showcase" component={ShowcaseScreen} />
          </>
        ) : null}
        <Route component={NotFoundScreen} />
      </Switch>
      <AllScreensNag />
    </ErrorBoundary>
  );
};
