import styled from 'styled-components';
import { getDimension, getColor } from 'lib/theme/utils';

export const PageLayout = styled.div`
  padding: 8rem 2rem 1rem;
  width: 100%;
  max-width: ${getDimension('max-screen-width')}px;
  background-color: ${getColor('off-white')};
  margin: 0 auto;
  flex: 1 0 auto;
  @media screen and (min-width: 768px) {
    padding: 9rem 4rem 2rem;
  }
`;
