import { createMixin } from '../../jw-core/core';
import { objectFromUrlQuery } from '../../lib/object-from-url-query';
import logger from '../../lib/log';

const log = logger('persistable');

/**
 * Wraps `fn` into a promise.
 * @param {Function} fn
 */
const promisify = fn => (...args) =>
  new Promise((resolve, reject) => {
    try {
      resolve(fn(...args));
    } catch (error) {
      reject(error);
    }
  });

/**
 * removes the query string from the location bar address
 */
const clearQuery = () => {
  var currentUrl = window.location.href.split('?')[0];
  window.history.replaceState({}, '', currentUrl);
};

/**
 * Creates a _mixin_ to add spa-exclusive functionality to userManager
 *
 * Use `types.compose` to add this functionality to other models.
 */
export const createSpaMixin = createMixin(({ types, flow }) => () => {
  const tokenKey = '$jwtoken';
  return (
    types
      .model({})
      .volatile(self => ({
        routerHistory: null,
        actionAfterAuthentication: null,
      }))
      // --------------------------------------------
      // specific helpers for spa functionality
      // --------------------------------------------
      .actions(self => ({
        /**
         * utility function to set root#status
         * it may not be necessary
         * @param {String} status
         */
        setStatus(status) {
          self.status = status;
        },

        /**
         * it's like startupSequence, but for the SPA only
         * formerly called `initSpa` was renamed `setup` for the samosa transition
         */
        setup() {
          self.setStatus('initializing');
          self.checkForTokenInLocation();
          self.authFromStoredToken();
        },

        /**
         * if there's a query var in the url like
         * `token=eyJabc123.eyJabc123456`
         * it will grab that and store it locally
         */
        checkForTokenInLocation() {
          const { token = null } = objectFromUrlQuery();
          if (token) {
            this.storeToken(token);
            clearQuery();
          }
        },

        /**
         * if we have a locally stored token we use that to log the user in
         */
        authFromStoredToken: flow(function* authFromStoredToken() {
          if (self.storedToken) {
            try {
              yield self.userManager.autoLogin(self.storedToken);
            } catch (error) {
              // eslint-disable-next-line no-console
              console.log('ERROR', error);
            }
          }
          self.setStatus('ready');
        }),

        /**
         * stores @token locally
         * @param {String} token
         */
        storeToken(token) {
          if (token !== null) {
            log.debug('saving storedToken');
            localStorage.setItem(tokenKey, token);
          }
        },

        /**
         * Clear stored token
         */
        clearStoredToken() {
          log.debug('removing storedToken');
          localStorage.removeItem(tokenKey);
          log.debug('storedToken removed');
        },

        setActionAfterAuthentication(type, payload = null) {
          // TODO: restrict the actions with an enum or something
          self.actionAfterAuthentication = { type, payload };
        },
      }))
      // --------------------------------------------
      // Functions MST expect
      // Since we are using
      // --------------------------------------------
      .actions(self => ({
        // mst expects this function to be async and return a promise
        // that's why we use `promisify` on what should be plain sync functions
        persist: flow(function* persist(options = {}) {
          // this is a flag that is only send when logging out,while resetting the data.
          // we may want to refactor the whole persist mechanism at some point
          // but this will work for now.
          const { resetting = false } = options;

          if (resetting) {
            // we're logging out
            log.info('resetting... clearStoredToken');
            yield promisify(self.clearStoredToken)();
          } else {
            // we're logging in
            log.debug('not resetting... storeToken');
            const userToken = self?.userManager?.token ?? null;
            yield promisify(self.storeToken)(userToken);
          }
        }),
      }))
      .views(() => ({
        /**
         * getter to easy access the stored token
         * makes it implementation agnostic
         */
        get storedToken() {
          return localStorage.getItem(tokenKey) ?? null;
        },
      }))
  );
});
