/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { useRootStore } from 'mst';
import { useHistory } from 'react-router-dom';
import { alertError } from 'lib/services/notification-service';
import { FullScreenLoader } from 'components/ds/modals';

const getRedirectAfterForPathname = pathname => {
  const pathMap = {
    'redeem-nav-gift': '/gift-coupons',
  };
  const pathRoot = pathname.substr(1, pathname.lastIndexOf('/') - 1);
  return pathMap[pathRoot] ?? '/account';
};

export const RedeemCode = observer(() => {
  const { code } = useParams();
  const history = useHistory();
  const store = useRootStore();
  const { userManager, validationErrorForKey } = store;

  useEffect(() => {
    const {
      location: { pathname },
    } = history;

    if (!userManager.loggedInAndReady) {
      history.push(`/signup?after=${pathname}`);
    } else {
      const redirectAfter = getRedirectAfterForPathname(pathname);
      userManager.applyCoupon(code).then(() => {
        const validationError = validationErrorForKey('code');
        if (validationError !== null) {
          alertError(validationError.message);
        }
        history.replace(redirectAfter);
      });
    }
  }, []);

  return <FullScreenLoader />;
});
