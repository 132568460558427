import { flow, getEnv, getRoot, types } from 'mobx-state-tree';
import { isFunction, snakeCase } from 'lodash';

import env from './lib/simple-env';
import { NetworkError, createError } from './lib/errors';
import { getLocale } from './lib/localization';
import invariant from './lib/invariant';
import { sleep } from './lib/util';

const createQueryString = (obj = null) => {
  if (!obj) return '';
  const string = Object.keys(obj)
    .map(key => {
      return `${snakeCase(key)}=${encodeURIComponent(obj[key])}`;
    })
    .join('&');

  return `?${string}`;
};

const decorateFetchOptions = (options = {}) => {
  // so we can override the headers
  const {
    apiKey,
    appSlug,
    platform,
    manifestVersion,
    headers: optionHeaders = {},
    token,
    appInstallAttribution,
    ...restOfOptions
  } = options;

  invariant(apiKey, `apiKey configuration is required for API requests`);

  const locale = getLocale();

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    Authorization: `Bearer ${apiKey}`,
    'User-Agent': `${appSlug}/${platform}/${manifestVersion}`,
    // the normal user agent wasn't getting honored for the harness,
    // so server will give priority to X-User-Agent value
    'X-User-Agent': `${appSlug}/${platform}/${manifestVersion}`,
    'Accept-Language': locale,
    ...optionHeaders,
  };

  if (token) {
    headers['X-User-Token'] = token;
  }

  if (appInstallAttribution) {
    headers['X-App-Install-Attribution'] = JSON.stringify(
      appInstallAttribution
    );
  }

  return { headers, ...restOfOptions, credentials: 'include' }; // credentials option needed for cross-site cookies
};

/**
 * Base object to provide an abstraction to access the api
 * with baked-in loading flag.
 *
 * Use `types.compose` to add this functionality to other models.
 *
 */
export const ApiAccess = types.model({}).actions(self => {
  return {
    /**
     * alias for window.fetch
     * @param  {...any} args
     */
    fetch(...args) {
      const { context = { addAction: () => {} } } = getEnv(self);

      const root = getRoot(self);
      const { simulateNetworkFailure = false } = root || {};
      if (!simulateNetworkFailure) {
        // this is useful for testing purposes
        const overrideEndpoint = env.get('overrideEndpoint', false);
        if (overrideEndpoint) {
          args[0] = overrideEndpoint;
        }
        return window.fetch?.apply(null, args);
      }

      context.addAction({
        name: 'fetch-offline',
        path: 'ApiAccess',
        type: 'mobx',
        args,
      });

      // temporal measure.
      // console.log('DOING NOTHING');
      return Promise.reject(new TypeError('Disconnected'));
    },

    /**
     * make a GET request to the API
     * @param {*} endpoint
     * @param {*} query
     */
    apiGet(endpoint, query) {
      const { createLogger = () => {} } = getEnv(self);
      const log = createLogger('api-access');
      log.info(`apiGet ${endpoint}`);
      return self.api(endpoint, query);
    },

    /**
     * make a POST request to the API
     * @param {*} endpoint
     * @param {*} query
     */
    apiPost(endpoint, query, bodyData = null) {
      const { createLogger = () => {} } = getEnv(self);
      const log = createLogger('api-access');
      log.info(`apiPost ${endpoint}`);
      const options = { method: 'POST' };

      if (bodyData) {
        options.body = JSON.stringify(bodyData);
        // console.log('BODY', options.body);
      }
      return self.api(endpoint, query, options);
    },

    apiPut(endpoint, query, bodyData = null) {
      const { createLogger = () => {} } = getEnv(self);
      const log = createLogger('api-access');
      log.info(`apiPut ${endpoint}`);
      const options = { method: 'PUT' };

      if (bodyData) {
        options.body = JSON.stringify(bodyData);
      }
      return self.api(endpoint, query, options);
    },

    /**
     * utility function to make API requests
     *
     * It automatically starts and stop loading,
     * maybe we should add error catching here too.
     *
     * todo: support generically stuffing the 'X-User-Token' header
     */
    api: flow(function* (endpoint, query = null, options) {
      const { createLogger = () => {}, platform } = getEnv(self);
      const log = createLogger('api-access');
      const token = self.token || null;
      const root = getRoot(self);
      const appInstallAttribution = root.globalConfig.appInstallAttribution;

      // get $platform-related stuff
      const platformName = platform.getPlatform();
      const apiKey = env.get(['jiveworldApiKey', platformName], false);
      const manifestVersion = platform.getProductVersion();
      const appSlug = env.get('appSlug');

      const fetchOptions = decorateFetchOptions({
        ...options,
        apiKey,
        appSlug,
        platform: platformName,
        manifestVersion,
        token,
        appInstallAttribution,
      });

      try {
        if (query) {
          endpoint += createQueryString(query);
        }
        const baseUrl = root.globalConfig.apiUrl;
        log.debug(`api: ${fetchOptions.method} ${baseUrl}${endpoint}`);
        root.startLoading(); // sets the loading flag on the root

        if (root.simulateNetworkFailure) {
          yield sleep(1000);
          // todo: figure out what a real network error looks like
          throw new NetworkError('simulated network failure');
        }

        const url = `${baseUrl}${endpoint}`;
        const response = yield self.fetch(url, fetchOptions);

        // todo: any other statuses to let through
        if (response.status && response.status >= 500) {
          throw new NetworkError(`Server error: ${response.status}`, {
            endpoint,
            query,
            fetchOptions,
          });
        }
        const data = yield response.json();
        log.debug('data response', data);

        const { error, result } = data;
        if (error) {
          // throwing here triggers the catch below
          // the createError factory takes care of setting the correct one.
          throw error;
        }

        if (isFunction(self.setApiResult)) {
          // always save the result of the last api call
          // if it has a title, which means it will be used for message
          if (result.title) {
            self.setApiResult(result);
          }
        }

        if (isFunction(getRoot(self).setFlash)) {
          if (result.messageKey) {
            // if the result has a messageKey, use it to display a flash
            getRoot(self).setFlash(result);
          }
        }

        return result;
      } catch (error) {
        log.warn('API ERROR', { error, endpoint, query, fetchOptions });
        // throw error;
        if (error instanceof TypeError) {
          throw new NetworkError(error, { endpoint, query, fetchOptions });
        } else {
          throw createError(error);
        }
      } finally {
        root.stopLoading(); // removes the loading flag on the root
      }
    }),
  };
});
