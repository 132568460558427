import { get, set } from 'lodash';
import deepmerge from 'deepmerge';
import { env as baseEnv } from './env';

// webpack will process the env variable in build-time
// so we'll have the correct require on run-time.
// it needs to be require() instead of import, though,
// otherwise it's a syntax error;
// assumes a valid default is provided by .env

// eslint-disable-next-line no-console
console.log(`overrideEnv: ./env.${process.env.REACT_APP_CONFIG_ENV}.js`);
const {
  env: overrideEnv,
} = require(`./env.${process.env.REACT_APP_CONFIG_ENV}.js`);

export const config = deepmerge(baseEnv, overrideEnv);

export const getConfig = (key, defaultValue = null) => {
  return get(config, key, defaultValue);
};

export const setConfig = (key, value) => {
  return set(config, key, value);
};

export default config;

global.jw_config = config;
global.getConfig = getConfig;
global.setConfig = setConfig;
