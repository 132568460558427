import __ from 'jw-core/lib/localization';

export const getNameValidationRules = () => {
  const max = 15;
  const minMaxMsg = __(
    'Name must be at most %{max} characters',
    'auth.nameMaxLengthValidationMessage',
    { max }
  );

  const validation = {
    required: {
      value: true,
      message: __('A name is required', 'auth.nameRequiredValidationMessage'),
    },
    maxLength: {
      value: max,
      message: minMaxMsg,
    },
  };

  return validation;
};

export const getEmailValidationRules = () => {
  const validation = {
    required: {
      value: true,
      message: __(
        'A valid email is required',
        'auth.emailRequiredValidationMessage'
      ),
    },
  };

  return validation;
};

export const getPasswordValidationRules = () => {
  const validation = {
    required: {
      value: true,
      message: __(
        'Password is required',
        'auth.passwordRequiredValidationMessage'
      ),
    },
  };

  return validation;
};
