import React from 'react';

export const SuccessIcon = ({
  fill = '#69c00d',
  stroke = '#fff',
  ...props
}) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" {...props}>
      <g fill="none">
        <circle cx="16" cy="16" r="16" fill={fill}></circle>
        <path
          stroke={stroke}
          strokeWidth="3"
          d="M9 17.765L14.444 22 23 10"
        ></path>
      </g>
    </svg>
  );
};
