import {
  applySnapshot,
  flow,
  getEnv,
  getRoot,
  getSnapshot,
  types,
} from 'mobx-state-tree';

import { composeWithName } from '../lib/type-utils/compose-with-name';
import env from '../lib/simple-env';
import { __, t } from '../lib/localization';
import * as safeTypes from '../lib/safe-mst-types';
import { destinations } from '../lib/constants/destinations';
import { ApiAccess } from '../api-access';
import invariant from '../lib/invariant';
import minibus from '../lib/minibus';
import { safelyHandleError } from '../lib/error-handling';
import { UserData } from './user-data';
import { AccountData } from './account-data';
import { lupaMode } from '../lib/app-util';
import { startupModalTypes } from '../lib/constants/vars';
import { alertLevels } from '../lib/errors';

const { ERROR, WARN } = alertLevels;

/**
 * UserManager
 *
 * provides auth services and owns all state related to the current user
 *
 * now also includes the former 'User' model actions which provides api calls
 * that operate on the current user.
 *
 * persistable trunk
 */
export const UserManager = composeWithName(
  'UserManager',
  ApiAccess,
  types
    .model('UserManager', {
      token: safeTypes.stringOrNull,
      accountData: types.optional(
        types.maybeNull(types.late(() => AccountData)),
        {}
      ), //user account data managed by server - immutable by client except via api calls to server
      lastSyncedVersion: safeTypes.numberDefaultZero, // client side version of the accountData value as of the most recent sync
      // todo: consider if this should be nullable now
      userData: types.optional(types.maybeNull(types.late(() => UserData)), {}), //  data to be synced to/from server
    })
    .views(self => ({
      get $log() {
        const { createLogger = () => {} } = getEnv(self);
        const log = createLogger('um:user-manager');
        return log;
      },
      get $platform() {
        const { platform = {} } = getEnv(self);
        return platform;
      },
      get $track() {
        const { track = () => {} } = getEnv(self);
        return track;
      },
      get $notifications() {
        const { notifications = {} } = getEnv(self);
        return notifications;
      },
    }))
    .volatile(() => ({
      loggedInAndReady: false, // keep this off until fully synced
      whatsNewUrl: null, // set by checkWelcome, and cleared after displayed
      pendingPaymentSelection: null, // 'monthly' or 'year', stashed until after authentication
      deferredNavigation: null, // destination to return to after authentication
      checkoutResult: null, // what's returned by the API after initiateCheckout
      apiResult: null, // the website API flows expect a result object with multiple properties which are handled by UI logic
      // upgradingAccount: false,
    }))
    .actions(self => ({
      setPendingPaymentSelection(path) {
        self.pendingPaymentSelection = path;
      },

      setDeferredNavigation(path) {
        self.deferredNavigation = path;
      },

      consumeDeferredNavigation() {
        const path = self.deferredNavigation;
        self.deferredNavigation = null;
        return path;
      },

      /**
       * (private)
       */
      applyAuthentication: flow(function* applyAuthentication(token) {
        if (self.authenticated) {
          self.$log.info('applyAuthentication - auto logout triggered');
          // sets a flag to signal the UI that we're not logging out for real
          // just temporarilly
          getRoot(self).startTransitioningAnonymousAccount();
          yield self.logout(true /*force*/, true /*skipSync*/);
        }

        self.token = token;
        yield self.refreshAccountData(
          true /*updateDependents*/,
          true /*trackAuthenticated*/
        );
        const selfRoot = getRoot(self);
        if (selfRoot !== null) {
          yield selfRoot.persistAll(); // no need to sync after logging in. AndSync();
        } else {
          self.$log.info('root is null for some reason');
        }
        self.$track('system__authenticated');
        // this can't be here because we haven't yet checked the welcome message status
        // self.loggedInAndReady = true; // todo: should probably move this out of here
      }),

      /**
       * Like login, except that it takes a token instead of email/password
       */
      autoLogin: flow(function* login(token) {
        const root = getRoot(self);
        try {
          self.$log.info(`autoLogin(${token})`);

          root.clearMessages();
          // @joseph I don't know if this is necessary,
          // I left it here just for parity
          if (self.authenticated) {
            self.$log.info(
              'login - already authenticated - syncing before reauthentication'
            );
            yield self.syncToServer();
          }

          yield self.applyAuthentication(token);
          yield self.checkWelcomeUrl();

          // self.loggedInAndReady = true;
          self.postAuthenticate();

          // Emit an event.
          minibus.emit('LOGIN_COMPLETE', self);

          // root.testingConfig.saveCredentials(email, password);
        } catch (error) {
          safelyHandleError(self, error, { unexpectedAlertLevel: ERROR });
        }
      }),

      login: flow(function* login(email, password) {
        const root = getRoot(self);
        try {
          const anonymousId = self.$platform.getInstallationId();
          self.$log.info(`login(${email})`);
          root.setPreauthenticatedSession(false);
          self.$log.info(`login(${email})`);
          self.$track('preauth__email_log_in', { email });

          root.clearMessages();
          if (self.authenticated) {
            self.$log.info(
              'login - already authenticated - syncing before reauthentication'
            );
            yield self.syncToServer();
          }

          const userCredentials = {
            email,
            password,
            anonymous_id: anonymousId,
          };

          const result = yield self.apiPost('users/auth', {}, userCredentials);
          yield self.applyAuthentication(result.userToken);
          yield self.checkWelcomeUrl();

          // @armando does this minibus event still trigger any logic?
          if (anonymousId) {
            minibus.emit('ANONYMOUS_LOGIN_COMPLETE');
          }

          // self.loggedInAndReady = true;
          self.postAuthenticate();

          // Emit an event.
          minibus.emit('LOGIN_COMPLETE', self);

          // root.testingConfig.saveCredentials(email, password);
        } catch (error) {
          // todo: test w/ unexpected server error
          safelyHandleError(self, error, { unexpectedAlertLevel: ERROR });
          /// @joseph there was a hack here to prevent the login screen to
          /// stay with a spinner if an unknown error happened.
          /// I don't think it's still necessary,
          /// but I didn't find a way to make that hack compatible with safelyHandleError
          /// So maybe we need to find a more robust solution.
        }
      }),

      signup: flow(function* signup(name, email, password, options = {}) {
        const { mailingListOptIn = true } = options;

        try {
          const anonymousId = self.$platform.getInstallationId();

          self.$log.info(`signupFromAnon(${email})`, 'log');
          // self.upgradingAccount = true;

          getRoot(self).setPreauthenticatedSession(false);
          getRoot(self).clearMessages();

          if (self.authenticated) {
            self.$log.info(
              'signup - already authenticated - syncing before reauthentication'
            );
            yield self.syncToServer();
          }

          self.$track('preauth__email_sign_up', { name, email });

          const userCredentials = {
            name,
            email,
            password,
            anonymous_id: anonymousId,
          };

          const result = yield self.apiPost(
            'users/signup',
            {},
            { ...userCredentials, mailing_list_opt_in: mailingListOptIn }
          );

          yield self.applyAuthentication(result.userToken);
          yield self.checkWelcomeUrl();

          // @armando does this minibus event still trigger any logic?
          minibus.emit('ANONYMOUS_SIGNUP_COMPLETE');
          // self.upgradingAccount = false;

          // self.loggedInAndReady = true;
          self.postAuthenticate();
        } catch (error) {
          safelyHandleError(self, error, { unexpectedAlertLevel: ERROR });
        }
      }),

      /**
       * handle the social logins. will create a user on the fly if needed
       *
       * provider codes:
       *   'google' - google oauth
       *   'facebook' - not yet supported
       *   'mock:[name]' - fake mode for test harness
       */
      omniauth: flow(function* omniauth(provider, token) {
        try {
          const anonymousId = self.$platform.getInstallationId();

          self.$log.info(`omniauth(${token})`);

          getRoot(self).setPreauthenticatedSession(false);
          getRoot(self).clearMessages();
          if (self.authenticated) {
            self.$log.info(
              'omniauth - already authenticated - syncing before reauthentication'
            );
            yield self.syncToServer();
          }

          // 'mock' used by mst-web-proto
          if (provider === 'google' || provider === 'mock') {
            self.$track('preauth__google_auth');
          } else {
            invariant(
              provider === 'google',
              'Currently support only google omniauth, missing branch'
            );
            self.$track('preauth__unexpected_auth');
          }

          const authParams = {
            provider,
            token,
            anonymous_id: anonymousId,
          };

          const result = yield self.apiPost('users/omniauth', authParams);
          yield self.applyAuthentication(result.userToken);
          yield self.checkWelcomeUrl();

          if (anonymousId) {
            minibus.emit('ANONYMOUS_SIGNUP_COMPLETE');
          }

          // Emit an event.
          minibus.emit('LOGIN_COMPLETE', self);

          // self.loggedInAndReady = true;
          self.postAuthenticate();
        } catch (error) {
          safelyHandleError(self, error, { unexpectedAlertLevel: ERROR });
        }
      }),

      /**
       * pretends to login via google and create new account if needed with
       * given first name if not already in system
       */
      mockOmniauth: flow(function* mockOmniauth(email, name) {
        try {
          getRoot(self).setPreauthenticatedSession(false);
          getRoot(self).clearMessages();
          yield self.omniauth('mock', `${email}|${name}`);
        } catch (error) {
          safelyHandleError(self, error, { unexpectedAlertLevel: ERROR });
        }
      }),

      // resume purchase flow if needed
      postAuthenticate() {
        const root = getRoot(self);

        self.$log.info(
          `postAuthenticate - deferred nav: ${self.deferredNavigation}, pending payment selection: ${self.pendingPaymentSelection}`
        );
        if (self.deferredNavigation) {
          const path = self.consumeDeferredNavigation();
          self.$log.info(`postAuth - deferredNavigation: ${path}`);
          root.navigate(path);
        } else {
          if (self.pendingPaymentSelection) {
            self.setPendingPaymentSelection(null);
            if (self.accountData?.fullAccess) {
              root.navigate(destinations.ACCOUNT_PAGE);
            } else {
              if (self.accountData?.hasSpecialPricing) {
                // root.setPendingModal('/account/specialPricingUnlocked');
                root.navigate(destinations.SPECIAL_PRICING_UNLOCKED_PAGE);
              } else {
                root.navigate(destinations.PRICING_PAGE);
              }
            }
          } else {
            if (!self.$platform.onWebsite()) {
              root.navigate(destinations.DASHBOARD_PAGE);
            }
          }
        }

        // this may need rethinking
        self.loggedInAndReady = true;
      },

      sendPasswordReset: flow(function* sendPasswordReset(
        email,
        hardValidation = false
      ) {
        try {
          getRoot(self).clearMessages();
          self.$track('preauth__request_password_reset', { email });
          const endpoint = 'users/send_password_reset';
          const result = yield self.apiPost(
            endpoint,
            { hard_validation: hardValidation ? 'true' : 'false' },
            {
              email,
            }
          );

          self.setApiResult({
            ...result,
            success: true,
            key: 'sendPasswordReset',
          });
          self.$notifications.notifySuccess(result.message);
        } catch (error) {
          safelyHandleError(self, error, { unexpectedAlertLevel: WARN });
        }
      }),

      resetPasswordByToken: flow(function* resetPasswordByToken(
        token,
        newPassword
      ) {
        try {
          const data = {
            reset_password_token: token,
            password: newPassword,
          };

          const result = yield self.apiPost(
            'users/reset_password_by_token',
            {},
            data
          );

          self.setApiResult({
            ...result,
            success: true,
            key: 'resetPassword',
          });

          self.$notifications.notifySuccess(result.message);
        } catch (error) {
          safelyHandleError(self, error, { unexpectedAlertLevel: ERROR });
        }
      }),

      resendEmailConfirmation: flow(function* resendEmailConfirmation() {
        try {
          getRoot(self).clearMessages();
          self.$track('account__resend_email_confirmation');
          const result = yield self.apiPost(
            'users/send_confirmation_instructions'
          );
          self.$notifications.notifySuccess(result.message);
        } catch (error) {
          safelyHandleError(self, error, { unexpectedAlertLevel: ERROR });
        }
      }),

      updateEmail: flow(function* updateEmail(newEmail) {
        yield self.updateProfileField('email', newEmail);
      }),

      updateName: flow(function* updateName(newName) {
        yield self.updateProfileField('name', newName);
      }),

      /**
       * note, we no longer that the old password is confirmed
       */
      updatePassword: flow(function* updatePassword(newPassword) {
        yield self.updateProfileField('password', newPassword);
      }),

      /**
       * Send update of name, email or password to server
       */
      updateProfileField: flow(function* updateProfileField(key, value) {
        try {
          // todo: factor out this try/catch/notification pattern?
          getRoot(self).clearMessages();
          const { message, accountData } = yield self.apiPost(
            'users/update_field',
            {
              key,
              value,
            }
          );
          yield self.applyNewAccountData(accountData, false);
          self.$notifications.notifySuccess(message);
        } catch (error) {
          safelyHandleError(self, error, { unexpectedAlertLevel: ERROR });
        }
      }),

      cancelPendingEmailChange: flow(function* cancelPendingEmailChange() {
        try {
          getRoot(self).clearMessages();
          self.$track('account__cancel_pending_email_change');
          const { message, accountData } = yield self.apiPost(
            'users/cancel_pending_email_change'
          );
          yield self.applyNewAccountData(accountData);
          self.$notifications.notifySuccess(message);
        } catch (error) {
          safelyHandleError(self, error, { unexpectedAlertLevel: WARN });
        }
      }),

      applyCoupon: flow(function* applyCoupon(code) {
        if (code === 'crash2') {
          code.crashTest();
        }

        try {
          getRoot(self).clearMessages();
          self.$track('account__redeem_coupon');
          // todo: figure out a better place to put back a test hook for unexpected crashes
          invariant(code !== 'invariant', 'invariant failure test');
          if (code === 'crash') {
            code.crashTest();
          }
          if (code === 'crash3') {
            // eslint-disable-next-line no-throw-literal
            throw 'crash3';
          }
          if (code === 'warn') {
            self.$notifications.alertWarning('this is a test warning alert');
            return;
          }
          if (code === 'error') {
            self.$notifications.alertError('this is a test error alert');
            return;
          }
          if (code === 'netfail') {
            yield self.fetch('http://foo.bar');
          }
          if (code === 'debug') {
            yield self.api(
              'users/debug',
              null, // query param
              {
                // additional fetch params
                method: 'POST',
                body: JSON.stringify({
                  data: JSON.stringify(getSnapshot(getRoot(self))),
                }),
              }
            );
            self.$notifications.notifySuccess('Debug data captured');
            return;
          }
          if (code === 'remove-all-assets') {
            yield getRoot(self).downloadManager.removeAllAssets();
            return;
          }

          const { accountData, ...extraParams } = yield self.apiPost(
            'users/apply_coupon',
            {
              code,
            }
          );

          // there's no other way for mst to communicate this {messageKey, daysLeft} variables to the UI
          getRoot(self).setFlash(extraParams);
          yield self.applyNewAccountData(accountData);
        } catch (error) {
          safelyHandleError(self, error, { unexpectedAlertLevel: WARN });
        }
      }),

      // for wondery, use a planSlug of 'bw-volume' and provide the volume slug as the 'contentSlug' param
      applyApplePayment: flow(function* applyApplePayment(
        planSlug,
        purchase,
        contentSlug = null
      ) {
        try {
          getRoot(self).clearMessages();
          self.$track('account__apply_apple_payment', { planSlug });
          const { message, accountData } = yield self.api(
            'users/apply_apple_payment',
            null,
            {
              // potentially large payloads need to be passed in the body
              method: 'POST',
              body: JSON.stringify({
                planSlug,
                contentSlug,
                purchase: JSON.stringify(purchase),
              }),
            }
          );
          self.$log(`apply_apple_payment resp: ${message}`);

          yield self.applyNewAccountData(accountData);
          // self.$notifications.notifySuccess(message);
        } catch (error) {
          safelyHandleError(self, error, { unexpectedAlertLevel: ERROR });
        }
      }),

      // resolves list of users for previous apple payments from the devise associated apple id
      resolveApplePurchasers: flow(function* resolveApplePurchasers(data) {
        try {
          getRoot(self).clearMessages();
          self.$track('account__resolve_apple_purchase_info');
          const result = yield self.api(
            'users/resolve_apple_purchasers',
            null,
            {
              // potentially large payloads need to be passed in the body
              method: 'POST',
              body: JSON.stringify({
                data: JSON.stringify(data),
              }),
            }
          );
          return result; // emails: array of email string if matched. empty array if none matched
        } catch (error) {
          safelyHandleError(self, error, { unexpectedAlertLevel: WARN });
          return [];
        }
      }),

      cancelAutoRenew: flow(function* cancelAutoRenew({
        ignoreError = false,
      } = {}) {
        try {
          self.$log.info(`cancelAutoRenew - ignoreError: ${ignoreError}`);
          getRoot(self).clearMessages();
          self.$track('account__cancel_auto_renew');
          const { message, accountData } = yield self.apiPost(
            'users/cancel_auto_renew',
            {
              ignoreError,
            }
          );
          yield self.applyNewAccountData(accountData);
          self.$notifications.notifySuccess(message);
        } catch (error) {
          safelyHandleError(self, error, { unexpectedAlertLevel: WARN });
        }
      }),

      /**
       * warning, not a end-user feature.
       * allows for testing convenience by immediately expiring the user's current subscription status.
       */
      testingResetSubscription: flow(function* resetSubscription() {
        try {
          getRoot(self).clearMessages();
          const { message, accountData } = yield self.apiPost(
            'users/reset_subscription'
          );
          yield self.applyNewAccountData(accountData);
          self.$notifications.notifySuccess(message);
        } catch (error) {
          safelyHandleError(self, error, { unexpectedAlertLevel: WARN });
        }
      }),

      // perhaps 'logout' belongs on root
      logout: flow(function* logout(force = false, skipSync = false) {
        try {
          self.$log.info('logout');
          self.$track('account__log_out');
          const root = getRoot(self);
          root.clearMessages();
          if (!self.$platform.onWebsite()) {
            // don't attempt to sync if account closed
            if (!skipSync) {
              yield self.syncToServer();
            }
          }
          yield self.reset();
          root.storyManager.resetProgress();
          yield root.persistAll({ resetting: true });
          root.setReportingContext();
          // Emit an event.
          minibus.emit('LOGOUT_COMPLETE');
        } catch (error) {
          safelyHandleError(self, error, { unexpectedAlertLevel: WARN });
        }
      }),

      reset: flow(function* () {
        try {
          self.$log.info('reset');
          self.loggedInAndReady = false;
          // note, need to preserve deferredNavigation and pendingPaymentSelection
          // through new authentication, so can't reset here.

          applySnapshot(self, {
            token: null,
            accountData: {},
            lastSyncedVersion: -1,
            userData: UserData.create(), // will probably make this nullable
          });
          // avoid bleeding the welcome message from the previous state
          self.whatsNewUrl = null;
          // fetch pre auth account data
          yield self.refreshPreauthAccountData();
        } catch (error) {
          safelyHandleError(self, error, { unexpectedAlertLevel: WARN });
        }
      }),

      exportVocab: flow(function* exportVocab(slug) {
        try {
          getRoot(self).clearMessages();

          const { message } = yield self.apiPost('users/send_vocab', {
            slug,
          });
          self.$notifications.notifySuccess(message);
        } catch (error) {
          safelyHandleError(self, error, { unexpectedAlertLevel: WARN });
        }
      }),

      closeAccount: flow(function* closeAccount() {
        try {
          getRoot(self).clearMessages();

          const { message } = yield self.apiPost('users/close_account');

          // be sure to purge current state to prevent bleeding into new signup
          yield self.logout(false /*force*/, true /*skipSync*/); // @armando, what's the syntax for named params in javascript?

          self.$notifications.notifySuccess(message);
        } catch (error) {
          safelyHandleError(self, error, { unexpectedAlertLevel: WARN });
        }
      }),
    }))
    .actions(self => ({
      initiateCheckout: flow(function* initiateCheckout(plan, urls) {
        const {
          checkoutSuccessUrl: successUrl,
          checkoutFailureUrl: failureUrl,
        } = env.get('website', urls);
        self.$log.info(
          `initiateCheckout - successUrl: ${successUrl}, failureUrl: ${failureUrl}`
        );
        const result = yield self.apiPost(
          'users/initiate_checkout',
          {
            planSlug: plan.slug,
            successUrl,
            failureUrl,
          },
          {}
        );

        // store the result in memory so it can be accessed by the UI
        self.setCheckoutResult(result);

        // @joseph I think it's cleaner to let the UI pickup after getting the result.
        // because under some conditions we need the user input before procceeding
        // with the Stripe checkout process
      }),

      // @armando, can we factor this in a way to also support the send coupon instructions result which also has multiple parts
      setCheckoutResult(result) {
        self.checkoutResult = result;
      },

      resetCheckoutResult() {
        self.checkoutResult = null;
      },

      setApiResult(result) {
        self.apiResult = result;
      },
      resetApiResult() {
        self.apiResult = null;
      },
    }))
    .views(self => ({
      get authenticated() {
        return self.token;
      },

      // checks for confirmed email in addition to 'loggedInAndReady' status
      // used by delay welcome message until after confirm email screen
      // todo: the whole 'loggedInAndReady' flow needs rethinking
      get readyAndConfirmed() {
        return (
          self.loggedInAndReady && !self.accountData.emailConfirmationRequired
        );
      },

      get showVocabListExportOption() {
        return self.userData.userSettings.showVocabListExportOption;
      },

      // still used by spa to guard against any legacy anonymous users still out there
      get isAnonymousUser() {
        return self.accountData?.anonymous;
      },

      // todo: more cleanly separate out the what's new and welcome modals
      get startupModalType() {
        if (self.resolvedWelcomeUrl) {
          return startupModalTypes.WELCOME;
        }

        if (self.whatsNewUrl) {
          return startupModalTypes.WHATS_NEW;
        }

        return startupModalTypes.NONE;
      },

      get resolvedWelcomeUrl() {
        const userSettings = self.userData?.userSettings;
        // paranoid null safety
        if (!userSettings) {
          return null;
        }

        if (userSettings.welcomeMessageDismissed) {
          return null;
        }

        if (lupaMode()) {
          return self.accountData?.welcomeUrl;
        } else {
          // jw/bolero only need a single flavor of the welcome message
          return t(env.get('welcomeSubscribedUrlKey'));
        }
      },

      get resolvedWhatsNewUrl() {
        if (lupaMode()) {
          return self.whatsNewUrl;
        } else {
          // for jw/bolero, driven by client-side config, ignore server provided URL
          return t(env.get('whatsNewUrlKey'));
        }
      },
    }))
    //
    // all of the actions which operate on the current user
    // which used to live in the 'User' model
    //
    .actions(self => {
      function buildSyncPayload() {
        const data = getSnapshot(self.userData);
        const storyProgresses = getRoot(self).storyManager.storyProgresses;
        return JSON.stringify({ ...data, storyProgresses });
      }

      return {
        // expose private method for debug view
        dumpSyncPayload() {
          return buildSyncPayload();
        },

        /**
         * fetches the latest server managed user state and if needed will refresh
         * catalog and client managed user data
         */
        refreshAccountData: flow(function* refreshAccountData(
          updateDependents = true,
          trackAuthenticated = false // todo: refactor to use named params
        ) {
          /// let's put data here for scoping reasons.
          let data;

          /// Step 1 of 2: Lets get new data from server
          try {
            self.$log.info(`refreshAccountData - ${new Date()}`);

            data = yield self.fetchAccountData();

            // this is a hack to get the authentication event logged against the anonymous session.
            // it needs to live here because it needs to be after the userId is fetched, but before we
            // applyNewAccountData sets the reporting context
            // if (!self.accountData && data && data.userId) {
            if (
              trackAuthenticated &&
              (env.get('enableSegmentAnonymous') ||
                env.get('analytics.enableAnonymous'))
            ) {
              self.$track('system__authenticated', {
                with_user_id: data.userId,
                email: data.email,
              });
            }
          } catch (error) {
            if (error.key === 'invalid_token') {
              throw error; // this error needs to be fatal to applyAuthentication
            }
            safelyHandleError(self, error, {
              ignoreNetworkErrors: true,
              unexpectedAlertLevel: WARN,
            });
            return; // short-circuit attempt to apply bogus account data
          }

          /// Step 2 of 2: let's apply thew newly retrieved data to the mst tree
          yield self.applyNewAccountData(data, updateDependents);
        }),

        /**
         * used to update server provided config data before logging in
         */
        refreshPreauthAccountData: flow(function* refreshPreauthAccountData() {
          try {
            self.$log.info(`refreshPreauthAccountData`);
            const data = yield self.fetchAccountData();
            self.accountData = AccountData.create(data);
          } catch (error) {
            safelyHandleError(self, error, {
              ignoreNetworkErrors: true,
              unexpectedAlertLevel: WARN,
            });
          }
        }),

        /**
         * updates the memory state with freshly received account data from the server.
         * shared helper method invoked from the various places that we receive an
         * accountData response.
         *
         * updateDependents - if false, then skip the catalog and userData updates.
         *   currently necessary when updating the profile info. can hopefully
         *   figure out how to make safe.
         *
         * (private)
         */
        applyNewAccountData: flow(function* applyNewAccountData(
          data,
          updateDependents = true
        ) {
          const root = getRoot(self);
          self.$log.info('applyNewAccountData', {
            updateDependents: updateDependents,
          });

          // this unfortunately didn't work. got a strange error
          // const errors = AccountData.validate(data);
          // self.$log.info(
          //   `account data validation error count: ${errors.length}`
          // );
          // if (errors.length) {
          //   const message = `hydrate validation errors: ${JSON.stringify(
          //     errors
          //   )}`;
          //   self.$log.error(message);
          // }

          const newAccountDataInstance = AccountData.create(data);

          // sorry, optional chaining inside template literals break my editors syntax highlighting
          const wmd = self?.userManager?.welcomeMessageDismissed ?? null;
          self.$log.info(`applyAccountData - welcomeMessageDismissed: ${wmd}`);

          if (newAccountDataInstance === null) {
            self.$log.error(
              'There was a problem applying new Data. Aborting',
              data
            );
            return;
          }

          self.accountData = newAccountDataInstance;
          self.$log.info('applyNewAccountData', {
            ad_lsv: data.lastSyncedVersion,
            um_lsv: self.lastSyncedVersion,
          });
          root.setReportingContext();

          // the catalog data is used by the classroom portal
          // todo: provide lighter weight access to the needed catalog data
          // if (self.$platform.onWebsite()) {
          //   return; // short circuit for lupa-spa
          // }

          if (updateDependents) {
            const catalogUrl = self.accountData.resolvedCatalogUrl;
            self.$log.debug(
              `account catalogUrl: ${catalogUrl}, smurl: ${root.storyManager.catalogUrl}`
            );
            yield root.storyManager.ensureCatalogUrl(catalogUrl);

            yield self.ensureSyncedVersion(data.lastSyncedVersion);

            // should perhaps not even populate the downloadManager object
            // for the website mode of the mst engine
            if (!self.$platform.onWebsite()) {
              // paranoia attempt to refresh failed catalog images if we pull-to-refresh
              self.$log.info(
                'applyNewAccountData - before ensureAllCatalogAssets'
              );
              root.downloadManager.ensureAllCatalogAssets();
            }
            // self.$log.info('applyNewAccountData - after ensureAllCatalogAssets');
          }
        }),

        /**
         * checks if our local user data's last sync basis matches the provided version
         * (presumably as provided within the server's accountData result).
         * if there's a mismatch then fetch the latest client data from the server.
         * (private)
         */
        ensureSyncedVersion: flow(function* ensureSyncedVersion(version) {
          if (self.lastSyncedVersion !== version) {
            self.$log.info(
              `lastSyncedVersion mismatch (old: ${self.lastSyncedVersion} / new:${version}) - syncing`
            );
            yield self.syncFromServer();
          } else {
            self.$log.info(
              `lastSyncedVersion matched (${version}) - not syncing`
            );
          }
        }),

        syncToServer: flow(function* syncToServer() {
          if (self.$platform.onWebsite()) {
            self.$log.info('Skipping server sync');
            return;
          }
          try {
            self.$log.info(
              `syncToServer - lastSyncVersion: ${self.lastSyncedVersion}`
            );
            const resultAccountData = yield self.api('users/data', null, {
              // additional fetch params
              method: 'POST',
              body: JSON.stringify({
                // client_data: JSON.stringify(getSnapshot(self.userData)),
                client_data: buildSyncPayload(),
              }),
            });
            self.accountData = AccountData.create(resultAccountData);
            self.lastSyncedVersion = self.accountData.lastSyncedVersion;
            // very important to immediately persist new lastSyncedVersion.
            // otherwise offline local progress can get overwritten.
            yield getRoot(self).persistAll();
            self.$log.info(`new lastSyncedVersion: ${self.lastSyncedVersion}`);
          } catch (error) {
            safelyHandleError(self, error, {
              ignoreNetworkErrors: true,
              unexpectedAlertLevel: WARN,
            });
          }
        }),

        syncFromServer: flow(function* syncFromServer() {
          if (self.$platform.onWebsite()) {
            self.$log.info('Skipping sync from server');
            return;
          }

          try {
            self.$log.info(
              `syncFromServer - um.lsv: ${self.lastSyncedVersion}`
            );
            // --temp hack until fetch api updated to separately include version
            const fetchedAccountData = yield self.fetchAccountData();
            const data = yield self.apiGet('users/data', {
              ts: new Date().getTime(), // ensure not cached
            });

            self.$log.debug('USER->DATA', data);

            // a server bug was previous triggering cause the data to be null
            invariant(data, 'users/data should not return null');
            if (!data) {
              return;
            }

            // hack to see if we can honor existing data
            // if (data) {
            //   const legacyAssistSettings = data['assist-settings'];
            //   if (legacyAssistSettings) {
            //     data.assistSettings = legacyAssistSettings;
            //     delete data['assist-settings'];
            //   }
            // }

            // todo: update GET data api to return both data and version number
            // getRoot(self).storyManager.reset(); // avoid dangling ref errors
            self.userData = UserData.create(data);
            // logger('debug')(`fetched storyProgresses: ${data.storyProgresses}`);
            getRoot(self).storyManager.spliceInStoryProgresses(
              data.storyProgresses
            );
            self.lastSyncedVersion = fetchedAccountData.lastSyncedVersion;
            // self.$log.info(`syncFromServer - fetchedAccountData.lastSyncedVersion: ${fetchedAccountData.lastSyncedVersion}`);
            self.accountData = AccountData.create(fetchedAccountData); // todo: clean this up!

            getRoot(self).asyncPersistAll();
          } catch (error) {
            // self.$log.warn(`syncFromServer - error: ${error}}`);
            // todo: log/archive the broken user data someplace to allow post-mortem analysis if needed
            safelyHandleError(self, error, {
              ignoreNetworkErrors: true,
              unexpectedAlertLevel: WARN,
            });

            // todo: think about how to handle edge case that we know we have stale data, but fail to fetch
            // self.userData = UserData.create();
          }
        }),

        /**
         * just fetch the account data w/o side effects
         * (private)
         */
        fetchAccountData: flow(function* fetchAccountData() {
          return yield self.apiGet('users/account', {
            ts: new Date().getTime(), // ensure not cached
          });
        }),

        /**
         * checks if the user should see a welcome message
         * (either on first login, or after updated 'what's now' post)
         * if affirmative, then sets root state
         * todo: confirm best interface to UI layer
         *
         * todo: rework this to be localizeabale
         */
        checkWelcomeUrl: flow(function* checkWelcomeUrl() {
          // never perform check for lupa-spa
          if (self.$platform.onWebsite()) {
            return;
          }

          const result = yield self.apiPost('users/check_welcome');
          self.$log.info(`check_welcome result: ${JSON.stringify(result)}`);

          if (result) {
            // server's 'welcomeUrl' logic no longer relevant for either lupa or jw
            // if (result.welcomeUrl) {
            //   self.$log.info(`result.welcomeUrl: ${result.welcomeUrl}`);
            //   getRoot(self).setWelcomeUrl(result.welcomeUrl);
            // }
            if (result.whatsNewUrl) {
              self.$log.info(`result.whatsNewUrl: ${result.welcomeUrl}`);
              self.whatsNewUrl = result.whatsNewUrl;
            }
          }
        }),

        // used by lupa-spa
        sendCouponInstructions: flow(function* sendCouponInstructions(code) {
          try {
            self.$log.info('sendCouponInstructions');
            const result = yield self.apiPost(
              'users/send_coupon_instructions',
              { code }
            );
            return result;
          } catch (error) {
            safelyHandleError(self, error, { unexpectedAlertLevel: WARN });
          }
        }),

        /**
         * nuke all user data and reset preferences back to defaults
         * only exposed via devtools
         */
        resetAllUserData: flow(function* resetAllUserData() {
          const root = getRoot(self);
          try {
            self.$log.info('clearAllProgress');
            self.userData = UserData.create({});
            root.storyManager.resetProgress();
            yield root.persist();
            yield self.syncToServer();
            self.$notifications.notifySuccess('All user data has been reset');
          } catch (error) {
            safelyHandleError(self, error, { unexpectedAlertLevel: WARN });
          }
        }),

        clearAllProgress: flow(function* clearAllProgress() {
          const root = getRoot(self);
          try {
            self.$log.info('clearAllProgress');
            // eslint-disable-next-line no-unused-expressions
            root?.userManager?.userData?.resetListeningLogs();
            // eslint-disable-next-line no-unused-expressions
            root?.storyManager?.resetProgress();
            yield root?.persist();
            yield self.syncToServer();
            self.$notifications.notifySuccess(
              __('All progress cleared', 'userManager.progressCleared')
            );
          } catch (error) {
            safelyHandleError(self, error, { unexpectedAlertLevel: WARN });
          }
        }),

        // used by spa teacher portal
        createClassroom: flow(function* createClassroom(label) {
          try {
            self.$log.info('crateClassroom');
            const {
              message,
              // messageKey,
              accountData,
            } = yield self.apiPost('classrooms', { label });

            yield self.applyNewAccountData(accountData);
            self.$notifications.notifySuccess(message);
            if (accountData && accountData.managedClassrooms) {
              return accountData.managedClassrooms[0];
            }
          } catch (error) {
            safelyHandleError(self, error, { unexpectedAlertLevel: ERROR });
          }
        }),

        // used by spa teacher portal
        onboardNewTeacher: flow(function* onboardNewTeacher() {
          try {
            self.$log.info('onboardNewTeacher : classroom/onboard');
            const {
              message,
              // messageKey,
              accountData,
            } = yield self.apiPost('classrooms/onboard');

            yield self.applyNewAccountData(accountData);
            self.$notifications.notifySuccess(message);

            if (accountData && accountData.managedClassrooms) {
              const newClassroom = accountData.managedClassrooms[0];
              return newClassroom;
            }

            return;
          } catch (error) {
            safelyHandleError(self, error, { unexpectedAlertLevel: ERROR });
          }
        }),

        clearClassroomPortalWelcome: flow(
          function* clearClassroomPortalWelcome() {
            try {
              const { accountData } = yield self.apiPost(
                'users/clear_classroom_portal_welcome'
              );

              yield self.applyNewAccountData(accountData);
            } catch (error) {
              safelyHandleError(self, error, { unexpectedAlertLevel: WARN });
            }
          }
        ),
      };
    })
    .views(self => ({
      get inAppleReview() {
        const { getBuildNumber, onIos } = self.$platform;
        return (
          onIos() && getBuildNumber() === self.accountData?.debugBuildNumber
        );
      },

      get applePaymentsAllowed() {
        return (
          self.$platform.onIos() && !self.accountData?.applePaymentsDisabled
        );
      },
      get couponsEnabled() {
        const disableCoupons = env.get('disableCoupons');
        const enableAccountFeatures = env.get('enableAccountFeatures');
        const betaTestMode = env.get('betaTestMode');

        if (disableCoupons || betaTestMode || !enableAccountFeatures) {
          return false;
        }

        return !self.inAppleReview;
      },

      get googleLoginEnabled() {
        const disableGoogleLogin = env.get('disableGoogleLogin');
        if (disableGoogleLogin) {
          return false;
        }
        return !self.inAppleReview;
      },

      get storyFilterLabels() {
        const result = {
          all: __('All stories', 'stories.filters.all'),
          unplayed: __('Unplayed', 'stories.filters.unplayed'),
          inProgress: __('In progress', 'stories.filters.inProgress'),
          completed: __('Completed', 'stories.filters.completed'),
          notDownloaded: __('Not downloaded', 'stories.filters.notDownloaded'),
          downloaded: __('Downloaded', 'stories.filters.downloaded'),
        };
        self.accountData.joinedClassrooms.forEach(classroom => {
          result[classroom.filterKey] = classroom.label;
        });
        return result;
      },

      storyFilterLabel(key) {
        return self.storyFilterLabels[key];
      },
    }))
);
