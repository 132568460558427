import * as NotificationService from './lib/services/notification-service';
import { getConfig } from 'config';

export const globalErrorHandler = event => {
  if (getConfig('showUnhandledErrors')) {
    const message = event?.originalError?.message ?? 'Unhandled error';
    NotificationService.alertError(message, {
      report: false, // do not re-send to bugsnag
    });
  }
};
