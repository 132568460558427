import { applySnapshot, getEnv, types } from 'mobx-state-tree';
import * as safeTypes from '../lib/safe-mst-types';

/**
 * AssistSettings
 *
 * holds the user's last selection assist mode configuration choices
 */
export const AssistSettings = types

  .model('AssistSettings', {
    assist: safeTypes.booleanDefaultFalse, // it's not clear to me if this should be persisted
    hints: safeTypes.booleanDefaultTrue,
    vocab: safeTypes.booleanDefaultTrue,
    trickyBits: safeTypes.booleanDefaultTrue,
    allText: safeTypes.booleanDefaultTrue,
    speed: types.optional(types.maybeNull(types.number), 0.8),
  })
  .views(self => ({
    get $log() {
      const { createLogger = () => {} } = getEnv(self);
      const log = createLogger('um:assist-settings');
      return log;
    },
  }))
  .actions(self => ({
    updateEverything(settings) {
      self.$log.info('Syncing assist-settings into mst');
      applySnapshot(self, settings);
    },

    toggleAssistMode() {
      self.assist = !self.assist;
    },

    toggleSetting(setting) {
      self[setting] = !self[setting];
    },

    toggleShowHints() {
      self.showHints = !self.showHints;
    },

    toggleShowVocab() {
      self.showVocab = !self.showVocab;
    },

    toggleShowTrickyBits() {
      self.showTrickyBits = !self.showTrickyBits;
    },

    toggleShowAllText() {
      self.showAllText = !self.showAllText;
    },

    setPlaybackSpeed(value) {
      self.playbackSpeed = value;
    },
  }))
  .views(self => {
    const offSettings = {
      hints: false,
      vocab: false,
      trickyBits: false,
      allText: false,
      speed: 1.0,
    };

    const getSetting = setting =>
      self.assist ? self[setting] : offSettings[setting];

    const setSetting = (setting, newVal) => {
      if (self.assist) {
        self[setting] = newVal;
      }
    };

    return {
      get showHints() {
        return getSetting('hints');
      },

      set showHints(newValue) {
        return setSetting('hints', newValue);
      },

      get showVocab() {
        return getSetting('vocab');
      },

      set showVocab(newValue) {
        return setSetting('vocab', newValue);
      },

      get showTrickyBits() {
        return getSetting('trickyBits');
      },

      set showTrickyBits(newValue) {
        return setSetting('trickyBits', newValue);
      },

      get showAllText() {
        return getSetting('allText');
      },

      set showAllText(newValue) {
        return setSetting('allText', newValue);
      },

      get playbackSpeed() {
        return getSetting('speed');
      },

      set playbackSpeed(newValue) {
        return setSetting('speed', newValue);
      },
    };
  });
