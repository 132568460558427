import { action, observable } from 'mobx';

/**
 * TODO: Consider moving this into MST?
 */

class DialogService {
  currentDialog = observable.box(null);

  openDialog = action(dialog => {
    this.currentDialog.set(dialog);
  });

  closeDialog = action(() => {
    this.currentDialog.set(null);
  });
}

const singleton = new DialogService();

if (process.env.NODE_ENV === 'development') {
  window.DialogService = singleton;
}

export default singleton;
