import { getEnv, types } from 'mobx-state-tree';

//
// Testing stuff
//

/**
 * home for random state used by the mst web prototype
 * FIXME: put into a mixin only for mst-proto
 */
export const TestingConfig = types
  .model('TestingConfig', {
    // persisted for testing convenience
    email: types.optional(types.string, ''),
    password: types.optional(types.string, ''),
    name: types.optional(types.string, ''),
    installationId: types.optional(types.string, ''),
  })
  .volatile(self => ({
    // // support for hacky alternative to the reporting service
    // successMessage: null,
    // warningMessage: null,
    // errorMessage: null,
    // drives hacky modal simulation
    pendingModal: null,
    pendingModalProps: null,
  }))
  .views(self => ({
    get $log() {
      const { createLogger = () => {} } = getEnv(self);
      const log = createLogger('proto-support');
      return log;
    },
  }))
  .actions(self => ({
    saveCredentials(email, password) {
      self.email = email;
      self.password = password;
    },

    setEmail(email) {
      self.email = email;
    },

    setPassword(password) {
      self.password = password;
    },

    setName(name) {
      self.name = name;
    },

    setInstallationId(installationId) {
      self.installationId = installationId;
    },

    setSuccessMessage(message) {
      self.successMessage = message;
    },

    setWarningMessage(message) {
      self.warningMessage = message;
    },

    setErrorMessage(message) {
      self.errorMessage = message;
    },

    setPendingModal(path, props) {
      self.$log.info(`setPendingModal: ${path}, ${JSON.stringify(props)}`);
      self.pendingModal = path;
      self.pendingModalProps = props;
    },

    consumePendingModal() {
      const path = self.pendingModal;
      const props = self.pendingModalProps;
      self.pendingModal = null;
      self.pendingModalprops = null;
      return { path, props };
    },

    clearMessages() {
      self.successMessage = null;
      self.warningMessage = null;
      self.errorMessage = null;
    },
  }));

// is there a better way to access the enum choices?
const mainNavOptions = ['dashboard', 'filters', 'me', 'account', 'settings'];

// @joseph is this still used in harness?
// hold UI state for mst-web-proto views
export const ProtoState = types // todo: rip this model out entirely now in favor of view state
  .model('TestingConfig', {
    mainNav: types.optional(types.enumeration(mainNavOptions), 'dashboard'),
  })
  .actions(self => ({
    setMainNav(mainNav) {
      self.mainNav = mainNav;
    },
  }))
  .views(self => ({
    get mainNavOptions() {
      return mainNavOptions;
    },
  }));

export const scratch = () => {};
