/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { prevent } from '../../lib/prevent';

export const ResendEmailButton = ({ coupon }) => {
  return (
    <a
      href="#"
      className="coupon-purchase__action-btn"
      onClick={prevent(coupon.resendInstructions)}
    >
      Resend email
    </a>
  );
};
