import React, { useState, useLayoutEffect } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { Button } from 'components/ds/buttons';
import { getColor, getDimension, includeTextStyles } from 'lib/theme/utils';
import { animated, useTransition } from 'react-spring';
import { useKeyboardBinding } from 'lib/hooks/use-keyboard-binding';
import { IconX } from 'components/ds/icons';
import { MarkdownContainer } from 'components/ds/common/markdown-container';
import { instructions } from 'lib/strings/en/classroom-student-instructions.js';
import { useCopyPowers } from 'lib/hooks/use-copy-powers';
import { getConfig } from 'config';

import __ from 'jw-core/lib/localization';

/**
 * This is a very basic string interpolation function that very shallowly mirrors i18n interpolation
 * we should do something better when we rething i18n
 * @param {String} str
 * @param {Object} ctx
 */
const interpolate = (str, ctx) => {
  const keys = str
    .match(/%\{[a-zA-Z]+\}/g)
    .map(s => s.replace('%{', '').replace('}', ''));

  let interpolated = str;

  keys.forEach(key => {
    interpolated = interpolated.replace(`%{${key}}`, ctx[key]);
  });

  // console.log('KEYS', keys);
  return interpolated;
};

const Container = styled(animated.div)`
  position: fixed;
  margin: 0;
  padding: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 10000;
  padding: 8px;
  @media screen and (min-width: 550px) {
    padding: 12px;
  }
  display: flex;
  align-items: center;
  & > .frame {
    background-color: ${getColor('off-white')};
    max-height: calc(100vh - 16px);
    max-width: ${getDimension('max-screen-width')}px;
    margin: 0 auto;
    border-radius: 8px;
    position: relative;
    display: flex;
    flex-direction: column;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    @media screen and (min-width: 550px) {
      max-height: calc(100vh - 24px);
    }
    .header {
      .inner {
        height: 56px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-bottom: 1px solid ${getColor('gray-1')};
        margin: 0 16px;
        max-width: ${getDimension('max-screen-width')}px;
        @media screen and (min-width: 550px) {
          margin: 0 24px;
        }
      }
      .modal-title {
        ${includeTextStyles('medium-heading')};
      }

      .modal-controls {
        margin-left: auto;
        display: flex;
      }
      .close-button {
        border: none;
        background: none;
        cursor: pointer;
        color: ${getColor('gray-4')};
        margin-right: -9px;
        &:focus {
          outline: none;
        }
      }
    }
    .content {
      height: 100%;
      overflow-y: scroll;
      overflow-x: hidden;
      @media screen and (max-width: 550px) {
        padding-bottom: 56px;
      }
    }

    .content-wrapper {
      padding: 0 16px;
      width: 100%;
      max-width: ${getDimension('max-screen-width')}px;
      margin: 0 auto;
      flex: 1 0 auto;
      @media screen and (min-width: 550px) {
        padding: 0 24px;
      }
    }

    .instructions {
      ${includeTextStyles('small-text')};
      color: ${getColor('gray-4')};
      padding: 12px 0 24px 0;
    }

    .invitation {
      background-color: ${getColor('off-off-white')};
      padding: 16px;

      h1 {
        ${includeTextStyles('body')};
        font-weight: 600;
        margin-bottom: 16px;
      }
    }

    .hidden {
      position: absolute;
      left: 0;
      top: 0;
      border: none;
      padding: none;
      opacity: 0;
    }

    .footer {
      .inner {
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid ${getColor('gray-1')};
        padding: 16px 0;
        margin: 0 16px;
        max-width: ${getDimension('max-screen-width')}px;
        @media screen and (min-width: 550px) {
          margin: 0 24px;
        }
      }
    }
  }
`;

export const ClassroomShareModal = observer(
  ({ classroom, onDone, onDismiss, children }) => {
    const [show, set] = useState(true);

    const { ref, active: copying, copyAction } = useCopyPowers();

    const transitions = useTransition(show, null, {
      from: { transform: 'scale(1.05)', opacity: 0 },
      enter: { transform: 'scale(1.0)', opacity: 1 },
      leave: { transform: 'scale(1.05)', opacity: 0 },
      onDestroyed: onDismiss,
      duration: 100,
    });

    useLayoutEffect(() => {
      // this blurs the active element which would be the button that called the modal
      // and blurs it so the keybindings work
      document.activeElement.blur();
      // this adds a special class to the body that prevent is from scrolling
      document.body.classList.add('__no-scroll');
      return () => {
        document.body.classList.remove('__no-scroll');
      };
    });

    const handleDone = () => {
      onDone();
      set(false);
    };

    const handleCancel = () => {
      set(false);
    };

    useKeyboardBinding('Escape', handleCancel);
    useKeyboardBinding('Meta+Enter', handleDone); //cmd+enter on mac or ctrl+enter in PC

    const invitation = interpolate(instructions, {
      invitationUrl: `${getConfig('website.baseUrl')}/students/join/${
        classroom.code
      }`,
      lupaUrl: getConfig('website.urls.downloadApp'),
      className: classroom.label,
      code: classroom.code,
    });

    return transitions.map(
      ({ item, key, props }) =>
        item && (
          <Container key={key} style={props}>
            <div className="frame">
              <div className="header">
                <div className="inner">
                  <div className="modal-title">
                    {__(
                      'Instructions for students',
                      'classroom.instructionsForStudents'
                    )}
                  </div>
                  <div className="modal-controls">
                    <button className="close-button" onClick={onDismiss}>
                      <IconX />
                    </button>
                  </div>
                </div>
              </div>
              <div className="content">
                <div className="content-wrapper">
                  <div className="instructions">
                    {__(
                      'You can paste the text below and share by email, Canvas etc.',
                      'classrooms.invitationDialogInstructions'
                    )}
                  </div>
                  <div className="invitation">
                    <MarkdownContainer source={invitation}></MarkdownContainer>
                  </div>
                  <textarea
                    ref={ref}
                    value={invitation}
                    className="hidden"
                    readOnly
                  />
                </div>
              </div>
              <div className="footer">
                <div className="inner">
                  <Button
                    label={
                      copying
                        ? __('Copied to clipboard', 'common.copiedToClipboard')
                        : __('Copy to clipboard', 'common.copyToClipboard')
                    }
                    presentation={'primary'}
                    onClick={copyAction}
                    size="small"
                  />
                </div>
              </div>
            </div>
          </Container>
        )
    );
  }
);
