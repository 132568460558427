import React from 'react';

export const ErrorIcon = () => {
  return (
    <svg width="20" height="20">
      <path
        fill="#FF6767"
        fillRule="evenodd"
        d="M10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10zM9.99 3c-.804 0-1.431.385-1.431 1.068 0 2.084.274 5.078.274 7.162 0 .542.53.77 1.157.77.47 0 1.137-.228 1.137-.77 0-2.084.275-5.078.275-7.162C11.402 3.385 10.755 3 9.99 3zm.02 13.5c.804 0 1.49-.701 1.49-1.5 0-.818-.686-1.5-1.49-1.5-.863 0-1.51.682-1.51 1.5 0 .799.647 1.5 1.51 1.5z"
      />
    </svg>
  );
};
