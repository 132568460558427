import React from 'react';
import { observer } from 'mobx-react';
import { useRootStore } from '../../truth';
import { prevent } from '../../lib/prevent';
import __ from 'jw-core/lib/localization';

import styled from 'styled-components';

const TableNote = styled.div`
  background: #fff;
  border: 1px solid #f2efe9;
  border-radius: 8px;
  padding: 2rem;
  margin-top: 2.5rem;
`;

const TableNoteText = styled.p`
  font-family: roboto, sans-serif;
  font-size: 2.125rem;
  line-height: 3rem;
  font-weight: 400;
  color: #999;
`;

const TableNoteTextButton = styled.button`
  font-family: roboto, sans-serif;
  font-size: 2.125rem;
  line-height: 3rem;
  font-weight: 400;
  border: none;
  outline: none;
  padding: 0;
  background: none;
  color: #19bbd4;
  background-image: linear-gradient(#19bbd4 50%, transparent 0);
  background-repeat: repeat-x;
  background-size: 2px 2px;
  background-position: 0 1.12em;
  cursor: pointer;
  margin-right: 2rem;
`;

const NoBreak = styled.span`
  word-break: break-all;
`;

export const PendingEmailNote = observer(() => {
  const { userManager } = useRootStore();
  const { accountData } = userManager ?? {};
  const showNote =
    accountData.unconfirmedEmail || accountData.emailConfirmationRequired;

  if (!showNote) {
    return null;
  }

  if (accountData.unconfirmedEmail) {
    return (
      <TableNote>
        <TableNoteText>
          {__('Pending verification', 'emailNote.pendingVerification')}
          <br />
          <NoBreak as={'strong'}>{accountData.unconfirmedEmail}</NoBreak>{' '}
        </TableNoteText>
        <TableNoteText>
          {__('We’ve sent an email to', 'emailNote.weSentAnEmail')}{' '}
          <NoBreak>{accountData.unconfirmedEmail}</NoBreak>{' '}
          {__(
            'with a link to confirm this email change.',
            'emailNote.withLinkToConfirm'
          )}{' '}
        </TableNoteText>
        <TableNoteTextButton
          onClick={prevent(userManager.resendEmailConfirmation)}
        >
          {__('Resend email', 'emailNote.resendEmail')}{' '}
        </TableNoteTextButton>
        <TableNoteTextButton
          onClick={prevent(userManager.cancelPendingEmailChange)}
        >
          {__('Cancel', 'common.cancel')}
        </TableNoteTextButton>
      </TableNote>
    );
  }
  if (accountData.emailConfirmationRequired) {
    return (
      <TableNote>
        <TableNoteText>
          {__('Pending verification', 'emailNote.pendingVerification')}
          <br />
          <NoBreak as={'strong'}>{accountData.unconfirmedEmail}</NoBreak>{' '}
        </TableNoteText>
        <TableNoteText>
          {__('We’ve sent an email to', 'emailNote.weSentAnEmail')}{' '}
          <NoBreak>{accountData.unconfirmedEmail}</NoBreak>{' '}
          {__(
            'with a link to verify your account.',
            'emailNote.withLinkToVerify'
          )}{' '}
        </TableNoteText>
        <TableNoteTextButton
          onClick={prevent(userManager.resendEmailConfirmation)}
        >
          {__('Resend email', 'emailNote.resendEmail')}{' '}
        </TableNoteTextButton>
      </TableNote>
    );
  }
});
