const ACCOUNT_PAGE = '/account';
const SPECIAL_PRICING_UNLOCKED_PAGE = '/account/specialPricingUnlocked';
const PRICING_PAGE = '/account/pricing';
const DASHBOARD_PAGE = '/dashboard';

export const destinations = {
  ACCOUNT_PAGE,
  SPECIAL_PRICING_UNLOCKED_PAGE,
  PRICING_PAGE,
  DASHBOARD_PAGE,
};
