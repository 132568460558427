import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { getColor } from 'lib/theme/utils';

import styled from 'styled-components';

const Wrapper = styled.div`
  color: ${getColor('gray-4')};
  margin-bottom: 4px;
`;

export const StudentEmailCell = ({ email }) => {
  const isMobile = useMediaQuery({
    query: '(max-device-width: 550px)',
  });

  if (!isMobile) {
    return email;
  }

  return <Wrapper>{email}</Wrapper>;
};
