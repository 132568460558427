import deepmerge from 'deepmerge';
import { objectFromUrlQuery } from 'lib/object-from-url-query';
import * as ln from 'jw-core/lib/localization';

import coreEn from '../../jw-core/langs/en.json';
import coreEs from '../../jw-core/langs/es.json';
import coreJa from '../../jw-core/langs/ja.json';
import appEn from '../../langs/en.json';
import appEs from '../../langs/es.json';
import appJa from '../../langs/ja.json';

const en = deepmerge(appEn, coreEn);
const es = deepmerge(appEs, coreEs);
const ja = deepmerge(appJa, coreJa);

ln.addTranslations('en', en);
ln.addTranslations('es', es);
ln.addTranslations('ja', ja);

const { locale } = objectFromUrlQuery();
/// just take the first part of the language string e.g. "en-US" -> "en"
const defaultLocale =
  navigator.language.toLowerCase().replace(/-.+$/, '') ?? 'en';

ln.setLocale(locale ?? defaultLocale);

window.__l = ln;
