import * as notifications from 'lib/services/notification-service';
import * as platform from 'lib/platform';

import React, { useContext, useState } from 'react';

import { DisplayStatus } from 'components/debug/display-status';
import { LoadingRoot } from './loading-root';
import { NetworkErrorScreen } from 'components/routes/network-error-screen';
import context from 'lib/services/context-service';
import createLogger from 'lib/log';
import { createRootStore } from '../jw-core/core';
import { createSpaMixin } from './mixins/spa-mixin';
import downloader from 'lib/downloader';
import { observer } from 'mobx-react';
import { track } from 'lib/instrumentation/track-events';
import { useMinibusSubscription } from 'lib/hooks/use-minibus';

export const root = createRootStore({
  mixin: createSpaMixin,
  dependencies: {
    createLogger,
    downloader, // this is a required dependency. Work needs to be done to make it optionsl.
    platform,
    track,
    notifications,
    context,
  },
});

window.root = root;

const Context = React.createContext({});
const { Provider } = Context;

export const RootStoreProvider = observer(({ children }) => {
  const [networkFailed, setNetworkFail] = useState(false);

  useMinibusSubscription(
    'NETWORK_ERROR:REFRESH_ACCOUNT_DATA', // Maybe define this in a constant, somewhere
    () => {
      setNetworkFail(true);
    },
    'mst'
  );

  if (networkFailed) {
    return <NetworkErrorScreen />;
  }

  if (root.status === 'initializing') {
    return <LoadingRoot root={root} />;
  }

  return (
    <>
      <DisplayStatus status={root.status} />
      <Provider value={root}>{children}</Provider>
    </>
  );
});

export const useRootStore = () => useContext(Context);

export const withRootStore = Component => {
  const Wrapped = props => <Component {...props} rootStore={root}></Component>;
  return Wrapped;
};
