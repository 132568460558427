import styled from 'styled-components';
import {
  includeTextStyles,
  getColorFromProp,
  cond,
  prop,
} from '../../../lib/theme/utils';

export const Box = styled.div`
  ${includeTextStyles('body')};
  background-color: ${getColorFromProp('backgroundColor')};
  border-color: ${getColorFromProp('borderColor')};
  border-width: ${cond('borderWidth', prop('borderWidth'), 0)};
`;
