import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { EmailIcon } from '../ui/icons/email-icon';
import { IconButton } from '../ui/buttons/icon-button';
import { GoogleButton } from '../ui/buttons/google-button';
import { AuthTabs } from '../auth/auth-tabs';
import { AuthFootnote } from '../auth/auth-footnote';
import { AuthContainer } from '../auth/auth-container';
import { useTitle } from '../../lib/hooks/use-title';
import { useAfterAuthRedirection } from './use-after-auth-redirection';

import __ from 'jw-core/lib/localization';

export const LoginScreen = observer(() => {
  useTitle('Sign in');
  useAfterAuthRedirection();

  const history = useHistory();

  const push = path => () => history.push(path);

  return (
    <AuthContainer>
      <div className="auth__content" data-test-id="auth-container">
        <AuthTabs />
        <GoogleButton
          title={__('Sign in with Google', 'auth.signInWithGoogle')}
        />
        <IconButton
          title={__('Sign in with email', 'auth.signInWithEmail')}
          icon={EmailIcon}
          loading={false}
          onClick={push('/login-with-email')}
        />
        <AuthFootnote>
          Have a code? Sign in above or{' '}
          <Link to="/signup">create&nbsp;your&nbsp;free&nbsp;account</Link> to
          redeem
        </AuthFootnote>
      </div>
    </AuthContainer>
  );
});
