import React from 'react';
import { observer } from 'mobx-react';
import ReactDOM from 'react-dom';
import { getConfig } from 'config';

import { LupaLogo } from '../../ui/icons/logo';
import { ExternalNavLinkIcon } from '../../ui/icons/external-nav-link';

const MainNavigationPortal = ({ children }) => {
  return ReactDOM.createPortal(children, document.getElementById('header'));
};

const LupaLink = ({ standalone = false, loggedIn = false, onClick }) => {
  const Container = standalone ? 'div' : 'li';
  const marketingSiteUrl = getConfig('website.marketingSiteUrl');

  return (
    <Container
      className={`site-header__nav__item ${
        standalone ? 'is-standalone' : 'is-nested'
      } ${loggedIn ? 'is-logged-in' : 'is-logged-out'}`}
    >
      <a
        href={marketingSiteUrl}
        onClick={onClick}
        className="site-header__nav__link site-header__nav__link--to-marketing"
        target="_blank"
        rel="noopener noreferrer"
      >
        About Lupa
        <ExternalNavLinkIcon />
      </a>
    </Container>
  );
};

export const GlobalNav = observer(() => {
  return (
    <MainNavigationPortal>
      <header className={`site-header js-site-header site-header--off-white`}>
        <div className="site-header__content">
          <div className="site-header__logo-nav-btn">
            <h1 className="site-header__logo site-header__logo--lupa">
              <a className="site-header__logo__link" href="/">
                <span className="site-header__logo__my">My</span>
                <span className="site-header__logo__lupa">Lupa</span>
                <LupaLogo />
              </a>
            </h1>
          </div>
          <LupaLink standalone />
        </div>
      </header>
    </MainNavigationPortal>
  );
});
