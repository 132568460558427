import styled from 'styled-components';
import { getFontName, getColor } from '../../lib/theme/utils';

/*

.account__subscribe-prompt {
    border-top: 1px solid rgba(0,0,0,.12);
    margin-bottom: 2rem;
}
.account__member-since, .account__subscribe-prompt {
    font-family: roboto,sans-serif;
    font-size: 2.125rem;
    line-height: 3rem;
    font-weight: 400;
    padding: 2.5rem 0;
}
*/

export const SectionDescription = styled.h2`
  border-top: 1px solid ${getColor('blackHairline')};
  margin-bottom: 2rem;
  font-family: ${getFontName('body')};
  font-size: 2.125rem;
  line-height: 3rem;
  font-weight: 400;
  padding: 2.5rem 0;
`;
