import minibus from './lib/minibus';
import cookies from 'js-cookie';
import { snakeCase } from 'lodash';
import logger from './lib/log';
import { getConfig } from 'config';
const log = logger('root-listener');

const domain = getConfig('website.cookieDomain');

const setDomainCookie = (name, value) => {
  name = snakeCase(name);
  cookies.set(name, value, { domain });
};

const removeDomainCookie = name => {
  name = snakeCase(name);
  cookies.remove(name, { domain });
};

minibus.subscribe('LOGIN_COMPLETE', userManager => {
  log.info(
    `LOGIN_COMPLETE, setting cookies, userName: ${userManager?.accountData?.name}, domain: ${domain}`
  );
  setDomainCookie('membershipState', userManager?.accountData?.membershipState);
  setDomainCookie('userName', userManager?.accountData?.name);
});

minibus.subscribe('LOGOUT_COMPLETE', () => {
  log.info(`LOGOUT_COMPLETE, clearing cookies`);
  removeDomainCookie('membershipState');
  removeDomainCookie('userName');
});
