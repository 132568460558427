import React from 'react';
import { getColor, includeTextStyles } from 'lib/theme/utils';
import { useConfirm } from 'components/ds/modals';
import { isFunction } from 'lodash';

import __ from 'jw-core/lib/localization';

import styled from 'styled-components';

const MenuStyleWrapper = styled.div`
  ${includeTextStyles('body')};
  display: flex;
  flex-direction: column;
  background: ${getColor('white')};
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  .menu-item {
    padding: 16px;
    position: relative;
    cursor: pointer;

    &::after {
      position: absolute;
      content: '';
      left: 16px;
      right: 16px;
      bottom: 0;
      height: 1px;
      background: ${getColor('gray-1')};
    }
    &:last-child {
      &::after {
        display: none;
      }
    }
    &:hover {
      color: ${getColor('dark-teal')};
    }

    &.item-is-disabled {
      color: ${getColor('gray-3')};
      cursor: not-allowed;
    }

    &.item-is-destructive {
      color: ${getColor('red')};
    }
  }
`;

export const MenuItem = ({
  label,
  testId,
  disabled,
  action = () => {},
  afterAction = () => {},
  isDestructive = false,
}) => {
  const className = `menu-item ${disabled === true ? 'item-is-disabled' : ''} ${
    isDestructive === true ? 'item-is-destructive' : ''
  }`;
  const maybeAction = () => {
    if (disabled) {
      return;
    }
    if (action(afterAction) !== false) {
      afterAction();
    }
  };
  // Note: using divs here is not accessible at all, but using
  //       links or buttons would give us other kind of problems
  //       if accessibility ever becomes a requirement
  //       we could do something with hidden links and ARIA rules
  return (
    <div className={className} onClick={maybeAction} data-test-id={testId}>
      {label}
    </div>
  );
};

export const ConfirmableMenuItem = ({
  confirmationTitle: title = __('Are you sure?', 'common.areYouSure'),
  confirmationBody: body,
  confirmationOkButtonLabel: okButtonLabel,
  confirmationCancelButtonLabel: cancelButtonLabel,
  action,
  ...props
}) => {
  const [Dialog, dialogActions] = useConfirm({
    onConfirm: action,
  });

  const itemAction = () => {
    dialogActions.on();
    // this is to prevent the menu to dismount automatically and close the dialog prematurely
    return false;
  };

  const cancelButtonAction = () => {
    if (isFunction(props.afterAction)) {
      props.afterAction();
    }
    // since we are overriding the cancel action,
    // we have to manually close the dialog.
    dialogActions.off();
  };

  const okIsDangerous = props.isDestructive ?? false;

  return (
    <>
      <MenuItem action={itemAction} {...props} />
      <Dialog
        {...{
          title,
          body,
          okButtonLabel,
          cancelButtonLabel,
          cancelButtonAction,
          okIsDangerous,
        }}
      />
    </>
  );
};

export const Menu = ({ items = [], children, afterAction = () => {} }) => {
  const treatItemsAsRenderFunction = isFunction(items);

  const mapOverItemsArray = () =>
    items.map((item, index) => {
      const { requiresConfirmation, ...props } = item;

      const ItemComponent = requiresConfirmation
        ? ConfirmableMenuItem
        : MenuItem;

      return (
        <ItemComponent
          {...props}
          key={`menu-item-${index}`}
          afterAction={afterAction}
        />
      );
    });

  return (
    <MenuStyleWrapper>
      {treatItemsAsRenderFunction
        ? items({ afterAction })
        : mapOverItemsArray()}
      {children}
    </MenuStyleWrapper>
  );
};
