import React from 'react';
import { Link } from 'react-router-dom';
import { getColor } from 'lib/theme/utils';

import styled from 'styled-components';

const LabelCellWrapper = styled.div`
  a {
    color: ${getColor('teal')};
  }

  @media screen and (max-width: 550px) {
    /* border-bottom: 1px solid ${getColor('gray-1')}; */
    padding-top: 16px;
    padding-bottom: 16px;
    margin-bottom: 12px;
    margin-right: 24px;
    a {
      font-weight: 600;
    }
  }
`;

export const ClassLabelCell = ({ label, id }) => {
  return (
    <LabelCellWrapper>
      <Link to={`/classrooms/${id}`} data-test-id="class-label">
        {label}
      </Link>
    </LabelCellWrapper>
  );
};
