import React from 'react';
import __ from 'jw-core/lib/localization';
import styled from 'styled-components';

const Wrapper = styled.div`
  h3 {
    font: var(--small-heading-font);
    text-transform: uppercase;
    padding-bottom: 8px;
    border-bottom: 1px solid var(--gray-1);
    margin-bottom: 12px;
  }

  dl {
    margin-bottom: 32px;
  }

  dt,
  dd {
    font: var(--body-font);
  }
  dd {
    margin-bottom: 16px;
    color: var(--gray-4);
  }

  @media print, screen and (min-width: 700px) {
    dl {
      column-count: 2;
      column-gap: 32px;
    }
    .credit {
      break-inside: avoid-column;
    }
  }
  @media print, screen and (min-width: 900px) {
    dl {
      column-count: 3;
    }
  }
`;

export const StoryCast = ({ story }) => {
  return (
    <Wrapper>
      <h3>{__('Voices', 'story.voices')}</h3>
      <dl>
        {story.cast.map((person, i) => (
          <div className="credit" key={`person-${i}`}>
            <dt>
              {person.fullName}
              {person.accent ? ` (${person.accent})` : null}
            </dt>
            <dd>{person.description}</dd>
          </div>
        ))}
      </dl>
    </Wrapper>
  );
};
