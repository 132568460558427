import React from 'react';
import { ShowcaseTables } from './pages/showcase-tables';
import { ShowcaseHeaders } from './pages/showcase-headers';
import { ShowcaseCode } from './pages/showcase-code';
import { ShowcaseTextStyles } from './pages/showcase-text-styles';
import { ShowcaseButtons } from './pages/showcase-buttons';
import { ShowcaseModals } from './pages/showcase-modals';
import { ShowcaseMenus } from './pages/showcase-menus';
import { ShowcaseTooltips } from './pages/showcase-tooltips';
import { Route, useRouteMatch, Link } from 'react-router-dom';
import { includeProp, getColor } from '../../../lib/theme/utils';

import styled from 'styled-components';

const Container = styled.div`
  padding-top: 14rem;
  padding-bottom: 8rem;
  display: flex;
  flex-direction: row;
  .sidebar {
    ${includeProp('textStyles.body')};
    border-right: 1px solid ${getColor('gray1')};
    width: 20rem;
    nav {
      display: flex;
      flex-direction: column;
      a {
        padding: 1rem;
      }
    }
  }
  .main-content {
    flex: 1;
    padding: 2rem;
  }
`;

const routes = [
  ['/text-styles', 'Text styles', ShowcaseTextStyles],
  ['/headers', 'Headers', ShowcaseHeaders],
  ['/tables', 'Tables', ShowcaseTables],
  ['/buttons', 'Buttons', ShowcaseButtons],
  ['/code', 'Codes', ShowcaseCode],
  ['/modals', 'Modals', ShowcaseModals],
  ['/menus', 'Menus', ShowcaseMenus],
  ['/tooltips', 'Tooltips', ShowcaseTooltips],
];

export const ShowcaseScreen = () => {
  let { path } = useRouteMatch();
  return (
    <Container>
      <div className="sidebar">
        <nav>
          {routes.map(([subpath, label]) => (
            <Link to={`${path}${subpath}`}>{label}</Link>
          ))}
        </nav>
      </div>
      <div className="main-content">
        {routes.map(([subpath, , Component]) => (
          <Route path={`${path}${subpath}`} exact component={Component} />
        ))}
      </div>
    </Container>
  );
};
