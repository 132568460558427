import { types } from 'mobx-state-tree';
import * as safeTypes from '../lib/safe-mst-types';
import { localizedProperty } from '../lib/localization-helper';

export const Credit = types
  .model('Credit', {
    // we probably don't need these two
    role: safeTypes.stringOrNull,
    credit: safeTypes.stringOrNull,

    // v4 catalog support
    name: safeTypes.stringDefaultBlank, // perhaps just keep this as 'credit'
    labelSlug: safeTypes.stringDefaultBlank, // localization key
    labelEn: safeTypes.stringDefaultBlank,
    labelJa: safeTypes.stringDefaultBlank,
    labelEs: safeTypes.stringDefaultBlank,
  })
  .views(self => ({
    get label() {
      return localizedProperty(self, 'label');
    },
  }));
