import React from 'react';
import { observer } from 'mobx-react';
import { useRootStore } from '../../../truth';
import { FullAutoRenew } from './full-auto-renew';
import { FullNoRenew } from './full-no-renew';
import { GroupAccess } from './group-access';

export const MembershipTable = observer(() => {
  const store = useRootStore();
  const membershipState = store?.userManager?.accountData?.membershipState;

  switch (membershipState) {
    case 'trial':
      return null;

    case 'full-auto-renew':
    case 'suspended':
      return <FullAutoRenew />;

    case 'full-no-renew':
      return <FullNoRenew />;

    case 'group-access':
      return <GroupAccess />;

    default:
      break;
  }
});
