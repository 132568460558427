import React from 'react';
import { getColor } from 'lib/theme/utils';
import { useMediaQuery } from 'react-responsive';

import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;

  & > .left {
    flex: 4;

    .cell-header {
      color: ${getColor('gray-4')};
    }
  }

  & > .right {
    flex: 6;
  }
`;

export const MobileHeaderCell = ({ header, children }) => {
  const isMobile = useMediaQuery({
    query: '(max-device-width: 550px)',
  });

  // pass thru for non-mobile devices
  if (!isMobile) {
    return children;
  }

  return (
    <Wrapper>
      <div className="left">
        <span className="cell-header">{header}</span>
      </div>
      <div className="right">{children}</div>
    </Wrapper>
  );
};
