import { getFontName } from '../../lib/theme/utils';
import styled from 'styled-components';

export const AuthFootnote = styled.div`
  font-family: ${getFontName('body')};
  font-size: 2.125rem;
  line-height: 3rem;
  font-weight: 400;
  text-align: center;
  margin-top: 5rem;
  color: #999;
  a {
    color: #19bbd4;
    background-image: linear-gradient(#19bbd4 50%, transparent 0);
    background-repeat: repeat-x;
    background-size: 2px 2px;
    background-position: 0 1.12em;
    cursor: pointer;
  }
`;
