/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { trackEvent } from 'lib/analytics';

const HeaderWrapper = styled.header`
  padding: 12px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  background: #fff;
  border-bottom: 1px solid var(--gray-1);
  margin-bottom: -1px;
  position: sticky;
  z-index: 1000;

  @media print {
    position: static; /* otherwise it renders on random places of the page */
  }

  .link {
    display: flex;
    align-items: center;
    text-decoration: none;
  }
  .logo-suffix {
    font: var(--body-font);
    color: var(--gray-4);
    margin-left: 1px;
  }
  .title {
    font: var(--body-font);
    color: var(--gray-4);
    margin-left: auto;
    padding-left: 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .print {
    margin-left: 20px;
    cursor: pointer;
  }

  @media print {
    padding-top: 0;

    .print {
      display: none;
    }
  }
`;

const LupaLogo = () => {
  return (
    <svg width="54" height="40" fill="none" viewBox="0 0 54 40">
      <path
        fill="#999"
        fillRule="evenodd"
        d="M12.776 33.858a.795.795 0 00.794-.792v-1.155a.795.795 0 00-.794-.792H.794a.795.795 0 00-.794.792v1.155c0 .435.357.792.794.792h11.982zm14.058 0a.795.795 0 00.794-.792v-1.155a.795.795 0 00-.794-.792H16.165a.795.795 0 00-.793.792v1.155c0 .435.357.792.793.792h10.669zm26.5-.792a.795.795 0 01-.794.792h-9.961a.795.795 0 01-.794-.792v-1.155c0-.436.357-.792.794-.792h9.96c.437 0 .794.356.794.792v1.155zm-14.147.792a.795.795 0 00.794-.792v-1.155a.795.795 0 00-.794-.792h-3.955a.795.795 0 00-.794.792v1.155c0 .435.357.792.794.792h3.955z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#19BBD4"
        fillRule="evenodd"
        d="M.005 25.578v2.756H13.51V20.57H9.969v5.007h-4.32V2.756h2.257V0H.005v2.756h2.101v22.822H.005zM22.77 27.13v1.203h4.748v-2.446h-1.635V10.79h-4.436v2.445h1.323v10.519c0 1.436-.7 2.173-2.258 2.173-1.206 0-1.479-.504-1.479-1.32V10.79h-4.748v2.445h1.634V25.19c0 2.329.896 3.416 3.153 3.416 1.79 0 2.919-.621 3.698-1.476zm14.022-12.227c0-1.009-.272-1.707-1.829-1.707-1.284 0-2.14.543-2.14 2.173v8.384c0 1.358.622 2.173 2.14 2.173 1.323 0 1.83-.504 1.83-1.707v-9.316zm-.623 13.702c-1.556 0-2.412-.31-3.346-1.32v10.269h1.634V40h-6.383v-2.445h1.635v-24.32h-1.635V10.79h4.748v1.204c.818-.893 2.064-1.475 3.543-1.475 2.49 0 3.541 1.28 3.541 3.687v10.13c0 2.717-1.129 4.27-3.736 4.27zm10.241-8.462c1.285 0 2.18.505 2.18 1.747v2.173c0 1.436-.817 1.941-2.18 1.941-1.011 0-1.4-.388-1.4-1.397V21.54c0-1.164.622-1.397 1.4-1.397zm2.18 6.986v1.204h4.709v-2.446h-1.635V14.672c0-2.99-1.479-4.153-4.71-4.153-3.346 0-4.786 1.397-4.786 4.153v1.203h3.035v-1.048c0-1.475.701-1.747 1.674-1.747 1.363 0 1.713.66 1.713 1.747v3.726c-.779-.621-1.752-.932-2.997-.932-2.608 0-3.658 1.398-3.658 3.998v3.454c0 2.756 1.245 3.532 2.997 3.532 1.478 0 2.724-.465 3.658-1.475z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

const PrintIcon = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 9H24V5.5C24 5.22386 23.7761 5 23.5 5H8.5C8.22386 5 8 5.22386 8 5.5V9ZM25 9V9.00005H27C28.1046 9.00005 29 9.89548 29 11V21C29 22.1046 28.1046 23 27 23H25V25.5C25 26.3284 24.3284 27 23.5 27H8.5C7.67157 27 7 26.3284 7 25.5V23H5C3.89543 23 3 22.1046 3 21V11C3 9.89548 3.89543 9.00005 5 9.00005H7V9V5.5C7 4.67157 7.67157 4 8.5 4H23.5C24.3284 4 25 4.67157 25 5.5V9ZM24 17V23V25.5C24 25.7761 23.7761 26 23.5 26H8.5C8.22386 26 8 25.7761 8 25.5V23V17H24ZM26 13C26.5523 13 27 12.5523 27 12C27 11.4478 26.5523 11 26 11C25.4477 11 25 11.4478 25 12C25 12.5523 25.4477 13 26 13ZM22 18H10V19H22V18ZM10 20H22V21H10V20ZM22 22H10V23H22V22Z"
        fill="#999999"
      />
    </svg>
  );
};

export const PageHeader = ({ title }) => {
  const { slug } = useParams();
  const handlePrintButton = () => {
    window.print();
    trackEvent('storyscript__print', { story_slug: slug });
  };

  return (
    <HeaderWrapper>
      <a className="link" href="https://www.lupa.app" target="_blank">
        <LupaLogo />
        <span className="logo-suffix">.app</span>
      </a>
      <h1 className="title">{title}</h1>
      <button className="print" onClick={handlePrintButton}>
        <PrintIcon />
      </button>
    </HeaderWrapper>
  );
};
