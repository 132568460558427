import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import __ from 'jw-core/lib/localization';
import { useRootStore } from '../../truth';
import { prevent } from '../../lib/prevent';
import { getFontName, getColor } from '../../lib/theme/utils';
import { IconArrowRight } from 'components/ds/icons';
import { SectionBreak } from 'components/ds/common/spacers';
import { getConfig } from 'config';
import { useCouponPrompt } from './account-hooks';

const CouponIcon = () => (
  <svg width="40" height="36" viewBox="0 0 40 36">
    <g fill="none" fillRule="evenodd">
      <path
        fill="#4DAEBD"
        d="M3 6v1h2V6h2v1h2V6h2v1h2V6h2v1h2V6h2v1h2V6h2v1h2V6h2v1h2V6h2v1h2V6h2v1h2V6h2v1h1v2h-1v2h1v2h-1v2h1v2h-1v2h1v2h-1v2h1v2h-1v2h1v2h-1v1h-2v-1h-2v1h-2v-1h-2v1h-2v-1h-2v1h-2v-1h-2v1h-2v-1h-2v1h-2v-1h-2v1h-2v-1h-2v1H9v-1H7v1H5v-1H3v1H1v-1H0v-2h1v-2H0v-2h1v-2H0v-2h1v-2H0v-2h1v-2H0v-2h1V9H0V7h1V6h2z"
      ></path>
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M23.813 12.672L17.5 24.297h-1.21l6.335-11.625h1.188zm.054 5.74a2.906 2.906 0 110 5.812 2.906 2.906 0 010-5.812zm0 1.664a1.242 1.242 0 100 2.484 1.242 1.242 0 000-2.484zm-7.594-7.345a2.906 2.906 0 110 5.813 2.906 2.906 0 010-5.813zm0 1.664a1.242 1.242 0 100 2.485 1.242 1.242 0 000-2.485z"
      ></path>
    </g>
  </svg>
);

const GiftIcon = () => (
  <svg width="40" height="36" viewBox="0 0 40 36">
    <path
      fill="#F7C70A"
      fillRule="evenodd"
      d="M27.5 18v14.249L20 36l-7.5-3.75V18l7.5 3.75L27.5 18zM2.75 13.125L9.5 16.5v14.25l1.421.71-8.171-4.085v-14.25zm34.5 0v14.25L30.5 30.75V16.5l6.75-3.375zM20 10.5l7.5 3.75v3L20 21l-7.5-3.75v-3L20 10.5zM9.5 5.25L17 9l-7.5 3.75v3L2 12V9l7.5-3.75zm21 0L38 9v3l-7.5 3.75v-3L23 9l7.5-3.75zM20 0l7.5 3.75L20 7.5l-7.5-3.75L20 0z"
    ></path>
  </svg>
);

const EduIcon = () => (
  <svg width="40" height="36" viewBox="0 0 40 36">
    <path
      fill="#626262"
      fillRule="evenodd"
      d="M32 19.527V26c0 3.314-5.373 6-12 6-6.513 0-11.814-2.594-11.995-5.83L8 26v-6.473l12 6.11 12-6.11zM39 16v7l.5 5v1h-2v-1l.5-5v-6.527l.929-.473H39zM20 4l20 10.182-20 10.182L0 14.182 20 4z"
    ></path>
  </svg>
);

const MarketingLinksContainer = styled.div`
  border-top: 1px solid ${getColor('gray-1')};

  & > a {
    display: block;
    color: ${getColor('gray-6')};
    border-bottom: 1px solid ${getColor('gray-1')};
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 14px 0;

    &:hover {
      color: ${getColor('teal')};
    }

    & > span.icon-left {
      margin-right: 12px;
      svg {
        width: 32px;
      }
    }

    & > span.title {
      font-family: ${getFontName('body')};
      font-size: 2rem;
      line-height: 3rem;
      font-weight: 400;
    }

    & > span.icon-right {
      margin-left: auto;
      color: ${getColor('gray-4')};
    }
  }
`;

const MarketingLink = ({
  icon: Icon,
  title,
  testId,
  middle = false,
  ...linkProps
}) => {
  const Component = linkProps.to ? Link : 'a';
  return (
    <Component {...linkProps} data-test-id={testId}>
      <span className="icon-left">
        <Icon />
      </span>
      <span className="title">{title}</span>
      <span className="icon-right">
        <IconArrowRight />
      </span>
    </Component>
  );
};

export const MarketingLinks = observer(() => {
  const store = useRootStore();
  const { userManager } = store;
  const { hasManagedClassrooms } = userManager?.accountData ?? {};

  const [Prompt, promptActions] = useCouponPrompt();

  return (
    <MarketingLinksContainer>
      <MarketingLink
        title={__('Redeem a code', 'account.redeemACode')}
        icon={CouponIcon}
        href="#"
        onClick={prevent(promptActions.on)}
        testId="redeem-code"
      />
      <MarketingLink
        title={__(
          'Purchase and manage gift coupons',
          'account.purchaseAndManageGiftCoupons'
        )}
        icon={GiftIcon}
        to="/gift-coupons"
        middle
        testId="gift-coupons"
      />
      {hasManagedClassrooms ? null : (
        <MarketingLink
          title={__(
            'Teaching Spanish? Create a Lupa Classroom',
            'account.teachingSpanish'
          )}
          href={getConfig('website.urls.classroomMarketingPage')}
          icon={EduIcon}
          testId="teach-spanish"
        />
      )}
      <Prompt />
      <SectionBreak />
    </MarketingLinksContainer>
  );
});
