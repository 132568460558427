import React from 'react';
import styled from 'styled-components';
import { SpinnerIcon } from '../ui/icons/spinner-icon';
import { getColor, getFontName, cond } from '../../lib/theme/utils';

const ButtonContainer = styled.button`
  cursor: pointer;
  font-family: ${getFontName('button')};
  font-size: 2.5rem;
  line-height: 3rem;
  text-align: center;
  display: inline-block;
  background: ${cond('isLoading', getColor('gray2'), getColor('teal'))};
  cursor: ${cond('isLoading', 'default', 'pointer')};
  padding: 1.5rem 2rem;
  border-radius: 0.5rem;
  transition: background-color 0.2s;
  color: ${getColor('white')};
  white-space: nowrap;
  border: none;
  width: 100%;
  position: relative;
`;

const IconContainer = styled.span`
  display: flex;
  position: absolute;
  right: 1.5rem;
  top: 0;
  height: 100%;
  align-items: center;
`;

export const AuthButton = ({ title, isLoading, onClick }) => {
  return (
    <ButtonContainer
      onClick={onClick}
      isLoading={isLoading}
      data-test-id={title}
    >
      {title}
      {isLoading && (
        <IconContainer>
          <SpinnerIcon color={'white'} />
        </IconContainer>
      )}
    </ButtonContainer>
  );
};
