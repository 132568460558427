import styled from 'styled-components';
import { getFontName, getColor, cond } from '../../../lib/theme/utils';

export const Table = styled.table`
  width: 100%;
  table-layout: fixed;
  margin-bottom: -1px;
`;

export const TableRow = styled.tr`
  border-top: 1px solid
    ${cond('noBorder', 'transparent', getColor('blackHairline'))};
  &:last-child {
    border-bottom: ${p => (p.noRule ? '' : '1px solid #e5e5e5')};
  }
`;

export const TableCell = styled.td`
  font-family: ${getFontName('body')};
  font-size: 2.125rem;
  line-height: 3rem;
  font-weight: 400;
  padding: 2.5rem 0;
  vertical-align: top;
  position: relative;
`;

export const TableLabelCell = styled(TableCell)`
  color: ${getColor('gray3')};
  width: 33.33333%;
  padding-right: 1rem;
`;

export const TableValueCell = styled(TableCell)`
  width: 66.66667%;
  color: ${cond('error', '#e55c58', 'inherit')};
`;
