import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import styled from 'styled-components';
import { AuthHeader } from './auth-header';
import { getColor, getFontName } from '../../lib/theme/utils';

const BaseTab = styled.div`
  font-family: ${getFontName('heading')};
  font-size: 3.375rem;
  line-height: 4rem;
  margin: 0 1.5rem;
  color: ${getColor('black')};
  transition: color 0.2s;
  position: relative;
  transition: color 0.2s;
  cursor: default;

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -2rem;
    left: 0;
    right: 0;
    height: 1.25rem;
    background: ${getColor('teal')};
    border-radius: 3px;
    transition: background-color 0.2s;
  }
`;

const LinkTab = styled(BaseTab).attrs({ as: Link })`
  color: ${getColor('gray3')};
  cursor: pointer;

  &:after {
    background: transparent;
  }

  &:hover {
    color: ${getColor('black')};
    &:after {
      background: ${getColor('blackHairline')};
    }
  }
`;

const Tab = ({ to, title }) => {
  const location = useLocation();
  const current = location.pathname === to;

  if (current) {
    return <BaseTab>{title}</BaseTab>;
  }

  return (
    <LinkTab to={to} data-test-id={title}>
      {title}
    </LinkTab>
  );
};

export const AuthTabs = () => {
  return (
    <AuthHeader>
      <Tab to={'/signup'} title={'Create account'} />
      <Tab to={'/login'} title={'Sign in'} />
    </AuthHeader>
  );
};
