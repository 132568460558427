import React from 'react';
import { Chapter } from './story-tree/chapter';

import { groupBy } from 'lodash';

export const StoryScript = ({ story }) => {
  const vocabsByPosition = groupBy(story.vocabs, 'chapterPosition');
  const sicsByPosition = groupBy(story.sics, 'chapterPosition');
  return story.chapters.map(chapter => (
    <Chapter
      chapter={chapter}
      vocabs={vocabsByPosition[chapter.position]}
      sics={sicsByPosition[chapter.position]}
      key={chapter.slug}
    />
  ));
};
