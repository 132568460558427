export const env = {
  appSlug: 'jw',

  forcedApiEnv: 'bolero',

  analytics: {
    segmentWriteKey: 'HdX7LjT2nCW9tMIb1YnWEUeCsf7T8M9G', // spa (bolero prod)
  },

  website: {
    marketingSiteUrl: 'https://www.jiveworld.com',
  },
};
