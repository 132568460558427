import React from 'react';
import { StoryCover } from './story-cover';
import { StoryCast } from './story-cast';
import { StoryCredits } from './story-credits';
import { StoryGuideLicense } from './story-guide-license';
import styled from 'styled-components';

const OverviewWrapper = styled.section`
  border-top: 1px solid var(--gray-1);
  padding: 24px 0 0;
  margin-bottom: 80px;
  margin-top: 0;

  @media print, screen and (min-width: 900px) {
    .credits {
      column-count: 3;
    }
  }
`;

export const StoryOverview = ({ story }) => {
  return (
    <OverviewWrapper>
      <div className="overview">
        <StoryCover story={story} />
        <StoryCast story={story} />
        <StoryCredits story={story} />
        <StoryGuideLicense />
      </div>
    </OverviewWrapper>
  );
};
