import React, { useState, useEffect, useContext } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { getConfig } from 'config';

const stripePromise = loadStripe(getConfig('stripe.publishableKey'));
const StripeContext = React.createContext(stripePromise);

export const StripeProvider = ({ children }) => {
  const [instance, setInstance] = useState(null);

  useEffect(() => {
    stripePromise.then(setInstance);
  }, []);

  if (!instance) {
    return null;
  }
  return (
    <StripeContext.Provider value={instance}>{children}</StripeContext.Provider>
  );
};

export const useStripe = () => useContext(StripeContext);

export const useStripeCheckout = () => {
  const stripe = useStripe();
  return sessionId => {
    return stripe.redirectToCheckout({ sessionId });
  };
};
