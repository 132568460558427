import {
  flow,
  getEnv,
  getParent,
  getRoot,
  getSnapshot,
  types,
} from 'mobx-state-tree';

import minibus from '../lib/minibus';
// import { AssetDownload } from './asset-download';

import { downloadStatus } from './download-constants';

// provide unscoped local access
const {
  NOT_DOWNLOADED,
  PENDING_DOWNLOAD,
  DOWNLOADING,
  DOWNLOAD_FAILED,
  DOWNLOADED,
} = downloadStatus;

/**
 * VolumeDownload
 *
 * tracks the download status of a particular story.
 */
export const VolumeDownload = types
  .model('VolumeDownload', {
    // todo: make this volatile since it won't get preserved anyways
    enqueuedAt: types.maybeNull(types.Date), // the really represents the 'enqueued' at and used as the queue order
  })
  .volatile(() => ({
    // todo: we probably need to add some extra state to track the currently downloade story.
    // right now when additional downloads are queued, it will potentially process then
    // in a unexpected order.
  }))
  .views(self => ({
    get $log() {
      const { createLogger = () => {} } = getEnv(self);
      const log = createLogger('dm:volume-download');
      return log;
    },
    get $downloader() {
      const { downloader = {} } = getEnv(self);
      return downloader;
    },
    get $track() {
      const { track = () => {} } = getEnv(self);
      return track;
    },
  }))
  .views(self => {
    return {
      get status() {
        if (self.someUnitsAreDownloading) {
          return DOWNLOADING;
        }

        if (self.someUnitsFailedToDownload) {
          return DOWNLOAD_FAILED;
        }

        if (self.someUnitsPending) {
          return PENDING_DOWNLOAD;
        }

        if (self.allUnitsAreDownloaded) {
          return DOWNLOADED;
        }
        return NOT_DOWNLOADED;
      },

      get someUnitsFailedToDownload() {
        const volume = getParent(self);
        return volume.units.some(unit => unit.download.isFailed);
      },

      get someUnitsPending() {
        const volume = getParent(self);
        return volume.units.some(unit => unit.download.isQueued);
      },

      get someUnitsAreDownloading() {
        const volume = getParent(self);
        return volume.units.some(unit => unit.download.isDownloading);
      },

      get allUnitsAreDownloaded() {
        const volume = getParent(self);
        return volume.units.every(unit => unit.download.isDownloaded);
      },

      get downloadProgressRatio() {
        const volume = getParent(self);
        const unitCount = volume.units.length;
        const aggregateProgressRatio = volume.units.reduce((acc, unit) => {
          return acc + unit.download.downloadProgressRatio;
        }, 0);
        return aggregateProgressRatio / unitCount;
      },

      get downloadProgressPercentage() {
        return Math.round(self.downloadProgressRatio * 100);
      },

      get dump() {
        return JSON.stringify(getSnapshot(self));
      },

      // private functioned exposed for debugging and actions use
      // fullAssets,
      // audioAssets,
      // hasAudioAssets,
      // someFullAssetStatus,
      // allAudioAssetStatus,
    };
  })
  .actions(self => {
    const manager = getRoot(self).downloadManager;

    const volume = getParent(self);
    // const catalogData = story.catalogData;

    return {
      // maybe need a better name?
      addToDownloadQueue: flow(function* addToDownloadQueue() {
        self.$track('story__queue_download', { story_slug: volume.slug });

        const availableSpaceInMb = yield self.$downloader.getAvailableSpaceInMb(
          manager.debugReducedAvailableSpaceInMb
        );

        if (volume.downloadSizeInMB > availableSpaceInMb) {
          self.$log.error(
            `disk is full - needed: ${volume.downloadSizeInMB}, avail: ${availableSpaceInMb}`
          );
          // we use minibus to trigger the rendering of a react component
          // because we don't have access to navigation from here
          minibus.emit('DISK_IS_FULL');
          return;
        }

        self.enqueuedAt = new Date();
        self.enqueueAllUnits();
        manager.runQueue(); // don't await
      }),

      enqueueAllUnits() {
        const volume = getParent(self);
        self.$log.debug(`queueing volume units: ${volume.slug}`);
        volume.units.forEach(unit => {
          self.$log.debug(`-- queueing unit downloads: ${volume.slug}`);
          unit.download.addToDownloadQueue();
        });
      },

      cancelDownload: flow(function* cancelDownload() {
        self.$track('volume__cancel_download', { story_slug: volume.slug });
        // we need to
        yield self.removeFromDisk();
      }),

      removeFromDisk: flow(function* remove() {
        // todo: try/catch
        self.enqueuedAt = null;

        for (let i = 0; i < volume.units.length; i++) {
          yield volume.units[i].download.removeFromDisk();
        }

        yield getRoot(self).persistAll();
      }),
    };
  })
  .views(self => {
    const is = status => self.status === status;

    return {
      get slug() {
        return getParent(self).slug;
      },

      get isDownloaded() {
        return is(DOWNLOADED);
      },

      get isNotDownloaded() {
        return is(NOT_DOWNLOADED) || is(DOWNLOAD_FAILED);
      },

      get isFailed() {
        return is(DOWNLOAD_FAILED);
      },

      get isDownloading() {
        return is(DOWNLOADING);
      },

      get isQueued() {
        return is(PENDING_DOWNLOAD) || is(DOWNLOADING);
      },
    };
  })
  // aliases for backward compatibility
  .views(self => ({
    get downloaded() {
      return self.isDownloaded;
    },

    get notDownloaded() {
      return self.isNotDownloaded;
    },

    get pending() {
      return self.isQueued;
    },
  }));
