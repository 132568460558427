import React from 'react';
import __ from 'jw-core/lib/localization';
import styled from 'styled-components';
import { StoryTree } from '.';
import { Glossary } from './glossary';

import { ChapterProvider } from './chapter-context';
import { Notes } from './notes';

const Wrapper = styled.div`
  border-top: 1px solid var(--gray-1);
  padding: 24px 0 0;
  margin-bottom: 80px;

  &.position--1 {
    page-break-before: always;
    @media print {
      margin-top: 0;
      border-top: none;
      padding-top: 0;
    }
  }

  .heading {
    font: var(--large-heading-font);
    margin-bottom: 56px;
  }

  .lines {
    background-image: repeating-linear-gradient(
      #fff,
      #fff 40px,
      var(--gray-1) 40px,
      var(--gray-1) 42px
    );
    background-size: 42px 100%;

    margin-bottom: 40px;

    .lines-content {
      background: white;

      @media print, screen and (min-width: 700px) {
        width: calc(50% + 16px);
        padding-right: 16px;
      }
    }
  }
`;

export const Chapter = ({ chapter, vocabs, sics }) => {
  return (
    <Wrapper
      id={`chapter-${chapter.slug}`}
      className={`position--${chapter.position}`}
    >
      <ChapterProvider value={chapter}>
        <h2 className="heading">
          {__('%{position}. %{title}', 'storyGuide.chapterHeading', chapter)}
        </h2>
        <Notes chapter={chapter} />
        <div className="lines">
          <div className="lines-content">
            <StoryTree elements={chapter.elements} />
          </div>
        </div>
        <Glossary vocabs={vocabs} sics={sics} id={chapter.slug} />
      </ChapterProvider>
    </Wrapper>
  );
};
