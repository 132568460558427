import React from 'react';
import styled from 'styled-components';
import { getColor, includeTextStyles } from 'lib/theme/utils';

const CardContainer = styled.div`
  ${includeTextStyles()}
  border:1px solid ${getColor('gray-1')};
  background: ${getColor('white')};
  border-radius: 8px;
  padding: 16px 20px 20px;
`;

export const Card = props => {
  return <CardContainer {...props} />;
};
