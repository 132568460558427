import { action, observable } from 'mobx';

/**
 * TODO: Consider moving this into MST?
 */

class MessageService {
  currentMessage = observable.box(null);

  showMessage = action(message => {
    this.currentMessage.set(message);
  });

  dismissMessage = action(() => {
    this.currentMessage.set(null);
  });
}

const singleton = new MessageService();

if (process.env.NODE_ENV === 'development') {
  window.MessageService = singleton;
}

export default singleton;
