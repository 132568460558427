import { useEffect } from 'react';
import { getBus } from '@thumbjive/minibus';

export const useMinibusSubscription = (subName, cb, busName = 'global') => {
  useEffect(() => {
    const bus = getBus(busName);
    const sub = bus.subscribe(subName, cb);
    return () => {
      sub.unsubscribe();
    };
  }, [busName, cb, subName]);
};
