import React from 'react';
import { typeMap } from './type-map';

export const StoryTree = ({ elements = [], overrideType = null, ...props }) =>
  elements.map((element, i) => {
    const Component = typeMap[element.objectClass];
    if (Component) {
      return (
        <Component
          root={element}
          key={`${element.entityType}-${i}`}
          {...props}
        />
      );
    }

    return null;
  });
