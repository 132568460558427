import React from 'react';
import { Paragraph } from './paragraph';
import { useChapterContext } from './chapter-context';

import styled from 'styled-components';

const Wrapper = styled.div`
  padding-bottom: 32px;
`;

export const Passage = ({ root: passage }) => {
  const { elements, address } = passage;
  const chapter = useChapterContext();
  const id = chapter.position + '--' + address.replace('.', '-');
  return (
    <Wrapper id={id}>
      {elements
        .filter(el => el.entityType === 'paragraph')
        .map((paragraph, i) => {
          const hint = i === 0 ? passage.hint : null;
          return (
            <Paragraph paragraph={paragraph} hint={hint} key={`passage-${i}`} />
          );
        })}
    </Wrapper>
  );
};
