import React from 'react';
import { Alert } from './alert';
import { useSwitch } from 'lib/hooks/use-switch';

export const useAlert = hookProps => {
  const [showingPrompt, { on, off }] = useSwitch(false);

  const Component = props => {
    if (!showingPrompt) {
      return null;
    }
    return <Alert action={off} {...hookProps} {...props} />;
  };

  return [Component, { on, off }];
};
