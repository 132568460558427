import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Helmet } from 'react-helmet';

const GlobalGuideStyles = createGlobalStyle`
 /* VARS */

  :root {
    --gray-6: #444;
    --gray-5: #626262;
    --gray-4: #999;
    --gray-3: #BBBBBB;
    --gray-2: #D0D0D0;
    --gray-1: #e5e5e5;
    --dark-blue: #4c7faa;
    --green: #4ed152;
    --red: #ff6767;
    --pink: #ffaeae;
    --light-orange: #ffa000;
    --lighter-orange: rgba(255,160,0,.12);
    --very-light-yellow: #fff2cd;
    --light-green: #edfaf0;
    --large-heading-font: bold 37px/48px arial;
    --medium-heading-font: bold 23px/28px arial;
    --small-heading-font: 700 16px/24px roboto;
    --body-font: 400 17px/24px roboto;
    --small-text-font: 400 15px/20px roboto;
    --tiny-text-font: 400 12px/20px roboto;
    --footer-text-font: 400 14px/20px roboto;
    --compact-script-font: 17px/24px 'Source Serif Pro';
  }

  /* RESETS */

  * {
    padding: 0;
    margin: 0;
    border: none;
    background: transparent;
    color: var(--gray-6);
    box-sizing: border-box;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  td {
    vertical-align: top;
  }
  img {
    display: block;
    max-width: 100%;
  }

  /* TELL CHROME TO PRINT BG COLORS */

  body {
    -webkit-print-color-adjust: exact;
    printer-colors: exact;
    color-adjust: exact;
  }

  #root{
    background-color: white;
  }
`;

const PageLayoutWrapper = styled.div`
  width: 100%;
  max-width: 1200px;
  padding: 0 20px;
  margin: 0 auto;

  @media print, screen and (min-width: 700px) {
    padding: 0 32px;
  }
  @media print {
    width: 900px;
    padding: 0;
  }
`;

export const PageLayout = props => {
  return (
    <>
      <GlobalGuideStyles />
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400&family=Source+Serif+Pro:ital,wght@0,400;0,600;1,400;1,600&display=swap"
          rel="stylesheet"
        ></link>
      </Helmet>
      <PageLayoutWrapper {...props} />
    </>
  );
};
