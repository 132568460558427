import React from 'react';
import { observer } from 'mobx-react';
import { useRootStore } from '../../truth';
import { FullScreenLoader } from '../ds/modals';

export const LoadingScreen = observer(() => {
  const store = useRootStore();

  if (!store.loadingData) {
    return null;
  }

  return <FullScreenLoader />;
});
