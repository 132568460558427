import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useRootStore } from 'mst';
import createLogger from 'lib/log';

export const useClassroomRedirectionEffect = () => {
  const store = useRootStore();
  const { loggedInAndReady, onboardNewTeacher } = store?.userManager;
  const { hasManagedClassrooms } = store?.userManager?.accountData ?? {};

  const history = useHistory();

  return useEffect(() => {
    const log = createLogger('classroom/redirect-hook');

    if (!loggedInAndReady) {
      log.debug('User is logged out. Will redirecttrough auth process');
      history.replace('/signup?after=/classrooms');
      return;
    }

    if (hasManagedClassrooms) {
      log.debug('Has managed classrooms. Will not redirect');
      return;
    } else {
      onboardNewTeacher().then(newClassroom => {
        history.push(`/classrooms/${newClassroom.id}`);
      });
    }
  }, [onboardNewTeacher, loggedInAndReady, hasManagedClassrooms, history]);
};
