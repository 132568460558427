import { getRoot, getEnv } from 'mobx-state-tree';
import {
  ValidationError,
  isDiskSpaceError,
  NetworkError,
  fail,
  alertLevels,
} from './errors';

const { ERROR, WARN } = alertLevels;

// 'unexpectedAlertLevel' is applied to non validation or network errors before sending along to the main error handler
export const safelyHandleError = (
  branch,
  error,
  { ignoreNetworkErrors = false, unexpectedAlertLevel = WARN }
) => {
  const root = getRoot(branch);
  // const { createLogger = () => {}, notifications = {} } = getEnv(root);
  // const { alertError = () => {} } = notifications;
  const { createLogger = () => {} } = getEnv(root);

  const log = createLogger('mst-error-handler');

  // wrap a thrown string (i.e. 'crash3' coupon code test)
  if (!(error instanceof Error)) {
    error = new Error(error);
  }

  if (isDiskSpaceError(error)) {
    // todo: cleanup redundancy with errors.js handling
    log.info('Disk error');
    error.alertLevel = ERROR;
    // not really our fault, but would be curious to see stats
    error.report = true;
    error.expected = true;

    // fail(error); // let react handle it
  }

  if (error instanceof ValidationError) {
    if (root) {
      root.setValidationError(error);
      return;
    } else {
      log.error(`unexpectedly missing root with error: ${error}`);
      // error.alertLevel = ERROR; // validation errors are always intended to be end-user seen
      // error.expected = true; // not really expected, but not need to prefix the message
      // error.report = true;
      // fail(error); // let react handle it
    }
  }

  if (error.key === 'account_closed') {
    // alertError(error.message);
    error.alertLevel = ERROR; // force to be visible
    error.expected = true;
    if (root) {
      root.userManager.reset();
    } else {
      // not sure what to do in this edge case.
    }
    error.report = false;
    // return;
  }

  // todo: will this string match fail on non-english locale devices? is it even needed?
  if (
    !(error instanceof NetworkError) &&
    error.message?.includes('Network request failed')
  ) {
    log.warn(`wrapping string matched network error: ${error}`);
    error = new NetworkError(error);
  }

  if (error instanceof NetworkError) {
    if (ignoreNetworkErrors) {
      log.info(`network error ignored`);
      return;
    } else {
      error.report = false;
      error.alertLevel = ERROR;
      error.expected = true;
      error.userMessage = 'Network request failed'; // todo: localize
    }
  }

  if (error.expected !== true) {
    error.userMessage = `Unexpected error: ${error.message}`; // todo: localize
    error.alertLevel = unexpectedAlertLevel;
    error.report = true;
  }

  // const message =
  //   error.message && error.message.includes('Network request failed')
  //     ? 'Network request failed'
  //     : `Unexpected error: ${error.message}`;

  // alertError(message, {
  //   exception: error,
  //   source: 'genericallyHandleError',
  //   handlerTag: 'generically-alerted-error',
  // });

  fail(error); // fall through to global handler
};
