import React from 'react';
import { Button } from 'components/ds/buttons';
import { IconRetry } from 'components/ds/icons';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 16px;
`;

export const NetworkErrorScreen = () => (
  <section className="error-404">
    <div className="error-404__content">
      <h1 className="error-404__heading">Oops. Something went wrong.</h1>
      <p className="error-404__body">
        It seems we're unable to reach our servers for some unknown reason.
        Please wait a few seconds and refresh. If the problem persists, please
        contact us.
      </p>
      <Wrapper>
        <Button
          icon={IconRetry}
          onClick={() => {
            window.location.reload();
          }}
          label={'Reload page'}
        />
      </Wrapper>
    </div>
  </section>
);
