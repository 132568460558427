/**
 * WHAT IS THIS FILE?
 *
 * this is a non-rendering component that handles the response from the checkout API.
 *
 *
 */
import { useEffect } from 'react';
import { observer } from 'mobx-react';
import __ from 'jw-core/lib/localization';
import { useRootStore } from '../../truth';
import DialogService from '../../lib/services/dialog-service';
import { useStripeCheckout } from '../../lib/stripe';
import { objectFromUrlQuery } from '../../lib/object-from-url-query';

const createDialogCreator = reset => {
  const dismiss = () => {
    reset();
    DialogService.closeDialog();
  };
  const dismissAnd = fn => () => {
    if (fn() !== false) {
      dismiss();
    }
  };
  return ({
    body,
    cancelButtonLabel = 'Cancel',
    okButtonAction = () => {},
    okButtonLabel = 'OK',
    showCancelButton = true,
  }) => {
    DialogService.openDialog({
      body,
      okButtonLabel,
      okButtonAction: dismissAnd(okButtonAction),
      cancelButtonLabel,
      cancelButtonAction: dismiss,
      showCancelButton,
    });
  };
};

export const CheckoutResultTarget = observer(() => {
  // access to the global store
  const store = useRootStore();
  const { userManager } = store;

  const { checkoutResult, resetCheckoutResult, accountData } = userManager;
  const createDialog = createDialogCreator(resetCheckoutResult);
  const stripeCheckout = useStripeCheckout();

  // this is for stripe auto redirect
  // if there's a checkout_token key with a valid stripe key we will
  // autoredirect to stripe.
  useEffect(() => {
    const { checkoutToken = null } = objectFromUrlQuery();
    if (checkoutToken) {
      stripeCheckout(checkoutToken)
        .then(result => {
          // eslint-disable-next-line no-console
          console.log('RESULT', result);
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.log('ERR', err);
        });
    }
  }, [stripeCheckout]);

  useEffect(() => {
    if (!checkoutResult) {
      // DialogService.closeDialog();
      return;
    }

    const {
      interstitialMessageKey,
      stripeSessionId,
      ...params
    } = checkoutResult;

    /**
     * utility function to redirect to stripe checkout
     */
    const checkout = () =>
      stripeCheckout(stripeSessionId)
        .then(result => {
          // eslint-disable-next-line no-console
          console.log('RESULT', result);
        })
        .catch(err => {
          // eslint-disable-next-line no-console
          console.log('ERR', err);
        });

    /**
     * This is the success condition.
     * We will redirect to stripe checkout without user input.
     */
    if (interstitialMessageKey === 'none') {
      checkout();
      return;
    }

    switch (interstitialMessageKey) {
      case 'new-subscription-carry-fwd-days':
        return createDialog({
          body: __(
            'You have %{fullAccessDays} days left of membership on your account. These will be reflected as "free days" on the next screen, and your card won\'t be charged until after this period.',
            'dialogs.newSubscriptionCarryFwdDays',
            params
          ),
          okButtonAction: checkout,
          okButtonLabel: 'Continue',
        });

      case 'purchase-while-subscribed':
        return createDialog({
          body: __(
            'Making this purchase will cancel your monthly subscription. The %{fullAccessDays} day(s) remaining on the current billing cycle will be added to the time you are purchasing.',
            'dialogs.purchaseWhileSubscribed',
            params
          ),
          okButtonAction: checkout,
          cancelButtonLabel: 'Go back',
        });

      case 'purchase-with-unused-days':
        return createDialog({
          body: __(
            'You still have %{fullAccessDays} day(s) of membership on this account. After purchasing you will have %{fullAccessDaysAfterPurchase} days of membership.',
            'dialogs.purchaseWithUnusedDays',
            params
          ),
          okButtonAction: checkout,
          okButtonLabel: 'Purchase',
        });

      // case 'cancel-auto-renew':
      //   return createDialog({
      //     body: __(
      //       "By canceling your subscription you won't receive any further charges, and your membership to Lupa will end on %{date}",
      //       'dialogs.cancelAutoRenew',
      //       {
      //         date: formatDate(accountData.fullAccessUntil),
      //       }
      //     ),
      //     cancelButtonLabel: 'Go back',
      //     okButtonLabel: 'Cancel Subscription',
      //     okButtonAction: userManager.cancelAutoRenew, // TODO: test this flow
      //   });

      // case 'redeem-while-subscribed':
      //   return createDialog({
      //     body: __(
      //       'Currently we cannot redeem a promo code for an account with an active subscription. You would have to first "cancel auto-renew", apply the promo code, and then (if you wish) re-subscribe.',
      //       'dialogs.redeemWhileSubscribed'
      //     ),
      //     showCancelButton: false,
      //     okButtonLabel: 'Cancel Subscription',
      //     okButtonAction: () => {}, // do nothing
      //   });

      case 'replace-subscription-carry-fwd-days':
        return createDialog({
          body: __(
            'Heads up! Updating your credit card will seem like you are starting a new subscription. \n Don\'t worry, you will not be charged twice, and your access and billing date will remain the same. On the next screen you will see "%{fullAccessDays} free days" — this reflects the time left of your current billing cycle. \nWe are working with our payments processor to make this less confusing.',
            'dialogs.replaceSubscriptionCarryFwdDays',
            params
          ),
          okButtonAction: checkout,
          okButtonLabel: 'Update Credit Card',
        });

      case 'new-subscription-too-many-days':
        return createDialog({
          body: __(
            "You have over two years (%{fullAccessDays} days) left of membership on your account. Since we cannot credit more than two years when subscribing, please come back when you're closer to the expiry date.\n\nIf you want to support our efforts further, please consider making a donation at radioambulante.org.",
            'dialogs.newSubscriptionTooManyDays',
            params
          ),
          showCancelButton: false,
          okButtonAction: () => {}, // do nothing
        });

      default:
        break;
    }
  }, [
    checkoutResult,
    createDialog,
    accountData.fullAccessUntil,
    userManager.cancelAutoRenew,
    stripeCheckout,
  ]);

  return null;
});
