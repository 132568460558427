import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useForm } from 'react-hook-form';
import { useRootStore } from '../../truth';
import { useHistory } from 'react-router-dom';
import { useSwitch } from '../../lib/hooks/use-switch';
import { AuthContainer } from '../auth/auth-container';
import { AuthFootnote } from '../auth/auth-footnote';
import { AuthHeader } from '../auth/auth-header';
import { AuthHeading } from '../auth/auth-heading';
import { AuthButton } from '../auth/auth-button';
import { AuthInput } from '../auth/auth-input';
import { useTitle } from '../../lib/hooks/use-title';
import { useAfterAuthRedirection } from './use-after-auth-redirection';

import __ from 'jw-core/lib/localization';
import styled from 'styled-components';

const ButtonContainer = styled.div`
  margin-top: 24px;
`;

export const LoginWithEmailScreen = observer(() => {
  useTitle('Sign in with email');
  useAfterAuthRedirection();

  const history = useHistory();
  const [loading, loadingSwitch] = useSwitch(false);
  const store = useRootStore();
  const { userManager, validationErrorForKey } = store;

  // clean previous messages
  useEffect(() => store.clearMessages(), [store]);

  const { handleSubmit, register } = useForm();

  const submitHandler = handleSubmit(({ email, password }) => {
    loadingSwitch.on();
    userManager.login(email, password).then(() => {
      loadingSwitch.off();
    });
  });

  const push = path => () => history.push(path);

  return (
    <AuthContainer>
      <AuthHeader showBackButton onBackButtonClick={push('/login')}>
        <AuthHeading>Sign in with email</AuthHeading>
      </AuthHeader>
      <form className="form" onSubmit={submitHandler}>
        <AuthInput
          ref={register}
          name={'email'}
          autoCapitalize="none"
          label={__('Email address', 'auth.emailAddress')}
          error={validationErrorForKey('email')}
          autocomplete={'off'}
          data-test-id="auth-email"
        />
        <AuthInput
          ref={register}
          name="password"
          type="password"
          autoCapitalize="none"
          error={validationErrorForKey('password')}
          label={__('Password', 'auth.password')}
          data-test-id="auth-password"
        />
        <ButtonContainer>
          <AuthButton
            isLoading={loading}
            title={__('Sign in', 'auth.signIn')}
            onClick={submitHandler}
          />
        </ButtonContainer>
      </form>
      <AuthFootnote>
        <Link to="/reset-password" data-test-id="forgot-password">
          {__('Forgot password?', 'auth.forgotPassword')}
        </Link>
      </AuthFootnote>
    </AuthContainer>
  );
});
