import React from 'react';
export const IconDownloadS = ({ color = 'currentColor', ...props }) => (
  <svg
    height="20"
    width="20"
    fill="none"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect height="11" width="6" fill={color} rx="1" x="7" y="2" />
    <rect height="1" width="12" fill={color} x="4" y="17" />
    <path
      d="M16.2929 9H3.70711C3.26165 9 3.03857 9.53857 3.35355 9.85355L9.64645 16.1464C9.84171 16.3417 10.1583 16.3417 10.3536 16.1464L16.6464 9.85355C16.9614 9.53857 16.7383 9 16.2929 9Z"
      fill={color}
    />
  </svg>
);
