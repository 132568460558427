import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useRootStore } from 'mst';
import { Flash } from 'components/ds/flash';
import { Gap } from 'components/ds/common/spacers';

import __ from 'jw-core/lib/localization';

const getFlashMessage = flash => {
  if (!flash) {
    return null;
  }

  const { messageKey, messageParams } = flash;

  switch (messageKey) {
    case 'joined-classroom':
      return __(
        'You have joined "%{classroomLabel}"',
        'account.flash.joinedClassroom',
        messageParams
      );
    case 'api.classrooms.dropped':
      // @joseph We may need better copy
      return __('You have left the class', 'account.flash.leftClassroom');
    default:
      break;
  }

  return null;
};

export const AccountFlash = observer(() => {
  const [message, setMessage] = useState(null);

  const store = useRootStore();

  // useEffect(() => {
  //   store.setFlash({
  //     messageKey: 'joined-classroom',
  //     messageFullKey: 'api.applyCode.joinedClassroom',
  //     messageParams: { classroomLabel: 'Advanced Spanish' },
  //   });
  // }, []);

  useEffect(() => {
    setMessage(getFlashMessage(store.flash));
  }, [store.flash]);

  if (!message) {
    return null;
  }

  return (
    <>
      <Flash message={message} onDismiss={store.resetFlash} />
      <Gap size={3} />
    </>
  );
});
