import React from 'react';
import { Dialog } from './dialog';

export const Alert = ({ title, text, action, ...props }) => {
  return (
    <Dialog
      title={title}
      body={text}
      okButtonAction={action}
      showCancelButton={false}
      {...props}
    />
  );
};
