import React, { useRef, useState, useEffect } from 'react';
import { Button } from '../buttons';
import styled from 'styled-components';
import { includeTextStyles, getColor } from 'lib/theme/utils';
import { useCopyPowers } from 'lib/hooks/use-copy-powers';
import __ from 'jw-core/lib/localization';

const CodeText = styled.span`
  ${includeTextStyles('code')};
  color: ${getColor('gray-6')};
`;

const CopyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  .code-highlight {
    ${includeTextStyles('coupon-code')};
    padding: 4px 8px;
    color: ${getColor('black')};
    background: ${getColor('very-light-yellow')};
    margin-right: 8px;
    cursor: default;
    white-space: nowrap;
  }
  input {
    position: absolute;
    left: 0;
    top: 0;
    border: none;
    padding: none;
    opacity: 0;
  }
  button {
    display: block;
    .button-label {
      text-align: center;
    }
  }
`;

export const Code = ({ code }) => {
  return <CodeText data-test-id="code-label">{code}</CodeText>;
};

/**
 * Buttons have automatic widths by default, which is fine in most cases.
 * Here, we change the text from 'Copy code' to 'Copied!' wich changes the
 * button width and causes the layout to shift.
 *
 * This prevents that.
 *
 * No sweet interaction ever comes for free.
 */
const useFreezeWidth = () => {
  const ref = useRef();
  const [btnWidth, setBtnWidth] = useState('auto');
  useEffect(() => {
    setBtnWidth(ref.current.offsetWidth + 2); // compensate for the borders
  }, []);
  const style = { width: btnWidth };

  return { ref, style };
};

export const CopyCode = ({
  code,
  label = __('Copy code', 'common.copyCode'),
  activeLabel = __('Copied!', 'common.copiedCode'),
}) => {
  const { ref: inputRef, active, copyAction } = useCopyPowers();
  const { ref: btnRef, style } = useFreezeWidth();

  return (
    <CopyWrapper>
      <input type="text" value={code} ref={inputRef} readOnly />
      <span type={'text'} className="code-highlight">
        {code}
      </span>
      <Button
        ref={btnRef}
        presentation="primary-outline"
        type="square"
        size="small"
        label={active ? activeLabel : label}
        onClick={copyAction}
        style={style}
        data-test-id="copy-code"
      />
    </CopyWrapper>
  );
};
