import React from 'react';
import { isFunction } from 'lodash';
import styled from 'styled-components';
import { getColor, getFontName, cond } from '../../lib/theme/utils';
import { prevent } from '../../lib/prevent';
import { useKeyboardBinding } from '../../lib/hooks/use-keyboard-binding';
import { SuccessIcon } from '../../components/ui/icons/success-icon';
import { InfoIcon } from '../../components/ui/icons/info-icon';

const AlertContainer = styled.div`
  background: ${getColor('offOffWhite')};
  border-radius: 1rem;
  min-width: 380px;
  max-width: 400px;
  margin-left: 2rem;
  margin-right: 2rem;
  padding: 2rem 2rem 0;
  position: relative;
`;

const AlertTitle = styled.div`
  display: flex;
  align-items: center;
  font-family: ${cond(
    'smallTitle',
    getFontName('body'),
    getFontName('dialogTitle')
  )};
  font-size: ${cond('smallTitle', '2.125rem', '2.5rem')};
  line-height: 3rem;
  margin-bottom: 2rem;
  font-weight: ${cond('smallTitle', '300', '700')};
`;

const AlertBody = styled.div`
  font-family: ${getFontName('body')};
  font-size: 2.125rem;
  line-height: 3rem;
  font-weight: 400;
  margin-bottom: 2rem;
`;

const ButtonsContainer = styled.div`
  flex-flow: row-reverse;
  display: flex;
  justify-content: ${cond('hasCancelButton', 'space-between', 'center')};
  border-top: 1px solid ${getColor('blackHairline')};
`;

const DialogButton = styled.a`
  display: flex;
  flex: 1;
  font-family: ${getFontName('body')};
  font-size: 2.125rem;
  line-height: 3rem;
  font-weight: 400;
  display: inline-block;
  padding: 2rem 0;
  transition: color 0.1s;
  margin: 0 auto;
`;

const OkButton = styled(DialogButton)`
  color: ${cond('danger', getColor('red'), getColor('teal'))};
  text-align: ${cond('hasCancelButton', 'right', 'center')};
`;

const CancelButton = styled(DialogButton)`
  color: ${getColor('gray3')};
`;

const IconContainer = styled.div`
  margin-right: 8px;
`;

export const AlertDialog = React.forwardRef(
  (
    {
      title = null,
      body,
      onDismiss = () => {},
      okButtonAction,
      okButtonLabel = 'OK',
      okIsDangerous = false,
      cancelButtonAction = () => {},
      cancelButtonLabel = 'Cancel',
      showCancelButton = false,
      showIcon = true,
      iconType = 'success',
    },
    ref
  ) => {
    const bodyContent = isFunction(body) ? body() : body;

    const handleOkButton = () => {
      if (isFunction(okButtonAction)) {
        if (okButtonAction() === false) {
          return;
        }
      }

      onDismiss();
    };

    // close with enter
    useKeyboardBinding('Enter', handleOkButton);

    const Icon = iconType === 'warning' ? InfoIcon : SuccessIcon;

    return (
      <AlertContainer ref={ref}>
        {title ? (
          <AlertTitle>
            {showIcon ? (
              <IconContainer>
                <Icon />
              </IconContainer>
            ) : null}
            {title}
          </AlertTitle>
        ) : null}
        <AlertBody>{bodyContent}</AlertBody>
        <ButtonsContainer hasCancelButton={showCancelButton}>
          <OkButton
            href={'#'}
            onClick={prevent(handleOkButton)}
            hasCancelButton={showCancelButton}
            danger={okIsDangerous}
            data-test-id="okButton"
          >
            {okButtonLabel}
          </OkButton>
          {showCancelButton ? (
            <CancelButton
              href={'#'}
              onClick={prevent(cancelButtonAction)}
              data-test-id="cancelButton"
            >
              {cancelButtonLabel}
            </CancelButton>
          ) : null}
        </ButtonsContainer>
      </AlertContainer>
    );
  }
);
