import React from 'react';
import { getColor, includeTextStyles } from 'lib/theme/utils';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: absolute;
  top: -20px;
  right: -8px;
  ${includeTextStyles('small-text-bold')};
  background: ${getColor('yellow')};
  color: rgba(0, 0, 0, 0.5) !important;
  padding: 8px 12px;

  svg.point {
    width: 8px;
    height: 100%;
    position: absolute;
    left: -7px;
    top: 0;
    path {
      fill: ${getColor('yellow')};
    }
  }

  svg.fold {
    position: absolute;
    right: 0;
    bottom: -8px;
    path {
      fill: ${getColor('very-dark-yellow')};
    }
  }
`;

export const CardRibbon = ({ ribbonTitle }) => {
  return (
    <Wrapper className="ribbon" data-test-id="discount-ribbon">
      <svg viewBox="0 0 8 40" fill="none" className="point">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 0H8V40H0L8 21L0 0Z"
        />
      </svg>
      {ribbonTitle}
      <svg width="8" height="8" viewBox="0 0 8 8" fill="none" className="fold">
        <path d="M0 0H8L0 8V0Z" />
      </svg>
    </Wrapper>
  );
};
