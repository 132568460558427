import { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { useRootStore } from '../truth';

export const EnsureAuth = observer(({ children }) => {
  const history = useHistory();
  const store = useRootStore();
  const userManager = store?.userManager ?? {};
  const userIsLoggedIn = store?.userManager?.loggedInAndReady;

  useEffect(() => {
    if (!userIsLoggedIn) {
      history.push('/signup');
    } else {
      if (userManager?.isAnonymousUser) {
        userManager.logout().then(() => {
          window.location = 'https://www.lupa.app';
        });
      }
    }
  }, [history, userManager, userIsLoggedIn]);

  if (!userIsLoggedIn) {
    return null;
  }

  if (userManager?.isAnonymousUser !== false) {
    return null;
  }

  return children;
});
