/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useForm } from 'react-hook-form';
import { useRootStore } from '../../truth';
import { useHistory, Link } from 'react-router-dom';
import { useSwitch } from '../../lib/hooks/use-switch';
import { AuthContainer } from '../auth/auth-container';
import { AuthHeader } from '../auth/auth-header';
import { AuthHeading } from '../auth/auth-heading';
import { AuthButton } from '../auth/auth-button';
import { AuthInput, AuthError } from '../auth/auth-input';
import { useTitle } from '../../lib/hooks/use-title';
import { objectFromUrlQuery } from '../../lib/object-from-url-query';
import { getConfig } from 'config';

import __ from 'jw-core/lib/localization';
import styled from 'styled-components';

const jwMode = getConfig('appSlug') === 'jw';

const ButtonContainer = styled.div`
  margin-top: 24px;
`;

export const ResetSuccessMessage = () => {
  return (
    <div className="auth">
      <div className="auth_content">
        <div className="message loading">
          <h2 className="message__heading">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              className="message__heading__icon"
            >
              <g fill="none">
                <circle cx="16" cy="16" r="16" fill="#69C00D"></circle>
                <path
                  stroke="#FFF"
                  strokeWidth="3"
                  d="M9 17.765L14.444 22 23 10"
                ></path>
              </g>
            </svg>
            {__('Password reset', 'auth.passwordReset')}
          </h2>{' '}
          <p className="message__body">
            {__(
              'Your password has been set. Try logging in on the mobile app.',
              'auth.passwordBeenReset'
            )}
          </p>
          {jwMode ? null : (
            <p className="message__body">
              <Link to="/login">
                {__('Go to my account.', 'auth.goToMyAccount')}
              </Link>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export const CreatePasswordScreen = observer(() => {
  useTitle('Create password');
  const history = useHistory();
  const [loading, loadingSwitch] = useSwitch(false);
  const store = useRootStore();

  const { userManager, validationErrorForKey } = store;
  const { apiResult } = userManager;

  // clean error messages from other screens
  useEffect(() => store.clearMessages(), [store]);

  useEffect(() => {
    if (userManager.loggedInAndReady) {
      history.push('account');
    }
  }, [userManager.loggedInAndReady, history]);

  const { handleSubmit, register } = useForm();

  const submitHandler = handleSubmit(({ newPassword }) => {
    loadingSwitch.on();
    const { resetPasswordToken } = objectFromUrlQuery();
    userManager
      .resetPasswordByToken(resetPasswordToken, newPassword)
      .then(() => {
        loadingSwitch.off();
      });
  });

  const push = path => () => history.push(path);

  // technically this could be the apiResult for something else
  // @joseph maybe it should be an object with keys instead?
  // like `resultForKey('sendPasswordReset')` ?
  if (apiResult?.success) {
    return <ResetSuccessMessage />;
  }

  return (
    <AuthContainer>
      <AuthHeader
        showBackButton={!jwMode}
        onBackButtonClick={push('/login-with-email')}
      >
        <AuthHeading>
          {__('Create a new password', 'common.createNewPassword')}
        </AuthHeading>
      </AuthHeader>
      <form className="form" onSubmit={submitHandler}>
        <AuthInput
          ref={register}
          type="password"
          name={'newPassword'}
          autoCapitalize="none"
          label={__('New password', 'auth.newPassword')}
          error={validationErrorForKey('new-password')}
          autocomplete={'off'}
        />
        <AuthError error={validationErrorForKey('reset_password_token')} />
        <ButtonContainer>
          <AuthButton
            isLoading={loading}
            title={__('Create password', 'common.createPassword')}
            onClick={submitHandler}
          />
        </ButtonContainer>
      </form>
    </AuthContainer>
  );
});
