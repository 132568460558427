import React from 'react';
import { getColor, getFontName, includeTextStyles } from 'lib/theme/utils';
import cx from 'classnames';
import styled from 'styled-components';
import { IconCheckmark } from 'components/ds/icons';
import { CardRibbon } from 'components/card-ribbon';
import __ from 'jw-core/lib/localization';

const PricingCardContainer = styled.div`
  --text-color: white;
  --cta-text-color: white;
  --background-color: ${getColor('gray')};
  --cta-background-color: ${getColor('gray-6')};

  position: relative;
  background: var(--background-color);
  border-radius: 8px;
  margin-bottom: 2rem;
  padding: 16px;
  padding-top: 48px;
  text-align: center;
  display: -ms-flexbox;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  flex-shrink: 0;
  flex-grow: 0;
  color: var(--text-color);

  @media screen and (min-width: 400px) {
    width: 264px;
  }
  @media screen and (min-width: 576px) {
    flex-basis: 264px;
  }
  /* @media screen and (max-width: 855px) {
    margin-bottom: 32px;
  }

  @media screen and (min-width: 550px) and (max-width: 855px) {
    &:nth-child(1) {
      margin-left: calc(50% - 132px);
      margin-bottom: 32px;
    }
  }

  @media screen and (min-width: 480px) {
    width: 40rem;
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (min-width: 550px) {
    margin: 0 1rem;
  } */

  &.type-coupon {
    --background-color: white;
    --text-color: ${getColor('gray-6')};
    --cta-background-color: ${getColor('teal')};
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.2);
  }

  &.type-one-year {
    --cta-background-color: ${getColor('dark-soft-green')};
    --background-color: ${getColor('softGreen')};
  }

  &.type-monthly {
    --cta-background-color: ${getColor('darker-teal')};
    --background-color: ${getColor('darkTeal')};
  }

  &.type-free-trial {
    --cta-background-color: ${getColor('gray-6')};
    --background-color: ${getColor('darker-blue')};
  }

  &.has-footnote {
    margin-bottom: 72px;
  }

  & > .top-part {
    height: 152px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    margin-bottom: 20px;

    & .title {
      ${includeTextStyles('pricing-card-heading')};
      color: var(--text-color);
    }

    & .price-container {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 4px 0;
      & > .price {
        ${includeTextStyles('pricing-card-price')};
        position: relative;
        color: var(--text-color);

        .currency-symbol {
          font-family: ${getFontName('headingMedium')};
          font-size: 27px;
          line-height: 32px;
          position: relative;
          bottom: 7px;
          margin-right: 2px;
          font-weight: 400;
        }

        .cents {
          font-size: 27px;
          line-height: 32px;
        }

        .interval {
          font-family: ${getFontName('headingMedium')};
          font-size: 27px;
          line-height: 32px;
          opacity: 0.5;
        }
      }
    }

    & > .pricing-description {
      ${includeTextStyles('body')};
      color: var(--text-color);

      .original {
        margin-right: 6px;
        text-decoration: line-through;
      }
    }
  }
  &.type-coupon > .top-part {
    border-color: ${getColor('gray-1')};
  }

  & > .features {
    margin-top: auto;
    .feature {
      ${includeTextStyles('body')};
      color: var(--text-color);

      &.secondary {
        opacity: 0.6;
      }
    }
  }

  & > .pricing-cta {
    ${includeTextStyles('pricing-card-cta')};
    border: none;
    border-radius: 8px;
    padding: 12px 16px;
    margin-top: 24px;
    cursor: pointer;
    transition: background-color 0.2s;
    color: var(--cta-text-color);
    white-space: nowrap;
    background: var(--cta-background-color);

    &:hover {
      opacity: 0.85;
    }
  }

  & > .footnote {
    position: absolute;
    bottom: -56px;
    left: 0;
    width: 100%;
    ${includeTextStyles('small-text')};
    color: ${getColor('gray-4')};
  }
`;

const round = n => Math.floor(n);

const cents = n => {
  const c = n % 1;
  if (c) {
    return '.' + Math.floor(c * 100);
  }
};

export const PricingCard = ({
  cta,
  featureTitle,
  featureSubTitle,
  interval = null,
  isCurrent = false,
  isDiscounted = false,
  showOriginalPrice = false,
  normalPrice = 0,
  price = 0,
  ribbonTitle = '',
  showRibbon = false,
  title = '',
  titleAsterisk = false,
  type = '',
  pricingExplanation = '',
  footNote = null,
  onCtaClick = () => {},
}) => {
  return (
    <PricingCardContainer
      className={cx([
        'card',
        'pricing-cta',
        `type-${type}`,
        { 'has-footnote': !!footNote },
      ])}
      data-test-id={title}
    >
      {showRibbon ? <CardRibbon ribbonTitle={ribbonTitle} /> : null}

      <div className="top-part">
        <h2 className="title">
          {title}
          {titleAsterisk ? <span className="asterisk">*</span> : null}
        </h2>

        <div className={cx('price-container', { discounted: isDiscounted })}>
          <span className="price">
            <span className="currency-symbol">$</span>
            {round(price)}
            <span className="cents">{cents(price)}</span>
            {interval ? <span className="interval">{interval}</span> : null}
          </span>
        </div>

        <div className="pricing-description">
          {showOriginalPrice ? (
            <span className="price original">
              <span className="currency-symbol">$</span>
              {normalPrice}
            </span>
          ) : null}
          {pricingExplanation}
        </div>

        {/*
        <div className="discount-container">
          {isDiscounted
            ? __('Save %{percentage}%', 'plans.savePercentage', {
                percentage: discountPercentage,
              })
            : null}
        </div> */}
      </div>

      <div className="features">
        <p className="feature">{featureTitle}</p>
        <p className="feature secondary">{featureSubTitle}</p>
      </div>
      {isCurrent ? (
        <p className="pricing-card__your-plan">
          <IconCheckmark />
          {__('My plan', 'plans.myPlan')}
        </p>
      ) : (
        <button
          className={cx(['pricing-cta', `type-${type}`])}
          onClick={onCtaClick}
        >
          {cta}
        </button>
      )}
      {footNote ? <div className="footnote">* {footNote}</div> : null}
    </PricingCardContainer>
  );
};
