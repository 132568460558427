import env from './simple-env';
import { fail, alertLevels } from './errors';

const applyFormat = (format, args) => {
  let argIndex = 0;
  return format.replace(/%s/g, () => args[argIndex++]);
};

const invariant = (condition, format, ...args) => {
  const throwInvariants = env.get(
    'throwInvariants',
    process.env.NODE_ENV !== 'production'
  );

  const failOptions = throwInvariants
    ? {
        alert: true,
        report: true,
        level: 'error',
      }
    : {
        alert: false,
        report: true,
        level: 'warning',
      };

  // completely short-circuit if disabled
  if (!throwInvariants) {
    return;
  }

  if (format === undefined) {
    throw new Error('invariant requires an error message argument');
  }

  if (!condition) {
    const error = new Error(applyFormat(format, args));
    error.name = 'Invariant Violation';
    error.report = true;
    error.alertLevel = alertLevels.WARN;
    fail(error, { context: 'Failed Invariant', ...failOptions });
  }
};

export default invariant;
