import { isFunction } from 'lodash';
// import simplEnv from './lib/simple-env';
import * as mst from 'mobx-state-tree';
// import * as pkg from '../../package.json';
import invariant from './lib/invariant';
import { Root } from './root';

let __rootSingleton = null;

// export const { version, build } = pkg;
export const { types } = mst;

const jw = global.jw || {};
// jw.core = { version, build };
global.jw = jw;

const requiredDependencies = [
  'createLogger',
  'downloader',
  'platform',
  'track',
  'notifications',
  'context',
];

const validateDependencies = dependencies => {
  for (let i = 0; i < requiredDependencies.length; i++) {
    const depName = requiredDependencies[i];
    invariant(
      dependencies[depName],
      `Root: Missing required dependency: ${depName}`
    );
  }
};

export const createMixin = fn => fn(mst);

export const createRootStore = (options = {}) => {
  // never, ever, create a second instance of root.
  if (__rootSingleton !== null) {
    return __rootSingleton;
  }

  // const { dependencies = {}, env = {}, mixin = null } = options;
  const { dependencies = {}, mixin = null } = options;
  validateDependencies(dependencies);

  // assuming now that the env will be initialized before mst
  // simpleEnv.initialize(env);

  const ComposedRoot = isFunction(mixin)
    ? types.compose(Root, mixin(Root))
    : Root;

  const rootStore = ComposedRoot.create({}, dependencies);

  __rootSingleton = rootStore;

  return rootStore;
};
