import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import __ from 'jw-core/lib/localization';
import { includeTextStyles, getColor } from 'lib/theme/utils';
import { TableRow, TableLabelCell, TableValueCell } from './table';
import { BlueLink as EditLink } from 'components/ui/buttons/link-button';
import { FieldError } from './field-error';
import { EditInput } from './edit-input';
import { Button } from 'components/ds/buttons';
import { useForm } from 'react-hook-form';

const Wrapper = styled.div`
  .button-wrapper {
    padding-top: 8px;
    display: flex;
    align-items: center;
  }

  footer {
    ${includeTextStyles('small-text')};
    color: ${getColor('gray3')};
    margin-top: 3rem;
  }

  .cancel {
    ${includeTextStyles('body')};
    background: none;
    padding: 8px 0;
    border: none;
    color: #999;
    text-decoration: underline;
    cursor: pointer;
    outline: none;
    margin-left: 16px;
  }
`;

export const TableField = observer(
  ({
    isPassword = false,
    validation,
    label,
    value,
    updateLabel = 'update',
    footNote = null,
    name = 'value',
    onEdit,
    note,
    error,
    ...fieldProps
  }) => {
    const [editing, setEditing] = useState(false);
    const { handleSubmit, register, errors } = useForm({
      defaultValues: { [name]: value },
    });

    const displayValue = isPassword ? '●●●●●●●●' : value;

    const submitHandler = handleSubmit(formValues => {
      return onEdit(formValues[name], () => {
        setEditing(false);
      });
    });

    useEffect(() => {
      if (error) {
        setEditing(true);
      }
    }, [error]);

    return (
      <TableRow data-test-id={label}>
        <TableLabelCell>{label}</TableLabelCell>
        <TableValueCell>
          {editing ? (
            <Wrapper>
              <form onSubmit={submitHandler}>
                <EditInput
                  register={register(validation)}
                  isPassword={isPassword}
                  name={name}
                  {...fieldProps}
                />
                {errors[name] ? (
                  <FieldError
                    error={errors[name]}
                    data-test-id="error-message"
                  />
                ) : (
                  <FieldError error={error} data-test-id="error-message" />
                )}
                <div className="button-wrapper">
                  <Button
                    presentation="primary"
                    onClick={submitHandler}
                    data-test-id="submit-button"
                    label={updateLabel}
                  />

                  <button
                    className="cancel"
                    onClick={() => setEditing(false)}
                    data-test-id="cancel-button"
                  >
                    {__('Cancel', 'common.cancel')}
                  </button>
                </div>
              </form>
              {footNote ? <footer>{footNote}</footer> : null}
            </Wrapper>
          ) : (
            <>
              {displayValue}
              <EditLink onClick={() => setEditing(true)}>
                {__('Edit', 'common.edit')}
              </EditLink>
              <FieldError error={error} />
            </>
          )}
          {!editing ? note : null}
        </TableValueCell>
      </TableRow>
    );
  }
);
