/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import __ from 'jw-core/lib/localization';
import { getColor, includeTextStyles } from 'lib/theme/utils';
import { LicenseAccessWarningTip } from './license-access-warning-tip';

import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex: row;
  align-items: center;
  ${includeTextStyles('small-text')};
  color: ${getColor('gray-4')};

  &.no-students {
    margin-left: 8px;
  }

  .vr {
    height: 16px;
    width: 1px;
    background: ${getColor('gray-1')};
    margin: 0 8px;
  }
`;

export const NoLicense = ({ classroom }) => {
  if (classroom.needsAccessWarning) {
    return (
      <LicenseAccessWarningTip>
        <Wrapper>
          {__('No license', 'classroom.noLicense')}
          <span className="vr" />
          {__(
            'Students without full access: %{trialStudentCount}',
            'classroom.studentsWithoutFullAccess',
            { trialStudentCount: classroom.trialStudentCount }
          )}
        </Wrapper>
      </LicenseAccessWarningTip>
    );
  } else {
    return (
      <Wrapper className="no-students">
        {__('No license', 'classroom.noLicense')}
      </Wrapper>
    );
  }
};
