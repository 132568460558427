import { homepage as baseName } from '../package.json';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import JwApp from './jw-app';
import { getConfig } from 'config';
import { theme } from './lib/theme';
import { RootStoreProvider } from './truth/';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { globalErrorHandler } from './global-error-handler';
import './root-listeners';
import './scss/global-lupa.scss';
import { getColor } from 'lib/theme/utils';

import 'lib/services/localization-service';

const GlobalStyle = createGlobalStyle`
@font-face{
	font-family:"larsseit-medium";
	src:url("${baseName}/assets/fonts/5454002/7a60ab9e-f836-4a67-893d-81a589724598.eot?#iefix");
	src:url("${baseName}/assets/fonts/5454002/7a60ab9e-f836-4a67-893d-81a589724598.eot?#iefix") format("eot"),url("${baseName}/assets/fonts/5454002/4cb7676d-9352-4367-9e09-4643861168a8.woff2") format("woff2"),url("${baseName}/assets/fonts/5454002/da0e4157-a84a-4e59-bf79-85090e987cd9.woff") format("woff"),url("${baseName}/assets/fonts/5454002/39916207-e1d1-4b02-ad96-3947023333fb.ttf") format("truetype");
}
@font-face{
	font-family:"larsseit-bold";
	src:url("${baseName}/assets/fonts/5454014/5ebbda2e-6878-4494-ab4d-112eeea23ac0.eot?#iefix");
	src:url("${baseName}/assets/fonts/5454014/5ebbda2e-6878-4494-ab4d-112eeea23ac0.eot?#iefix") format("eot"),url("${baseName}/assets/fonts/5454014/38f62b25-9bb5-4b8f-ab6d-8a5d73286ec1.woff2") format("woff2"),url("${baseName}/assets/fonts/5454014/5fb439fe-d913-4b7d-9727-20ba5bfe48ff.woff") format("woff"),url("${baseName}/assets/fonts/5454014/7b8b3e80-242b-4f48-9ed6-d0dc4175a61c.ttf") format("truetype");
}

#root{
  background-color:${getColor('off-white')};
}

.__no-scroll{
  overflow: hidden;
}

.__with-nag .site-footer{
  padding-bottom:56px;
}

`;

window.Bugsnag = Bugsnag;

Bugsnag.start({
  apiKey: getConfig('bugsnag.apiKey'),
  plugins: [new BugsnagPluginReact(React)],
  onError: globalErrorHandler,
});

const ErrorBoundary = Bugsnag.getPlugin('react');

const AppComponent = getConfig('appSlug') === 'jw' ? JwApp : App;

ReactDOM.render(
  <ErrorBoundary>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <RootStoreProvider>
        <AppComponent />
      </RootStoreProvider>
    </ThemeProvider>
  </ErrorBoundary>,
  document.getElementById('root')
);
