import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { EmailIcon } from '../ui/icons/email-icon';
import { GoogleButton } from '../ui/buttons/google-button';
import { IconButton } from '../ui/buttons/icon-button';
import { AuthTabs } from '../auth/auth-tabs';
import { AuthFootnote } from '../auth/auth-footnote';
import { AuthContainer } from '../auth/auth-container';
import { AuthSmallPrint } from '../auth/auth-small-print';
import { useTitle } from '../../lib/hooks/use-title';
import { useAfterAuthRedirection } from './use-after-auth-redirection';

import __ from 'jw-core/lib/localization';

export const SignupScreen = observer(() => {
  useTitle('Create account');
  useAfterAuthRedirection();

  const history = useHistory();

  const push = path => () => history.push(path);
  return (
    <AuthContainer>
      <div className="auth__content">
        <AuthTabs />
        <GoogleButton
          title={__(
            'Create account with Google',
            'auth.createAccountWithGoogle'
          )}
        />
        <IconButton
          title={__('Create account with email', 'auth.createAccountWithEmail')}
          icon={EmailIcon}
          loading={false}
          onClick={push('/signup-with-email')}
        />
        <AuthFootnote>
          Have a code? Create your free account above or{' '}
          <Link to="/login">sign&nbsp;in</Link> to redeem
        </AuthFootnote>
        <AuthSmallPrint />
      </div>
    </AuthContainer>
  );
});
