import React from 'react';
import { Notification } from 'components/ds/notification';
import { reportException } from '../../lib/error-reporting';

import { observer } from 'mobx-react';
import { action, observable } from 'mobx';

// import logger from '../log';
// const log = logger('notification-service');

const createNotificationService = () => {
  const current = observable.box(null);

  const open = action(notification => {
    current.set(notification);
  });
  const clear = action(() => {
    current.set(null);
  });

  return { open, clear, current };
};

const NotificationService = createNotificationService();

if (process.env.NODE_ENV === 'development') {
  window.NotificationService = NotificationService;
}

const notificationTypes = {
  ERROR: 'error',
  SUCCESS: 'success',
  WARNING: 'warning',
};

export const notifySuccess = message => {
  // log.info(`success: ${message}`);
  NotificationService.open({
    message,
    type: notificationTypes.SUCCESS,
  });
};

export const alertWarning = message => {
  // log.info(`warn: ${message}`);
  NotificationService.open({
    message,
    type: notificationTypes.WARNING,
  });
};

export const alertError = (message, data = {}) => {
  // log.info(`error: ${message}`);
  forwardException(message, data); // todo, finish the error refactoring for this repo
  NotificationService.open({
    message,
    type: notificationTypes.ERROR,
  });
};

const forwardException = (message, data = {}) => {
  const { report, exception, level = 'error', handlerTag, ...debugData } = data;

  if (report === false) {
    return;
  }

  if (exception) {
    reportException(exception, { ...debugData }, { handlerTag, level });
  } else {
    // Sentry.captureMessage(message, level);
    // console.log(`TODO Sentry.captureMessage(${message}, ${level})`);
  }
};

export const NotificationsTarget = observer(() => {
  const notification = NotificationService.current.get();
  if (notification === null) {
    return null;
  }
  return (
    <Notification {...notification} onDismiss={NotificationService.clear} />
  );
});
