import React from 'react';
import { useHistory } from 'react-router-dom';
import { PageLayout } from 'components/ds/layouts/page-layout';
import { Header } from 'components/ds/header';
import { CopyCode } from 'components/ds/common/code';
import __ from 'jw-core/lib/localization';

export const ClassroomDetailLayout = ({
  classroom,
  children,
  showBackLink = true,
  backLinkLabel = __('My classes', 'classroom.myClasses'),
  backLinkPath = '/classrooms',
  stickyHeader = false,
  headerMenuItems,
  headerActionControl,
}) => {
  const history = useHistory();
  const goBack = () => {
    if (!backLinkPath) {
      return history.goBack();
    }
    return history.push(backLinkPath);
  };

  return (
    <PageLayout>
      <Header
        title={classroom.label}
        showBackLink={showBackLink}
        backLinkLabel={backLinkLabel}
        onBackLinkClick={goBack}
        accessory={<CopyCode code={classroom.code} />}
        makeSticky={stickyHeader}
        menuItems={headerMenuItems}
        actionControl={headerActionControl}
      />
      {children}
    </PageLayout>
  );
};
