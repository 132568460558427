import React from 'react';

export const ClassroomIcon = props => {
  return (
    <svg width={28} height={24} fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 0.600098L28 7.72737L14 14.8546L0 7.72737L14 0.600098ZM14 15.6499L5 11.2049V16.6999C5 17.7499 7.88 20.5499 14 20.5499C20.12 20.5499 23 17.7499 23 16.6999V11.2049L14 15.6499ZM27.3 8.85994L26.6 9.20994V13.8999L26.25 17.3999V18.0999H27.65V17.3999L27.3 13.8999V8.85994Z"
        fill="#444444"
      />
    </svg>
  );
};
