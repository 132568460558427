import React from 'react';
import { Card } from 'components/ds/common/card';
import { includeTextStyles, getColor } from 'lib/theme/utils';

import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .content {
    & .title {
      ${includeTextStyles('body')};
      margin-bottom: 4px;
    }

    & .small-text {
      ${includeTextStyles('small-text')};
      color: ${getColor('gray-4')};
      a {
        color: ${getColor('dark-teal')};
        text-decoration: underline;
      }
    }
  }

  @media (min-width: 721px) {
    .content {
      /* max-width: 50%; */
    }

    .control {
      min-width: 36rem;
      display: flex;
      justify-content: flex-end;
    }
  }

  @media (max-width: 720px) {
    text-align: center;
    flex-direction: column;
    .control {
      margin-top: 16px;
    }
  }
`;

export const EmptyCard = ({ children, control }) => (
  <Card>
    <Container>
      <div className="content">{children}</div>
      <div className="control">{control}</div>
    </Container>
  </Card>
);
