import { getEnv, getParent, types } from 'mobx-state-tree';
import env from '../lib/simple-env';
import { v4CatalogMode } from '../lib/app-util';
import __, { t } from '../lib/localization';
import * as safeTypes from '../lib/safe-mst-types';
import { CLASSROOM_FILTER_KEY_PREFIX } from '../lib/constants/vars';
import { Classroom } from './classroom';
import { PaymentData } from './payment-data';
import { Plan } from './plan';
import { PurchasedCoupon } from './purchased-coupon';

const EMPTY_USER_ID = '_empty_';

/**
 * AccountData
 *
 * the server managed user account state.
 * read-only to the client (except via api calls)
 */
export const AccountData = types
  .model('AccountData', {
    userId: types.optional(types.maybeNull(types.string), EMPTY_USER_ID),
    email: safeTypes.stringDefaultBlank, //displayed in the account screen if changed after first registration and not yet reconfirmed.does not block login ?? string
    name: safeTypes.stringDefaultBlank,
    emailConfirmationRequired: safeTypes.booleanDefaultFalse,
    unconfirmedEmail: safeTypes.stringOrNull,
    catalogUrl: safeTypes.stringOrNull, // at the moment, the server can potentially return null for this
    catalogV4Url: safeTypes.stringOrNull,
    accountSiteBaseUrl: safeTypes.stringOrNull,
    accountPageLinkName: safeTypes.stringOrNull,
    memberSinceYear: safeTypes.numberOrNull,
    lastSyncedVersion: types.optional(types.maybeNull(types.number), -1), // - incremented by server last when ever sync'ed in or out
    group: safeTypes.stringOrNull, // nullable - metrics tracking group
    membershipState: types.optional(types.maybeNull(types.string), 'trial'),
    fullAccess: safeTypes.booleanDefaultFalse, // if true, then has access to all stories, if false, then only 'trial == true' stories,
    canAccessAllStoryScripts: safeTypes.booleanDefaultFalse,
    autoRenew: safeTypes.booleanDefaultFalse,
    anonymous: safeTypes.booleanDefaultFalse,
    fullAccessUntil: safeTypes.stringOrNull, // TODO: how to best handle dates?
    appStoreBuildNumber: safeTypes.stringOrNull,
    playStoreBuildNumber: safeTypes.stringOrNull,
    debugBuildNumber: safeTypes.stringOrNull, // apple review
    daysLeft: safeTypes.numberDefaultZero,
    unlockedStorySlugs: safeTypes.arrayOfStringsDefaultEmpty,
    unlockedVolumeSlugs: safeTypes.arrayOfStringsDefaultEmpty,
    hasEverPaid: safeTypes.booleanDefaultFalse,
    promoteEnrollment: safeTypes.booleanDefaultFalse,
    promoteDownload: safeTypes.booleanDefaultFalse,
    hasAffiliatePricing: safeTypes.booleanDefaultFalse,
    affiliateWelcomeHeading: safeTypes.stringOrNull,
    paymentData: types.maybeNull(types.late(() => PaymentData)),
    welcomeUrl: safeTypes.stringOrNull,
    whatsNewUrl: safeTypes.stringOrNull,
    showFutureStories: safeTypes.booleanDefaultFalse, // if true, then forward dated stores will be not be omitted from story list views
    applePaymentsDisabled: safeTypes.booleanDefaultTrue,
    hideIosCouponUi: safeTypes.booleanDefaultFalse,
    hasApplePaidAccess: types.optional(types.boolean, false),
    monthlyPlanPrice: types.optional(types.maybeNull(types.number), 10),
    yearPlanPrice: types.optional(types.maybeNull(types.number), 99),
    hasSpecialPricing: safeTypes.booleanDefaultFalse,
    pricingDescription: safeTypes.stringOrNull,
    pricingDescriptionKey: safeTypes.stringOrNull,
    monthlyNormalPrice: types.optional(types.maybeNull(types.number), 10),
    yearNormalPrice: types.optional(types.maybeNull(types.number), 99),
    monthlyApplePrice: types.optional(types.maybeNull(types.number), 9.99),
    yearApplePrice: types.optional(types.maybeNull(types.number), 99.99),
    plans: types.optional(
      types.maybeNull(types.late(() => types.array(Plan))),
      []
    ),
    applePlans: types.optional(
      types.maybeNull(types.late(() => types.array(Plan))),
      []
    ),
    giftPlans: types.optional(
      types.maybeNull(types.late(() => types.array(Plan))),
      []
    ),
    purchasedCoupons: types.optional(
      types.maybeNull(types.late(() => types.array(PurchasedCoupon))),
      []
    ),
    // used for server driven debugging of startup failure flows
    debugStatus: safeTypes.stringOrNull,
    classroomPortalWelcomePending: safeTypes.booleanDefaultFalse,
    joinedClassrooms: types.optional(
      types.maybeNull(
        // drives student dashboard view within app
        types.late(() => types.array(Classroom))
      ),
      []
    ),
    managedClassrooms: types.optional(
      types.maybeNull(
        // drives teacher classroom portal view within spa
        types.late(() => types.array(Classroom))
      ),
      []
    ),
    hasManagedClassrooms: safeTypes.booleanDefaultFalse,
    licensedClassroomLabel: safeTypes.stringOrNull, // name to display on account page if group-access
  })
  .views(self => ({
    get $platform() {
      const { platform = {} } = getEnv(self);
      return platform;
    },
  }))
  .actions(self => ({
    setValue(k, v) {
      self[k] = v;
    },
    setPromoteDownload() {
      self.promoteDownload = true;
    },
    setFullAccess(value) {
      self.fullAccess = value;
    },
  }))
  .views(self => ({
    get isEmpty() {
      // represents the logged out state
      return self.userId === EMPTY_USER_ID;
    },

    get accountPageUrl() {
      const token = getParent(self).token;
      return `${self.accountSiteBaseUrl}/account?token=${token}`;
    },

    get membershipDisplay() {
      switch (self.membershipState) {
        case 'trial':
          return __('Trial', 'account.membership.trial');
        case 'suspended':
          return __(
            'Suspended (monthly subscription)',
            'account.membership.suspended'
          );
        case 'full-no-renew':
          return __('Full access', 'account.membership.fullAccess');
        case 'full-auto-renew':
          return __('Full access', 'account.membership.fullAccess');
        case 'group-access':
          return __('Group access', 'account.membership.groupAccess');
        default:
          return self.membershipState; // unexpected, should probably log error
      }
    },

    get showRecurringBilling() {
      return (
        self.membershipState === 'full-no-renew' ||
        self.membershipState === 'full-auto-renew'
      );
    },

    get recurringBillingDisplay() {
      if (self.membershipState === 'full-auto-renew') {
        return __('On', 'common.on');
      } else {
        return __('Off', 'common.off');
      }
    },

    get showClassroomLicenseNag() {
      return !!self.managedClassrooms?.find(c => c.license?.isOverSubscribed);
    },

    // handle different iOS pricing
    get monthlyDevicePrice() {
      if (self.$platform.onIos() && !self.hasSpecialPricing) {
        return self.monthlyApplePrice;
      } else {
        return self.monthlyPlanPrice;
      }
    },

    get quarterDevicePrice() {
      return self.quarterPlan.applePrice;
    },

    get quarterNormalPrice() {
      return self.quarterPlan.normalPrice;
    },

    // This plan is exclusive to Apple (for now)
    get quarterPlan() {
      return self.applePlans.find(plan => plan.slug === 'quarter');
    },

    get yearDevicePrice() {
      if (self.$platform.onIos() && !self.hasSpecialPricing) {
        return self.yearApplePrice;
      } else {
        return self.yearPlanPrice;
      }
    },

    get hasPurchasedCoupons() {
      return self.purchasedCoupons && self.purchasedCoupons.length > 0;
    },

    get hasJoinedClassrooms() {
      return self.joinedClassrooms && self.joinedClassrooms.length > 0;
    },

    joinedClassroomForFilterKey(key) {
      const id = key.replace(CLASSROOM_FILTER_KEY_PREFIX, '');
      return self.joinedClassrooms.find(classroom => classroom.id === id);
    },

    // uses localized version for bolero, or server-provided value for lupa
    get resolvedWhatsNewUrl() {
      if (self.$platform.lupaMode) {
        return self.whatsNewUrl;
      } else {
        // for jw/bolero, driven by client-side config, ignore server provided URL
        return t(env.get('whatsNewUrlKey'));
      }
    },

    get storeBuildNumber() {
      if (self.$platform.onIos()) {
        return self.appStoreBuildNumber;
      }

      if (self.$platform.onAndroid()) {
        return self.playStoreBuildNumber;
      }

      // todo: ingegrate website version
      return '0.0';
    },

    get shouldUpdate() {
      const currentBuildNumber = parseInt(self.$platform.getBuildNumber());
      const newBuildNumber = parseInt(self.storeBuildNumber);
      if (!currentBuildNumber || !newBuildNumber) {
        // this apparently can get triggered somehow when logging out and was breaking the unit tests
        // invariant(false, 'update check - missing store version config');
        self.$log.warn('shouldUpdate - missing store version config');
        return false; // don't barf for release build if server misconfigured
      }
      return newBuildNumber > currentBuildNumber;
    },

    get resolvedCatalogUrl() {
      if (v4CatalogMode()) {
        return self.catalogV4Url;
      } else {
        return self.catalogUrl;
      }
    },
  }));

const defaultCreateMethod = AccountData.create;

// Overload the creation process
AccountData.create = json => {
  const errors = AccountData.validate(json, [{ path: '', type: AccountData }]);

  if (errors.length) {
    if (process.env.NODE_ENV !== 'production') {
      throw new Error('Account data Hydration error');
    }
    return null;
  }
  const newData = defaultCreateMethod.apply(AccountData, [json]);
  return newData;
};

// AccountData.preProcessSnapshot(snapshot => {
//   console.log('PREPROCESS', snapshot);
// });
