import React from 'react';

export const LupaLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="64"
    viewBox="0 0 64 64"
  >
    <g fill="#222" fillRule="evenodd">
      <path d="M16.137 47.43a.947.947 0 01-.944.943H.943A.947.947 0 010 47.43v-1.378c0-.519.425-.943.944-.943h14.249c.519 0 .944.424.944.943v1.378zm16.716 0a.946.946 0 01-.944.943H19.223a.947.947 0 01-.944-.944v-1.378c0-.519.425-.943.944-.943H31.91c.52 0 .944.424.944.943v1.378zm30.568 0a.946.946 0 01-.944.943H50.632a.947.947 0 01-.943-.944v-1.378c0-.519.424-.943.943-.943h11.845c.52 0 .944.424.944.943v1.378zm-15.878 0a.947.947 0 01-.944.943h-4.703a.947.947 0 01-.944-.944v-1.378c0-.519.425-.943.944-.943h4.703c.52 0 .944.424.944.943v1.378zM.006 41.787V38.5h2.499V11.286H.006V8h9.395v3.286H6.717V38.5h5.137v-5.97h4.212v9.257z" />
      <path d="M27.076 41.787v-1.435c-.925 1.018-2.268 1.759-4.396 1.759-2.685 0-3.75-1.296-3.75-4.073V23.783h-1.943v-2.916h5.646v16.476c0 .973.324 1.574 1.759 1.574 1.852 0 2.684-.88 2.684-2.592V23.783h-1.573v-2.916h5.276V38.87h1.944v2.916h-5.647zm16.675-16.014c0-1.204-.323-2.036-2.174-2.036-1.528 0-2.546.647-2.546 2.591v9.997c0 1.62.74 2.592 2.546 2.592 1.573 0 2.174-.601 2.174-2.036V25.773zm-.74 16.338c-1.851 0-2.869-.37-3.98-1.574v12.245h1.944v2.916h-7.59v-2.916h1.943v-29h-1.944v-2.915h5.647v1.435c.972-1.065 2.453-1.76 4.212-1.76 2.962 0 4.211 1.528 4.211 4.398v12.08c0 3.24-1.342 5.09-4.443 5.09zm14.77-8.007c0-1.481-1.065-2.083-2.592-2.083-.926 0-1.666.278-1.666 1.666v3.656c0 1.204.463 1.667 1.666 1.667 1.62 0 2.591-.602 2.591-2.314v-2.592zm0 7.683v-1.435c-1.111 1.203-2.592 1.759-4.35 1.759-2.083 0-3.564-.926-3.564-4.212v-4.12c0-3.1 1.249-4.767 4.35-4.767 1.48 0 2.638.371 3.563 1.111V25.68c0-1.296-.416-2.082-2.036-2.082-1.157 0-1.99.323-1.99 2.082v1.25h-3.61v-1.435c0-3.286 1.712-4.952 5.692-4.952 3.842 0 5.6 1.388 5.6 4.952v13.376h1.944v2.916h-5.6z" />
    </g>
  </svg>
);
