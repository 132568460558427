import React, { useEffect } from 'react';
import { ErrorBoundary } from '../error-boundary';
import { Switch, Route, useHistory } from 'react-router-dom';
import { DefaultRoute } from './default';
import { ResetPasswordScreen } from './reset-password-screen';
import { CreatePasswordScreen } from './create-password-screen';
import { NotFoundScreen } from './not-found-screen';
import { trackPage } from '../../lib/analytics';
import { NotificationsTarget } from 'lib/services/notification-service';
import { AllScreensNag } from 'components/classrooms/classroom-nag';
import { PlaceholderScreen } from 'jw-app/placeholder-screen';

const usePageTracker = () => {
  const history = useHistory();

  // track page changes automatically
  useEffect(() => {
    // the first page, just after the effect has mounted
    trackPage(history.location.pathname.replace(/^\//, ''));
    // listen for subsequent page changes
    return history.listen(location => {
      trackPage(location.pathname.replace(/^\//, ''));
    });
  }, [history]);
};

export const JwRoutes = () => {
  usePageTracker();

  return (
    <ErrorBoundary>
      <NotificationsTarget />

      <Switch>
        <Route path="/" exact component={DefaultRoute} />
        <Route path="/signup" component={PlaceholderScreen} />
        <Route path="/login" component={PlaceholderScreen} />
        <Route path="/account" component={PlaceholderScreen} />
        <Route path="/reset-password" component={ResetPasswordScreen} />
        <Route path="/create-password" component={CreatePasswordScreen} />
        <Route component={NotFoundScreen} />
      </Switch>
      <AllScreensNag />
    </ErrorBoundary>
  );
};
