import segment from './segment-script';
import { getConfig } from 'config';
import createLogger from '../log';
import { createAnalyticsContainer } from './analytics-container';

const format = eventName => {
  const prefix = getConfig('analytics.eventPrefix');
  return `${prefix}:${eventName}`;
};

export const createSegmentAnalyticsContainer = () => {
  const log = createLogger('analytics:segment');
  const segmentWriteKey = getConfig('analytics.segmentWriteKey', null);
  const enabled = segmentWriteKey !== null;

  if (enabled) {
    segment().load(segmentWriteKey);
  }

  let __notified;
  const noop = () => {
    if (!__notified) {
      log('Segment analytics are disabled');
      __notified = true;
    }
  };

  const trackFn = enabled
    ? (eventName, eventProperties) => {
        log('Call to Segment.track', { eventName, eventProperties });
        segment().track(format(eventName, eventProperties));
      }
    : noop;

  const identifyFn = enabled
    ? (...args) => {
        log('Call to Segment.identify', args);
        segment().identify(...args);
      }
    : noop;

  const pageFn = enabled
    ? (pageName, eventProperties) => {
        log('Call to Segment.page', { pageName, eventProperties });
        segment().page(pageName, eventProperties);

        // JE: I figured out how to get the page events to display better within Mixpanel,
        // so disabling the track event version for now.

        // make a track event for the page
        // trackFn(`page__${pageName}`, eventProperties);
      }
    : noop;

  const container = createAnalyticsContainer({
    log,
    trackFn,
    identifyFn,
    pageFn,
  });

  return container;
};
