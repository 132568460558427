import React from 'react';
import { StoryMeta } from './story-meta';
import __ from 'jw-core/lib/localization';
import dayjs from 'dayjs';
import styled from 'styled-components';

const Wrapper = styled.section`
  @media print, screen and (min-width: 900px) {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    justify-content: space-between;

    .cover-image {
      width: 33.333%;
      padding-left: calc(64px / 3);
      margin-bottom: 56px;
    }
    .cover-text {
      width: 50%;
    }
    .credits {
      column-count: 3;
    }
  }

  .cover-image {
    margin: 8px 0 24px;
    max-width: 360px;
    width: 100%;
  }
  .cover-heading {
    font: var(--large-heading-font);
    margin-bottom: 16px;
  }
  .cover-tagline {
    font: var(--medium-heading-font);
    margin-bottom: 24px;
  }
  .cover-description {
    font: var(--body-font);
    margin-bottom: 16px;
  }
  .cover-release {
    font: var(--body-font);
    margin-bottom: 16px;
    color: var(--gray-4);
  }
`;

const formatDate = date => dayjs(date).format('MMM D, YYYY');

export const StoryCover = ({ story }) => {
  return (
    <Wrapper>
      <img className="cover-image" src={story.listImageUrl} alt={story.title} />
      <div className="cover-text">
        <h2 className="cover-heading">{story.title}</h2>
        <h2 className="cover-tagline">{story.tagline}</h2>
        <p className="cover-description">{story.description}</p>
        <p className="cover-release">
          {__('Lupa release on %{date}', 'storyGuide.lupaReleaseOn', {
            date: formatDate(story.releaseDate),
          })}
          <br />
          {__(
            'Originally broadcast on %{date}',
            'storyGuide.originallyBrodcast',
            {
              date: formatDate(story.originalBroadcastDate),
            }
          )}
          <br />
        </p>
        <StoryMeta story={story} />
      </div>
    </Wrapper>
  );
};
