import styled from 'styled-components';
import { getFontName } from '../../lib/theme/utils';

export const AuthHeading = styled.h1`
  font-family: ${getFontName('heading')};
  font-size: 3.375rem;
  line-height: 4rem;
  text-align: center;
  padding: 0 2.5rem;
`;
