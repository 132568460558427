/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { find } from 'lodash';
import { useParams } from 'react-router-dom';
import { useRootStore } from 'mst';
import { useLicenseLearnMoreAlert } from 'components/classrooms/classroom-hooks';
import { useStorySelector } from './use-story-selector';
import { StoriesAssignmentSection } from './stories-assignment-section';
import { ModalWrapper } from './modal-wrapper';
import { getColor, includeTextStyles } from 'lib/theme/utils';
import { StoriesGrid } from 'components/stories/stories-grid';
import { getConfig } from 'config';

import __ from 'jw-core/lib/localization';

const Instructions = styled.div`
  ${includeTextStyles('small-text')};
  color: ${getColor('gray-4')};
  padding-top: 12px;
  padding-bottom: 16px;

  @media screen and (min-width: 550px) {
    padding-bottom: 32px;
  }
`;

const StoriesSelectorWrapper = styled(StoriesGrid)`
  padding-bottom: 8px;
  @media screen and (min-width: 550px) {
    padding-bottom: 8px; /* override default padding from stories-grid */
  }
  .story-selector-section-header {
    padding: 24px 0 12px;
    border-bottom: 1px solid ${getColor('gray-1')};
  }

  .story-selector-section-title {
    ${includeTextStyles('small-heading')};
    display: flex;
    align-items: center;
    text-transform: uppercase;
    a {
      ${includeTextStyles('small-text')};
      color: ${getColor('dark-teal')};
      display: flex;
      margin-left: 8px;
      text-transform: none;
      svg {
        margin-right: 4px;
        width: 20px;
        height: 20px;
      }
    }
  }

  .story-selector-section-description {
    ${includeTextStyles('small-text')};
    color: ${getColor('gray-4')};
    a {
      color: ${getColor('dark-teal')};
    }
  }

  .story-selector-item {
    padding: 0 16px;
    margin-left: -16px;
    margin-right: -16px;
    border-radius: 8px;
    transition: all 0.3s;
    background: transparent;
    cursor: pointer;
    &:hover {
      background: ${getColor('off-off-white')};
    }
  }

  .story-cell-thumbnail-overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.4);
    color: ${getColor('yellow')};
    border-radius: 4px;
    transition: opacity 0.1s ease-in;
    opacity: 0;
    svg {
      width: 40px;
      height: 40px;
      transition: transform 0.12s ease-out;
      transform: scale3d(0.5, 0.5, 0.5);
    }

    &.selected {
      opacity: 1;
      svg {
        transform: scale3d(1, 1, 1);
      }
    }
  }
`;

export const ClassroomAssignModal = observer(({ onDismiss }) => {
  const { id } = useParams();
  const { userManager, storyManager } = useRootStore();

  const { accountData = {} } = userManager;
  const classroom = find(accountData.managedClassrooms, { id });
  const { assignments } = classroom;
  const { trial = [], notTrial = [] } = storyManager;
  const [Alert, alertActions] = useLicenseLearnMoreAlert();

  const { current: selector } = useStorySelector(assignments);

  const handleDone = async () => {
    // save an API call if nothing changed
    if (selector.touched) {
      await classroom.updateAssignmentList(selector.selectedItems);
    }
    // with the transition code, the modal dismisses itself!
    // onDismiss();
  };

  return (
    <ModalWrapper onDone={handleDone} onDismiss={onDismiss}>
      <Instructions>
        {__(
          ' Your students will see selected stories as assignments in the Lupa mobile app',
          'classroom.modalInstructions'
        )}
      </Instructions>
      <StoriesSelectorWrapper>
        <StoriesAssignmentSection
          stories={trial}
          title={__('Free Stories', 'classroom.freeStories')}
          description={__(
            'Students can access these %{appName} stories without a paid licence',
            'classroom.trialStoriesDescription',
            { appName: 'Lupa' }
          )}
          selector={selector}
          lessonPlans={getConfig('classrooms.exampleLessonPlansUrl')}
        />
        <StoriesAssignmentSection
          stories={notTrial}
          title={__('Premium Stories', 'classroom.premiumStories')}
          description={
            <a href="#" onClick={alertActions.on}>
              {__('Learn more', 'common.learnMore')}
            </a>
          }
          selector={selector}
        />
      </StoriesSelectorWrapper>

      <Alert />
    </ModalWrapper>
  );
});
