import React from 'react';
import { getColor, includeTextStyles } from 'lib/theme/utils';
import styled from 'styled-components';

const AccessCellWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 550px) {
    color: ${getColor('gray-4')};
    margin-bottom: 4px;
  }

  svg {
    margin-right: 8px;
    width: 12px;
    height: 12px;
    color: ${getColor('green')};
    &.red {
      color: ${getColor('red')};
    }
  }

  /* We'll make this button look like a link */
  button {
    ${includeTextStyles('body')};
    cursor: pointer;
    margin: 0;
    padding: 0;
    border: none;
    background: none;
    text-decoration: underline;
    color: ${getColor('gray-4')};
    &:hover {
      color: ${getColor('dark-teal')};
    }
  }
`;

export const AccessDot = ({ fullAccess }) => {
  return (
    <svg viewBox="0 0 10 10" className={`${fullAccess ? 'green' : 'red'}`}>
      <circle cx="5" cy="5" r="5" fill="currentColor"></circle>
    </svg>
  );
};

export const StudentAccessCell = ({ student, classroom, onAssignSeat }) => {
  const { accessDescription, fullAccess } = student;

  return (
    <AccessCellWrapper>
      <AccessDot fullAccess={fullAccess} />
      {accessDescription}
    </AccessCellWrapper>
  );
};
