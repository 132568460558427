import { capitalize } from 'lodash';
import { types } from 'mobx-state-tree';
import * as safeTypes from '../lib/safe-mst-types';

/**
 * SubscriptionData
 *
 * singleton child of AccountData which groups the subscription related details
 */
export const PaymentData = types
  .model('PaymentData', {
    autoRenewInterval: safeTypes.stringOrNull,
    paymentProvider: safeTypes.stringOrNull, // apple
    cardLastFour: safeTypes.stringOrNull,
    cardExp: safeTypes.stringOrNull,
    cardBrand: safeTypes.stringOrNull,
    autoRenewAmount: safeTypes.numberOrNull,
    lastPaymentAt: safeTypes.stringOrNull,
    lastPaymentAmount: safeTypes.numberOrNull,
    autoRenewFailed: safeTypes.booleanDefaultFalse,
  })
  .views(self => ({
    get paymentMethodDisplay() {
      if (self.paymentProvider === 'apple') {
        return 'Apple subscription';
      }
      if (self.cardExp) {
        const [month, year] = self.cardExp.split(' / ');

        const exp = `${month.length > 1 ? month : `0${month}`}/${year.slice(
          2
        )}`;

        return `${capitalize(self.cardBrand)} ****${
          self.cardLastFour
        }, expires ${exp}`;
      }

      return null;
    },
  }));
