import React from 'react';
import { SpinnerIcon } from '../icons/spinner-icon';
import { prevent } from '../../../lib/prevent';
import styled from 'styled-components';

const IconButtonAnchor = styled.a`
  display: block;
  background: #fff;
  border-radius: 4px;
  margin-bottom: 1rem;
  padding: 0 1.25rem;
  height: 6rem;
  font-family: roboto, sans-serif;
  font-size: 2.125rem;
  line-height: 3rem;
  font-weight: 400;
  line-height: 6rem;
  color: #333;
  text-align: center;
  position: relative;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.08);
  transition: box-shadow 0.15s;

  &:hover {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  }
`;

const ButtonIconContainer = styled.span`
  position: absolute;
  top: 1rem;
  left: 1.25rem;
`;

const SpinnerIconContainer = styled.span`
  position: absolute;
  top: 1rem;
  right: 1.25rem;
`;

export const IconButton = ({
  onClick = () => {},
  title,
  icon: Icon,
  loading = false,
}) => {
  return (
    <IconButtonAnchor href="#" onClick={prevent(onClick)} data-test-id={title}>
      <ButtonIconContainer>
        <Icon />
      </ButtonIconContainer>
      <SpinnerIconContainer>{loading && <SpinnerIcon />}</SpinnerIconContainer>
      {title}
    </IconButtonAnchor>
  );
};
