import React from 'react';
import { CouponPurchase } from './coupon-purchase';

export const PurchasedCoupons = ({ coupons }) => {
  if (!coupons.length) {
    return null;
  }
  return (
    <div className="gift-coupons__purchased">
      <h2 className="gift-coupons__heading">My coupon purchases</h2>
      {coupons.map(coupon => (
        <CouponPurchase coupon={coupon} key={coupon.code} />
      ))}
    </div>
  );
};
