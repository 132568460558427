/* eslint-disable no-console */
/* eslint-disable import/no-anonymous-default-export */

/**
 * inspired by https://github.com/visionmedia/debug
 * but very configurable and without the Node.js dependency.
 */

// import { includes } from 'lodash';
import defaultChalk from 'chalk';
import micromatch from '../micromatch';
import logLevels from './log-levels';

const defaultPalette = [
  'black',
  'red',
  'green',
  'yellow',
  'blue',
  'magenta',
  'cyan',
  'white',
  'redBright',
  'greenBright',
  'yellowBright',
  'blueBright',
  'magentaBright',
  'cyanBright',
  'whiteBright',
];

const defaultFormatter = (namespace, a, chalkFn) =>
  chalkFn.bold(`[${namespace}]`) + chalkFn(` - ${a}`);

const defaultConfiguration = {
  // namespaces or pattern to disable namespaces
  disabled: [],
  // namespaces or pattern to disable namespaces
  enabled: [],
  // default namespaces to send to loggly
  logglyRepeated: ['error', 'warn'],
  // palette, uses a very basic palette by default
  palette: defaultPalette,
  // the logger function. Defaults to console.log, but is overrideable
  // in case we want to do something else like send to loggly
  logFn: console.log,
  // Chalk is not autodetecting color in Chrome for me
  // so I added the option to automatically send a custom instance of chalk
  chalk: defaultChalk,
  // The function called when a namespace is disabled.
  noOp: () => {},
  // make it possible to configure how things get formatted into the console
  formatterFn: defaultFormatter,
  // default log level
  // @armando, what exactly does this default value here control?
  logLevel: logLevels.debug,
};

/**
 * Utility function that transforms a string into a number
 */
// FIXME: extract chalk functionality into middleware
const __hashes = {};
const hashForString = str => {
  if (__hashes[str]) {
    return __hashes[str];
  }
  let sum = str
    .split('')
    .map(char => char.charCodeAt(0))
    .reduce((x = 0, y) => x + y);
  sum += str.length;
  __hashes[str] = sum;
  return sum;
};

/**
 * Yes this is a factory that returns another factory.
 * It's so every project that uses it can have it's own configuration:
 * it can come from ENV variables, localStorage or wherever
 * just pass a configuration object to this function and create a singleton.
 *
 * @param {Object} configuration
 */
export default function (configuration = {}) {
  configuration = { ...defaultConfiguration, ...configuration };
  const {
    palette,
    disabled: _disabled,
    enabled: _enabled,
    // logglyRepeated,
    logFn,
    formatterFn,
    noOp,
    chalk,
    logLevel,
    // services = {},
  } = configuration;

  // const runtimeConfig = {
  //   forwardAllToLoggly: false,
  // };

  const runtimeConfig = new Map([['logLevel', logLevel]]);

  // const setRuntimeConfig = (key, value) => {
  //   runtimeConfig.set(key, value);
  // };

  // const getRuntimeConfig = key => {
  //   runtimeConfig.get(key);
  // };

  // const createRepeater = (...args) => repeaters => {
  //   if (typeof repeaters.map === 'undefined') {
  //     console.warn(
  //       `Error: \`send\` expects an array of services, got \`${repeaters}\` instead`
  //     );
  //     return;
  //   }
  //   repeaters.forEach(service => {
  //     if (typeof services[service] === 'function') {
  //       services[service](...args);
  //     } else {
  //       console.log(`Warning: service [${service}] is not defined`);
  //     }
  //   });
  // };

  // remove empty entries
  const enabled = _enabled.filter(s => s.length > 0);

  /**
   * checks wether a namespace is enabled by configuration
   * in the optimal way possible
   * @param {String} namespace
   */
  const __enabledNamespaces = new Set();

  function isForceEnabled(namespace) {
    // no enabled configuration
    if (!enabled.length) {
      return false;
    }

    // every log is enabled
    if (enabled[0] === '*') {
      return true;
    }

    // don't go trough the pattern matching again
    if (__enabledNamespaces.has(namespace)) {
      return true;
    }

    // use micromatch to match namespace against wildcards
    for (let i = 0; i < enabled.length; i++) {
      const pattern = enabled[i];
      if (micromatch(namespace, pattern)) {
        __enabledNamespaces.add(namespace);
        return true;
      }
    }

    return false;
  }

  // remove empty entries
  const disabled = _disabled.filter(s => s.length > 0);

  /**
   * checks wether a namespace is disabled by configuration
   * in the optimal way possible
   * @param {String} namespace
   */
  const __disabledNamespaces = new Set();
  function isDisabled(namespace) {
    // no disabled configuration
    if (!disabled.length) {
      return false;
    }
    // every log is disabled
    if (disabled[0] === '*') {
      return true;
    }
    // don't go trough the pattern matching again
    if (__disabledNamespaces.has(namespace)) {
      return true;
    }

    // use micromatch to match namespace against wildcards
    for (let i = 0; i < disabled.length; i++) {
      const pattern = disabled[i];
      if (micromatch(namespace, pattern)) {
        __disabledNamespaces.add(namespace);
        return true;
      }
    }

    return false;
  }

  // Keep really simple for now. Add support for arbitrary wild cards when needed
  // function isLogglyRepeated(namespace) {
  //   return (
  //     includes(logglyRepeated, namespace) ||
  //     namespace.includes('loggly') ||
  //     namespace.includes('error') ||
  //     namespace.includes('warn')
  //   );
  // }

  const patchLog = (log, fn) => {
    const patched = (...args) => {
      if (fn(...args) !== false) {
        log(...args);
      }
    };

    return patched;
  };

  /**
   *
   * This adds jiddleware to a logger creator
   *
   * @param {*} loggerCreator
   * @param {*} middlewareFn
   */
  const addLoggerMiddleware = (loggerCreator, middlewareFn) => {
    const patchedLoggerCreator = namespace => {
      if (!isForceEnabled(namespace)) {
        if (isDisabled(namespace)) {
          return noOp;
        }
      }

      let logger = loggerCreator(namespace);
      logger.__log = patchLog(logger.__log, (...args) =>
        middlewareFn(namespace, ...args)
      );

      return logger;
    };

    patchedLoggerCreator.runtimeConfig = runtimeConfig;

    return patchedLoggerCreator;
  };

  const __cache = new Map();

  /**
   * Log factory.
   * @param {string} namespace
   */
  let createLogger = namespace => {
    // If we already have a cached logger creator for this namespace
    const cached = __cache.get(namespace);
    if (cached) {
      // console.log(`${namespace} was cached`);
      return cached;
    }

    const hash = hashForString(namespace);
    const color = palette[hash % palette.length];

    try {
      const chalkFn = color[0] === '#' ? chalk.hex(color) : chalk[color];

      // The actual log function that is returned.
      function __log(a, b, level = logLevels.info) {
        const msg = formatterFn(a, { namespace, chalkFn, level });
        if (b) {
          logFn(msg, b);
        } else {
          logFn(msg);
        }
      }

      function log(...args) {
        log.__log(...args);
      }

      log.__log = __log;

      // create the log.[level] functions
      Object.keys(logLevels).forEach(levelName => {
        if (logLevels[levelName] > 0) {
          // ignore the 'none' tier. duh.
          log[levelName] = (a, b) => {
            // only execute if the level is above the configured one
            if (logLevels[levelName] < runtimeConfig.get('logLevel')) {
              return;
            }
            log.__log(a, b, logLevels[levelName]);
          };
        }
      });

      // FIXME: don't remember what this is for
      log.color = color;

      // cache the logger creator for this namespace
      __cache.set(namespace, log);

      return log;
    } catch (error) {
      console.log({ error, color, namespace });
    }
  };

  createLogger.runtimeConfig = runtimeConfig;

  return {
    createLogger,
    addLoggerMiddleware,
    runtimeConfig,
  };

  // return createLogger;
}

export const patchLog = (log, fn) => {
  return (...args) => {
    if (fn(...args) !== false) {
      log(...args);
    }
  };
};

export const addLoggerMiddleware = (loggerCreator, middlewareFn) => {
  return namespace => {
    let logger = loggerCreator(namespace);
    return patchLog(logger, (...args) => middlewareFn(namespace, ...args));
  };
};
