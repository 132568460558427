import React from 'react';
import { reportException } from '../lib/error-reporting';

export const FullScreenError = ({ message = 'Something went wrong' }) => {
  const reload = () => {
    window.location = '/account';
  };
  return (
    <section className="error-boundary">
      <div className="error-boundary__container">
        <h1 className="error-boundary__heading">Oops</h1>
        <div className="error-boundary__subheading">{message}</div>
        <div className="error-boundary__message">
          The developers have been notified.
        </div>
        <button className="btn" onClick={reload}>
          Reload application
        </button>
      </div>
    </section>
  );
};

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    reportException(error, info);
  }

  render() {
    if (this.state.hasError) {
      return <FullScreenError />;
    }
    return this.props.children;
  }
}
