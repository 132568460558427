import React from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { useSwitch } from 'lib/hooks/use-switch';
import { ClassroomSection } from './classroom-section';
import { ClassroomAssignModal } from 'components/classrooms/classroom-assign-modal';
import { Button } from 'components/ds/buttons';
import { EmptyCard } from './empty-card';
import { StoriesGrid } from 'components/stories/stories-grid';
import { AssignmentCell } from './assignment-cell';
import { getConfig } from 'config';
import __ from 'jw-core/lib/localization';

const EmptyAssignments = ({ onEdit }) => {
  return (
    <EmptyCard
      control={
        <Button
          label={__('Select stories', 'classroom.selectStories')}
          presentation="primary"
          onClick={onEdit}
        />
      }
    >
      <div className="title">
        {__('No assignments for this class', 'classroom.noAssignments')}
      </div>
      <div className="small-text">
        Get started with our two trial stories, or{' '}
        <a href={`mailto:${getConfig('website.emails.educationalSales')}`}>
          contact customer support
        </a>{' '}
        to unlock all stories with a class license
      </div>
    </EmptyCard>
  );
};

export const ClassroomAssignments = observer(({ classroom }) => {
  const history = useHistory();
  const { assignments } = classroom;
  const hasAssignments = assignments.length > 0;

  // controls the display of the modal
  const [editing, editSwitch] = useSwitch(false);

  const handleSelectAssignment = assignment => {
    history.push(`/classrooms/${classroom.id}/${assignment.episodeSlug}`);
  };

  if (!hasAssignments) {
    return (
      <>
        <ClassroomSection title={__('Assignments', 'classroom.assignments')}>
          <EmptyAssignments onEdit={editSwitch.on} />
        </ClassroomSection>
        {editing ? <ClassroomAssignModal onDismiss={editSwitch.off} /> : null}
      </>
    );
  }

  return (
    <>
      <ClassroomSection
        title={__('Assignments', 'classroom.assignments')}
        withHeadingMargin={false}
        control={
          <Button
            label={__('Select stories', 'classroom.selectStories')}
            presentation="primary"
            size="small"
            onClick={editSwitch.on}
          />
        }
      >
        <StoriesGrid>
          {assignments.map((assignment, i) => {
            if (!assignment.story) {
              return null;
            }
            return (
              <AssignmentCell
                key={`assignment-${i}`}
                assignment={assignment}
                onSelectAssignment={handleSelectAssignment}
              />
            );
          })}
        </StoriesGrid>
      </ClassroomSection>
      {editing ? <ClassroomAssignModal onDismiss={editSwitch.off} /> : null}
    </>
  );
});
