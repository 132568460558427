export const downloadStatus = {
  NOT_DOWNLOADED: 'not-downloaded',
  PENDING_DOWNLOAD: 'pending-download',
  DOWNLOADING: 'downloading',
  DOWNLOAD_FAILED: 'download-failed',
  DOWNLOADED: 'downloaded',
};

export const assetKeys = {
  BANNER_IMAGE: 'story-image', // better names, perhaps someday rename the property keys
  LIST_IMAGE: 'story-thumbnail',
  FULL_DATA: 'full-data',
  NORMAL_AUDIO_PREFIX: 'normal-audio-',
};
