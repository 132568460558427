// the __f version will turn all of the object properties into functions instead of strings
// this relies on the component to invoke at render time
import { __f } from '../localization';

const tipTypes = {
  TIP: 'TIP',
  DIALOG: 'DIALOG',
};

// todo: figure out how to move this back to just the native repo

const tipsData = {
  // 'story-view-chapter-card-intro': {
  //   message:
  //     'Listen to each chapter twice to improve your listening skills fast.',
  //   type: tipTypes.TIP,
  //   buttons: ['Got it', 'Skip help'],
  //   isOnboarding: true,
  // },
  // 'study-view-first-listen-assist-mode': {
  //   message:
  //     'Assist mode is "on" when you listen to a new chapter. Adjust the speed and other settings so your ear gets just enough help to follow the story.',
  //   type: tipTypes.TIP,
  //   buttons: ['Got it', 'Skip help'],
  //   isOnboarding: true,
  // },
  // 'study-view-first-relisten-assist-mode': {
  //   message:
  //     'Assist mode is "off" initially when you relisten to a chapter, since you should now be able to understand it at the natural native speed. If not, you can tap to switch back "on".',
  //   type: tipTypes.TIP,
  //   buttons: ['Got it', 'Skip help'],
  //   isOnboarding: true,
  // },

  'assist-settings-hints': {
    message: __f(
      'Short titles (in English) displayed at the top of the script relating to the current passage. These help you stay on track in the story',
      'tips.assistSettingsHints'
    ),
    type: tipTypes.TIP,
    buttons: [__f('Got it', 'tips.gotIt')],
  },
  'assist-settings-tricky-bits': {
    message: __f(
      'Phrases spoken unusually fast, with a strong accent, or which may otherwise be difficult for your ear to catch',
      'tips.assistSettingsTrickyBits'
    ),
    type: tipTypes.TIP,
    buttons: [__f('Got it', 'tips.gotIt')],
  },
  'assist-settings-vocab': {
    message: __f(
      'Useful words for your vocab list (underlined in green) and non-standard words and usage (in orange)',
      'tips.assistSettingsVocab'
    ),
    type: tipTypes.TIP,
    buttons: [__f('Got it', 'tips.gotIt')],
  },
  'assist-settings-abc-tip': {
    message: __f(
      '"Full text" mode is off when this panel is open so you can see the effect of changing a setting',
      'tips.assistSettingsAbcTip'
    ),
    type: tipTypes.TIP,
    buttons: [__f('Got it', 'tips.gotIt')],
  },

  // 'remove-download-dialog': {
  //   heading: 'Remove download',
  //   message:
  //     'Removing audio files frees up space on your device. You will need to re-download to continue playing the story. Your progress statistics, vocab list, and listening position are not affected.',
  //   type: tipTypes.DIALOG,
  //   buttons: ['Remove', 'Cancel'],
  // },
  // 'mark-story-complete-dialog': {
  //   heading: 'Mark story complete',
  //   message:
  //     "This will mark all chapters complete, but won't affect your progress statistics or vocab list.",
  //   type: tipTypes.DIALOG,
  //   buttons: ['Mark Complete', 'Cancel'],
  // },
  // 'reset-story-dialog': {
  //   heading: 'Reset story',
  //   message:
  //     "This will mark all chapters as unlistened and will remove any words in your vocab list for this story, but won't affect your progress statistics.",
  //   type: tipTypes.DIALOG,
  //   buttons: ['Reset', 'Cancel'],
  // },
  // 'unlock-chapter-dialog': {
  //   heading: 'Start Chapter',
  //   message:
  //     "Starting this chapter will mark all previous chapters as complete, but won't affect your progress statistics or vocab list.",
  //   type: tipTypes.DIALOG,
  //   buttons: ['OK', 'Cancel'],
  // },
};

export default tipsData;
