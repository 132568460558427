import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { observer } from 'mobx-react';
import { ClassroomList } from '../classrooms/classroom-list';
import { ClassroomDetail } from '../classrooms/classroom-detail';
import { AssignmentDetail } from '../classrooms/assignment/assignment-detail';
import { DownloadLinks } from 'components/account/download-links';

export const ClassroomScreen = observer(() => {
  let { path } = useRouteMatch();

  return (
    <>
      <Switch>
        <Route path={`${path}`} exact component={ClassroomList} />
        <Route path={`${path}/:id`} exact component={ClassroomDetail} />
        <Route
          path={`${path}/:id/:episodeSlug`}
          exact
          component={AssignmentDetail}
        />
      </Switch>
      <DownloadLinks />
    </>
  );
});
