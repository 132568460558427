const l = window.location;
const baseUrl = l.protocol + '//' + l.hostname + (l.port ? `:${l.port}` : '');

export const env = {
  REACT_APP_CONFIG_ENV: process.env.REACT_APP_CONFIG_ENV, // for debug reference

  appName: 'MyLupa',
  appSlug: 'myLupa',
  appTitle: process.env.REACT_APP_SITE_TITLE ?? '',
  forcedApiEnv: process.env.REACT_APP_DEFAULT_API_ENV ?? 'devtest',
  manifestVersion: 'proto',

  jiveworldApiKey: {
    website: 'QowNmFgzVLu4WnattXzN', // for now, shared for all deployment environments
  },

  bugsnag: {
    apiKey: '56ed11fed6bfebab28223aa62b630786',
    enabled: true,
  },

  google: {
    clientId:
      '903123262392-86hnsj69ds3oatf615tuf7q4vf4kajh7.apps.googleusercontent.com',
  },

  stripe: {
    publishableKey: 'pk_test_Zri5LbMMapbrkavc5yW3Z1fV00rJUj8DZw',
  },

  analytics: {
    //'HP0iVsUQB6F9CawQ6uVEZLD9kpcrZcJ3', // internal testing
    segmentWriteKey: null, // segment is disabled if no key provided, enabled when provided
    enableSimulatedAnalytics: false,
    enableAnonymous: true,
    eventPrefix: 'w',
  },

  logging: {
    // `enableConsoleLog` when true, the logger‘s use of console.log is short-circuited
    enableConsoleLog: process.env.NODE_ENV !== 'production',
  },

  alertUnhandledErrors: false,

  website: {
    baseUrl,
    staticPath: `/assets`,
    checkoutSuccessUrl: `${baseUrl}/account?checkout=success`,
    checkoutFailureUrl: `${baseUrl}/account?checkout=error`,
    // this allows sharing cookies between the service tier and website
    cookieDomain: '.jiveworld.com',

    marketingSiteUrl: 'https://www.lupa.app',
    urls: {
      downloadApp: 'https://get.lupa.app',
      classroomMarketingPage: 'https://lupa.app/schools/classrooms',
      classroomLicenseLearnMore: 'https://jiveworld.com',
    },
    emails: {
      feedback: 'feedback@jiveworld.com',
      support: 'support@jiveworld.com',
      educationalSales: 'edu-sales@jiveworld.com',
    },
    emailOptions: {
      subject: 'My feedback on Lupa',
      body:
        "Please explain what worked, what didn't work, and what you'd like to see improved",
    },
    downloadUrls: {
      appStore:
        'https://apps.apple.com/us/app/lupa-learn-spanish-fluently/id1480744663?ls=1',
      playStore:
        'https://play.google.com/store/apps/details?id=com.jiveworld.lupa',
    },
  },

  classrooms: {
    exampleLessonPlansUrl: 'https://go.lupa.app/lupa-lessons',
  },
};
