import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { find } from 'lodash';
import { useParams } from 'react-router-dom';
import { useRootStore } from 'mst';
import { Table } from 'components/ds/tables';
import { ProgressCell } from './progress-cell';
import { AssignmentDetailLayout as Layout } from './assignment-detail-layout';
import { VocabCountCell } from './vocab-count-cell';
import { StudentNameCell } from '../table-cells/student-name-cell';
import { StudentEmailCell } from '../table-cells/student-email-cell';
import { StudentTimeStudiedCell } from '../table-cells/student-time-studied-cell';

import __ from 'jw-core/lib/localization';
import { SectionBreak } from 'components/ds/common/spacers';
import { Text } from 'components/ds/common/text';

export const AssignmentDetail = observer(() => {
  const [data, setData] = useState(null);
  const { id, episodeSlug } = useParams();
  const store = useRootStore();
  const story = store.storyManager?.story(episodeSlug);
  const catalogData = story?.catalogData;
  const { accountData = {} } = store.userManager;
  const classroom = find(accountData.managedClassrooms, { id });

  // FIXME: I think this belongs on MST. Not sure how.
  useEffect(() => {
    classroom.fetchAssignmentData(episodeSlug).then(data => {
      setData(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadingData = data === null || store.loadingData;
  const tableData = data?.studentProgresses ?? [];

  return (
    <Layout classroom={classroom} story={story}>
      {tableData.length > 0 ? (
        <Table
          loading={loadingData}
          data={tableData}
          cells={[
            {
              headerLabel: __('Name', 'common.name'),
              component: StudentNameCell,
            },
            {
              headerLabel: __('Email', 'common.email'),
              component: StudentEmailCell,
            },
            {
              headerLabel: __('Progress', 'common.progress'),
              renderCell: progress => (
                <ProgressCell {...{ catalogData, progress }} />
              ),
            },
            {
              headerLabel: __('Time studied', 'common.timeStudied'),
              renderCell: ({ allTimeStudiedHourMin }) => {
                return (
                  <StudentTimeStudiedCell
                    allTimeStudiedHourMin={allTimeStudiedHourMin}
                  />
                );
              },
            },
            {
              headerLabel: __('Vocab', 'common.vocab'),
              component: VocabCountCell,
            },
          ]}
        />
      ) : (
        <Text>
          {__(
            'After you add students to this class you will see their progress for this story here',
            'classroom.studentsZeroState'
          )}
        </Text>
      )}
      {/* Armando, not sure if you rather use Gap or SectionBreak here */}
      <SectionBreak />
    </Layout>
  );
});
