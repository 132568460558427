// NOTE this is not the end shape of localizable long texts
export const instructions = `

# Lupa: Improve your Spanish listening


Lupa is a mobile app designed to help improve your real-world Spanish listening. You'll hear podcasts from the award-winning Radio Ambulante — true stories from real people all over Latin America.

It's challenging material, but Lupa helps you out with vocab, hints, notes and even translations so you can get by. Use as little of the help as possible, and use your ears as much as possible, so you're better prepared when you meet Spanish native speakers outside the classroom.

GETTING STARTED

1. Follow this link, and create your Lupa account using your student email address %{invitationUrl}
2. On your smartphone or tablet visit %{lupaUrl} to download Lupa and sign in with the same student email address

In the app you should see that you've joined "%{className}" and you'll be able to see class assignments and instructions.

Hint: if you don't see the class in the Lupa app

- check that you're signed in with the correct email address
- try entering the code %{code} on the account screen

`;
