const __cache = {};

// transform a pattern with * into a regular expression
function patternToRegexp(pattern) {
  const escaped = pattern.replace(/[-[\]{}()+?.,\\^$|#\s]/g, '\\$&');
  return new RegExp(escaped.replace(/\*/g, '.*?'));
}

function match(namespace, pattern) {
  if (typeof __cache[namespace] !== 'undefined') {
    return __cache[namespace];
  }

  let result = null;

  if (namespace.indexOf('*') === -1) {
    result = namespace === pattern;
  } else {
    const regexp = patternToRegexp(pattern);
    result = namespace.match(regexp) !== null;
  }

  __cache[namespace] = result;
}

export default match;
