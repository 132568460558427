import React from 'react';
import { Code, CopyCode } from '../../../ds/common/code';
import styled from 'styled-components';

const Gap = styled.div`
  width: 100%;
  height: 40px;
`;

export const ShowcaseCode = () => {
  const code =
    'JW' +
    Math.floor(Math.random() * 1e8)
      .toString(32)
      .toUpperCase();
  return (
    <>
      <Code code={code} />
      <Gap />
      <CopyCode code={code} />
    </>
  );
};
