import * as React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useForm } from 'react-hook-form';
import { useRootStore } from 'mst';
import { useHistory } from 'react-router-dom';
import { useSwitch } from 'lib/hooks/use-switch';
import { AuthContainer } from 'components/auth/auth-container';
import { AuthFootnote } from 'components/auth/auth-footnote';
import { AuthHeader } from 'components/auth/auth-header';
import { AuthHeading } from 'components/auth/auth-heading';
import { AuthButton } from 'components/auth/auth-button';
import { AuthInput } from 'components/auth/auth-input';
import { AuthSmallPrint } from 'components/auth/auth-small-print';
import { AuthError } from 'components/auth/auth-input';
import { useTitle } from 'lib/hooks/use-title';
import { useAfterAuthRedirection } from './use-after-auth-redirection';
import DialogService from '../../lib/services/dialog-service';
import { getNameValidationRules } from 'lib/validation-rules';
import { getColor, includeTextStyles } from '../../lib/theme/utils';
import __ from 'jw-core/lib/localization';

import styled from 'styled-components';

const ButtonContainer = styled.div`
  margin-top: 24px;
`;

const CheckBoxContainer = styled.div`
  display: flex;
  padding: 8px 0 24px 0;
  @media screen and (min-width: 550px) {
    padding-top: 16px;
  }
  .checkbox {
    display: block;
    position: relative;
    margin: 0 8px 0 0;
    div {
      width: 20px;
      height: 20px;
      border: 1px solid ${getColor('gray-2')};
      background: ${getColor('white')};
      border-radius: 4px;
      cursor: pointer;
    }
    input {
      opacity: 0;
      position: absolute;
      &:hover + div {
        border-color: ${getColor('gray-2')};
      }
      &:focus + div {
        border-width: 2px;
      }
      &:checked + div {
        background: #fff
          url('data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M12.8692%202L14.6667%203.25859L7.32491%2013.7438L1.33337%209.54846L2.59197%207.751L6.78604%2010.6877L12.8692%202Z%22%20fill%3D%22%2319BBD4%22%2F%3E%0A%3C%2Fsvg%3E%0A')
          center center no-repeat;
      }
    }
  }
  label {
    ${includeTextStyles('small-text')}
    color: ${getColor('gray-4')};
    padding: 8px 0;
    margin: -8px 0;
  }
`;

export const SignupWithEmailScreen = observer(() => {
  useTitle('Create account with email');
  useAfterAuthRedirection();

  const history = useHistory();
  const [loading, loadingSwitch] = useSwitch(false);
  const store = useRootStore();
  const { userManager, validationError } = store;

  const [optin, optinSwitch] = useSwitch(true);

  // clean error messages from other screens
  React.useEffect(() => store.clearMessages(), [store]);

  const { handleSubmit, register, errors } = useForm();

  const submitHandler = handleSubmit(({ name, email, password }) => {
    const dismiss = () => {
      loadingSwitch.off();
      DialogService.closeDialog();
    };

    loadingSwitch.on();

    DialogService.openDialog({
      showIcon: false,
      title: 'Please check this email is correct:',
      body: () => {
        return <p className="modal__email-check">{email}</p>;
      },
      okButtonLabel: 'Confirm',
      okButtonAction: () => {
        userManager
          .signup(name, email, password, { mailingListOptIn: optin })
          .then(dismiss);
      },
      cancelButtonLabel: 'Go back',
      cancelButtonAction: dismiss,
      showCancelButton: true,
    });
  });

  const getErrorForKey = key => {
    if (validationError?.key === key) {
      return validationError;
    }

    return null;
  };

  const push = path => () => history.push(path);

  return (
    <AuthContainer>
      <AuthHeader showBackButton onBackButtonClick={push('/signup')}>
        <AuthHeading>Create account</AuthHeading>
      </AuthHeader>
      <form className="form" onSubmit={submitHandler}>
        <AuthInput
          ref={register(getNameValidationRules())}
          name={'name'}
          autoCapitalize="none"
          label={__('First name', 'auth.firstName')}
          error={getErrorForKey('name')}
          data-test-id="first-name"
        />
        {errors.name ? <AuthError error={errors.name}></AuthError> : null}
        <AuthInput
          ref={register}
          name={'email'}
          autoCapitalize="none"
          label={__('Email address', 'auth.emailAddress')}
          error={getErrorForKey('email')}
          data-test-id="email-address"
        />
        <AuthInput
          ref={register}
          name="password"
          type="password"
          autoCapitalize="none"
          error={getErrorForKey('password')}
          label={__('Password', 'auth.password')}
          data-test-id="password"
        />
        <CheckBoxContainer>
          <div className="checkbox">
            <input
              type="checkbox"
              name={'mailingListOptIn'}
              checked={optin}
              onClick={optinSwitch.toggle}
            />
            <div onClick={optinSwitch.toggle}></div>
          </div>
          <label htmlFor="mailingListOptIn" onClick={optinSwitch.toggle}>
            {__(
              'Send me Lupa news, updates, and special offers',
              'sendMeLupaNews'
            )}
          </label>
        </CheckBoxContainer>
        <ButtonContainer>
          <AuthButton
            isLoading={loading}
            title={__('Create account', 'auth.createAccount')}
            onClick={submitHandler}
          />
        </ButtonContainer>
      </form>
      <AuthFootnote>
        Already have an account?{' '}
        <Link to="/login">{__('Sign in', 'auth.signIn')}</Link>
      </AuthFootnote>
      <AuthSmallPrint />
    </AuthContainer>
  );
});
