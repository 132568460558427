/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useRootStore } from '../../truth';
import { AppContainer } from '../account/app-container';
import DialogService from '../../lib/services/dialog-service';

export const CloseAccountScreen = () => {
  const store = useRootStore();
  const { accountData } = store?.userManager ?? {};
  const history = useHistory();

  const handleCloseAccount = () => {
    DialogService.openDialog({
      title: 'Are you sure?',
      body: 'Closing your account can not be undone',
      okButtonLabel: 'Yes, close my account',
      okButtonAction: () => {
        // close account,
        store.userManager.closeAccount().then(() => {
          history.push('/account-closed');
        });
        // then redirect to /account-closed
      },
      okIsDangerous: true,
      cancelButtonLabel: 'Keep account',
      cancelButtonAction: DialogService.closeDialog, //TODO: having to specify this is annoying
      showCancelButton: true,
      showIcon: false,
    });
  };

  return (
    <AppContainer>
      <a href="/account" className="nav-back">
        My Account
      </a>

      <h2 className="close-account__heading">
        <alert-icon className="message__heading__icon" />
        Close account
      </h2>
      <p className="close-account__copy">
        if you close your account you will no longer be able to sign in with{' '}
        {accountData.email} and you will not receive any further account related
        emails.
      </p>
      <div className="close-account__btn-container">
        <a href="/account" className="btn btn--outline btn--round">
          Go back
        </a>

        <a
          href="#"
          className="btn btn--outline btn--round btn--danger"
          onClick={handleCloseAccount}
        >
          Close account
        </a>
      </div>
    </AppContainer>
  );
};
