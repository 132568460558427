import { flow, getEnv, getRoot, types } from 'mobx-state-tree';
import { get, sumBy, isEmpty } from 'lodash';
import moment from 'moment';
import __ from '../lib/localization';
import { getParentOfName } from '../lib/type-utils/get-parent-of-name';
import * as safeTypes from '../lib/safe-mst-types';
import { SORT_ORDER, sortBy } from '../lib/util';
import { END_OF_STORY_CHAPTER } from '../lib/constants/vars';

import { StoryCatalogData } from '../catalog';
import { StoryDownload } from '../download-manager';
import { StoryProgress } from '../user-manager';
import invariant from '../lib/invariant';

const sortByTitle = sortBy('catalogData.title');
const sortByDuration = sortBy('catalogData.durationMinutes');
// switch to millis once schema update propagated to local store
// const sortByDuration = sortBy('catalogData.durationMillis');
const sortByMostRecent = sortBy('catalogData.releaseDate', SORT_ORDER.DESC);
const sortByOriginalBroadcastDate = sortBy('catalogData.originalBroadcastDate');
const sortByAssignmentDueDate = sortBy('assignment.dueDate');

// Note, this map is a peer of storySortLabels, using the same storySortKeys
export const storySortParams = {
  assignmentDueDate: { sortFn: sortByAssignmentDueDate, sectionKey: 'all' },
  mostRecent: { sortFn: sortByMostRecent, sectionKey: 'all' },
  title: { sortFn: sortByTitle, sectionKey: 'all' },
  duration: { sortFn: sortByDuration, sectionKey: 'all' },
  country: { sortFn: sortByMostRecent, sectionKey: 'countries' },
  topic: { sortFn: sortByMostRecent, sectionKey: 'topics' },
  originalBroadcast: { sortFn: sortByOriginalBroadcastDate, sectionKey: 'all' },
};

/**
 * Story
 *
 * Transient story representation which wraps each of our three story data aspects:
 *   StoryCatalogData
 *   StoryDownload
 *   StoryProgress
 */
export const Story = types
  .model('Story', {
    slug: safeTypes.identifierDefaultBlank,
    catalogData: types.optional(
      types.maybeNull(types.late(() => StoryCatalogData)),
      {}
    ),
    download: types.optional(
      types.maybeNull(types.late(() => StoryDownload)),
      {}
    ),
    progress: types.optional(
      types.maybeNull(types.late(() => StoryProgress)),
      {}
    ),
  })
  .volatile(() => ({
    /**
     * keeps a reference to the full story data
     * not serializable
     */
    fullData: null,

    // keep this always in memory once story/volume screen first visited so that
    // vocab view will work regardless of fullData availability
    vocabLookupData: {},
  }))
  .views(self => ({
    get $log() {
      const { createLogger = () => {} } = getEnv(self);
      const log = createLogger('sm:story');
      return log;
    },
    get $downloader() {
      const { downloader = {} } = getEnv(self);
      return downloader;
    },
  }))
  .actions(self => {
    // beware, this was null for the very first attempt to open a chapter after a clean install
    // const { downloader } = getEnv(self);

    // tiny semaphore to safely open the data eagerly
    const [CLOSED, OPENING, OPEN] = [-1, 0, 1];

    // @armando. what's the lifecycle of this state? i'm not exactly sure for
    // what scenario you added this, but i was seeing some inconsistent
    // behavior when testing from the mst-proto console.
    let status = CLOSED;
    return {
      /**
       * Gets the downloaded file from disk that corresponds to the URL
       */
      open: flow(function* open() {
        self.$log.info(`story.open - status: ${status}`);
        self.$log.info('story.open - log() test');
        if (status === OPENING) {
          self.$log.info('story.open() - already OPENING - ignoring');
          return;
        }

        if (status === OPEN) {
          if (self.isCurrentAndLatest) {
            if (self.fullData) {
              self.$log.info('ignoring redundant story.open()');
              return;
            }
            self.$log.info('reopening story with full data');
          } else {
            self.$log.warn(
              'story.open() - another story was already open - closing first'
            );
            self.close();
          }
        }

        status = OPENING;
        if (self.download.isFullDataAvailable) {
          // const downloader = getEnv(self).downloader;
          invariant(self.$downloader, "story.open - 'downloader' unassigned");

          self.fullData = yield self.$downloader.open(
            self.download.fullDataAsset
          );
          if (isEmpty(self.vocabLookupData)) {
            self.$log.info(`story.open - assigning vocabLookupData`);
            self.vocabLookupData = JSON.parse(
              JSON.stringify(self.fullData.vocabs)
            );
          }
          self.progress.pruneOrphanVocabs();
        }
        const manager = getParentOfName(self, 'StoryManager');
        // eslint-disable-next-line no-unused-expressions
        manager?.setCurrentStory(self);
        status = OPEN;
      }),

      ensureVocabLookupData: flow(function* ensureVocabLookupData() {
        // self.$log.info(`story[${self.slug}].ensureVocabLookupData - entering - vocab count: ${self.vocabCount}`);

        // paranoia guard (should already get skipped at the volume level)
        if (!self.download?.fullDataAsset?.isDownloaded) {
          return; // nop unless already downloaded
        }

        // todo, clear this if new version of fullData is retrieve from the server
        // self.$log.debug(`story[${self.slug}].ensureVocabLookupData - present: ${!isEmpty(self.vocabLookupData)}`);
        if (!isEmpty(self.vocabLookupData)) {
          return;
        }

        // todo: update server catalog generation to move this to a separate blob.
        // right now if you open the vocab panel right away after opening a
        // story, you won't see the vocab list because it's still loading

        // do we need another semaphore here?
        self.$log.info(`story[${self.slug}].ensureVocabLookupData - fetching`);
        const fullData = yield self.$downloader.open(
          self.download.fullDataAsset
        );
        if (fullData) {
          // not sure if the deep clone is needed, but wasn't sure how garbage collection
          // would work if we just snag a reference from the full fullData
          self.vocabLookupData = JSON.parse(JSON.stringify(fullData.vocabs));
        }
      }),

      /**
       * Resets the fullData.
       * Not sure if necessary, but I think this will help keeping memory clean
       */
      close() {
        self.fullData = null;
        const manager = getParentOfName(self, 'StoryManager');
        // eslint-disable-next-line no-unused-expressions
        manager?.setCurrentStory(null);
        status = CLOSED;
      },

      chapter(chapterPosition) {
        self.$log.debug(
          `chapter(${chapterPosition} - chapters.length: ${self.catalogData.chapters.length}`
        );
        return self.catalogData.chapters[chapterPosition - 1] || null;
      },

      // used to skip forward or back to future/past units within the volume
      markCurrentUnit() {
        self.volume.markCurrentUnit(self);
      },

      exportVocab: flow(function* exportVocab() {
        if (self.vocabCount) {
          yield getRoot(self)?.userManager?.exportVocab(self.slug);
        } else {
          self.$log.info(
            `story[${self.slug}.exportVocab - no vocab saved, skipping`
          );
        }
      }),
    };
  })
  .views(self => ({
    get unplayed() {
      return self.progress.unplayed;
    },

    get played() {
      return self.progress.played;
    },

    get inProgress() {
      return self.progress.inProgress;
    },

    // substate of 'completed'
    get relistening() {
      return self.progress.relistening;
    },

    // drives the "continue" CTA label
    get listening() {
      return self.progress.listening;
    },

    get downloaded() {
      return self.download.isDownloaded && !self.locked;
    },

    // a unit which is visually presented as 'locked' within a series on the unit cards
    // beware, this concept of 'locked' is distinct from volumes without access
    // and actually only applied to volumes for which access is available
    get forwardUnit() {
      return (
        self.volume?.multiUnit &&
        self.volume?.ready &&
        self.catalogData?.unitNumber > self.volume?.furthestUnitNumber &&
        !self.isCurrentUnit
      );
    },

    // the furthest unit which has been listened to
    get isFurthestUnit() {
      return self.catalogData?.unitNumber === self.volume?.furthestUnitNumber;
    },

    get completed() {
      return self.progress.completed;
    },

    get status() {
      return `${self.download.status} - ${self.progress.status}`;
    },

    get isCurrent() {
      const manager = getParentOfName(self, 'StoryManager');
      return manager?.currentStory === self;
    },

    // eligible to be studied, used to determine if unit card progress bar visibility
    get ready() {
      return self.volume?.ready && !self.forwardUnit;
    },

    // is latest version of current full data cached
    get isCurrentAndLatest() {
      return (
        self.isCurrent &&
        self.fullData &&
        self.fullData.version === self.catalogData.version
      );
    },

    // deprecated
    get progressBar() {
      return self.studyProgressRatio;
    },

    // todo: revisit this
    get minutesRemaining() {
      const { catalogData, progress } = self;

      const { chapter: chapterPosition, millisPlayed } = progress.furthestPoint;
      const pastMillisPlayed =
        self.catalogData.chapters
          .slice(0, chapterPosition - 1)
          .reduce((sum, chapter) => sum + chapter.durationMillis, 0) +
        millisPlayed;

      const oneMinuteInMillis = 60 * 1000;

      const minutesRemaining =
        (catalogData.durationMinutes * oneMinuteInMillis - pastMillisPlayed) /
        oneMinuteInMillis;
      return Math.round(minutesRemaining);
    },

    get initialCarouselIndex() {
      const initialIndex = self.progress.completed
        ? self.chapterCount
        : self.progress.currentPoint.chapter - 1;

      return initialIndex;
    },

    get furthestChapter() {
      return self.chapter(self.progress.furthestPoint.chapter);
    },

    get currentChapterPosition() {
      return self.progress.currentPoint.chapter;
    },

    get currentChapter() {
      return self.chapter(self.progress.currentPoint.chapter);
    },

    chapter(position) {
      if (position === END_OF_STORY_CHAPTER) {
        return null;
      } else {
        if (position > self.catalogData.chapters.length || position < 1) {
          invariant(
            false,
            `${self.slug}.chapter(${position}) - invalid position`
          );
          return null;
        }
        return self.catalogData.chapters[position - 1];
      }
    },

    get atEndOfStory() {
      return self.progress?.currentPoint?.atEndOfStory;
    },

    get chapterCount() {
      return self.catalogData.chapters.length;
    },

    // used in jw app dashboard
    get timeLeftMinutes() {
      return Math.ceil(self.timeLeftMillis / (60 * 1000));
    },

    get timeLeftMillis() {
      const currentChapterPosition = self.progress?.currentPoint?.chapter ?? 0; // chapterPosition isn't 0 indexed
      const unplayedChapters = self.catalogData?.chapters?.slice(
        currentChapterPosition + 1
      );
      /// the sum of the durations of all the unplayed chapters
      const unplayedDurationMillis = sumBy(
        unplayedChapters,
        ch => ch.durationMillis
      );
      /// plus the remaining of the current chapter
      return (
        unplayedDurationMillis +
        self.chapter(currentChapterPosition)?.timeLeftMillis
      );
      // return self.durationMillis - story.progress.currentPoint.millisPlayed;
    },

    get studyProgressRatio() {
      if (self.progress.completed) {
        return 1;
      }
      let chapterProgress = self.progress.furthestPoint.chapter - 1;
      if (self.progress.furthestPoint.iteration > 1) {
        chapterProgress = chapterProgress + 0.5;
      }
      return chapterProgress / self.catalogData.numberOfChapters;

      // not sure why the furthestPlayProgress was always 0 in web-proto, revisit later
      // const furthestChapter = self.furthestChapter;
      // if (furthestChapter) {
      //   return (furthestChapter.position - 1 + furthestChapter.furthestPlayProgress) / self.catalogData.numberOfChapters;
      // } else {
      //   return 0;
      // }
    },

    // this version is more convenient for the web-proto
    get studyProgressPercentage() {
      return Math.round(self.studyProgressRatio * 100);
    },

    get totalPoints() {
      return self.progress?.listeningStats?.totalPoints;
    },

    get vocabCount() {
      return self.progress?.vocabsCount;
    },

    get vocabCountDescription() {
      return __('%{count} items', 'vocab.itemsCount', {
        count: self.vocabCount,
      });
    },

    get listeningStats() {
      return self.progress?.listeningStats;
    },

    get locked() {
      const root = getRoot(self);
      if (get(root, ['userManager', 'accountData', 'fullAccess'])) {
        return false;
      }
      const unlockedSlugs = get(root, [
        'userManager',
        'accountData',
        'unlockedStorySlugs',
      ]);
      if (unlockedSlugs && unlockedSlugs.includes(self.slug)) {
        return false;
      }
      if (self.volume?.isUnlocked) {
        return false;
      }
      return !self.catalogData.trial;
    },

    get vocabViewData() {
      return self.progress.vocabsViewData;
    },

    // someday we might separate out the vocablookup data, but for now
    // rely on the fulldata being downloaded
    get vocabLookupDataAvailable() {
      // return !isEmpty(self.vocabLookupData);
      return self.download?.isFullDataAvailable;
    },

    get showResetStory() {
      return !self.progress.unplayed;
    },

    get showMarkComplete() {
      return !self.progress.completed && !self.locked;
    },

    get showDownload() {
      return self.download.isNotDownloaded && !self.locked;
    },

    get showCancelDownload() {
      return self.download.isQueued;
    },

    get showRemoveDownload() {
      return self.download.isDownloaded;
    },

    get showUpdateAudio() {
      return self.download.isAudioUpdateAvailable;
    },

    get isNew() {
      const manager = getRoot(self).storyManager;
      const { newThisWeek } = manager;

      return newThisWeek.includes(self);
    },

    get isReleased() {
      const manager = getRoot(self).storyManager;
      const { currentDate } = manager;
      const today = moment(currentDate);
      const storyDate = moment(self.catalogData.releaseDate);
      return storyDate.isSameOrBefore(today);
    },

    // links to the matching classroom assignment if this story is included in any joined classrooms
    // if story included in multiple classrooms, last one wins
    // (not going to worry about fully handling that edge case for now)
    get assignment() {
      let result = null;
      getRoot(self).userManager.accountData.joinedClassrooms.forEach(
        classroom => {
          const match = classroom.assignmentForSlug(self.slug);
          if (match) {
            result = match;
          }
        }
      );
      return result;
    },

    get volume() {
      return getRoot(self)?.storyManager?.volumes?.find(volume => {
        return volume.slug === self.catalogData.volumeSlug;
      });
    },

    get unitNumber() {
      return self.catalogData?.unitNumber;
    },

    get isCurrentUnit() {
      return self.volume?.currentUnit?.slug === self.slug;
    },

    get title() {
      if (self.volume?.multiUnit) {
        // beware, can't use 'self' on mst modals except for the core properties
        return __('Ep %{unitNumber}. %{baseTitle}', 'story.multiEpisodeTitle', {
          unitNumber: self.unitNumber,
          baseTitle: self.baseTitle,
        });
      }
      return self.baseTitle;
    },

    get baseTitle() {
      return self.catalogData?.title;
    },

    // get titlePrefix() {
    //   return self.catalogData?.titlePrefix;
    // },

    get tagline() {
      return self.catalogData?.localizedTagline;
    },

    get description() {
      return self.catalogData?.localizedDescription;
    },

    get durationDescription() {
      return self.catalogData?.durationDescription;
    },

    get thumbImagePath() {
      return self.download?.listImagePath;
    },

    get bannerImagePath() {
      if (self.volume) {
        return self.volume.thumbImagePath;
      } else {
        return self.download?.bannerImagePath;
      }
    },

    resolveSpeakerData(label) {
      return self.catalogData?.resolveSpeakerData(label);
    },
  }));
