import React from 'react';

export const AccountIcon = props => {
  return (
    <svg width={24} height={24} fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 12c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12zM3.508 18.992A10.955 10.955 0 011 12C1 5.925 5.925 1 12 1s11 4.925 11 11c0 2.656-.941 5.092-2.508 6.992C18.435 15.68 15.59 14 12 14c-3.59 0-6.435 1.68-8.492 4.992zM12 14a5 5 0 100-10 5 5 0 000 10z"
        fill="#333"
      />
    </svg>
  );
};
